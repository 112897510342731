﻿import React, { createContext, useContext } from 'react';
import { IListingRequest } from '@vivli/features/listing-request/infrastructure/interface';
import { UseFormReturn } from 'react-hook-form';
import { ListingRequestStatusEnum } from '@vivli/features/listing-request/infrastructure/enum';
import { IStudy } from '@vivli/features/studies/infrastructure/interface';

interface IListingRequestContext {
    listingRequest?: IListingRequest;
    setListingRequest: (listingRequest: IListingRequest) => void;
    isLoading: boolean;
    handleFormSave: (
        formApi: UseFormReturn<IListingRequest, object>,
        isSubmit: boolean,
        nextStatus?: ListingRequestStatusEnum,
        nextEvent?: () => void
    ) => void;
    handleStatusUpdate: (listingRequest: IListingRequest, status: ListingRequestStatusEnum) => void;
    isReadOnly: boolean;
    setIsReadOnly: React.Dispatch<React.SetStateAction<boolean>>;
    handleNext: (formApi: UseFormReturn<IListingRequest, object>, parsedTitle: string) => void;
    isEditable: boolean;
    setIsEditable: (value: boolean) => void;
}

export const ListingRequestContext = createContext<IListingRequestContext>(null);

export const useListingRequestContext = () => useContext(ListingRequestContext);
