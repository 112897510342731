﻿import React, {CSSProperties, useEffect, useState} from 'react';
import {Color, Size} from '@vivli/shared/theme';
import {useAssignedAppType} from "@vivli/core/infrastructure/hook";
import {AssignedAppTypeEnum} from "@vivli/shared/infrastructure/enum";
import {useRequestedStudyBehavior} from "@vivli/features/studies/infrastructure/hook";

const detailContainerStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    width: '30%',
    minWidth: '392px',
    marginLeft: '-50px'
}

const fieldBodyStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    fontSize: Size.FontSize.Medium,
    flexWrap: 'wrap'
}

const labelStyle: CSSProperties = {
    color: Color.DARK_GRAY,
    marginRight: '10px',
    whiteSpace: 'pre-wrap',
}

interface AdvancedOptionsDetailComponentProps {
    isThisRequestOnlySetting: boolean;
    isDownloadableSetting: boolean;
    isSkipIrpReviewSetting: boolean;
    templateDataUploaded: boolean;
    isVivliAdmin: boolean;
}

export const AdvancedOptionsDetailComponent = (
    {   isThisRequestOnlySetting,
        isDownloadableSetting,
        isSkipIrpReviewSetting,
        templateDataUploaded,
        isVivliAdmin}: AdvancedOptionsDetailComponentProps) => {

    const assignedAppType = useAssignedAppType();
    const isAmr = assignedAppType === AssignedAppTypeEnum.Amr;
    const {optionOnTitlesConstants, optionOffTitlesConstants} = useRequestedStudyBehavior();
    const [thisRequestOnlyText, setThisRequestOnlyText] = useState('');
    const [downloadableText, setDownloadableText] = useState('');
    const [skipIrpReviewText, setSkipIrpReviewText] = useState('');


    useEffect(() => {
        const templateDataUploadedText = templateDataUploaded
            ? 'Data has been loaded for this and future requests'
            : 'Data will be loaded for this and future requests'
        const troText = isThisRequestOnlySetting
            ? optionOnTitlesConstants.thisRequestOnlyIpd
            : templateDataUploadedText // in non-TRO case, exact text varies
                                       // depending on upload status of template data
        setThisRequestOnlyText(troText);
        const downloadText = isDownloadableSetting
            ? optionOnTitlesConstants.downloadable
            : optionOffTitlesConstants.downloadable
        setDownloadableText(downloadText);
        const skipText = isSkipIrpReviewSetting
            ? optionOnTitlesConstants.skipIrpReview
            : optionOffTitlesConstants.skipIrpReview
        setSkipIrpReviewText(skipText)

    }, [isThisRequestOnlySetting, isDownloadableSetting, isSkipIrpReviewSetting, templateDataUploaded])

    return (
        <div style={detailContainerStyle}>
            <div style={fieldBodyStyle}>
                <div>
                    <span style={labelStyle}>
                            {thisRequestOnlyText}
                    </span>
                </div>
                {!isAmr && <div>
                        <span style={labelStyle}>
                                {downloadableText}
                        </span>
                    </div>
                }
                {isVivliAdmin && <div>
                    <span style={labelStyle}>
                            {skipIrpReviewText}
                    </span>
                </div>}
            </div>
        </div>
    )
}
