﻿import React, { useEffect } from 'react';
import loadScript from 'load-script';
import { Size } from '@vivli/shared/theme';
import {
  IAnnotationWidgetConfig,
  IModalApi,
} from '@vivli/shared/infrastructure/interface';

/*
 *
 * This was left as a class style and only moved into the new area instead
 * due to the way it interacts with 3rd party scripts.
 *
 * */

interface AnnotatorProps {
  annotation: object;
  updatePicoOutput: any;
  modalService: IModalApi;
  annotatorWidgetOptions: IAnnotationWidgetConfig;
}

const options: any = {
  baseUri: 'http://data.vivli.org',
  subject: {
    '@id': 'http://data.vivli.org/articles/123556',
    '@type': 'http://data.vivli.org/Article',
  },
  graphSearchEndpointUrl: '',
  idsEndpointUrl: '',
};

export const Annotator = ({
  annotation,
  updatePicoOutput,
  modalService,
  annotatorWidgetOptions,
}: AnnotatorProps) => {
  const annotator = '#anntr'; //this is the name of annotator outermost div
  let $annotator: any;

  const loadScriptAsync = (url: string, sri?: string) => {
    return new Promise((resolve, reject) => {
      loadScript(
        url,
        sri
          ? {
              attrs: {
                integrity: sri,
                crossorigin: 'anonymous',
              },
            }
          : null,
        (err: any, script: any) => {
          if (err) {
            reject(err);
          } else {
            resolve(script);
          }
        }
      );
    });
  };

  const loadAnnotation = (annotation) => {
    try {
      if (annotation) {
        const isEntityRef = (obj) =>
          typeof obj === 'string' &&
          obj.startsWith('http://data.cochrane.org/concepts/');

        const injectLabels = (unlabeledAnnotation, callback) => {
          const conceptUris = {};
          const pico = unlabeledAnnotation['@graph'].referencesConcept;
          assignConceptUris(pico);
          const conceptUriKeys = Object.keys(conceptUris);
          if (conceptUriKeys.length > 0) {
            (window as any).$.get(
              `https://cloud.cochrane.org/concepts?ids=${conceptUriKeys.join(
                ','
              )}`,
              (res) => {
                if (res.length > 0) {
                  res.forEach((entry) => {
                    const id = entry['@id'];
                    if (conceptUris[id] !== undefined) {
                      conceptUris[id] = {
                        '@id': id,
                        label: entry.label,
                      };
                    }
                  });
                }
                const conceptLabels = assignConceptLabels(pico, conceptUris);
                const graph = {
                  ...unlabeledAnnotation['@graph'],
                  referencesConcept: conceptLabels,
                };
                const expandedAnnotation = {
                  ...unlabeledAnnotation,
                  '@graph': graph,
                };
                callback(null, expandedAnnotation);
              }
            );
          }

          function assignConceptUris(graph) {
            Object.keys(graph).forEach((key) => {
              if (isEntityRef(graph[key])) {
                conceptUris[graph[key]] = {};
              } else if (typeof graph[key] === 'object') {
                assignConceptUris(graph[key]);
              }
            });
          }

          function assignConceptLabels(graph: object, concepts: object) {
            const newGraph = JSON.parse(JSON.stringify(graph));
            Object.keys(newGraph).forEach((key) => {
              if (isEntityRef(newGraph[key])) {
                if (
                  newGraph[key] ===
                  'http://data.cochrane.org/concepts/not-reported'
                ) {
                  if (key === '@id') {
                    newGraph['@type'] = 'Concept';
                    newGraph.label = 'Not reported';
                  } else {
                    newGraph[key] = {
                      '@id': 'http://data.cochrane.org/concepts/not-reported',
                      '@type': 'Concept',
                      label: 'Not reported',
                    };
                  }
                } else {
                  newGraph[key] = concepts[graph[key]];
                }
              } else if (typeof graph[key] === 'object') {
                newGraph[key] = assignConceptLabels(newGraph[key], concepts);
              }
            });
            return newGraph;
          }
        };
        injectLabels(annotation, (err, expandedAnnotation) => {
          $annotator.picofy('setAnnotation', expandedAnnotation);
        });
      }
    } catch (e: any) {
      modalService.error('Error loading cochrane annotator: ' + e.message);
    }
  };

  const handleAnnotation = (e, annotation) => {
    try {
      if (annotation) {
        updatePicoOutput(annotation);
        loadAnnotation(annotation);
      }
    } catch (err: any) {
      if (err) {
        // console.log(err.message);
        modalService.error(
          'Error saving annotation. Please copy the contents of this message and send to Vivli. ' +
            err.message
        );
      }
    }
  };

  useEffect(() => {
    (async () => {
      if ((window as any).$ === undefined) {
        await loadScriptAsync(
          annotatorWidgetOptions.jqueryUrl,
          annotatorWidgetOptions.jqueryUrlSha
        );
      }
      if ((window as any).$('document').picofy === undefined) {
        await loadScriptAsync(annotatorWidgetOptions.widgetUrl);
      }
      options.graphSearchEndpointUrl =
        annotatorWidgetOptions.cochraneSearchEndpointUrl;
      options.idsEndpointUrl =
        annotatorWidgetOptions.cochraneIdentityEndpointUrl;
      $annotator = (window as any).$(annotator);
      $annotator.on('pico:annotated', handleAnnotation);
      $annotator.picofy(options);
      if (annotation) {
        loadAnnotation(annotation);
      }
    })();

    //this function runs before component is destroyed
    return cleanUp;
  }, []);

  const cleanUp = () => {
    if ($annotator) {
      $annotator.off('pico:annotated', handleAnnotation);
      const picofied = (window as any).$('.picofied');
      if (picofied.length) {
        picofied.remove();
      }
    }
  };

  return (
    <div className="annotatorHost" style={{ marginBottom: Size.PADDING }}>
      <link rel="stylesheet" href={annotatorWidgetOptions.stylesheetUrl} />
      <div id="anntr"></div>
    </div>
  );
};
