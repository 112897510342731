import React, {createContext, useContext} from "react";
import {ISafetyConcern} from "@vivli/features/data-requests/infrastructure/interface";

interface ISafetyConcernsContext {
    safetyConcerns: ISafetyConcern[];
    addSafetyConcern: (safetyConcern: ISafetyConcern) => void;
}

export const SafetyConcernsContext = createContext<ISafetyConcernsContext>(null);

export const useSafetyConcernsContext = () => useContext(SafetyConcernsContext);


