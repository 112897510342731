import {AnalysisPurposeEnum} from "@vivli/features/data-requests/infrastructure/enum";

export function useAnalysisPurposeTextMap(): (purpose: AnalysisPurposeEnum) => string {
    const mapText = (purpose: AnalysisPurposeEnum) => {
        switch (purpose) {
            case AnalysisPurposeEnum.NewResearchTreatmentEffectiveness:
                return 'New research question to examine treatment effectiveness on secondary endpoints and/or within subgroup populations';
            case AnalysisPurposeEnum.NewResearchTreatmentSafety:
                return 'New research question to examine treatment safety';
            case AnalysisPurposeEnum.ConfirmingResearchTreatmentEffectiveness:
                return 'Research that confirms or validates previously conducted research on treatment effectiveness';
            case AnalysisPurposeEnum.ConfirmingResearchTreatmentSafety:
                return 'Research that confirms or validates previously conducted research on treatment safety';
            case AnalysisPurposeEnum.PreliminaryResearch:
                return 'Preliminary research to be used as part of a grant proposal';
            case AnalysisPurposeEnum.SummaryLevelDataMetaAnalysis:
                return 'Summary-level data meta-analysis'
            case AnalysisPurposeEnum.ParticipantLevelDataMetanalysis:
                return 'Participant-level data meta-analysis'
            case AnalysisPurposeEnum.SupportClinicalTrialDesign:
                return 'Support clinical trial design';
            case AnalysisPurposeEnum.StatisticalMethods:
                return 'Statistical methods';
            case AnalysisPurposeEnum.TrainingTesting:
                return 'Training/Testing';
            case AnalysisPurposeEnum.Other:
                return 'Other';
        }
        return purpose;
    }

    return mapText;
}
