import { AdminHeaderComponent } from '../header/admin-header.component';
import { AdminHeaderTitleComponent } from '../header/admin-header-title.component';
import React, { CSSProperties, ReactElement, useEffect, useState } from 'react';
import { Color, Styles } from '@vivli/shared/theme';
import { TabButtonsComponent } from './tab-buttons.component';
import { TabComponent } from './tab.component';
import { TabButtonContainerComponent } from './tab-button-container.component';

const tabContainerStyle: CSSProperties = {
    position: 'relative',
    height: '100%',
    width: '100%',
    overflow: 'auto',
};

const tabStyle: CSSProperties = {
    position: 'absolute',
    height: '100%',
    width: '100%',
    padding: '5px 0',
};

const tabContentHiderStyle: CSSProperties = {
    position: 'absolute',
    height: '100%',
    width: '100%',
    zIndex: 1,
    backgroundColor: '#e9eaef',
};

interface TabsPageComponentProps {
    title?: string;
    subTitle?: string;
    defaultTab?: number;
    children: ReactElement<typeof TabComponent>[] | ReactElement<typeof TabComponent>;
    commandTitle?: string;
    commandOnClick?: () => void;
    commandImage?: string;
    commandOptions?: { title: string; onClick?: () => void }[];
    hideBarIfSingleTab?: boolean;
}

export const TabsComponent = ({
    title,
    subTitle,
    defaultTab,
    children,
    commandImage,
    commandOnClick,
    commandOptions,
    commandTitle,
    hideBarIfSingleTab = false,
}: TabsPageComponentProps) => {
    const [selectedTab, setSelectedTab] = useState(defaultTab || 0);
    const [tabs, setTabs] = useState<ReactElement<typeof TabComponent>[]>();

    const handleTabClick = (tabIndex: number) => {
        setSelectedTab(tabIndex);
        const onClickFunc = tabs[tabIndex].props['onTabClick'];
        onClickFunc && onClickFunc();
    };

    const getActiveChild = () => {
        if (!tabs) {
            return null;
        }

        return tabs[selectedTab];
    };

    const getRightHeaderContent = () => {
        return getActiveChild()?.props['rightHeaderContent'];
    };

    const showTabBar = () => {
        return !hideBarIfSingleTab || tabs?.length > 1;
    };

    const containerStyle = (): CSSProperties => {
        const hasTitle = title !== undefined && title !== null;
        return {
            height: '100%',
            display: 'grid',
            gridTemplateRows: `${hasTitle ? 'auto ' : ''}${showTabBar() ? 'auto ' : ''}1fr`,
        };
    };

    useEffect(() => {
        if (selectedTab === defaultTab) {
            return;
        }

        setSelectedTab(defaultTab);
    }, [defaultTab]);

    useEffect(() => {
        if (Array.isArray(children)) {
            setTabs(children.filter((c) => c));
        } else {
            setTabs([children]);
        }
    }, [children]);

    return (
        <div style={containerStyle()}>
            {title && (
                <AdminHeaderComponent style={{ marginLeft: 0, marginRight: 0 }}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <AdminHeaderTitleComponent title={title} />
                        {subTitle && <div style={{ color: Color.DARK_GRAY }}>{subTitle}</div>}
                    </div>
                </AdminHeaderComponent>
            )}
            {showTabBar() && (
                <div style={{ ...Styles.CENTERED_FLEX, width: '100%', position: 'relative' }}>
                    <TabButtonsComponent
                        outerClassName="do-not-print"
                        outerStyle={{ width: '100%' }}
                        rightHeaderContent={getRightHeaderContent()}
                        commandImage={commandImage}
                        commandOnClick={commandOnClick}
                        commandOptions={commandOptions}
                        commandTitle={commandTitle}
                    >
                        {tabs
                            ?.filter((t) => t)
                            .map((tab, i) => (
                                <TabButtonContainerComponent
                                    key={i}
                                    title={tab.props['title']}
                                    isSelected={selectedTab === i}
                                    disabled={tab.props['disabled']}
                                    onClick={() => handleTabClick(i)}
                                    hint={tab.props['hint']}
                                    count={tab.props['count']}
                                    countSubject={tab.props['countSubject']}
                                />
                            ))}
                    </TabButtonsComponent>
                </div>
            )}
            <div style={tabContainerStyle}>
                <div style={tabContentHiderStyle}></div>
                {tabs
                    ?.filter((t) => t)
                    .map((child, i) => (
                        <div
                            style={{
                                ...tabStyle,
                                zIndex: selectedTab === i ? 2 : 0,
                                height: selectedTab === i ? '100%' : 0,
                                overflow: selectedTab === i ? 'visible' : 'hidden',
                            }}
                            key={i}
                        >
                            {child}
                        </div>
                    ))}
            </div>
        </div>
    );
};
