﻿import {ICitation} from "@vivli/shared/features/citations/infrastructure/interface";
import {CitationComponent} from "@vivli/shared/features/citations/components";
import {useCitationsContext} from "@vivli/shared/features/citations/infrastructure/context";
import {DeleteButtonComponent, EditButtonComponent} from "@vivli/shared/components";
import React, {CSSProperties, useState} from "react";
import {useModalService} from "@vivli/shared/infrastructure/context";
import {IModalOptions} from "@vivli/shared/infrastructure/interface";
import {EditCitationFormComponent} from "./edit-citation-form.component";


const citationRowGeneric: CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
}

const citationSection: CSSProperties = {
    ...citationRowGeneric,
    flex: '1 1 80%',
    maxWidth: '80%',
    width: 'auto',
    marginLeft: '10px',
}

const citationRowButtons: CSSProperties = {
    ...citationRowGeneric,
    flex: '1 1 20%',
    maxWidth: '20%',
    marginTop: '15px',
    marginBottom: '10px',
    marginRight: '10px',
    paddingRight: '15px',
    justifyContent: 'space-between',
}

const wrapperStyle = {
    padding: '15px',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'rgb(233, 234, 239)',
} as React.CSSProperties;

interface CitationContainerComponentProps {
    citation: ICitation;
    dataRequestDoiStem: string;
}

export const CitationContainerComponent = ({citation, dataRequestDoiStem,
                                           }: CitationContainerComponentProps) => {

    const { updateCitation, canDeleteOrEdit, removeCitation, isStudy} = useCitationsContext()
    const canModify = canDeleteOrEdit() && !isStudy;
    const modal = useModalService();
    const [isRemovingCitation, setIsRemovingCitation] = useState(false);

    const handleRemoveButtonClick = (e) => {
        e.stopPropagation();
        setIsRemovingCitation(true);
        const message = `Are you sure you want to remove the citation ${citation?.title} from the data request?`;
        modal.confirm(message, {
            onConfirm: () => {
                setIsRemovingCitation(true)
                removeCitation(citation.id, dataRequestDoiStem);
                setIsRemovingCitation(false);
            },
            onCancel: () => setIsRemovingCitation(false),
        });
    };

    const handleUpdateButtonClick = (e) => {
        e.stopPropagation();
        const options : IModalOptions = {
            style: wrapperStyle
        }
        const modalId = modal.custom(<EditCitationFormComponent
            citation={citation}
            onCancel={() => modal.dismiss(modalId)}
            onSave={(citation) => {
                modal.dismiss(modalId);
                updateCitation(citation, dataRequestDoiStem);
            }}/>, options)
    };


    return (
        <div className="citation-container">
            <div className="citation-row-body" style={citationRowGeneric}>
                <div className="citation-section" style={citationSection}>
                    <CitationComponent
                        citation={citation}
                    />
                </div>
                {canModify &&
                    <div className="citation-modify-buttons" style={citationRowButtons}>
                        <EditButtonComponent style={{width: 'auto'}} onClick={(e) => handleUpdateButtonClick(e)}/>
                        <DeleteButtonComponent onClick={(e) => handleRemoveButtonClick(e)}/>
                    </div>
                }
            </div>
        </div>
    )
}
