﻿import {ButtonComponent, TextFieldComponent,} from "@vivli/shared/components";
import React, {CSSProperties, useState} from "react";
import {Color, Size, Styles} from "@vivli/shared/theme";
import {DTIAMRCommonConst, DTIFormFields} from "@vivli/shared/infrastructure/constants";

const containerStyle: CSSProperties = {
    padding: '40px',
    display: 'grid',
    gridTemplateRows: 'auto auto auto',
    rowGap: '10px',
    minWidth: '500px',
};

const buttonContainerStyle: CSSProperties = {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    columnGap: '10px',
};

const genericStyle: CSSProperties = {
    marginTop: Size.FIELD_SEPARATION,
    marginBottom: Size.FIELD_SEPARATION,

}

export const oneAcrossStyleNoBox: CSSProperties = {
    ...genericStyle,
    flex: '1 1 100%',
    maxWidth: '100%',
}

export const titleRowStyle: CSSProperties = {
    ...Styles.FORM_ROW,
    color: Color.VIVLI_DARK_BLUE,
}

interface AddCitationComponentProps {
    onSave: (citationDoi: string) => void
    onCancel: () => void;
}

export const AddCitationComponent = ({onSave, onCancel}: AddCitationComponentProps) => {

    const [citationDoi, setCitationDoi] = useState<string>('');
    const handleOnSave = () => {
        onSave(citationDoi);
    };

    return (

        <div style={containerStyle}>
            <div style={titleRowStyle}>
                Add a New Citation
            </div>
            <div style={oneAcrossStyleNoBox}>
                <TextFieldComponent value={citationDoi}
                                    style={oneAcrossStyleNoBox}
                                    label={'Citation DOI (e.g. 10.xxxx/xxxxx)'}
                                    dataId={DTIFormFields.FormStudyDoi}
                                    onChange={(e) => setCitationDoi(e.target.value)}
                />

            </div>
            <div style={buttonContainerStyle}>
                <ButtonComponent style={buttonContainerStyle}
                                    onClick={handleOnSave}
                                    dataId={DTIAMRCommonConst.AddButton}
                                    disabled={citationDoi.length === 0}>
                                    Add
                </ButtonComponent>
                <ButtonComponent dataId={DTIAMRCommonConst.CancelButton} style={buttonContainerStyle} onClick={onCancel}>Cancel</ButtonComponent>
            </div>
        </div>


    );

}
