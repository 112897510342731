import React, {CSSProperties} from 'react';
import {FieldHeaderComponent} from "@vivli/shared/components";
import {Size} from "@vivli/shared/theme";

const containerStyle: CSSProperties = {
    margin: 'auto',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}

const headerStyle: CSSProperties = {
    fontSize: Size.FontSize.Larger,
    fontWeight: Size.FontWeight.SemiBold,
}

export const VmDeprovisionedViewComponent = () => {
    return (
        <div style={containerStyle}>
            <div>
                <FieldHeaderComponent
                    style={headerStyle}
                    title={'Research environment has been deprovisioned'}
                />
            </div>
        </div>
    )
}
