import {useForm} from "react-hook-form";
import {IResearchTeam} from "@vivli/features/data-requests/infrastructure/interface";
import joi, {ObjectSchema} from "joi";
import {joiResolver} from "@hookform/resolvers/joi";
import {researchTeamMemberFormSchema} from "./use-research-team-member-form.hook";

export const researchTeamFormSchema: ObjectSchema<IResearchTeam> = joi.object({
    researchTeamDetails: joi.array().items(researchTeamMemberFormSchema).default([])
}).options({ allowUnknown: true })

export function useResearchTeamForm(researchTeam: IResearchTeam, mode: 'onBlur' | 'onChange' | 'onTouched' | 'all' = 'all') {
    return useForm({
        mode,
        resolver: joiResolver(researchTeamFormSchema),
        defaultValues: researchTeam,
        reValidateMode: 'onChange'
    });
}
