import React from 'react';
import {OrganizationsServiceContextWrapper} from '@vivli/features/organizations/infrastructure/wrappers';
import {UsersServiceContextWrapper} from '@vivli/features/users/infrastructure/wrappers';
import {ChatServiceContextWrapper} from '@vivli/features/chat/infrastructure/wrappers';
import {DataRequestsServiceContextWrapper} from '@vivli/features/data-requests/infrastructure/wrappers';
import {
    StudiesSearchServiceContextWrapper,
    StudiesServiceContextWrapper,
} from '@vivli/features/studies/infrastructure/wrappers';
import {ResultRequestServiceContextWrapper} from '@vivli/features/results-request/infrastructure/wrappers';
import {VirtualMachineServiceContextWrapper} from '@vivli/features/virtual-machine/infrastructure/wrappers';
import {VivliConfigurationServiceContextWrapper} from '@vivli/core/infrastructure/wrappers';
import {
    DataPackageFileServiceContextWrapper,
    DataPackageServiceContextWrapper,
} from '@vivli/shared/features/data-package/infrastructure/wrappers';
import {AzureSearchServiceContextWrapper} from '@vivli/features/search/infrastructure/wrappers';
import {MetricsServiceContextWrapper} from '@vivli/features/dashboard/infrastructure/wrappers';
import {ContextInjectorComponent} from '@vivli/shared/components';
import {IContextWrapper} from '@vivli/shared/infrastructure/interface';
import {ListingRequestServiceContextWrapper} from '@vivli/features/listing-request/infrastructure/wrappers';
import {AdminConsoleServiceContextWrappers} from '@vivli/features/admin-console/infrastructure/wrappers';
import {UtilityApiServiceContextWrapper} from "@vivli/shared/infrastructure/utilities";
import {CitationsServiceContextWrapper} from "@vivli/shared/features/citations/infrastructure/wrappers";
import {NotificationServiceContextWrapper} from "@vivli/shared/features/notification/infrastructure/wrappers";
import {EnquiryServiceContextWrapper} from "@vivli/features/enquiry/infastructure/wrappers";



const contextWrappers = [
    <VivliConfigurationServiceContextWrapper/>,
    <OrganizationsServiceContextWrapper/>,
    <ListingRequestServiceContextWrapper/>,
    <UsersServiceContextWrapper/>,
    <ChatServiceContextWrapper/>,
    <DataRequestsServiceContextWrapper/>,
    <StudiesServiceContextWrapper/>,
    <DataPackageServiceContextWrapper/>,
    <DataPackageFileServiceContextWrapper/>,
    <ResultRequestServiceContextWrapper/>,
    <VirtualMachineServiceContextWrapper/>,
    <StudiesSearchServiceContextWrapper/>,
    <MetricsServiceContextWrapper/>,
    <AzureSearchServiceContextWrapper/>,
    <UtilityApiServiceContextWrapper/>,
    <AdminConsoleServiceContextWrappers/>,
    <CitationsServiceContextWrapper/>,
    <NotificationServiceContextWrapper/>,
    <EnquiryServiceContextWrapper/>
];

export const ServicesWrapper = ({children}: IContextWrapper) => {
    return (
        <ContextInjectorComponent contextWrappers={contextWrappers}>
            {children}
        </ContextInjectorComponent>
    );
};
