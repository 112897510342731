import { ReactElement, ReactNode } from 'react';
import { BehaviorSubject } from 'rxjs';

interface TabComponentProps {
    children: ReactElement;
    title: string;
    hint?: string;
    count?: number;
    disabled?: boolean;
    countSubject?: BehaviorSubject<number>;
    onTabClick?: () => void;
    rightHeaderContent?: ReactNode;
}

export const TabComponent = ({ children }: TabComponentProps) => {
    return children;
};
