import React, { CSSProperties } from 'react';
import { DropdownMenuItemComponent } from './dropdown-menu-item.component';
import { IDropdownMenuItem } from '@vivli/shared/infrastructure/interface';

const iconItemsContainerStyle: CSSProperties = {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    maxHeight: '300px',
    overflow: 'auto',
};

interface DropdownMenuContentComponentProps {
    items: IDropdownMenuItem[];
    onItemClick?: (item: IDropdownMenuItem) => void;
    sort?: 'asc' | 'desc';
    optionStyle?: CSSProperties;
}

export const DropdownMenuContentComponent = ({ items, onItemClick, sort, optionStyle }: DropdownMenuContentComponentProps) => {
    const handleContainerClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        return false;
    };

    const sortFunc = (a: IDropdownMenuItem, b: IDropdownMenuItem): number => {
        return a.title.localeCompare(b.title);
    };

    const menuItems = sort ? (sort === 'asc' ? items?.sort(sortFunc) : items?.sort(sortFunc).reverse()) : items;

    return (
        <div style={iconItemsContainerStyle} onClick={handleContainerClick} className="scrolly" data-test-id="dropdown_menu">
            {menuItems?.map((item, i) => (
                <DropdownMenuItemComponent
                    key={i}
                    item={item}
                    onItemClick={onItemClick}
                    optionStyle={optionStyle}
                    data-test-id={`${item.dataIdPrefix || 'default'}-menu-item`}
                />
            ))}
        </div>
    );
};
