import React, {CSSProperties} from 'react';
import {AdvancedSelectComponent, AdvancedSubHeaderComponent, BasicCheckboxComponent} from "@vivli/shared/components";
import {amrFieldTitleStyle, inputGroupBreakStyle} from "../../search-filters.styles";
import {FilterGroupComponent} from "../../filter-group.component";
import {useAmrSearchOptionsContext, useSearchContext} from "@vivli/features/search/infrastructure/context";

const checkboxContainerStyle: CSSProperties = {
    display: 'flex',
    marginBottom: '10px'
}

const checkboxStyle: CSSProperties = {
    transform: 'scale(1.5)',
    marginRight: '20px'
}

export const FifthFilterGroupComponent = () => {
    const {amrFilterValues} = useSearchContext();
    const {
        sampleSourceSelectedOptions,
        setSampleSourceSelectedOptions,
        includePediatricsInfo,
        setIncludePediatricsInfo,
        includeGenotypeInfo,
        setIncludeGenotypeInfo
    } = useAmrSearchOptionsContext();

    const specimenOptions = amrFilterValues?.specimenSources.map(l => ({value: l.value, label: l.value}))

    const handleGenotypeChanged = (checked: boolean) => {
        setIncludeGenotypeInfo(checked)
    }

    const handlePediatricsChanged = (checked: boolean) => {
        setIncludePediatricsInfo(checked);
    }

    return <FilterGroupComponent>
        <div style={inputGroupBreakStyle}>
            <AdvancedSubHeaderComponent title={'Sources of Samples'} style={amrFieldTitleStyle} />
            <AdvancedSelectComponent
                isMulti={true}
                isSearchable={true}
                onChange={setSampleSourceSelectedOptions}
                options={specimenOptions}
                selectedOptions={sampleSourceSelectedOptions}
                menuPlacement={"top"}
            />
        </div>

        <div>
            <div style={checkboxContainerStyle}>
                <BasicCheckboxComponent style={checkboxStyle} name={'genotypeCheckbox'} checked={includeGenotypeInfo}
                                        onChange={handleGenotypeChanged}/>
                <label htmlFor='genotypeCheckbox'>Includes Genotype Information</label>
            </div>
            <div style={checkboxContainerStyle}>
                <BasicCheckboxComponent style={checkboxStyle} name={'pediatricsCheckbox'}
                                        checked={includePediatricsInfo} onChange={handlePediatricsChanged}/>
                <label htmlFor='pediatricsCheckbox'>Includes Pediatrics Information</label>
            </div>
        </div>
    </FilterGroupComponent>
}
