export enum Msal2EventTypeEnum {
    UpdatingPasswordEvent = 'UpdatingPasswordEvent',
    UpdatingProfileEvent = 'UpdatingProfileEvent',
    UpdatedPasswordEvent = 'UpdatedPasswordEvent',
    UpdatedProfileEvent = 'UpdatedProfileEvent',
    LoggingInEvent = 'LoggingInEvent',
    SilentLoggingInEvent = 'SilentLoggingInEvent',
    LoginEvent = 'LoginEvent',
    UserLoginEvent = 'UserLoginEvent',
    SilentLoginEvent = 'SilentLoginEvent',
    LogoutEvent = 'LogoutEvent',
    UserCancelledLogin = 'UserCancelledLogin',
    RefreshAuthExpirationEvent = 'RefreshAuthExpirationEvent',
    UnauthorizedEvent = 'UnauthorizedEvent',
    FailedToInitialize = 'FailedToInitialize',
    TokenUpdateEvent = 'TokenUpdateEvent',
    SignUpEvent = 'SignUpEvent',
    SignedUpEvent = 'SignedUpEvent',
    AuthEventsInitialized = 'AuthEventsInitialized',
    LogoutWarning = 'LogoutWarning',
}
