import React, {CSSProperties} from 'react';
import {Color, Size} from "@vivli/shared/theme";
import {useConfigService} from "@vivli/core/infrastructure/context";
import {useTranslation} from "react-i18next";
import {TranslationKeyEnum} from "@vivli/shared/infrastructure/enum";

const containerStyle: CSSProperties = {
    maxHeight: Size.HEADER_HEIGHT,
    minHeight: Size.HEADER_HEIGHT,
    backgroundColor: Color.VIVLI_DARK_BLUE,
    display: 'flex',
    flex: `1 0 ${Size.HEADER_HEIGHT}px`,
    justifyContent: 'space-between',
    boxShadow: Size.BOX_SHADOW,
    position: 'relative',
    zIndex: 101
}

const linkContainerStyle: CSSProperties = {
    alignSelf: 'center',
    marginLeft: 50,
}

const copyContainerStyle: CSSProperties = {
    marginRight: Size.PADDING,
    color: 'rgba(255, 255, 255, 0.6)',
    fontSize: Size.FontSize.Small,
    alignSelf: 'center',
}

export const FooterComponent = () => {
    const {t} = useTranslation();
    const {viewRequestResultsOnly} = useConfigService();
    const howToGuideLink = t(TranslationKeyEnum.howToGuideLink);
    const privacyLink = t(TranslationKeyEnum.privacyLink);
    const cookiePolicyLink = t(TranslationKeyEnum.cookiePolicyLink);
    const eeaDisclosurePolicyLink = t(TranslationKeyEnum.eeaDisclosurePolicyLink);
    const contactUsLink = t(TranslationKeyEnum.contactUsLink);

    return (
        <div style={containerStyle} className='do-not-print'>
            <div style={linkContainerStyle}>
                {!viewRequestResultsOnly &&
                <div>
                    <a href={howToGuideLink} target='_blank' style={{color: 'rgba(255, 255, 255, 0.6)', textDecoration: 'none', marginRight: 15}}>How To Guide</a>
                    <a href={privacyLink} target='_blank' style={{color: 'rgba(255, 255, 255, 0.6)', textDecoration: 'none', marginRight: 15}}>Privacy</a>
                    <a href={cookiePolicyLink} target='_blank' style={{color: 'rgba(255, 255, 255, 0.6)', textDecoration: 'none', marginRight: 15}}>Cookie Policy</a>
                    <a href={eeaDisclosurePolicyLink} target='_blank' style={{color: 'rgba(255, 255, 255, 0.6)', textDecoration: 'none', marginRight: 15}}>EEA Disclosure Policy</a>
                    <a href={contactUsLink} target='_blank' style={{color: 'rgba(255, 255, 255, 0.6)', textDecoration: 'none', marginRight: 15}}>Contact Us</a>
                </div>
                }
            </div>
            <div style={copyContainerStyle}>
                &copy; Copyright 2017 - {new Date().getFullYear()} Vivli
            </div>
        </div>
    )
}
