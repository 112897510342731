import React, {CSSProperties, forwardRef, useEffect, useRef} from 'react';
import {Color, Size} from "@vivli/shared/theme";
import Tippy from "@tippyjs/react";
import {followCursor} from 'tippy.js';
import {EllipsisTextComponent} from "../ellipsis-text.component";
import {BaseInputFieldComponent} from "./base-input-field.component";
import {FormFieldTypeEnum} from "@vivli/shared/infrastructure/enum";
import {IFormField} from "@vivli/shared/infrastructure/interface";

const valueStyle: CSSProperties = {
    paddingTop: '4px',
    border: 'none',
    fontSize: Size.FontSize.Large,
    fontWeight: Size.FontWeight.SemiBold,
    color: Color.DARK_GRAY,
    flex: '1 1 auto',
    resize: 'none',
    borderWidth: 'initial',
    borderStyle: 'none',
    borderColor: 'initial',
    paddingLeft: '15px',
    paddingRight: '15px',
    paddingBottom: '5px',
    backgroundColor: 'rgba(0, 0, 0, 0)',
    width: '100%'
}

const fieldStyle: CSSProperties = {
    minHeight: '27px'
}

interface TextFieldReadOnlyFormComponentProps extends IFormField {
}

/*component to provide standard style used in read-only forms*/
export const TextFieldReadOnlyFormComponent = forwardRef((props: TextFieldReadOnlyFormComponentProps, ref) => {
    const textInputRef = useRef<HTMLInputElement>();
    const {
        defaultValue,
        value,
        readonly,
        tooltip,
        focusOnLoad,
        onChange,
        onBlur,
        onFocus
    } = props;

    const handleInitialFocus = () => {
        if (focusOnLoad && textInputRef.current) {
            textInputRef.current.focus();
        }
    }

    useEffect(() => {
        handleInitialFocus();
    }, [])

    const readonlyDisplay = (
        <div style={{...valueStyle, cursor: 'default'}} title={defaultValue}>
            <Tippy content={defaultValue} disabled={!tooltip} appendTo='parent' followCursor={true} plugins={[followCursor]}>
                <div><EllipsisTextComponent text={defaultValue}/></div>
            </Tippy>
        </div>
    )

    return (
        <BaseInputFieldComponent
            {...props}
            type={FormFieldTypeEnum.Text}
            inputRef={textInputRef}
        >
            <div style={fieldStyle}>
                {readonlyDisplay}
            </div>
        </BaseInputFieldComponent>
    )
})
