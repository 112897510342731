import { AssignedAppTypeEnum } from '@vivli/shared/infrastructure/enum';
import { StorageService } from '@vivli/shared/infrastructure/service';

export const getAssignedAppType = () => {
    return StorageService.getItem<AssignedAppTypeEnum>(
        'assignedAppType',
        'local'
    );
};

export function useAssignedAppType(): AssignedAppTypeEnum {
    return getAssignedAppType();
}
