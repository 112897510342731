import React, {CSSProperties, useState} from "react";
import {IDropdownMenuItem} from "@vivli/shared/infrastructure/interface";
import {DTIDropdownMenu} from "@vivli/shared/infrastructure/constants";

interface DropdownMenuItemComponentProps {
    onItemClick?: (item: IDropdownMenuItem) => void;
    item: IDropdownMenuItem;
    hierarchical?: boolean;
    optionStyle?: CSSProperties;
}

export const DropdownMenuItemComponent = (
    {
        onItemClick,
        item,
        hierarchical = false,
        optionStyle
    }: DropdownMenuItemComponentProps
) => {

    const menuItemStyle = (clickable: any, isHovered: boolean): CSSProperties => ({
        cursor: clickable ? 'pointer' : 'default',
        padding: hierarchical ? '3px' : '5px',
        pointerEvents: 'auto',
        backgroundColor: isHovered ? 'rgba(0,0,0,0.2)' : 'white'
    })

    const itemTitleStyle: CSSProperties = {
        fontWeight: hierarchical ? 'normal' : 'bold',
        ...optionStyle
    }

    const [isHovered, setIsHovered] = useState(false);

    const handleItemClick = (item: IDropdownMenuItem, e) => {
        e.preventDefault();
        e.stopPropagation();

        onItemClick && onItemClick(item);
        item.onClick && item.onClick();

        return false;
    }

    const dataId = DTIDropdownMenu.DropdownMenuItem(item?.title?.replace(' ', '_').toLowerCase());

    return (
        <div
            onClick={(e) => handleItemClick(item, e)}
            style={menuItemStyle(item.onClick, isHovered)}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            data-test-id={dataId}
        >
            <div style={itemTitleStyle}>{item.title}</div>
            {item.icon && <div>{item.icon}</div>}
        </div>
    )
}
