import {RestApi} from "@vivli/core/infrastructure/rest";
import {IOrcidApiService} from "@vivli/features/studies/infrastructure/interface";
import {Observable} from "rxjs";
import {IConfig} from "@vivli/shared/infrastructure/interface";
import {IRestRequestOptions} from '@vivli/core/infrastructure/interface';
import axios from 'axios-observable';

export class OrcidApiService extends RestApi implements IOrcidApiService {
    getOrcidAuthToken = (code: string, datasetId: string, orcidId: string): Observable<string> => {


        /* Code left here intentionally for case when we resolve the issue with CORS
          const authUrl = new URL(`${config.studyOrcidConfig.authUrl}`);
               const data = `client_id=${config.studyOrcidConfig.clientId}&client_secret=${config.studyOrcidConfig.clientSecret}&grant_type=authorization_code&code=${code}&scope=%2Factivities%2Fupdate`;
          //&redirect_uri=${redirect}
               const options : IRestRequestOptions = {
                   bypassAuth: true,
                   baseUrl: authUrl.origin,
                   additionalHeaders:{'Content-Type': 'application/x-www-form-urlencoded', 'Accept': 'application/json'}
               }
               return this.handlePost<IOrcidToken>(`/oauth/token`, data, options);
               */

        return this.handlePost(`orcid/orcidCreds/${datasetId}/${orcidId}/${code}`);
    }

    sendOrcidInvites = (datasetId: string): Observable<boolean> => {

        return this.handlePost(`orcid/orcidInvites/${datasetId}`);
    }
}


