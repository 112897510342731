import {CSSProperties, useEffect, useRef, useState} from "react";
import {
    ButtonComponent,
    CheckboxFormFieldComponent,
    DropdownFormFieldComponent,
    LinkFieldComponent,
    MultiSelectDropdownFormFieldComponent,
    TextAreaFormFieldComponent,
    TextFormFieldComponent,
    TranslationComponent
} from "@vivli/shared/components";
import {IDropdownMenuItem} from "@vivli/shared/infrastructure/interface";
import {EnquiryDiscussionGridComponent} from "./enquiry-discussion-grid.component";
import {IDataInfoRequest, IEnquiry, IEnquiryOrganizations} from "@vivli/features/enquiry/infastructure/interface";
import {DataTypeOptions, DirDropDownConstants} from "@vivli/features/enquiry/infastructure/constants";
import {useEnquiryContext, useEnquiryServiceContext} from "@vivli/features/enquiry/infastructure/context";
import {first} from "rxjs/operators";
import {useModalService} from "@vivli/shared/infrastructure/context";
import {Color, Size, Styles} from "@vivli/shared/theme";
import {useFormContext, useWatch} from "react-hook-form";
import {useActiveUser} from "@vivli/core/infrastructure/context";
import {DirStatusEnum, EnquiryStatusEnum} from "@vivli/features/enquiry/infastructure/enum";
import {DataRequestStatusEnum} from "@vivli/features/data-requests/infrastructure/enum";
import {AssetsConstant, DTICommonConst} from "@vivli/shared/infrastructure/constants";
import {useSharedDataContext} from "@vivli/features/data-requests/infrastructure/context";
import {AdvancedDropdownMenuComponent} from "@vivli/features/search/components";
import {ResponseHint} from "./response-hint";
import {ReasonHint} from "./reason-hint";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faTrash} from '@fortawesome/free-solid-svg-icons';
import {useDoiTransform} from "@vivli/features/studies/infrastructure/hook";

const flexStyle: CSSProperties = {
    display: "flex",
    alignItems: "center",
}
const flexStyleColumn: CSSProperties = {
    display: "flex",
    alignItems: "center",
    flexDirection: 'column'
}
const twoColumnGrid: CSSProperties = {
    display: 'grid',
    justifyContent: 'space-around',
    gridTemplateColumns: '1fr 3fr',
    columnGap: '20px',
}
const twoColumnInnerGrid: CSSProperties = {
    display: 'grid',
    justifyContent: 'space-around',
    gridTemplateColumns: '3fr 1fr',
    columnGap: '0px',
}
const threeColumnGrid: CSSProperties = {
    display: 'grid',
    justifyContent: 'space-around',
    gridTemplateColumns: '1fr 2fr 1fr',
    columnGap: '25px',
}
const fourColumnGrid: CSSProperties = {
    display: 'grid',
    justifyContent: 'space-around',
    gridTemplateColumns: '20px 1fr 2fr 1fr',
    columnGap: '20px',
}

const sixColumnGrid: CSSProperties = {
    display: 'grid',
    justifyContent: 'left',
    gridTemplateColumns: '25px 13% 50% 18% 1fr 5%',
    columnGap: '15px',

}
const paddingTop20: CSSProperties = {
    paddingTop: '20px',
}

const paddingTop35: CSSProperties = {
    paddingTop: '35px',
}
const paddingTop17: CSSProperties = {
    paddingTop: '17px',
}
const paddingTop5: CSSProperties = {
    paddingTop: '5px',
}
const dirContainer: CSSProperties = {
    padding: '20px',
    backgroundColor: '#DDDDDD',//'#f5f5f5',
    border: '3px solid #c3bcbc',
    marginTop: '30px',
    height: 'auto',
}

const marginTop20: CSSProperties = {
    marginTop: '20px',
}
const marginTop17: CSSProperties = {
    marginTop: '17px',
}
const linkRegistryUrlStyle: CSSProperties = {
    backgroundColor: 'none',
    boxShadow: 'none',
    borderBottom: 'none',
    flexDirection: 'row',
    fontSize: '16px',
    paddingTop: '0px',
    marginTop: '0px',

}
const iconStyle: CSSProperties = {
    fontSize: '1.5em',
    padding: '0px 0px 0px 0px',
    color: Color.TEXT_LIGHT_BLUE,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginBottom: '15px',
    cursor: 'pointer',
};
const isViviliStyle: CSSProperties = {
    color: '#ff0000',
    cursor: 'pointer',
    paddingTop: '20px',
}
const smallButtonStyle = {
    height: 25,
    color: Color.DARK_GRAY,
    backgroundColor: '#DDDDDD',
    fontSize: Size.FontSize.XLarge,
    width: 25,
    minWidth: 25,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
    ':active': {transform: 'scale(0.98)'},
    ':hover': {opacity: Size.HOVER_OPACITY},
    transition: `scale 0.35s, opacity ${Size.HOVER_TRANSITION_TIME}s`
};
const smallButtonStyleError = {
    height: 25,
    color: Color.ERROR_RED,
    backgroundColor: '#DDDDDD',
    fontSize: Size.FontSize.XLarge,
    width: 25,
    minWidth: 25,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
    ':active': {transform: 'scale(0.98)'},
    ':hover': {opacity: Size.HOVER_OPACITY},
    transition: `scale 0.35s, opacity ${Size.HOVER_TRANSITION_TIME}s`
};
const dropDownBtnStyle: CSSProperties = {
    backgroundImage: `url(${AssetsConstant.DOWN_CHEVRON_WHITE})`,
    paddingRight: Size.PADDING,
    fontWeight: Size.FontWeight.SemiBold,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right 20px center',
    backgroundSize: '20px',
    marginBottom: '15px',
    marginRight: '0',
    width: '100px',
}

interface EnquiryDataInfoRequestComponentProps {
    dirIndex: number;
    onRemove: (dirIndex: number) => void;
    onUpdate: (dirIndex: number, dir: IDataInfoRequest) => void;
}

export const EnquiryDataInfoRequestComponent = ({
                                                    dirIndex,
                                                    onRemove,
                                                    onUpdate
                                                }: EnquiryDataInfoRequestComponentProps) => {
    const {
        handleGetStudy,
        setNotify,
        nctError,
        sponsorIdError,
        getAddDirStudy,
        isLoading,
        toggleDir,
        isDirOpen,
        setDirAsClosed,
        addDirRef,
        removeDirRef,
        study
    } = useEnquiryContext();
    const enquiryService = useEnquiryServiceContext();
    const modalService = useModalService();
    const user = useActiveUser();
    const dataInfoRequest = useWatch({name: `dataInfoRequests.${dirIndex}`});
    const formApi = useFormContext<IEnquiry>();
    const modal = useModalService();
    const enquiry = formApi.watch();
    const {myDataRequests} = useSharedDataContext().myDataRequests;
    const [enquiryOrganizations, setEnquiryOrganizations] = useState<IEnquiryOrganizations[]>();
    const [studyReadOnly, setStudyReadOnly] = useState<boolean>(false);
    const [nctIdHasValue, setNctIdHasValue] = useState<boolean>(false);
    const [reasonReadOnly, setReasonReadOnly] = useState<boolean>(false);
    const [responseReadOnly, setResponseReadOnly] = useState<boolean>(false);
    const [isRequestStudyMenuOpen, setIsRequestStudyMenuOpen] = useState(false);
    const nctErrorText = 'Please enter valid NCTID. NCTID must be entered in the format NCT12345678.';
    const sponsorIdErrorText = 'Please enter valid  SponosrId'
    const reasonLabel = DirDropDownConstants.NotAvailableReasonOptions;
    const dataRequestedLabel = DirDropDownConstants.DataTypeOptions;
    const dirStatusLabel = DirDropDownConstants.DirStatusOptions;
    const dirRef = useRef<HTMLDivElement>();
    const {transformDoiUrl} = useDoiTransform();

    const [SavedStatus, SetSavedStatus] = useState<number>(-1);
    const [displayRequestStudyBtn, setDisplayRequestStudyBtn] = useState<boolean>(false);
    const [lastNctOrSponsorId, setLastNctOrSponsorId] = useState(dataInfoRequest.nctId || dataInfoRequest.sponsorId);

    const reasonMenuItems = Object.keys(reasonLabel).map<IDropdownMenuItem>(key => {
        return {
            title: reasonLabel[key],
            value: Number(key)
        }
    });
    const dataRequestedMenuItems = dataRequestedLabel.map(({key, value}) => ({title: value, value: Number(key)}));
    const dirStatusMenuItems = Object.keys(dirStatusLabel).map<IDropdownMenuItem>(key => {
        return {
            title: dirStatusLabel[key],
            value: Number(key)
        }
    });
    const organizationMenuItems = enquiryOrganizations?.map<IDropdownMenuItem>(({valueField, keyField}) => {
        return {
            title: valueField,
            value: keyField
        }
    });
    const isRequester = enquiry.requesterId === user.userId;
    const isOrgAdmin = user.orgMemberships.some(x => x.orgId === dataInfoRequest.orgId && x.isOrgAdmin);
    const isOrgMember = user.orgMemberships.some(x => x.orgId === dataInfoRequest.orgId);
    const isAdmin = user.isVivliAdmin || isOrgAdmin;
    const isInReview = enquiry.status === EnquiryStatusEnum.Review;
    const isApprovedState = isInReview
        || enquiry.status === EnquiryStatusEnum.Withdrawn
        || enquiry.status === EnquiryStatusEnum.Archived;
    //in review status - show Notify checkbox to admin, enquirer and DC for the DIR organization
    //in draft or form validation status - to admin and enquirer
    const showNotify = (isInReview && (user.isVivliAdmin || isRequester || isOrgMember))
        || ((enquiry.status === EnquiryStatusEnum.Draft || enquiry.status === EnquiryStatusEnum.EnquiryValidation)
            && (user.isVivliAdmin || isRequester))
    const dispayPreviousEnqLink = (isInReview || enquiry.status === EnquiryStatusEnum.EnquiryValidation)
        && (user.isVivliAdmin || isOrgMember);
    //VivliAdmin always can edit the title - #11444
    const titleReadOnly = !user.isVivliAdmin && (!!(formApi.getValues().dataInfoRequests[dirIndex].registryUrl) || studyReadOnly);
    const hasError = formApi.getFieldState(`dataInfoRequests.${dirIndex}`).invalid;
    const isReadOnlyRequester = enquiry.status !== EnquiryStatusEnum.Draft && isRequester;
    const isSponsorReadOnly = studyReadOnly && !(isInReview && isAdmin);
    const nctIsEditable = !studyReadOnly || (enquiry.status === EnquiryStatusEnum.Review && (user.isVivliAdmin || (!nctIdHasValue && isOrgAdmin)))
    const handleFailure = (err: string) => {
        modalService.error(`An error occurred retrieving the Organizations. Please try again or contact Vivli support. Message: ${err}`);
    };


    const handleGetNctSponsorIdStudy = (e) => {
        if (e.target.value !== '' && e.target.value !== lastNctOrSponsorId) {
            setLastNctOrSponsorId(e.target.value);
            handleGetStudy(e.target.value, dirIndex, formApi);
        }
    }
    const handleGetNctIdStudy = (e) => {
        if (e.target.value !== '' && e.target.value !== lastNctOrSponsorId) {
            const fieldState = formApi.getFieldState(`dataInfoRequests.${dirIndex}.nctId`);
            if (fieldState?.invalid) {
                //do not do a lookup if NCT Id is invalid
                return;
            }
            handleGetNctSponsorIdStudy(e);

        }
    }

    const handleKeyUpNctIdGetStudy = (e) => {
        if (e.key === 'Enter') {
            handleGetNctIdStudy(e)
        }
    }
    const handleKeyUpGetStudy = (e) => {
        if (e.key === 'Enter') {
            handleGetNctSponsorIdStudy(e)
        }
    }
    const handleDeleteDirStudy = () => {

        const confirmMsg = `Are you sure you want to remove this study from the enquiry?`;
        modal.confirm(confirmMsg, {
            onConfirm: () => {
                onRemove(dirIndex);
                setDirAsClosed(dirIndex); // remove from "openDirs" array
            },
        });
    }
    const getOrgName = (orgId) => {

        var orgs = enquiryOrganizations?.filter(o => o.keyField === dataInfoRequest.orgId);
        if (orgs?.length > 0) {
            return orgs[0].valueField;
        }
        return '';
    }

    const getEnquiriesReportLink = (nctId) => {
        return `/admin/report?reportType=Enquiry&nctId=${nctId}`;
    }


    const addToCart = (selectedItem?: IDropdownMenuItem) => {
        const isNewRequest = selectedItem.value === 'addnewrequest';
        const receivedTitle = isNewRequest ? 'New Research Data Request' : selectedItem.title;
        getAddDirStudy(enquiry.id, dataInfoRequest.id, receivedTitle, isNewRequest);
        setIsRequestStudyMenuOpen(false);
    };

    const handleSelectMenuOutsideClick = () => {
        setIsRequestStudyMenuOpen(false);
    };

    const dropDownStudyItems = (myDataRequests || [])
        .filter((dr) => dr.userId === user.userId && dr.status === DataRequestStatusEnum.Draft)
        .map(
            (dataRequest) =>
                ({
                    title: dataRequest.requestTitle || dataRequest.id,
                    value: dataRequest.id,
                    dataRequest,
                } as IDropdownMenuItem)
        )
        .concat({
            title: '+ Add New Request',
            value: 'addnewrequest',
        } as IDropdownMenuItem)


    useEffect(() => {
        enquiryService
            .getEnquiryOrganizations()
            .pipe(first())
            .subscribe(data => {
                setEnquiryOrganizations(data);
            }, handleFailure)


        if ((!user.isVivliAdmin && enquiry.status == EnquiryStatusEnum.EnquiryValidation)
            || (user.userId !== enquiry.requesterId && !user.isVivliAdmin && enquiry.status == EnquiryStatusEnum.Draft)
            || isApprovedState) {
            setStudyReadOnly(true);
        }

        if (!user.orgMemberships.some(x => x.orgId === dataInfoRequest.orgId && (x.isDataProvider || x.isOrgAdmin))
            && !user.isVivliAdmin) {
            setReasonReadOnly(true);
            setResponseReadOnly(true);
        }

        if ((dataInfoRequest.status === DirStatusEnum.AvailableUnlisted
                || dataInfoRequest.status === DirStatusEnum.AvailableListed)
            && (isRequester || user.isVivliAdmin) && enquiry.status === EnquiryStatusEnum.Review) {
            setDisplayRequestStudyBtn(true);
        }
        setNctIdHasValue(!!(dataInfoRequest.nctId));
    }, []);

    useEffect(() => {
        if (dataInfoRequest.status) {
            if (SavedStatus != -1 && SavedStatus != dataInfoRequest.status) {
                setNotify(dataInfoRequest, dirIndex);
            }
            SetSavedStatus(dataInfoRequest.status);
        }
        handleResponseWarning();
        formApi.trigger([`dataInfoRequests`]);
    }, [dataInfoRequest.status]);

    useEffect(() => {
        addDirRef(dirRef, dirIndex);

        return () => {
            removeDirRef(dirIndex)
        }
    }, []);


    const handleResponseWarning = () => {
        const dataInfoRequests = formApi.getValues('dataInfoRequests');
        const currentDataInfoRequest = dataInfoRequests[dirIndex];

        const confirmMsg = `Please provide a Sponsor ID so this study can be requested in a data request.
        If your study is listed on clinicaltrials.gov, please provide the sponsor ID associated with that listing.
        If it is not listed on clinicaltrials.gov, please provide an internal sponsor ID.
         Once you've added the Sponsor ID, please click the “Save & Notify” button on top.`;

        if (currentDataInfoRequest.sponsorId.length < 1 && (currentDataInfoRequest.status === DirStatusEnum.AvailableUnlisted ||
            currentDataInfoRequest.status === DirStatusEnum.AvailableListed)) {
            modal.error(confirmMsg);

            dataInfoRequest.status = SavedStatus;
            onUpdate(dirIndex, dataInfoRequest);
        }
    };


    return (
        <div style={dirContainer} ref={dirRef}>
            {!isDirOpen(dirIndex) &&

                <div style={sixColumnGrid}>
                    <div>
                        <ButtonComponent title="Open full view"
                                         style={hasError ? smallButtonStyleError : smallButtonStyle}

                                         onClick={() => toggleDir(dirIndex)}
                        >+
                        </ButtonComponent>
                    </div>
                    <div style={paddingTop5}><strong>NCT ID:</strong> {dataInfoRequest.nctId}</div>
                    <div style={paddingTop5}><strong>Study Title:</strong> {dataInfoRequest.studyTitle}</div>
                    <div style={paddingTop5}><strong>Data Contributor:</strong> {getOrgName(dataInfoRequest.orgId)}
                    </div>
                    <div style={paddingTop5}><strong>Status:</strong> {dataInfoRequest.statusString}</div>
                    {hasError &&
                        <div>❗</div>
                    }

                </div>
            }
            {isDirOpen(dirIndex) &&
                <div>
                    <div>
                        {enquiry.status === EnquiryStatusEnum.Draft &&
                            <div style={{paddingBottom: '15px', paddingLeft: '40px'}}>Please enter an NCT Id or Sponsor
                                Id if the study is on clinicaltrials.gov, or enter
                                the study title.</div>
                        }
                        {displayRequestStudyBtn &&
                            <div>
                                <AdvancedDropdownMenuComponent
                                    items={dropDownStudyItems}
                                    onClickOutside={() => handleSelectMenuOutsideClick()}
                                    onItemClick={(item) => addToCart(item)}
                                >
                                    <ButtonComponent
                                        isLoading={isLoading}
                                        style={
                                            {
                                                ...Styles.Button.BUTTON_VIVLI_BLUE,
                                                backgroundColor: isRequestStudyMenuOpen ? Color.VIVLI_DARK_BLUE : Color.VIVLI_LIGHT_BLUE,
                                                ...dropDownBtnStyle,
                                                ':active': 'inherit',
                                            } as CSSProperties
                                        }
                                        className="request_study_menu"
                                        data-test-id={DTICommonConst.RequestStudyMenu}
                                    >
                                        Request{' '}
                                        <TranslationComponent
                                            style={{
                                                textTransform: 'capitalize',
                                            }}
                                        >
                                            study
                                        </TranslationComponent>
                                    </ButtonComponent>
                                </AdvancedDropdownMenuComponent>
                            </div>
                        }
                    </div>
                    <div style={fourColumnGrid}>
                        <div>
                            <ButtonComponent title="Close full view"
                                             style={smallButtonStyle}
                                             onClick={() => toggleDir(dirIndex)}
                            >-
                            </ButtonComponent>
                        </div>
                        <div>
                            <div
                                style={dispayPreviousEnqLink ? twoColumnInnerGrid : null}>

                                <TextFormFieldComponent
                                    name={`dataInfoRequests.${dirIndex}.nctId`}
                                    label={'NCT ID'}
                                    onBlur={e => handleGetNctIdStudy(e)}
                                    onKeyDown={e => handleKeyUpNctIdGetStudy(e)}
                                    key={'nctId'}
                                    readonly={!nctIsEditable}
                                    error={nctError ? nctErrorText : ''}
                                ></TextFormFieldComponent>

                                {dispayPreviousEnqLink &&

                                    <LinkFieldComponent
                                        linkTitle={"Previous Enquiries"}
                                        tooltip={"See the list of enquiries associated to this Study."}
                                        linkContent={getEnquiriesReportLink(dataInfoRequest?.nctId)}
                                        label={''}
                                        style={{...linkRegistryUrlStyle, marginLeft: '0px'}}
                                        linkStyle={{fontSize: '14px'}}
                                    />

                                }
                            </div>
                            <div style={{...flexStyleColumn, ...paddingTop17}}>OR</div>
                            <TextFormFieldComponent
                                name={`dataInfoRequests.${dirIndex}.sponsorId`}
                                label={'Sponsor ID'}
                                style={marginTop17}
                                onBlur={e => handleGetNctSponsorIdStudy(e)}
                                onKeyDown={e => handleKeyUpGetStudy(e)}
                                readonly={isSponsorReadOnly}
                                error={sponsorIdError ? sponsorIdErrorText : ''}
                            ></TextFormFieldComponent>

                        </div>
                        <div>
                            <TextAreaFormFieldComponent
                                name={`dataInfoRequests.${dirIndex}.studyTitle`}
                                label={'Study Title'}
                                readonly={titleReadOnly}
                            ></TextAreaFormFieldComponent>
                            <div>


                            </div>
                        </div>

                        <div>
                            {showNotify &&
                                <CheckboxFormFieldComponent
                                    name={`dataInfoRequests.${dirIndex}.notify`}
                                    label={'Notify on "Save & Notify":'}
                                    truncateLabel={false}

                                />
                            }
                            {enquiry.status === EnquiryStatusEnum.Draft && (user.isVivliAdmin || isRequester) &&
                                <FontAwesomeIcon aria-hidden="true" style={iconStyle}
                                                 onClick={() => handleDeleteDirStudy()} icon={faTrash}/>
                            }
                            <DropdownFormFieldComponent
                                label={'Data Contributor'}
                                name={`dataInfoRequests.${dirIndex}.orgId`}
                                items={organizationMenuItems}
                                readonly={isApprovedState && !user.isVivliAdmin || isReadOnlyRequester}
                            />
                            <div style={paddingTop20}>Sponsor: {dataInfoRequest?.sponsor}</div>

                        </div>
                    </div>
                    <div style={fourColumnGrid}>
                        <div></div>
                        <div style={paddingTop20}>Primary Completion
                            Date: {dataInfoRequest?.studyCompletionDate?.substring(0, 10)}</div>
                        <div style={paddingTop20}>
                            <LinkFieldComponent
                                linkTitle={dataInfoRequest?.registryUrl}
                                linkContent={dataInfoRequest?.registryUrl}
                                label={'Clinical Trials: '}
                                labelStyle={{paddingBottom: '15px'}}
                                style={linkRegistryUrlStyle}
                            />
                        </div>
                        <div>
                            {dataInfoRequest?.isVivliPosted &&
                                <div style={isViviliStyle}
                                     onClick={() => window.open(transformDoiUrl(study?.studyMetadataDoi), '_blank')}>
                                    This Study is listed on the Vivli Platform
                                </div>
                            }
                        </div>
                    </div>

                    <div style={{...twoColumnGrid, ...paddingTop20}}>
                        <div style={paddingTop35}>
                            {(!isApprovedState && (user.isVivliAdmin || isRequester && !isReadOnlyRequester)) &&
                                <MultiSelectDropdownFormFieldComponent
                                    label={'Data Requested'}
                                    name={`dataInfoRequests.${dirIndex}.dataType`}
                                    items={dataRequestedMenuItems}
                                    objectKey={'value'}
                                    labelKey={'title'}
                                    arrayKey={'value'}
                                />
                            }
                            {(isApprovedState || isReadOnlyRequester) &&
                                <div>
                                    <p>Data Requested:</p>
                                    <ul>
                                        {dataInfoRequest.dataType?.map((x) =>
                                            <li>{DataTypeOptions.find(t => t.key == x.toString())?.value}</li>)}
                                    </ul>
                                </div>
                            }
                            <DropdownFormFieldComponent
                                name={`dataInfoRequests.${dirIndex}.status`}
                                label={'Response'}
                                items={dirStatusMenuItems}
                                style={marginTop20}
                                hint={<ResponseHint/>}
                                widerHint={true}
                                readonly={responseReadOnly}
                            ></DropdownFormFieldComponent>
                            <div style={paddingTop20}>{dataInfoRequest.statusString}</div>
                            <DropdownFormFieldComponent
                                name={`dataInfoRequests.${dirIndex}.notAvailableReason`}
                                label={'Reason'}
                                items={reasonMenuItems}
                                style={marginTop20}
                                hint={<ReasonHint/>}
                                widerHint={true}
                                readonly={reasonReadOnly}
                            ></DropdownFormFieldComponent>
                        </div>
                        <div>
                            <EnquiryDiscussionGridComponent dirIndex={dirIndex}
                                                            dataInfoRequest={dataInfoRequest}></EnquiryDiscussionGridComponent>
                        </div>
                    </div>
                    {isAdmin &&
                        <div style={{...flexStyle, ...paddingTop20}}>
                            <TextAreaFormFieldComponent
                                name={`dataInfoRequests.${dirIndex}.internalNotes`}
                                label={'Internal Information'}
                                readonly={!user.isVivliAdmin && !isApprovedState}
                                rows={3}
                            ></TextAreaFormFieldComponent>
                        </div>}
                    <div style={{...threeColumnGrid, ...marginTop20}}>
                        <div>Date of Final
                            Response: {dataInfoRequest?.finalResponseDate?.substring(0, 10)} </div>
                        <div>Request Number(s): {dataInfoRequest?.dataRequestsDois} </div>
                    </div>
                </div>
            }
        </div>
    );
}
