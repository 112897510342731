export function useAppEnvironment() {
    const domain = window.location.host.toLowerCase();

    // split the domain to grab the environment and type
    const envParts = domain.split('.');
    let activeEnv = envParts[0];
    const envType = envParts[envParts.length - 1];

    // assign a better name to the local url and prod url
    if (activeEnv === 'vivli') {
        activeEnv = 'local';
    } else if (activeEnv === 'search') {
        activeEnv = 'prod'
    }

    return {
        activeEnv,
        envType
    }
}
