﻿import {GridComponent} from "@vivli/shared/components";
import React, {CSSProperties} from "react";
import {ColDef, GridApi} from "ag-grid-community";
import {IListingRequestSummary} from "@vivli/features/listing-request/infrastructure/interface";

const gridStyle: CSSProperties = {
    marginTop: 0,
    cursor: 'pointer',
    height: '100%'
}

interface ListingRequestsGridComponentProps {
    columnDefs: ColDef[];
    listingRequestSummaries: IListingRequestSummary[];
    onRowClick?: (listingRequestSummary: IListingRequestSummary) => void;
    onGridReady?: (gridApi: GridApi) => void;
    onRowCountChange?: (count: number) => void;
    dataRefreshing?: boolean;
}
export const ListingRequestsGridComponent = (
    {
        columnDefs,
        listingRequestSummaries,
        onRowClick,
        onGridReady,
        onRowCountChange,
        dataRefreshing
    }: ListingRequestsGridComponentProps
) => {

    const handleRowClick = (listingRequestSummary: IListingRequestSummary) => {
        onRowClick && onRowClick(listingRequestSummary);
    }
    return (
        <GridComponent
            rowData={listingRequestSummaries}
            columns={columnDefs}
            onRowClick={handleRowClick}
            onGridReady={onGridReady}
            fullWidthColumns={true}
            style={gridStyle}
            emptyMessage={'No Listing Requests Found'}
            asyncUpdateKey={'id'}
            onRowCountChange={onRowCountChange}
            dataRefreshing={dataRefreshing}
        />
    )
}
