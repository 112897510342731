﻿import React, {CSSProperties} from "react";
import {
    DropdownFieldComponent,
    FieldHeaderComponent,
    TextFieldComponent
} from "@vivli/shared/components";
import {Size} from "@vivli/shared/theme";
import {IOrganization} from "@vivli/features/organizations/infrastructure/interface";
import {useOrganizations} from "@vivli/features/organizations/infrastructure/hook";

const marginBottomStyle: CSSProperties = {
    marginBottom: Size.FIELD_SEPARATION
}

const alphabeticalSort = (a, b) => {
    return a.title < b.title ? -1 : 1;
};
const getOptions = (list: IOrganization[]) => {
    return list?.map(({id, name, code}) => ({ value: {orgId: id, orgName: name, orgCode: code}, title: name })).sort(alphabeticalSort);
}

interface StudyLookupComponentProps {
    nctValue: string,
    setNctValue: (nctValue: string) => void,
    handleNctSearch: (nct) => void,
    orgValue: {orgId: string, orgName: string, orgCode: string},
    setOrgValue: React.Dispatch<React.SetStateAction<{orgId: string, orgName: string, orgCode: string}>>
}
export const StudyLookupComponent = (
    {
        nctValue,
        setNctValue,
        handleNctSearch,
        orgValue,
        setOrgValue
    }: StudyLookupComponentProps
) => {
    const {organizations} = useOrganizations();
    const orgIdItems = getOptions(organizations);

    const invalidNct = !nctValue || !/^NCT\d{8}$/gi.test(nctValue);
    const nctError = "NCTID is required to continue. NCTID must be entered in the format NCT12345678.";
    const orgError = "Organization is required to continue.";

    const handleNctChange = (e) =>{
        setNctValue(e.target.value);
        handleNctSearch(e.target.value);
    }
    const handleDropdownChange = (e) => {
        setOrgValue(e);
    }

    return (
        <>
            <FieldHeaderComponent title='STUDY LOOKUP - ENTER NCT ID TO CONTINUE' />
            <TextFieldComponent
                label={'NCT ID (e.g. NCT0001234)'}
                value={nctValue}
                style={marginBottomStyle}
                onChange={handleNctChange}
                error={invalidNct ? nctError : ''}
            />
            <DropdownFieldComponent
                label={'Data Contributor Organization'}
                value={orgValue}
                style={marginBottomStyle}
                items={orgIdItems}
                onChange={handleDropdownChange}
                error={!orgValue ? orgError : ''}
                objectKey={'orgId'}
            />
        </>
    )
}
