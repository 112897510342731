import {ResultsRequestStatusEnum} from "@vivli/features/results-request/infrastructure/enum";
import {IUser} from "@vivli/shared/infrastructure/interface";

export abstract class ResultRequestFormatService {
    public static formatStatus(status: ResultsRequestStatusEnum, user: IUser): string {
        if (user.isVivliAdmin) {
            return status;
        }

        switch (status) {
            case ResultsRequestStatusEnum.RetrievingResults:
            case ResultsRequestStatusEnum.HeuristicsRequireReview:
                return 'Result Request in Progress';
            case ResultsRequestStatusEnum.Disapproved:
                return 'Result Retrieval Failed Review';
            case ResultsRequestStatusEnum.Approved:
            case ResultsRequestStatusEnum.HeuristicsPassed:
                return 'Result Retrieval Succeeded';
            case ResultsRequestStatusEnum.RetrievingResultsFailed:
                return 'Result Retrieval Error';
        }
    }
}
