﻿import {
    IOrganization,
    IOrganizationInvitation,
    IOrganizationMember,
    IRorResponseItem
} from "@vivli/features/organizations/infrastructure/interface";
import React, {createContext, useContext} from "react";
import {IUser} from "@vivli/shared/infrastructure/interface";

interface IOrganizationDetailsContext {
    organization?: IOrganization;
    invitations?: IOrganizationInvitation[];
    invitationsCount?: number;
    handleSave?: (updatedOrg) => void;
    isSaving?: boolean;
    inviteOrgMember?: (invite) => void;
    deleteInvite?: (invite) => void;
    getRorMatches?: (searchTerm) => IRorResponseItem[];
    currentMembers?: IOrganization[];
    setCurrentMembers?: IOrganization[];
    handleRoleUpdate?: (orgId: string, fieldName: string, userToUpdate: IUser, setIsLoading: React.Dispatch<React.SetStateAction<boolean>>) => void;
    updatedUser?: IOrganizationMember;
    isLoading?: boolean;
}

export const OrganizationDetailsContext = createContext<IOrganizationDetailsContext>(null);

export const useOrganizationDetailsContext = () => useContext(OrganizationDetailsContext);


