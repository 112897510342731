﻿import {
    CheckboxFormFieldComponent,
    DatePickerFormFieldComponent,
    TextAreaFormFieldComponent,
    TextFieldReadOnlyFormComponent
} from "@vivli/shared/components";
import React, {CSSProperties} from "react";
import {useListingRequestContext} from "@vivli/features/listing-request/infrastructure/context";
import {useNavigate} from "react-router-dom";
import moment from "moment";


const statusUpdateContainerStyle: CSSProperties = {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '20px',
    padding: '20px',
    alignItems: 'start',
    paddingBottom: '90px',
    height: '100%',
    width: "100%"
}
const vivliPrivateFieldStyle: CSSProperties = {
    backgroundColor: 'rgb(255, 246, 217)',
    marginBottom: '15px',
}

const readonlyStyle: CSSProperties = {
    cursor: 'default',
    backgroundColor: 'rgb(210, 213, 217)'
}
const studyScreenSytle: CSSProperties = {
    textDecoration: 'underline',
    margin: '15px',
    cursor: 'pointer',
}

export const ListingRequestStatusUpdateForm = () => {

    const {listingRequest} = useListingRequestContext();
    const navigate = useNavigate();
    const study = listingRequest?.datasets[0];
    const hasSubmittIpd = study?.hasSubmittedIpd ? 'Yes' : 'No';
    const studyUrl = () => {
        navigate(`/admin/studies/${study.id}/admin-study-feature`);
    }

    const postedDate = () => {
        if ((!study) || study?.postedDate === null || study?.postedDate?.toString() === '0001-01-01T00:00:00+00:00' || study?.postedDate === undefined)
            return '';
        return moment(study?.postedDate).format('MM/DD/YYYY');
    }
    const submittedDate = () => {
        if (listingRequest?.submittedDate === null || study?.submittedDate === undefined)
            return '';
        return moment(listingRequest?.submittedDate).format('MM/DD/YYYY');
    }

    const postDate = postedDate();
    const submitDate = submittedDate();

    return (
        <>
            <div style={statusUpdateContainerStyle}>
                <TextFieldReadOnlyFormComponent
                    defaultValue={submitDate}
                    name={submitDate}
                    label={'Date Study Submitted'}
                    readonly={true}
                    style={readonlyStyle}
                />
                <TextFieldReadOnlyFormComponent
                    defaultValue={postDate}
                    name={postDate}
                    label={'Date Posted'}
                    readonly={true}
                    style={readonlyStyle}
                />
                <TextFieldReadOnlyFormComponent
                    defaultValue={study?.orgName}
                    label={'Contributor'}
                    readonly={true}
                    style={readonlyStyle}
                />
                <TextFieldReadOnlyFormComponent
                    defaultValue={hasSubmittIpd}
                    label={'IPD Uploaded'}
                    readonly={true}
                    style={readonlyStyle}
                />

                <div>
                    <TextAreaFormFieldComponent
                        name={'uploadNotes'}
                        label={'Data Upload notes'}
                        style={vivliPrivateFieldStyle}
                    />
                    <TextAreaFormFieldComponent
                        name={'internalNotes'}
                        label={'Internal Notes'}
                        style={vivliPrivateFieldStyle}
                    />
                </div>
                <div>

                    <DatePickerFormFieldComponent
                        name={'nextActionDate'}
                        label={'Next Action Date'}
                        optClassName="vivliPrivateDateFieldStyle"
                        style={vivliPrivateFieldStyle}

                    />
                    <TextAreaFormFieldComponent
                        name={'nextActionNotes'}
                        label={'Next Action Notes'}
                        style={vivliPrivateFieldStyle}
                    />
                    <CheckboxFormFieldComponent
                        name={'datasets[0].bypassDataContributorReview'}
                        label={'Bypass Data Contributor Review'}
                        reverseOrientation={true}
                        truncateLabel={false}
                    />
                    <CheckboxFormFieldComponent
                        name={'datasets[0].bypassIRPReview'}
                        label={'Bypass IRP Review'}
                        reverseOrientation={true}
                        truncateLabel={false}
                    />
                    <div style={studyScreenSytle} onClick={studyUrl}>Click here for full Study Screen</div>
                </div>

            </div>
        </>
    )
}
