import React, {CSSProperties, useEffect, useRef, useState} from 'react';
import {finalize, first, map} from "rxjs/operators";
import {DataTransformationService} from "@vivli/shared/infrastructure/service";

interface BasicCheckboxComponent {
    style?: CSSProperties;
    name?: string;
    checked?: boolean;
    onChange?: (checked: boolean) => void;
}

export const BasicCheckboxComponent = ({ style, name, checked, onChange }: BasicCheckboxComponent) => {
    const [isChecked, setIsChecked] = useState<boolean>(checked || false);
    const checkboxRef = useRef<HTMLInputElement>();

    const handleOnChange = (e) => {
        setIsChecked(e.target.checked)
        onChange && onChange(e.target.checked);
    }

        useEffect(() => {
            if (checked === isChecked) {
                return;
            }
            setIsChecked(checked);
        }, [checked])

    return <input
        ref={checkboxRef}
        type='checkbox'
        style={style}
        name={name}
        checked={isChecked}
        onChange={handleOnChange}
    />;
}
