﻿import React, { CSSProperties } from 'react';
import { useParams } from 'react-router-dom';
import { useDataRequest } from '@vivli/features/data-requests/infrastructure/hook';
import { DataRequestContext } from '@vivli/features/data-requests/infrastructure/context';
import { LoadIndicatorCenteredComponent } from '@vivli/shared/components';
import { AmrDataRequestComponent } from '@vivli/features/data-requests/components';
import { DataRequestContextWrapper, StatusUpdateContextWrapper } from '@vivli/features/data-requests/infrastructure/wrappers';
import {NotificationContextWrapper} from "@vivli/shared/features/notification/infrastructure/wrappers";

const containerStyle: CSSProperties = {
    backgroundColor: 'rgb(233, 234, 239)',
    overflow: 'hidden',
    transform: 'translate3d(0,0,0)', // force chrome to use GPU for rendering - fixes UI 'whiteout' glitch related to scrollbars (I think),
    display: 'flex',
    flexDirection: 'column',
    minWidth: '100%',
    flexGrow: 2,
    height: '100%',
};

export const AmrDataRequestFeature = () => {
    const { requestId } = useParams();
    const { dataRequest } = useDataRequest(requestId);

    if (!dataRequest) {
        return <LoadIndicatorCenteredComponent />;
    }

    return (
        <div style={containerStyle}>
            <DataRequestContextWrapper dataRequest={dataRequest}>
                <DataRequestContext.Consumer>
                    {({ organizations }) => {
                        if (!organizations) {
                            return <LoadIndicatorCenteredComponent />;
                        }

                        return (
                            <StatusUpdateContextWrapper dataRequest={dataRequest}>
                            <NotificationContextWrapper>
                                <AmrDataRequestComponent />
                            </NotificationContextWrapper>
                            </StatusUpdateContextWrapper>
                        );
                    }}
                </DataRequestContext.Consumer>
            </DataRequestContextWrapper>
        </div>
    );
};
