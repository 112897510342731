import React from 'react';
import {useDataRequestContext} from "@vivli/features/data-requests/infrastructure/context";
import {useActiveUser} from "@vivli/core/infrastructure/context";
import {LoadIndicatorCenteredComponent} from "@vivli/shared/components";
import {DataRequestStatusEnum, VmAdminApprovalStatusEnum} from "@vivli/features/data-requests/infrastructure/enum";
import {BlockMessageComponent} from "../block-message.component";
import {EsignComponent} from "../esign.component";

const environmentMessages = {
    notAvailable: 'RESEARCH ENVIRONMENT IS NOT AVAILABLE FOR THIS DATA REQUEST UNTIL IT IS PARTIALLY FULFILLED.',
    notAuthorized: 'YOU ARE NOT AUTHORIZED TO REQUEST A RESEARCH ENVIRONMENT FOR THIS DATA REQUEST.',
    notDuaApproved: 'YOUR DUA MUST BE APPROVED BEFORE YOU REQUEST OR ACCESS A RESEARCH ENVIRONMENT FOR THIS DATA REQUEST. PLEASE CONTACT YOUR VIVLI ADMIN.',
    notSigned: 'YOU MUST E-SIGN THE VIVLI TERMS OF USE AGREEMENT TO CONTINUE.',
    notSignedInProcess: 'YOU MUST E-SIGN THE VIVLI TERMS OF USE AGREEMENT TO CONTINUE. PROCESSING THIS MAY TAKE SOME TIME; PLEASE RETURN IN A WHILE.'
}

export const VmStatusGuardComponent = ({children}) => {
    const {dataRequest} = useDataRequestContext();
    const user = useActiveUser();

    if (!dataRequest) {
        return <LoadIndicatorCenteredComponent />;
    }

    const userIsOwner = user.userId === dataRequest.userId;
    const userDetails = dataRequest
        .researchTeam
        .researchTeamDetails
        .find(x => x.userId === user.userId);

    const isPartiallyFulfilledOrFulfilled = dataRequest.status === DataRequestStatusEnum.Fulfilled ||
        dataRequest.status === DataRequestStatusEnum.PartiallyFulfilled
    const isAuthorizedToRequestVm = userIsOwner || userDetails?.hasAccessToVirtualMachineResource || userDetails?.isLeadResearcher;
    const isDuaApproved = userDetails?.adminApprovalStatus === VmAdminApprovalStatusEnum.Approved ||
        userDetails?.adminApprovalStatus === VmAdminApprovalStatusEnum.Automatic;
    const isSigned = dataRequest?.requirementsAndAttestations?.userIDsHaveAgreedToDataUse.some(x => x === user.userId);

    if (!isPartiallyFulfilledOrFulfilled) {
        return <BlockMessageComponent message={environmentMessages.notAvailable} />
    }

    if (!isAuthorizedToRequestVm) {
        return <BlockMessageComponent message={environmentMessages.notAuthorized} />
    }

    if (!isDuaApproved) {
        return <BlockMessageComponent message={environmentMessages.notDuaApproved} />
    }

    if (!isSigned) {
        return <BlockMessageComponent message={environmentMessages.notSigned}>
            <EsignComponent />
        </BlockMessageComponent>
    }

    return children;
}
