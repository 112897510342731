﻿import React from "react";
import {IVivliConfiguration} from "@vivli/shared/infrastructure/interface";

export const creditHint = (
    <a href={'https://credit.niso.org/'} target={'_blank'}>CRediT – Contributor Roles Taxonomy (niso.org)</a>
)

export const vivliOrgHint = 'In order to post your study on the platform, Vivli needs to associate ' +
    'the study with either an existing organization or a new organization within the Vivli Platform. ' +
    'This field shows the name assigned to that organization within the Vivli system.';

export const additionalInfoHint = 'Any information that you provide in this field will be visible to ' +
    'researchers searching for studies. You can include any citations related to your clinical ' +
    'research, or any other information that might be used by the researcher to determine ' +
    'whether your study will support their research.';

export const funderEmailHint = 'If the study was funded by one or more external funders, ' +
    'we expect that the grant(s) included funds to support the sharing of the data. ' +
    'Vivli will issue an invoice for the cost of posting the study. Please provide the email ' +
    'of a contact within your organization who can manage payment of that invoice; normally ' +
    'this will be someone who oversees external grants.';

export const embargoHint = 'If you need to embargo your data, we will make the study available for ' +
    'researchers to request, but the data itself will not be provided until the embargo date has ' +
    'passed.  This might be necessary, for example, if the data itself cannot be provided until the ' +
    'results of the study are published.';

export const contactHint = 'If you are willing to be contacted, the Vivli team will email you any ' +
    'requests for collaboration or questions.  Making yourself available for contact does not imply ' +
    'a commitment to collaborate on any or all requests – it is your decision to answer questions or ' +
    'collaborate on a case-by-case basis.';

export const anonymizedHint = 'If you need help anonymizing your clinical research data, Vivli can connect ' +
    'you with vendors who can help. Please note that it is the data contributor’s responsibility to ensure ' +
    'that the data is appropriately anonymized.';

export const dataPackageHint = (vivliConfig: IVivliConfiguration) =>(
    <div>
        It is expected that all data packages will include the following 4 file types to support the researcher’s use of your data:
        <ul>
            {  vivliConfig?.ipdFileTypes?.filter((x) => x.requiredCtBasic).map((x) => <li><b>{x.fileType}</b> - {x.hint}</li>)}
        </ul>
        Any other documents that may be useful to the researcher can be included and will be welcomed.
    </div>
)

export const signAgreementHint = 'This will open a new browser tab to begin the DocuSign legal agreement ' +
    'signing process that will allow you to provide some basic information about you and your organization. ' +
    'Once you have signed the agreement it will be sent to an individual who can sign on behalf of your ' +
    'organization. If you have any questions about this, please use the chat function.';

