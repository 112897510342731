import React from 'react';
import {GridComponent} from '@vivli/shared/components';
import {useActiveUser} from '@vivli/core/infrastructure/context'
import {ColDef, GridApi} from 'ag-grid-community';
import {useNavigate} from "react-router-dom";
import {IEnquiry} from "@vivli/features/enquiry/infastructure/interface";
import {EnquiryStatusEnum} from "@vivli/features/enquiry/infastructure/enum";
import {GridCellRendererEnum} from "@vivli/shared/infrastructure/enum";

interface EnquiresGridComponentProps {
    enquires: IEnquiry[];
    type: string;
    onGridReady?: (gridApi: GridApi) => void;
    emptyMessage?: string;
    hideFloatingFilter?: boolean;
    hideHeader?: boolean;
    hideUpdatedColumn?: boolean;
    hideCreatedColumn?: boolean;
    hideDateColumn?: boolean;
    autoHeight?: boolean;
    dataRefreshing?: boolean;
}


export abstract class StatusFormat {
    public static formatStatus(status:EnquiryStatusEnum): string {
        switch (status){
            case EnquiryStatusEnum.Draft:
                return 'Draft';
            case EnquiryStatusEnum.EnquiryValidation:
                return 'Enquiry Validation';
            case EnquiryStatusEnum.Review:
                return 'Review';
            case EnquiryStatusEnum.Archived:
                return 'Archived';
            case EnquiryStatusEnum.Withdrawn:
                return 'Withdrawn';
        }
    }
}
export const EnquiresGridComponent = (
    {
        enquires,
        onGridReady,
        type,
        emptyMessage,
        hideFloatingFilter,
        hideHeader,
        hideUpdatedColumn,
        hideCreatedColumn,
        hideDateColumn,
        autoHeight,
        dataRefreshing
    }: EnquiresGridComponentProps) => {
    const user = useActiveUser();
    const navigate = useNavigate();


    const columnDefs: ColDef[] = [
        {
            field: 'id',
            headerName: 'ID',
            filter: true,
            sortable: true,
            tooltipField: 'id',
            headerTooltip: 'Enquiry Id',
            minWidth: 50,
            maxWidth: 100
        }, {
            field: 'requesterName',
            headerName: 'Requester',
            filter: true,
            sortable: true,
            tooltipField: 'requesterName',
            headerTooltip: 'Requester',
            minWidth: 280
        }, {
            field: 'enquiryPurpose',
            headerName: 'Purpose',
            filter: true,
            sortable: true,
            headerTooltip: 'Purpose',
            minWidth: 380
        }, {
            field: 'updatedDate',
            headerName: 'Updated',
            filter: true,
            sortable: true,
            headerTooltip: 'Last Update',
            minWidth: 100,
            hide: hideUpdatedColumn,
            cellRenderer: GridCellRendererEnum.GridCellDateTime
        }, {
            field: 'submittedDate',
            headerName: type,
            filter: true,
            sortable: true,
            headerTooltip: 'Date Submitted',
            minWidth: 100,
            hide: hideDateColumn,
            cellRenderer: GridCellRendererEnum.GridCellDateTime
        }, {
            field: 'createdDate',
            headerName: 'Drafted',
            filter: true,
            sortable: true,
            headerTooltip: 'Date Created',
            minWidth: 100,
            hide: hideCreatedColumn,
            cellRenderer: GridCellRendererEnum.GridCellDateTime
        }, {
            field: 'status',
            headerName: 'Status',
            filter: true,
            sortable: true,
            headerTooltip: 'Status',
            minWidth: 50,
            valueFormatter: params => StatusFormat.formatStatus(params.data.status),
        }, {
            field: 'statusForDownload',
            headerName: 'Status',
            filter: true,
            sortable: true,
            headerTooltip: 'Status Description',
            minWidth: 50,
            hide: true,
        }, {
            field: 'dataInfoRequestsCount',
            headerName: '# of Studies',
            filter: true,
            sortable: true,
            headerTooltip: 'Number of Studies',
            minWidth: 50,
            cellStyle: {textAlign: 'center'}
        }

    ];


    const handleRowClick = (enquiry: IEnquiry) => {
        navigate(`/admin/enquiry/${enquiry.id}`);
    }

    const asyncUpdatePredicate = (oldVal: IEnquiry, newVal: IEnquiry) => {
        return oldVal.status !== newVal.status;
    }

    return (
        <GridComponent
            rowData={enquires}
            fullWidthColumns={true}
            onRowClick={handleRowClick}
            onGridReady={onGridReady}
            columns={columnDefs}
            emptyMessage={emptyMessage}
            hideFloatingFilter={hideFloatingFilter}
            hideHeader={hideHeader}
            autoHeight={autoHeight}
            pagination={true}
            asyncUpdateKey='id'
            asyncUpdatePredicate={asyncUpdatePredicate}
            dataRefreshing={dataRefreshing}
        />
    )
}
