import {Msal2FakeService} from "./msal-2-fake.service";
import {Msal2AuthService} from "./msal-2-auth.service";
import {IMsal2ProviderOptions} from "../lib/interface/msal-2-provider-options.interface";
import {IMsal2AuthService} from "./interface";
import {environment} from "@vivli/core/environments";

export abstract class Msal2AuthFactory {

    public static Create(options: IMsal2ProviderOptions): IMsal2AuthService {
        if (environment.isAutomation && !environment.production) {
            return new Msal2FakeService(options)
        }

        return new Msal2AuthService(options);
    }
}
