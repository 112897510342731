import React, { CSSProperties, useState } from 'react';
import { Color, Size } from '@vivli/shared/theme';
import { ButtonComponent } from '@vivli/shared/components';
import { VMSizeEnum } from '@vivli/features/virtual-machine/infrastructure/enum';
import { IVirtualMachineType } from '@vivli/shared/infrastructure/interface';
import { AssetsConstant } from '@vivli/shared/infrastructure/constants';

const gridColumnTitleStyle: CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 'bold',
    color: Color.VIVLI_DARK_BLUE,
    minHeight: 50,
    justifyContent: 'center',
};

const buttonContainerStyle: CSSProperties = {
    padding: Size.PADDING,
    display: 'flex',
    justifyContent: 'center',
};

const buttonStyle: CSSProperties = {
    minHeight: 50,
    paddingRight: 50,
    backgroundImage: `url(${AssetsConstant.WHITE_RIGHT_ARROW})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right 20px center',
};

const columnContainerStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
};

const checkmarkStyle: CSSProperties = {
    minHeight: 50,
    paddingRight: 50,
    backgroundImage: `url(${AssetsConstant.PICO_CHECKMARK_BLACK})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'left 10px center',
};

const strataStyle: CSSProperties = {
    transform: 'scale(2)',
    marginRight: Size.PADDING,
};

const licenseStyle: CSSProperties = {
    transform: 'scale(2)',
    marginRight: Size.PADDING,
};

const cellStyle: CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: 'rgb(23, 150, 197)',
    flex: '1',
    padding: '5px 10px',
    backgroundColor: 'white',
    minHeight: '65px',
    textAlign: 'center',
};

const containerStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
};

interface VmTableColumnComponentProps {
    researchVmType: IVirtualMachineType;
    isWorking: boolean;
    selectedVmSize: VMSizeEnum;
    provisionVm: (size: VMSizeEnum, licenseSAS: boolean, licenseSTATA: boolean) => void;
}

export const VmTableColumnComponent = ({ researchVmType, isWorking, selectedVmSize, provisionVm }: VmTableColumnComponentProps) => {
    const [activeResearchVmType, setActiveResearchVmType] = useState<IVirtualMachineType>(researchVmType);

    const { title, initialCost, afterInitialPeriod, machineSize, jupyterNotebook, pythonR, stata, sas, startTitle, vmSize } =
        activeResearchVmType;

    const buttonDataId = `${startTitle.replace(' ', '_').toLowerCase()}_button`;

    return (
        <div style={containerStyle} key={title}>
            <div style={gridColumnTitleStyle}>{title}</div>

            <div style={columnContainerStyle}>
                <div
                    style={{
                        ...cellStyle,
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <div>{initialCost}</div>
                    {/* <div><small>{continuousExplanation}</small></div> */}
                </div>
                <div style={cellStyle}>
                    <div>{afterInitialPeriod}</div>
                </div>
                <div style={cellStyle}>{machineSize}</div>
                <div style={cellStyle}>{jupyterNotebook && <div style={checkmarkStyle} />}</div>
                <div style={cellStyle}>{pythonR && <div style={checkmarkStyle} />}</div>
                <div style={cellStyle}>
                    {/* null is a valid option to not show this field */}
                    {stata !== null && (
                        <input
                            style={strataStyle}
                            type="checkbox"
                            checked={stata}
                            onChange={(value) => {
                                setActiveResearchVmType({
                                    ...activeResearchVmType,
                                    stata: value.target.checked,
                                });
                            }}
                        />
                    )}
                </div>
                <div style={cellStyle}>
                    {/* null is a valid option to not show this field */}
                    {sas !== null && (
                        <input
                            style={licenseStyle}
                            type="checkbox"
                            checked={sas}
                            onChange={(value) => {
                                setActiveResearchVmType({
                                    ...activeResearchVmType,
                                    sas: value.target.checked,
                                });
                            }}
                        />
                    )}
                </div>
            </div>

            <div style={buttonContainerStyle}>
                <ButtonComponent
                    style={buttonStyle}
                    isLoading={isWorking && selectedVmSize === vmSize}
                    disabled={isWorking && selectedVmSize !== vmSize}
                    onClick={() => provisionVm(vmSize, sas, stata)}
                    dataId={buttonDataId}
                >
                    {startTitle}
                </ButtonComponent>
            </div>
        </div>
    );
};
