import React, {CSSProperties} from 'react';
import {IFormField} from "@vivli/shared/infrastructure/interface";
import {BaseControlledFormFieldComponent} from "./base-controlled-form-field.component";
import {CheckboxComponent} from "../input/checkbox.component";

interface CheckboxFormFieldComponentProps extends IFormField {
    reverseOrientation?: boolean;
    name: string;
    inputStyle?: CSSProperties;
    disabled?: boolean;
}

export const CheckboxFormFieldComponent = (props: CheckboxFormFieldComponentProps) => {
    const { style, reverseOrientation } = props;
    let checkboxStyle: CSSProperties = {...style};
    if (reverseOrientation) {
        checkboxStyle = {
            ...style,
            flexDirection: 'row-reverse',
            justifyContent: 'flex-end'
        }
    }

    return (
        <BaseControlledFormFieldComponent {...props}>
            <CheckboxComponent {...props} style={checkboxStyle} />
        </BaseControlledFormFieldComponent>
    )
}
