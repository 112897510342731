import {createContext, useContext} from 'react';
import {IDataRequest} from '@vivli/features/data-requests/infrastructure/interface';

export interface ISharedDataContext {
    myDataRequests: {
        myDataRequests: IDataRequest[];
        setMyDataRequests: (myDataRequests: IDataRequest[]) => void;
        addDataRequest: (dataRequest: IDataRequest) => void;
        draftCount: number;
        inProgressCount: number;
        fulfilledCount: number;
        notApprovedCount: number;
        archivedCount: number;
        showRequestAddedBadge: boolean;
        popRequestAddedBadge: () => void;
    };
}

export const SharedDataContext = createContext<ISharedDataContext>(null);

export const useSharedDataContext = () => useContext(SharedDataContext);
