import React from 'react';
import { useResearchEnvironmentContext } from '@vivli/features/data-requests/infrastructure/context';
import { LoadIndicatorCenteredComponent } from '@vivli/shared/components';
import { ProvisionVirtualMachineComponent } from './provision-virtual-machine.component';
import { VmStatusEnum } from '@vivli/features/virtual-machine/infrastructure/enum';
import { VmProvisioningViewComponent } from './provision-views/vm-provisioning-view.component';
import { VmDeprovisionedViewComponent } from './provision-views/vm-deprovisioned-view.component';
import { VmProvisioningFailedViewComponent } from './failed-views/vm-provisioning-failed-view.component';
import { VmDeprovisioningFailedViewComponent } from './failed-views/vm-deprovisioning-failed-view.component';
import { VmAddingDataPackagesFailedViewComponent } from './failed-views/vm-adding-data-packages-failed-view.component';
import { VmStartingFailedViewComponent } from './failed-views/vm-starting-failed-view.component';
import { VmStoppingFailedViewComponent } from './failed-views/vm-stopping-failed-view.component';
import { VmProvisionedViewComponent } from './provision-views/vm-provisioned-view.component';

export const ResearchEnvironmentComponent = () => {
    const { virtualMachine, isLoadingVirtualMachine, provisionStatus } = useResearchEnvironmentContext();

    if (isLoadingVirtualMachine) {
        return <LoadIndicatorCenteredComponent />;
    }

    if (!virtualMachine && provisionStatus != VmStatusEnum.Provisioning) {
        return <ProvisionVirtualMachineComponent />;
    }

    switch (provisionStatus) {
        case VmStatusEnum.AddingDataPackages:
        case VmStatusEnum.Stopping:
        case VmStatusEnum.Starting:
        case VmStatusEnum.DeProvisioning:
        case VmStatusEnum.Provisioning:
            return <VmProvisioningViewComponent />;
        case VmStatusEnum.ProvisioningFailed:
            return <VmProvisioningFailedViewComponent />;
        case VmStatusEnum.DeProvisioningFailed:
            return <VmDeprovisioningFailedViewComponent />;
        case VmStatusEnum.AddingDataPackagesFailed:
            return <VmAddingDataPackagesFailedViewComponent />;
        case VmStatusEnum.StartingFailed:
            return <VmStartingFailedViewComponent />;
        case VmStatusEnum.StoppingFailed:
            return <VmStoppingFailedViewComponent />;
        case VmStatusEnum.DeProvisioned:
            return <VmDeprovisionedViewComponent />;
        default:
            return <VmProvisionedViewComponent />;
    }
};
