﻿import {
    CheckboxFormFieldComponent,
    DatePickerFormFieldComponent,
    TextAreaFormFieldComponent,
    TextFormFieldComponent
} from "@vivli/shared/components";
import React, {CSSProperties, useEffect} from "react";
import {Size} from "@vivli/shared/theme";
import {useNotificationContext} from "@vivli/shared/features/notification/infrastructure/context";
import {useNotificationForm} from "../../../infrastructure/hook/src/lib/form-hook/use-notification-form.hook";
import {FormProvider} from "react-hook-form";

const vivliPrivateFieldStyle: CSSProperties = {
    backgroundColor: 'rgb(255, 246, 217)'
}

const mainContainerStyle: CSSProperties = {
    paddingLeft: Size.INSET_SIZE,
    paddingRight: Size.INSET_SIZE,
    paddingBottom: Size.INSET_SIZE,
}

const formContainerStyle: CSSProperties = {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    columnGap: '20px',
};

const buttonContainerStyle: CSSProperties = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
};
const textStyle: CSSProperties = {
    margin: "20px",
    display: "flex"
}
const dateText: CSSProperties = {
    marginLeft: "10px",
    fontWeight: 600
}
const rightSectionStyle: CSSProperties = {
    display: "flex",
    flexDirection: "column",
    gap: '30px'
}
export const NotificationFormComponent = ({notification}) => {

    const {
        setNotificationFormApi,
        firstAndLastDates,
        lastSentMessageState,
    } = useNotificationContext()
    const formApi = useNotificationForm(notification)

    useEffect(() => {
        setNotificationFormApi(formApi)
    }, [formApi.formState.isValid])


    return (
        <FormProvider {...formApi}>
            <div style={mainContainerStyle}>
                <div style={{marginBottom: "10px"}}>
                    <h5>Notifications will be posted to Open Chat</h5>


                    <CheckboxFormFieldComponent
                        name={'active'}
                        label={'Active'}
                        reverseOrientation={true}
                        style={null}/>

                </div>
                <div style={formContainerStyle}>
                    <div>
                        <TextAreaFormFieldComponent
                            name={'notificationText'}
                            label={'Notification Content'}
                            style={vivliPrivateFieldStyle}/>


                        <div style={{paddingTop: "20px"}}>
                            <div style={textStyle}>Previous message was posted on:
                                <div
                                    style={dateText}>{firstAndLastDates[0] ? (firstAndLastDates[0]) : lastSentMessageState}</div>
                            </div>
                            <div style={textStyle}>Next message scheduled for:
                                <div style={dateText}>{firstAndLastDates[1] ? firstAndLastDates[1] : "N/A"}</div>
                            </div>
                        </div>

                        <div>
                        </div>
                    </div>

                    <div style={rightSectionStyle}>
                        <DatePickerFormFieldComponent
                            name={'startDate'}
                            label={'Start Date'}
                            optClassName="vivliPrivateDateFieldStyle"
                            style={vivliPrivateFieldStyle}/>

                        <TextFormFieldComponent
                            name={'notificationFrequency'}
                            label={'Frequency (Days)'}
                            style={vivliPrivateFieldStyle}/>

                        <DatePickerFormFieldComponent
                            name={'endDate'}
                            label={'End Date/ Next Checkin'}
                            optClassName="vivliPrivateDateFieldStyle"
                            style={vivliPrivateFieldStyle}/>
                    </div>

                </div>
            </div>
        </FormProvider>
    )
}
