﻿import {RestApi} from "@vivli/core/infrastructure/rest";
import {
    IMetricsApiService,
    IMetricsReport, IVivliAdminSummary
} from "@vivli/features/dashboard/infrastructure/interface";
import {Observable} from "rxjs";

export class MetricsApiService extends RestApi implements IMetricsApiService {
    getMetricsReport = (queryString: string, orgId: string): Observable<IMetricsReport> => {
        return this.handleGet<IMetricsReport>(`/metricsReport/${orgId}?${queryString}`);
    }

    getAggregateMetricsReport = (queryString: string): Observable<IMetricsReport> => {
        return this.handleGet<IMetricsReport>(`/metricsReport?${queryString}`);
    }

    getAdminSummary = (queryString: string): Observable<IVivliAdminSummary> => {
        return this.handleGet<IVivliAdminSummary>(`/metricsReport/summary?${queryString}`);
    }
}
