import {VmStatusEnum} from "@vivli/features/virtual-machine/infrastructure/enum";
import {LoggerService, PubSubService} from "@vivli/shared/infrastructure/service";
import {useConfigService} from "@vivli/core/infrastructure/context";
import {useEffect, useRef} from "react";
import {IDataRequest} from "@vivli/features/data-requests/infrastructure/interface";



export function useDrPubSubHook(dataRequest:IDataRequest, updateOperationStatus: (message: any) => void)
{
    const config = useConfigService();
    const pubSubService = useRef<PubSubService>(new PubSubService(config.environmentName, config.apiUri));
    const isSubscribedToMessages = useRef<boolean>(false);
    const pollRef = useRef<number>(null);

    const subscribeToDrMessages = ( callback: (message: string) => void) => {

        if (!isSubscribedToMessages.current && dataRequest) {
            pubSubService.current?.SubscribeToEvent(`DR:${dataRequest.id}`, 'DrProcessingMessage', callback);
            isSubscribedToMessages.current = true;
        } else if (!isSubscribedToMessages.current && !dataRequest) {
            //if dr is not defined yet - try again in 15 seconds
            window.setTimeout(() => {
                subscribeToDrMessages(callback);
            }, 15100);
        }
    };

    const closeConnections = () => {

        if (pollRef.current !== null) {
            clearInterval(pollRef.current);
        }

        isSubscribedToMessages.current = false;
        pubSubService.current?.CloseConnection();
    }

    const closeChannel = () => {
        isSubscribedToMessages.current = false;
        pubSubService.current?.UnsubscribeFromChannel(`DR:${dataRequest?.id}`);
    }

    useEffect(() => {
        return () => {
            closeConnections();
        }
    }, []);

    useEffect(() => {
        subscribeToDrMessages(updateOperationStatus); //initiate subscription
    }, [dataRequest]);


    return {
        subscribeToDrMessages,
        closeConnections,
        closeChannel
    }
}
