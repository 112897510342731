import { Route, Routes } from 'react-router-dom';
import {
    ReportFeature,
} from '@vivli/features/dashboard/feature';
import {
    OrganizationDetailsFeature,
    OrganizationsFeature,
} from '@vivli/features/organizations/feature';
import {
    AdminStudyFeature,
    AnnotateStudyFeature,
    AwaitingUploadStudiesFeature,
    BulkUpdateStudiesFeature,
    BulkUploadStudiesFeature,
    StudiesFeature,
    SubmitStudyFeature,
} from '@vivli/features/studies/feature';
import {UserDetailsFeature, UsersFeature} from '@vivli/features/users/feature';
import { ManageVmsFeature } from '@vivli/features/virtual-machine/feature';
import { DataRequestsFeature } from '@vivli/features/data-requests/feature';
import { ListingRequestsFeature } from '@vivli/features/listing-request/feature';
import { AdminConsoleRequestsFeature } from '@vivli/features/admin-console/feature';
import React from 'react';
import {EnquiresFeature, EnquiryFeature} from "@vivli/features/enquiry/feature";
import {DashboardFeatureRouteWrapper} from "@vivli/features/dashboard/infrastructure/wrappers";

export const PortalRoutesComponent = () => (
    <Routes>
        <Route index path="dashboard" element={<DashboardFeatureRouteWrapper/>} />
        <Route path="orgs" element={<OrganizationsFeature />} />
        <Route
            path="studies/submitStudy/:studyId?/:tab?"
            element={<SubmitStudyFeature />}
        />
        <Route
            path="studies/annotateStudy/:studyId/:tab?"
            element={<AnnotateStudyFeature />}
        />
        <Route path="studies/:studyId/:tab" element={<AdminStudyFeature />} />
        <Route path="studies/:type?" element={<StudiesFeature />} />
        <Route path="users" element={<UsersFeature />} />
        <Route path="users/:userId" element={<UserDetailsFeature/>} />

        <Route path="vms/:vm?" element={<ManageVmsFeature />} />
        <Route
            path="requests/:requestStatus?"
            element={<DataRequestsFeature />}
        />
        <Route
            path="awaitingUpload/"
            element={<AwaitingUploadStudiesFeature />}
        />
        <Route
            path="orgs/:orgId/:tab?"
            element={<OrganizationDetailsFeature />}
        />
        <Route index path="dashboard" element={<DashboardFeatureRouteWrapper/>} />
        <Route
            path="bulkUploadStudies/:useSecureStorage?"
            element={<BulkUploadStudiesFeature />}
        />
        <Route
            path="bulkUpdateStudies"
            element={<BulkUpdateStudiesFeature />}
        />
        <Route path="report" element={<ReportFeature />} />
        <Route
            path="study-submissions/*"
            element={<ListingRequestsFeature />}
        />
        <Route
            path="adminConsole/:tab"
            element={<AdminConsoleRequestsFeature />}
        />
        <Route path="enquiries/:tab?"
            element={<EnquiresFeature />} />
        <Route path="/enquiry/:id?" element={<EnquiryFeature/>} />
    </Routes>
);
