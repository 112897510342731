import {useEffect, useState} from "react";
import {useStudiesService} from "@vivli/features/studies/infrastructure/context";
import {IStudy} from "@vivli/features/studies/infrastructure/interface";
import {useConfigService} from "@vivli/core/infrastructure/context";
import {finalize, first, map} from "rxjs/operators";
import {DataTransformationService} from "@vivli/shared/infrastructure/service";

export function useStudies() {
    const [studies, setStudies] = useState<IStudy[]>();
    const [isLoadingStudies, setIsLoadingStudies] = useState(false);

    const studiesService = useStudiesService();
    const config = useConfigService();

    useEffect(() => {

        const appType = config.assignedAppType;
        setIsLoadingStudies(true)
        const sub = studiesService.getAllStudies(appType)
            .pipe(
                first(),
                map(DataTransformationService.formatStudies),
                finalize(() => setIsLoadingStudies(false))
            )
            .subscribe(setStudies);

        return () => {
            sub.unsubscribe();
        }
    }, [])

    return {
        studies,
        isLoadingStudies
    }
}
