﻿import {ButtonComponent, TextFieldComponent,} from "@vivli/shared/components";
import React, {CSSProperties, useEffect, useState} from "react";
import {Size, Styles} from "@vivli/shared/theme";
import {ICitation} from "@vivli/shared/features/citations/infrastructure/interface";
import {
    CitationAuthorsComponent,
    oneAcrossStyleNoBox,
    titleRowStyle
} from "@vivli/shared/features/citations/components";
import {DTIAMRCommonConst, DTIFormFields} from "@vivli/shared/infrastructure/constants";

const buttonContainerStyle: CSSProperties = {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    columnGap: '10px',
};

const genericStyle: CSSProperties = {
    marginTop: Size.FIELD_SEPARATION,
    marginBottom: Size.FIELD_SEPARATION,
}

const fourAcrossStyleNoBox: CSSProperties = {
    ...genericStyle,
    flex: '1 1 25%',
    maxWidth: '24%',
}

const threeAcrossStyleButton: CSSProperties = {
    ...genericStyle,
    flex: '1 1 20%',
    flexDirection: 'column',
    maxWidth: '20%',
    marginTop: Size.FIELD_SEPARATION * 2
}

export const twoThirdsAcrossStyleSmallNoBox: CSSProperties = {
    ...genericStyle,
    flex: '1 1 100%',
    maxWidth: '100%',
}

interface EditCitationFormComponentProps {
    citation?: ICitation;
    defaultCitationObject?: ICitation;
    onSave: (citation: ICitation) => void
    onCancel: () => void;
}

export const EditCitationFormComponent = ({
                                              citation,
                                              defaultCitationObject,
                                              onSave,
                                              onCancel}: EditCitationFormComponentProps) => {


    const [newCitation, setNewCitation] = useState<ICitation>(null);

    const isUpdate = citation !== null && citation !== undefined;
    const saveButtonText = isUpdate ? 'Update' : 'Add';
    const titleManual = isUpdate ? 'Update Citation' : 'Add a Citation Manually';
    const titleAuto: string = 'Press Update to refresh the citation from its DOI registry';
    const titleToUse = isUpdate && citation?.doi ? titleAuto : titleManual;

    //update form is readonly if we have a citation and it has a DOI - updates done
    //by refreshing from the DOI registry
    const readOnly = titleToUse === titleAuto;

    const handleOnSave = () => {
        onSave(newCitation);
    };

    const addAuthorToCitation = () => {
        const newAuthor = {givenName: '', familyName: ''}
        setNewCitation({...newCitation, authors: [...newCitation?.authors, newAuthor]})
    }

    const removeAuthorFromCitation = (index) => {
        const trimmedCitation = {...newCitation?.authors?.splice(index,1)}
        setNewCitation({...newCitation, authors: [...newCitation?.authors], ...trimmedCitation});
    }

    useEffect(() => {
        if (citation && !newCitation) {
            setNewCitation(citation);

        } else {
            setNewCitation(defaultCitationObject);
        }
    }, []);


    return (

        <div style={{marginTop: '40px'}}>
                <div>
                    <div style={titleRowStyle}>
                        {titleToUse}
                    </div>
                    <div style={Styles.FORM_ROW}>
                        <TextFieldComponent value={newCitation?.title}
                                            name={newCitation?.title}
                                            style={oneAcrossStyleNoBox} label={'Title'}
                                            readonly={readOnly}
                                            onChange={(e) => setNewCitation({...newCitation, title: e.target.value})}
                                            dataId={DTIFormFields.FormTitle}
                        />
                    </div>
                    <div style={Styles.FORM_ROW}>
                        <TextFieldComponent
                            value={newCitation?.url}
                            name="url"
                            style={fourAcrossStyleNoBox}
                            label={'URL'}
                            readonly={readOnly}
                            onChange={(e) => setNewCitation({...newCitation, url: e.target.value})}
                            dataId={DTIFormFields.FormLink}
                        />

                        <TextFieldComponent
                            value={newCitation?.journal}
                            name="journal"
                            style={fourAcrossStyleNoBox}
                            label={'Publication or other organization'}
                            readonly={readOnly}
                            dataId={DTIFormFields.FormJournal}
                            onChange={(e) => setNewCitation({...newCitation, journal: e.target.value})}
                        />

                        <TextFieldComponent
                            value={newCitation?.pages}
                            name="pages"
                            style={fourAcrossStyleNoBox}
                            label={'Location'}
                            readonly={readOnly}
                            dataId={DTIFormFields.FormPages}
                            onChange={(e) => setNewCitation({...newCitation, pages: e.target.value})}
                        />

                        <TextFieldComponent
                            value={newCitation?.yearPublished}
                            name="yearPublished"
                            style={fourAcrossStyleNoBox}
                            label={'Year Published'}
                            readonly={readOnly}
                            dataId={DTIFormFields.FormYear}
                            onChange={(e) => setNewCitation({...newCitation, yearPublished: e.target.value})}
                        />
                    </div>
                    <div style={Styles.FORM_ROW}>
                        <div style={{width: '66%', marginTop: '-10px',   maxHeight: '300px',
                            overflow: 'auto'}}>
                            {
                                newCitation?.authors?.map((author, index) => (
                                    <div style={twoThirdsAcrossStyleSmallNoBox}>
                                        <CitationAuthorsComponent
                                            author={newCitation?.authors[index]}
                                            citation={newCitation}
                                            index={index}
                                            onRemoveAuthor={() => {
                                                removeAuthorFromCitation(index);
                                            }}
                                        />
                                    </div>
                                ))
                            }
                        </div>
                        {!readOnly &&
                            <ButtonComponent
                                style={threeAcrossStyleButton}
                                dataId={DTIAMRCommonConst.AddButton}
                                onClick={() => {
                                    addAuthorToCitation()
                                }}>Add Author
                            </ButtonComponent>
                        }
                    </div>
                </div>
            <div style={buttonContainerStyle}>
                <ButtonComponent style={buttonContainerStyle}
                                 dataId={DTIAMRCommonConst.SaveButton}
                                 onClick={handleOnSave}
                                 disabled={newCitation?.title.length === 0}>
                    {saveButtonText}
                </ButtonComponent>
                <ButtonComponent dataId={DTIAMRCommonConst.CancelButton} style={buttonContainerStyle} onClick={onCancel}>Cancel</ButtonComponent>
            </div>
        </div>


    );

}
