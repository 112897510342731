import React, {CSSProperties, useEffect, useState} from 'react';
import {Size} from "@vivli/shared/theme";
import {DatePickerComponent} from "./input/date-picker.component";
import {IDateRange} from "@vivli/shared/infrastructure/interface";

const dateFieldStyle: CSSProperties = {
    maxWidth: '400px',
    marginRight: '10px',
    marginLeft: '10px'
}

const containerStyle: CSSProperties = {
    display: 'flex'
}

const placeholderText = 'mm/yyyy';

interface DateRangeComponent {
    onChange?: (range: IDateRange) => void;
    value?: IDateRange;
    dateFormat?: string;
}

export const DateRangeComponent = ({ onChange, value, dateFormat }: DateRangeComponent) => {
    const [fromDate, setFromDate] = useState<Date>();
    const [toDate, setToDate] = useState<Date>();

    if (!dateFormat) {
        dateFormat = 'MM/yyyy';
    }

    const handleValueUpdate = () => {
        if (!value) { return; }

        if (value.from?.getTime() !== fromDate?.getTime()) {
            setFromDate(value.from);
        }

        if (value.to?.getTime() !== toDate?.getTime()) {
            setToDate(value.to);
        }
    }

    useEffect(() => {
        if (value?.from?.getTime() !== fromDate?.getTime() ||
            value?.to?.getTime() !== toDate?.getTime()) {
            onChange && onChange({from: fromDate, to: toDate});
        }
    }, [fromDate, toDate])

    useEffect(() => {
        handleValueUpdate();
    }, [value])

    useEffect(() => {
        handleValueUpdate();
    }, [])

    return (
        <div style={containerStyle} className='advanced_dates'>
            <div style={{ paddingRight: Size.HALF_PADDING }}>
                <DatePickerComponent
                    label={'From'}
                    onChange={setFromDate}
                    value={fromDate}
                    style={dateFieldStyle}
                    dateFormat={dateFormat}
                    showMonthYearPicker={true}
                    placeholderText={placeholderText}
                />
            </div>
            <div>
                <DatePickerComponent
                    label={'To'}
                    onChange={setToDate}
                    value={toDate}
                    style={dateFieldStyle}
                    dateFormat={dateFormat}
                    showMonthYearPicker={true}
                    placeholderText={placeholderText}
                />
            </div>
        </div>
    )
}
