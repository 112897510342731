import React, {CSSProperties, useState} from 'react';
import {
    useResearcherSubtitle,
    useResearchTeamCreate,
    useResearchTeamPermissions,
} from '@vivli/features/data-requests/infrastructure/hook';
import {IDataRequest, IResearchTeamMemberDetail} from '@vivli/features/data-requests/infrastructure/interface';
import {ButtonComponent, FieldHeaderComponent} from '@vivli/shared/components';
import {Color, Size} from '@vivli/shared/theme';
import {ResearchTeamMemberRoleEnum} from '@vivli/features/data-requests/infrastructure/enum';
import {useFormContext, useWatch} from 'react-hook-form';
import {TeamMemberFormComponent} from './team-member-form.component';
import {DraftTeamMemberModalComponent} from './draft-team-member-modal.component';
import {useModalService} from '@vivli/shared/infrastructure/context';
import {DraftTeamMemberListItemComponent} from './draft-team-member-list-item.component';
import {first} from 'rxjs/operators';
import {useDataRequestsService} from '@vivli/features/data-requests/infrastructure/context';
import {DTICommonConst} from "@vivli/shared/infrastructure/constants";

const containerStyle: CSSProperties = {
    padding: Size.INSET_SIZE,
    paddingTop: 0,
};

const formContainerStyle: CSSProperties = {
    marginBottom: '40px',
};

const btnStyle = {
    backgroundColor: Color.WHITE,
    borderColor: '#A0A0A0',
    borderWidth: 1,
    borderStyle: 'solid',
    color: '#A0A0A0',
};

const addContainerStyle: CSSProperties = {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '15px',
};

interface ResearchTeamFeatureProps {
    dataRequest?: IDataRequest;
    isLoading?: boolean;
    onDataRequestUpdated?: (dataRequest: IDataRequest) => void;
}

export const DraftResearchTeamComponent = ({
                                               dataRequest,
                                               isLoading,
                                               onDataRequestUpdated
                                           }: ResearchTeamFeatureProps) => {
    const modalService = useModalService();
    const createResearcher = useResearchTeamCreate();
    const useSubtitle = useResearcherSubtitle();
    const {userCanEdit} = useResearchTeamPermissions(dataRequest);
    const dataRequestsService = useDataRequestsService();
    const {control, setValue} = useFormContext();
    const [isChangingOwner, setIsChangingOwner] = useState(false);

    const arrayName = 'researchTeam.researchTeamDetails';
    const formApi=useFormContext()
    const fields = useWatch({name: arrayName}) as IResearchTeamMemberDetail[];

    const getFieldName = (index: number) => `${arrayName}.${index}`;

    const addToFieldArray = (teamMember: IResearchTeamMemberDetail) => {
        const updatedFieldArray = [...fields, teamMember];
        setValue(arrayName, updatedFieldArray, {shouldDirty:true});
    };
    const remove = (index: number, field: IResearchTeamMemberDetail[]) => {
        field.splice(index, 1)
        setValue(arrayName, field, {shouldDirty:true});
    }
    const insert = (index: number, role: IResearchTeamMemberDetail, field: IResearchTeamMemberDetail[]) => {
        field.splice(index, 0, role)
        setValue(arrayName, field, {shouldDirty:true});

    }


    const addResearcher = () => {
        const teamMember = createResearcher(ResearchTeamMemberRoleEnum.AdditionalResearcher);

        const modalId = modalService.custom(
            <DraftTeamMemberModalComponent
                teamMember={teamMember}
                onSave={(teamMember) => {
                    modalService.dismiss(modalId);
                    addToFieldArray(teamMember);
                }}
                onCancel={() => {
                    modalService.dismiss(modalId);
                }}
                dataRequest={dataRequest}
                userCanEdit={userCanEdit}
            />,
            {
                style: {
                    width: '80%',
                },
            }
        );
    };

    const editResearcher = (teamMember: IResearchTeamMemberDetail, fieldIndex: number) => {
        const fieldName = getFieldName(fieldIndex);
        const modalId = modalService.custom(
            <DraftTeamMemberModalComponent
                teamMember={teamMember}
                onSave={(updatedTeamMember) => {
                    modalService.dismiss(modalId);
                    setValue(fieldName, updatedTeamMember, {shouldDirty: true});
                    formApi.trigger();
                }}
                onCancel={() => {
                    modalService.dismiss(modalId);
                }}
                dataRequest={dataRequest}
                userCanEdit={userCanEdit}
            />,
            {
                style: {
                    width: '80%',
                },
            }
        );
    };

    const removeResearcher = (teamMember: IResearchTeamMemberDetail, fieldIndex: number) => {
        const confirmMsg = `Are you sure you want to remove "${teamMember.firstName} ${teamMember.lastName}"?`;
        modalService.confirm(confirmMsg, {
            onConfirm: () => {
                remove(fieldIndex, fields);
            },
        });
    };

    const removeStatistician = () => {
        const statisticianIndex = fields.findIndex((rtd) => rtd.isStatistician && !rtd.isLeadResearcher);
        if (statisticianIndex >= 0) {
            remove(statisticianIndex, fields);
        }
    };
    const addStatistician = () => {
        const statistician = createResearcher(ResearchTeamMemberRoleEnum.Statistician);
        insert(1, statistician, fields);
    };
    const setLeadIsStatistician = (leadIsStatistician: boolean) => {
        const researchTeamDetails = fields;
        const leadMember = researchTeamDetails.find((rtd) => rtd.isLeadResearcher);
        const statisticianMember = researchTeamDetails.find((rtd) => rtd.isStatistician && !rtd.isLeadResearcher);

        if (leadIsStatistician && leadMember.isStatistician) {
            removeStatistician();
        } else if (!statisticianMember && !leadIsStatistician) {
            addStatistician();
        }
    };

    const handleOnMakeOwner = (teamMember: IResearchTeamMemberDetail) => {
        const confirmMsg = `Are you sure you want to make "${teamMember.firstName} ${teamMember.lastName}" the Admin of the data request?`;
        modalService.confirm(confirmMsg, {
            onConfirm: () => {
                setIsChangingOwner(true);
                dataRequestsService
                    .changeDataRequestOwner(dataRequest.id, teamMember)
                    .pipe(first())
                    .subscribe((dr) => {
                        onDataRequestUpdated && onDataRequestUpdated(dr);
                        setIsChangingOwner(false);
                    }, modalService.error);
            },
        });
    };

    return (
        <div style={containerStyle}>
            {fields.map((field, i) => {
                const teamMember = field as IResearchTeamMemberDetail;
                const fieldName = getFieldName(i);
                const subTitle = useSubtitle(teamMember);


                if (teamMember.isLeadResearcher || teamMember.isStatistician) {
                    return (
                        <div key={field.id}>
                            <div style={formContainerStyle}>
                                <TeamMemberFormComponent
                                    setLeadIsStatistician={setLeadIsStatistician}
                                    fieldName={fieldName}
                                    dataRequest={dataRequest}
                                    isLoading={isLoading}
                                    subTitle={subTitle}
                                    readonly={false}
                                    teamMember={teamMember}
                                />
                            </div>

                            {teamMember.isStatistician && (
                                <div style={addContainerStyle}>
                                    <FieldHeaderComponent title={'ADDITIONAL RESEARCHERS'}/>
                                    {userCanEdit && (
                                        <ButtonComponent style={btnStyle} onClick={addResearcher} dataId={DTICommonConst.AddButton}>
                                            Add +
                                        </ButtonComponent>
                                    )}
                                </div>
                            )}
                        </div>
                    );
                } else {
                    return (
                        <div key={field.id}>
                            <DraftTeamMemberListItemComponent
                                fieldIndex={i}
                                fieldName={fieldName}
                                teamMember={teamMember}
                                dataRequest={dataRequest}
                                isLoading={isLoading || isChangingOwner}
                                onRemove={removeResearcher}
                                onEdit={editResearcher}
                                onMakeOwner={handleOnMakeOwner}
                                onDataRequestUpdated={onDataRequestUpdated}
                            />
                        </div>
                    );
                }
            })}
        </div>
    );
};
