import React, { CSSProperties } from 'react';
import { usePicoSearchOptionsContext } from '@vivli/features/search/infrastructure/context';
import Select from 'react-select';
import { SearchInputButtonsComponent } from './search-input-buttons.component';
import { MultiValueContainerComponent } from './pico/multi-value-container.component';

const containerStyle: CSSProperties = {
    width: '100%',
    height: '100%',
    justifyContent: 'space-between',
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '1fr auto',
};

const inputContainerStyle: CSSProperties = {
    height: '100%',
    width: '100%',
    paddingLeft: '10px',
    position: 'relative',
    display: 'grid',
    alignItems: 'center',
};

interface SearchInputComponent {
    disableInput?: boolean;
}

const placeholder = 'What are you looking for today?';

export const SearchSelectComponent = ({ disableInput = false }: SearchInputComponent) => {
    const { updateAutoCompleteItems, autoCompleteItems, setNormalTerms, normalTerms } = usePicoSearchOptionsContext();

    const handleInputChange = (value) => {
        const formattedValue = value?.replace(/ /g, '_');
        const newValue = `${formattedValue}*` || '';

        updateAutoCompleteItems(newValue);
    };

    const customStyles = {
        control: (base) => ({
            ...base,
            border: 0,
            // This line disables the blue border
            boxShadow: 'none',
        }),
        placeholder: (base) => ({
            ...base,
            fontSize: '20px',
            fontFamily: 'sans-serif',
            fontWeight: 400,
        }),
        valueContainer: (base) => ({
            ...base,
            cursor: 'text',
        }),
    };

    const getAutoCompleteItems = () => {
        return autoCompleteItems?.map((item) => ({
            label: item.displayLabel,
            value: item.id,
        }));
    };

    return (
        <div style={containerStyle}>
            <div style={inputContainerStyle}>
                {!disableInput && (
                    <Select
                        value={normalTerms}
                        options={getAutoCompleteItems()}
                        isSearchable={true}
                        isMulti={true}
                        isClearable={true}
                        openMenuOnClick={false}
                        openMenuOnFocus={false}
                        closeMenuOnSelect={true}
                        hideSelectedOptions={true}
                        placeholder={placeholder}
                        components={{
                            DropdownIndicator: () => null,
                            IndicatorSeparator: () => null,
                            MultiValueContainer: MultiValueContainerComponent,
                        }}
                        onChange={(terms) => setNormalTerms(terms.flat())}
                        onInputChange={handleInputChange}
                        noOptionsMessage={() => 'No Terms Found'}
                        backspaceRemovesValue
                        menuPosition={'fixed'}
                        styles={customStyles}
                    />
                )}
            </div>
            <SearchInputButtonsComponent />
        </div>
    );
};
