import React from 'react';
import {VmFailureComponent} from "../vm-failure.component";
import {BlockMessageComponent} from "../block-message.component";
import {useResearchEnvironmentContext} from "@vivli/features/data-requests/infrastructure/context";

export const VmAddingDataPackagesFailedViewComponent = () => {
    const {addDataPackagesToVm} = useResearchEnvironmentContext();

    return (
        <BlockMessageComponent message={'Failed to Add Data Packages to Research Environment'}>
            <VmFailureComponent title={'Retry Adding Data Packages to Research Environment'} onClick={addDataPackagesToVm} isRedButton={true} />
        </BlockMessageComponent>
    )
}
