import React, {CSSProperties} from 'react';
import {Size} from "@vivli/shared/theme";

const containerStyle: CSSProperties = {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
}

const textStyle: CSSProperties = {
    fontSize: 50,
    fontWeight: Size.FontWeight.Light
}

interface ErrorComponentProps {
    message?: string;
}

export const ErrorComponent = ({ message }: ErrorComponentProps) => {
    const defaultMsg = 'An error has occurred. Please refresh the page or contact your administrator.';
    return (
        <div style={containerStyle}>
            <span style={textStyle}>{message || defaultMsg}</span>
        </div>
    )
}
