﻿import {useActiveUser, useConfigService} from "@vivli/core/infrastructure/context";
import {useAssignedAppType} from "@vivli/core/infrastructure/hook";
import {AssignedAppTypeEnum} from "@vivli/shared/infrastructure/enum";
import {IStudy} from "@vivli/features/studies/infrastructure/interface";
import {StudyStatusEnum} from "@vivli/features/studies/infrastructure/enum";
import {DataRequestPermissionsService} from "@vivli/features/data-requests/infrastructure/service";

export function useAdminStudyPermissions(study: IStudy, dataPackageSubmitted: boolean) {
    const activeUser = useActiveUser();
    const appType = useAssignedAppType();
    const config = useConfigService();
    const userIsVivliAdmin = () =>{
        return activeUser.isVivliAdmin;
    }
    const isVivAdmin = userIsVivliAdmin();

    const studyIpdIsDownloadable = (study: IStudy) => {
        const hasDataPackageId = study?.ipdDataPackageId !== null;
        return hasDataPackageId &&
            (study?.downloadableStudyIPDDataPackage);
    }

    const ipdReadyToDownload = () => {
        return (dataPackageSubmitted && studyIpdIsDownloadable(study));
    }

    const userIsInStudyOrg = () => {
        return activeUser?.orgMemberships.some(o => o.orgId === study.orgId);
    }

    const userIsOrgAdmin = () => {
        let isOrgAdmin = false;
        const testOrgAdmin = activeUser?.orgMemberships.some(o => o.orgId === study.orgId && o.isOrgAdmin);
        if (!!testOrgAdmin) {
            isOrgAdmin = testOrgAdmin;
        }
        return isOrgAdmin;
    }

    const userIsOrgDataProvider = () => {
        return activeUser?.orgMemberships.some(o => o.orgId === study.orgId && o.isDataProvider);
    }

    const userIsDataProviderOrgAdmin = () => {
        return activeUser?.orgMemberships.some(o => o.orgId === study.orgId && (o.isDataProvider && o.isOrgAdmin));
    }

    const userIsDatasetOwner = () => {
        return activeUser?.orgMemberships.some(o => o.orgId === study.orgId && (o.isDataProvider && o.isDatasetOwner &&
            study.ownerIds.some(i=>i === activeUser.userId)));
    }

    const userCanRemovePosting = () => {
        return (userIsVivliAdmin() && study.status === StudyStatusEnum.Posted);
    }

    const userCanDownloadIpd = () => {
        const activeUserHasDownloadPermissions =
            appType === AssignedAppTypeEnum.Amr
                ? true  //anyone can download, even a Vivli Admin
                : !activeUser.isVivliAdmin;

        return (activeUserHasDownloadPermissions && dataPackageSubmitted);
    }

    const userCanUploadIpd = () => {
        const activeUserHasUploadPermissions =
            appType === AssignedAppTypeEnum.Amr
                ? true  //anyone can download, even a Vivli Admin
                : !activeUser.isVivliAdmin;

        return activeUserHasUploadPermissions && !dataPackageSubmitted;

    }

    const userCanSeeDataPackage = () => {
        const canAlwaysSee =
            appType === AssignedAppTypeEnum.Amr
                ? true  //anyone can upload or download, even a Vivli Admin
                : userIsVivliAdmin();

        return (canAlwaysSee || userIsOrgDataProvider());
    }

    const canSeeDataCount = () => {
            return config.irusCountOn && study.status === StudyStatusEnum.Posted;
    }

    const userCanApprove = () =>  {
        switch (study?.status) {
            case StudyStatusEnum.SubmittedToVivliForPreCheck:
            case StudyStatusEnum.CurationBlocked:
            case StudyStatusEnum.Posted:
                return isVivAdmin;
            case StudyStatusEnum.AwaitingCuration:
            case StudyStatusEnum.CurationInProgress:
            case StudyStatusEnum.CurationInternalReview:
            case StudyStatusEnum.CurationRevisionsRequired:
                return (isVivAdmin || activeUser.isAnnotator);
            case StudyStatusEnum.AwaitingCurationQaReview:
            case StudyStatusEnum.CurationQaReviewInProgress:
                return (isVivAdmin || activeUser.isQaReviewer);
            case StudyStatusEnum.Draft:
            case StudyStatusEnum.SubmittedToVivliForPostCheck:
            // case StudyStatus.SponsorApprovesCuration:
            // case StudyStatus.SponsorRequiresCurationRevisions:
                return (DataRequestPermissionsService.isOrgMember(activeUser,study?.orgId, om => om.isDataProvider) || isVivAdmin);
        }
        return false;
    }

    //note that userCanSave permissions don't necessarily
    //mean the user can save every field. Except for Vivli admins,
    //saving after Draft stage is limited to a few fields, so
    //although the user may see the Save button, it will only
    //work for the permitted fields.
    const userCanSave = () => {
        if (!study){
            return false;
        }
        const isStudyOwner = userIsDataProviderOrgAdmin() || userIsDatasetOwner();
        switch (study?.status) {
            case StudyStatusEnum.Draft:
            case StudyStatusEnum.SubmittedToVivliForPostCheck:
                return (DataRequestPermissionsService.isOrgMember(activeUser,study?.orgId, om => om.isDataProvider)
                    || isVivAdmin || isStudyOwner);
            case StudyStatusEnum.AwaitingCuration:
            case StudyStatusEnum.CurationInProgress:
            case StudyStatusEnum.CurationInternalReview:
            case StudyStatusEnum.CurationRevisionsRequired:
                return (DataRequestPermissionsService.isOrgMember(activeUser,study?.annotatorOrgId, om => om.isAnnotator)
                    || isVivAdmin || isStudyOwner);
            case StudyStatusEnum.AwaitingCurationQaReview:
            case StudyStatusEnum.CurationQaReviewInProgress:
                return (DataRequestPermissionsService.isOrgMember(activeUser,study?.qaReviewerOrgId, om => om.isQaReviewer)
                    || isVivAdmin || isStudyOwner);
            case StudyStatusEnum.SubmittedToVivliForPreCheck:
            case StudyStatusEnum.Posted:
                return (isVivAdmin || isStudyOwner);
        }
        return false;
    }

    const userCanDelete = () =>  {
        switch (study?.status) {
            case StudyStatusEnum.Draft:
            case StudyStatusEnum.SubmittedToVivliForPreCheck:
                return (DataRequestPermissionsService.isOrgMember(activeUser,study?.orgId, om => om.isDataProvider) || activeUser?.isVivliAdmin);
            case StudyStatusEnum.SubmittedToVivliForPostCheck:
            case StudyStatusEnum.SponsorApprovesCuration:
            case StudyStatusEnum.SponsorRequiresCurationRevisions:
                return activeUser?.isVivliAdmin;
        }
        return false;
    }

    const userCanDeleteStudyIpdPackage = () =>  {

        return isVivAdmin && study.ipdDataPackageId !== null;
    }

    const userCanSoftDeleteStudyIpdPackage = () =>  {

        return (DataRequestPermissionsService.isOrgMember(activeUser,study.orgId, om => om.isDataProvider) || isVivAdmin);
    }

    return {
        userCanDownloadIpd,
        userIsOrgAdmin,
        userIsOrgDataProvider,
        userIsInStudyOrg,
        userIsVivliAdmin,
        userCanRemovePosting,
        userCanApprove,
        userCanSave,
        userCanDelete,
        userCanDeleteStudyIpdPackage,
        userCanSoftDeleteStudyIpdPackage,
        studyIpdIsDownloadable,
        ipdReadyToDownload,
        userCanSeeDataPackage,
        canSeeDataCount,
        userCanUploadIpd,
    }
}
