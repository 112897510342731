import {useAuthService, useLoginExpiration, useLogoutOnClose} from "@vivli/core/authentication";
import {IContextWrapper} from "@vivli/shared/infrastructure/interface";

export const LoginExpirationWrapper = ({children}: IContextWrapper) => {
    const authService = useAuthService();

    useLoginExpiration(authService);
    useLogoutOnClose(authService);

    return children;
}
