﻿import React from "react";
import {Styles} from "@vivli/shared/theme";
import {IStudy} from "@vivli/features/studies/infrastructure/interface";
import {EmbeddedQuickSearchComponent} from "./embedded-quick-search.component";
import {QuickSearchResultsComponent} from "./quick-search-results.component";
import {IFilterSet} from "@vivli/features/search/infrastructure/interface";
import {FilterOperatorEnum} from "@vivli/features/search/infrastructure/enum";
import {useQuickSearchContext} from "@vivli/features/search/infrastructure/context";

interface EmbeddedQuickSearchResultsComponentProps {
    isLanding: boolean,
    study: IStudy
}
export const EmbeddedQuickSearchResultsComponent = (
{
    isLanding,
    study
}: EmbeddedQuickSearchResultsComponentProps) => {
    const {isShowingResults, addFilterSet, setSearchText} = useQuickSearchContext();
    const studyRegistryId = study.registryInfo && study.registryInfo[0].registryId;

    const handleSearch = (id: string) => {
        setSearchText(id);
        const isNctId = id.startsWith('NCT');
        const keyValue = isNctId ? 'nctId' : 'sponsorProtocolId';
        const filterSet: IFilterSet = {
            key: keyValue,
            items: [{value: id}],
            itemOperator: FilterOperatorEnum.And,
            operator: FilterOperatorEnum.And
        }
        addFilterSet(filterSet);
    }

    return (
        <>
            {isLanding &&
                <div style={Styles.FORM_ROW}>
                    <EmbeddedQuickSearchComponent
                        currentId={studyRegistryId}
                        executeSearch={(id) => {handleSearch(id)}}
                    />
                </div>
            }

            <QuickSearchResultsComponent isOpen={isShowingResults} />
        </>
    )
}
