import React, {CSSProperties, useState} from 'react';
import {IVirtualMachine} from "@vivli/features/virtual-machine/infrastructure/interface";
import {ButtonComponent} from "@vivli/shared/components";
import {Color} from "@vivli/shared/theme";

const containerStyle: CSSProperties = {
    backgroundColor: Color.VIVLI_DARK_BLUE,
    color: 'white',
    borderRadius: '15px',
    padding: '25px'
}

const buttonContainerStyle: CSSProperties = {
    display: 'flex',
    paddingTop: '25px',
    justifyContent: 'center',
    alignItems: 'center'
}

const selectStyle: CSSProperties = {
    padding: '10px',
    borderRadius: '10px'
}

interface SelectVirtualMachineModalComponentProps {
    virtualMachines: IVirtualMachine[];
    onVmSelected: (vm: IVirtualMachine) => void;
}

export const SelectVirtualMachineModalComponent = (
    {
        virtualMachines,
        onVmSelected
    }: SelectVirtualMachineModalComponentProps
) => {
    const [selectedVm, setSelectedVm] = useState<IVirtualMachine>(null);

    const handleOnChange = (e) => {
        const vm = virtualMachines.find(vm => vm.id === e.target.value);
        setSelectedVm(vm);
    }

    return (
        <div style={containerStyle}>
            <select onChange={handleOnChange} style={selectStyle}>
                <option value={null}>- Select a Request -</option>

                {virtualMachines?.map(vm =>
                    <option value={vm.id} key={vm.id}>{`${vm.dataRequestDoi} - ${vm.dataRequestTitle}`}</option>
                )}
            </select>
            <div style={buttonContainerStyle}>
                <ButtonComponent onClick={() => selectedVm && onVmSelected(selectedVm)}>Submit</ButtonComponent>
            </div>
        </div>
    )
}
