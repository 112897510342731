import {OrgAdminHeaderComponent} from "./org-admin-header.component";
import {OrgTabsComponent} from "./org-tabs.component";
import React, {CSSProperties, useEffect} from "react";
import {Size} from "@vivli/shared/theme";
import {useOrgDetailsFormHook} from "@vivli/features/organizations/infrastructure/hook";
import {FormProvider} from "react-hook-form";
import {useOrganizationDetailsContext} from "@vivli/features/organizations/infrastructure/context";

const orgDetailsFeatureStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 40,
    paddingRight: 40,
    paddingTop: Size.TOP_INSET_SIZE,
    height: '100%'
}
const bodyContainerStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 2,
    height: 'calc(100% - 70px)',
    overflow: 'auto',
    paddingRight: 40
}

export const OrgDetailsFormComponent = () => {
    const {organization} = useOrganizationDetailsContext();
    const formApi = useOrgDetailsFormHook(organization);

    useEffect(() => {
        formApi.trigger();
    }, [])
    useEffect(() => {
        formApi.reset(organization, {keepErrors: true, keepIsValid: false, keepDirty: false});
    }, [organization])

    return (
        <FormProvider {...formApi}>
            <div style={orgDetailsFeatureStyle}>
                <OrgAdminHeaderComponent />

                <div style={bodyContainerStyle} className='scrolly'>
                    <OrgTabsComponent organization={organization}/>
                </div>
            </div>
        </FormProvider>

    )
}
