export enum DirStudyNotAvailableResponseEnum {
    None = 0, //Study Completion Date criteria is not yet met
    NotCompleted = 1, //Data Sharing Prohibited by Consent, Legal, Regulatory, or Contractual Constraints
    Prohibited = 2, //Indications have not received market authorization.
    NotAuthorized = 3, //Indications have not received market authorization.
    InsufficientData = 4, //Likelihood of re-identification of patients given small number of patients and/or involves a rare disease
    Other = 5, //Other (See Discussion)
    NotResponsible = 6 //Not responsible for Data Sharing

}
