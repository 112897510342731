﻿import React from "react";
import {AdminHeaderButtonComponent} from "@vivli/shared/components";
import {useListingRequestContext} from "@vivli/features/listing-request/infrastructure/context";
import {ListingRequestStatusEnum} from "@vivli/features/listing-request/infrastructure/enum";
import {useModalService} from "@vivli/shared/infrastructure/context";
import {useFormContext} from "react-hook-form";
import {IListingRequest} from "@vivli/features/listing-request/infrastructure/interface";

export const ListingRequestHeaderButtonsComponent = () => {
    const {
        listingRequest,
        isLoading,
        handleStatusUpdate,
        handleFormSave
    } = useListingRequestContext();
    const formApi = useFormContext<IListingRequest>();
    const modalService = useModalService();
    const status = ListingRequestStatusEnum[listingRequest?.status];
    const isDraft = status === ListingRequestStatusEnum.Draft;
    const isWithdrawnOrApproved = status === ListingRequestStatusEnum.Approved || status === ListingRequestStatusEnum.Withdrawn;
    const showWithdraw = !isWithdrawnOrApproved && listingRequest?.id;
    const modalMessage = 'This submission is being reviewed or has been posted, please contact Vivli to complete the process of withdrawing the submission';
    const continueMessage = "Continuing with the Withdraw will mark the submission withdrawn, and will delete the temporary study that was created.";

    const handleWithdraw = () => {
        if (isDraft && listingRequest.approvedDate == null){
            modalService.confirm(continueMessage, {
                title: 'Confirm Withdraw?',
                cancelText: "Cancel",
                confirmText: "OK",
                onConfirm: () => {
                    handleStatusUpdate(listingRequest, ListingRequestStatusEnum.Withdrawn);
                }
            });
        }
        else{
            modalService.message(modalMessage, { showContinueButton: true, showLogo: true });
        }
    }

    return (
        <>
            {showWithdraw &&
                <AdminHeaderButtonComponent
                    style={{marginRight: 10}}
                    title={'Withdraw'}
                    isLoading={isLoading}
                    isDisabled={isLoading}
                    onClick={handleWithdraw}
                />
            }
            {isDraft &&
                <>
                    <AdminHeaderButtonComponent
                        style={{marginRight: 10}}
                        title={'Save'}
                        isLoading={isLoading}
                        isDisabled={isLoading || !formApi.formState.isDirty}
                        onClick={() => handleFormSave(formApi, false)}
                    />
                    <AdminHeaderButtonComponent
                        style={{marginRight: 10}}
                        title={'Submit'}
                        isLoading={isLoading}
                        isDisabled={isLoading || !formApi.formState.isValid}
                        onClick={() => handleFormSave(formApi, true, ListingRequestStatusEnum.Submitted)}
                    />
                </>
            }
        </>
    )
}
