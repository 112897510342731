﻿import React, { useEffect, useState } from 'react';
import { ListingRequestsPageComponent } from '@vivli/features/listing-request/components';
import { ITab, IUser } from '@vivli/shared/infrastructure/interface';
import { IListingRequestSummary } from '@vivli/features/listing-request/infrastructure/interface';
import { ListingRequestStatusEnum } from '@vivli/features/listing-request/infrastructure/enum';
import { useActiveUser } from '@vivli/core/infrastructure/context';
import { useListingRequestSummaries } from '@vivli/features/listing-request/infrastructure/hook';
import { useNavigate } from 'react-router-dom';
import { LoadIndicatorCenteredComponent } from '@vivli/shared/components';

enum TabRouteEnum {
    draft = 'draft',
    inprogress = 'inprogress',
    approved = 'approved',
    withdrawn = 'withdrawn',
}
enum TabTitleEnum {
    draft = 'Draft',
    inprogress = 'In Progress',
    approved = 'Approved/Posted',
    withdrawn = 'Withdrawn',
}

const createdSort = (a, b) => b.createdDate.localeCompare(a.createdDate);
const submittedSort = (a, b) => b.submittedDate.localeCompare(a.submittedDate);
const approvedSort = (a, b) => b.approvedDate.localeCompare(a.approvedDate);
const withdrawnSort = (a, b) => b.withdrawnDate.localeCompare(a.withdrawnDate);

const getTabs = (user: IUser, listingRequestSummaries?: IListingRequestSummary[]) => {
    const draftData = listingRequestSummaries?.filter((l) => l.status === ListingRequestStatusEnum.Draft).sort(createdSort);
    const postedData = listingRequestSummaries?.filter((l) => l.status === ListingRequestStatusEnum.Approved).sort(approvedSort);
    const withdrawnData = listingRequestSummaries?.filter((l) => l.status === ListingRequestStatusEnum.Withdrawn).sort(withdrawnSort);
    const inProgressData = listingRequestSummaries
        ?.filter(
            (l) =>
                l.status !== ListingRequestStatusEnum.Withdrawn &&
                l.status !== ListingRequestStatusEnum.Draft &&
                l.status !== ListingRequestStatusEnum.Approved
        )
        .sort(submittedSort);

    const defaultTabs: ITab<IListingRequestSummary[]>[] = [
        {
            id: TabRouteEnum.draft,
            sortOrder: 0,
            title: TabTitleEnum.draft,
            data: draftData,
            count: draftData?.length,
        },
        {
            id: TabRouteEnum.inprogress,
            sortOrder: 1,
            title: TabTitleEnum.inprogress,
            data: inProgressData,
            count: inProgressData?.length,
        },
        {
            id: TabRouteEnum.approved,
            sortOrder: 2,
            title: TabTitleEnum.approved,
            data: postedData,
            count: postedData?.length,
        },
        {
            id: TabRouteEnum.withdrawn,
            sortOrder: 5,
            title: TabTitleEnum.withdrawn,
            data: withdrawnData,
            count: withdrawnData?.length,
        },
    ];

    return defaultTabs.sort((a, b) => a.sortOrder - b.sortOrder);
};

export const ListingRequestsFeature = () => {
    const user = useActiveUser();
    const navigate = useNavigate();
    const [tabs, setTabs] = useState<ITab<IListingRequestSummary[]>[]>();
    const { listingRequestSummaries, isLoadingListingRequests } = useListingRequestSummaries();

    const handleRowClick = (listingRequestSummary: IListingRequestSummary) => {
        navigate(`/study-submission/${listingRequestSummary.id}/InformationAboutYourTeam`, { state: { skipPortal: true } });
    };

    const handleTabClick = (tab: ITab<IListingRequestSummary[]>) => {
        navigate(`${tab.id}`, { replace: true });
    };

    useEffect(() => {
        setTabs(getTabs(user));
    }, []);

    useEffect(() => {
        if (!listingRequestSummaries) {
            return;
        }
        const updatedTabs = getTabs(user, listingRequestSummaries);

        setTabs(updatedTabs);
    }, [listingRequestSummaries]);

    if (!listingRequestSummaries) {
        return <LoadIndicatorCenteredComponent />;
    }

    return (
        <ListingRequestsPageComponent
            title={'Single Study Submissions'}
            tabs={tabs}
            onRowClick={handleRowClick}
            onTabClick={handleTabClick}
            dataRefreshing={isLoadingListingRequests}
        />
    );
};
