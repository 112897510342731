import {useEffect, useState} from "react";
import {IDataProvider} from "@vivli/features/data-requests/infrastructure/interface";
import {useDataRequestsService} from "@vivli/features/data-requests/infrastructure/context";
import {first} from "rxjs/operators";

export function useDataRequestProviders(dataRequestId: string, isInProcess: boolean) {
    const [dataProviders, setDataProviders] = useState<IDataProvider[]>();

    const dataRequestsService = useDataRequestsService();

    useEffect(() => {
        if (!dataRequestId) { return; }

        const sub = dataRequestsService.getDataProviders(dataRequestId, isInProcess)
            .pipe(first())
            .subscribe(setDataProviders)

        return () => {
            sub.unsubscribe();
        }
    }, [dataRequestId])

    return dataProviders;
}
