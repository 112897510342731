﻿import React, {CSSProperties} from "react";
import {
    AdminHeaderButtonComponent,
    AdminHeaderComponent,
    AdminHeaderTitleComponent,
    GoBackLinkComponent,
    leftHeaderContainerStyle
} from "@vivli/shared/components";
import {Size, Styles} from "@vivli/shared/theme";
import {useFormContext} from "react-hook-form";
import {useStudyContext} from "@vivli/features/studies/infrastructure/context";
import {IStudy} from "@vivli/features/studies/infrastructure/interface";
import {useAdminStudyStatusChange} from "@vivli/features/studies/infrastructure/hook";
import {useModalService} from "@vivli/shared/infrastructure/context";
import {useNavigate} from "react-router-dom";
import {DTISubmitStudyHeader} from "@vivli/shared/infrastructure/constants";

const flexRowStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'row'
}
const marginStyle: CSSProperties = {
    marginRight: Size.INSET_SIZE,
}
const submitButtonStyle: CSSProperties = {
    ...Styles.Button.BUTTON_DARKER_GREEN_CHECK,
    ...marginStyle,
}

interface SubmitStudyHeaderComponentProps {
    studyId: string,
    userCanDelete: () => boolean,
    userCanApprove: () => boolean,
    userCanSave: () => boolean
}
export const SubmitStudyHeaderComponent = (
    {
        studyId,
        userCanDelete,
        userCanApprove,
        userCanSave
    }: SubmitStudyHeaderComponentProps
) => {
    const formApi = useFormContext<IStudy>();
    const modalService = useModalService();
    const navigate = useNavigate();
    const {study, handleDeleteStudy, handleUpdateAndStatusChange, handleFormSave, isSavingStudy} = useStudyContext();
    const studyStatusChange = useAdminStudyStatusChange(study);
    const statusObject = studyStatusChange.getCurationStatusObject();

    const finalStep = () => {
        modalService.message('Study submitted to Vivli for processing.  You will be notified when the study has been curated and posted to Vivli. You will now be brought to a list of studies in progress.',
            {showBackground: false, showLogo: true, showContinueButton: true});
        navigate('/admin/studies/InProgress');
    }

    const handleSubmit = () => {
        handleUpdateAndStatusChange(formApi, statusObject.approveProps, finalStep);
    }

    const isDisabled = !formApi.formState.isValid || !formApi.formState.isDirty;
    const showWithdrawButton = studyId && userCanDelete();
    const showSubmitButton = studyId && userCanApprove();
    const showSaveButton = studyId && userCanSave();

    return (
        <AdminHeaderComponent>
            <div style={{ flexDirection: 'column' }}>
                <div style={{...leftHeaderContainerStyle, paddingBottom: 10}}>
                    <GoBackLinkComponent destination={'/admin/studies/Draft'}/>
                </div>
                <AdminHeaderTitleComponent title={'Submit New Study'} />
            </div>
            <div style={flexRowStyle}>
                {showWithdrawButton &&
                    <AdminHeaderButtonComponent
                        title={'Withdraw Submission'}
                        onClick={handleDeleteStudy}
                        isLoading={isSavingStudy}
                        style={marginStyle}
                        className={'submitStudy_withdraw'}
                        dataId={DTISubmitStudyHeader.SubmitStudyWithdraw}
                    />
                }
                {showSubmitButton &&
                    <AdminHeaderButtonComponent
                        title={'Submit for Curation'}
                        onClick={handleSubmit}
                        isLoading={isSavingStudy}
                        isDisabled={!formApi.formState.isValid}
                        style={submitButtonStyle}
                        className={'submitStudy_submit'}
                        dataId={DTISubmitStudyHeader.SubmitStudySubmit}
                    />
                }
                {showSaveButton &&
                    <AdminHeaderButtonComponent
                        title={'Save Draft'}
                        onClick={() => handleFormSave(formApi)}
                        isLoading={isSavingStudy}
                        isDisabled={isDisabled}
                        className={'submitStudy_save'}
                        dataId={DTISubmitStudyHeader.SubmitStudySave}
                    />
                }
            </div>
        </AdminHeaderComponent>
    )
}
