import { IEnvironment } from './environment.interface';
import { AssignedAppTypeEnum } from '@vivli/shared/infrastructure/enum';

let assignedAppType = AssignedAppTypeEnum.Default;

if (import.meta.env.VITE_ASSIGNED_APP_TYPE.toLowerCase().includes('amr')) {
    assignedAppType = AssignedAppTypeEnum.Amr;
}
export const environment: IEnvironment = {
    production: import.meta.env.VITE_IS_PRODUCTION === 'true',
    isLocal: import.meta.env.VITE_IS_LOCAL === 'true',
    useStrictMode: import.meta.env.VITE_USE_STRICT_MODE === 'true',
    assignedAppType: assignedAppType,
    isLoggerEnabled: import.meta.env.VITE_IS_LOGGER_ENABLED === 'true',
    isAutomation: import.meta.env.VITE_IS_AUTOMATION === 'true',
    viewRequestResultsOnly: import.meta.env.VITE_VIEW_REQUEST_RESULTS_ONLY === 'true',
    useIntercepts: import.meta.env.VITE_USE_INTERCEPTS === 'true',
};
