﻿import React from "react";
import {Styles} from "@vivli/shared/theme";
import {
    FieldHeaderComponent,
    fieldHeaderStyle,
    verticalTopStyle
} from "@vivli/shared/components";

//Todo : come back in future and make this it's own component
const getOutcomes = (outcomes) => {
    return (
        outcomes.map((outcome, i) => (
            <>
                <tr key={`prow_${i}`} style={verticalTopStyle}>
                    <td style={{ paddingBottom: 3 }}>{outcome.domain ?? ''}</td>
                    <td style={Styles.TABLE_COLUMN_FILLER} />
                    <td style={{ paddingBottom: 3 }} >{outcome.classification ?? ''}</td>
                    <td style={Styles.TABLE_COLUMN_FILLER} />
                </tr>
                <tr key={`prow1_${i}`} style={verticalTopStyle}>
                    <td colSpan={2} style={{ paddingBottom: 3 }} >
                        {outcome.isNonLastChild &&
                            <div style={{ marginTop: 15 }}>OR</div>
                        }
                    </td>
                    <td colSpan={9} style={{ paddingBottom: 3 }}>{outcome.description ?? ''}</td>
                </tr>
            </>
        ))
    )
}

export const PicoOutcomesComponent = ({outcomes}) => {
    return (
        <>
            <FieldHeaderComponent title={'OUTCOMES'} style={fieldHeaderStyle} />
            <div>
                <table style={Styles.FULL_WIDTH_TABLE}>
                    <tbody>
                    <tr>
                        <th style={Styles.TABLE_HEADER}>{'Outcome'}</th>
                        <th style={Styles.TABLE_COLUMN_FILLER} />
                        <th style={Styles.TABLE_HEADER}>{'Classification'}</th>
                        <th style={Styles.TABLE_COLUMN_FILLER} />
                    </tr>
                    {getOutcomes(outcomes)}
                    </tbody>
                </table>
            </div>
        </>
    )
}
