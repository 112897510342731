import { useLocation, useNavigate } from 'react-router-dom';
import { useActiveUser } from '@vivli/core/infrastructure/context';
import { useUserPermissions } from '@vivli/features/users/infrastructure/hook';
import { useListingRequestSummaries } from '@vivli/features/listing-request/infrastructure/hook';
import { useEffect } from 'react';
import { LoadIndicatorCenteredComponent } from '@vivli/shared/components';
import { useParams } from 'react-router';
import { sum } from 'ag-grid-community/dist/lib/utils/number';

export const ListingRequestPortalComponent = ({ children }: { children?: JSX.Element }) => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const { id } = useParams();

    const user = useActiveUser();
    const { isDatasetOwner } = useUserPermissions();
    const { listingRequestSummaries, isLoadingListingRequests } = useListingRequestSummaries();

    const _handleNavigation = () => {
        //Direct user to the grid if they have LRs, otherwise to a new form
        //If user is a dataset owner, should brought to admin view of grid (with side nav)
        const hasListingRequests = listingRequestSummaries?.length > 0;

        const gridUrl = isDatasetOwner ? `/admin/study-submissions/draft` : `/study-submissions/draft`;
        const startingPage = hasListingRequests ? gridUrl : '/study-submission/draft/InformationAboutYourTeam';
        navigate(startingPage, { replace: true });
    };

    useEffect(() => {
        if (!listingRequestSummaries || !user || state?.skipPortal || id) {
            return;
        }

        _handleNavigation();
    }, [listingRequestSummaries, user]);

    if (isLoadingListingRequests) {
        return <LoadIndicatorCenteredComponent />;
    }

    return children;
};
