import {IOrganization} from "@vivli/features/organizations/infrastructure/interface";
import {JoiValidationOptionsConstant} from "@vivli/shared/infrastructure/constants";
import joi, {ObjectSchema} from "joi";
import {joiResolver} from "@hookform/resolvers/joi";
import {useForm} from "react-hook-form";


const formSchema: ObjectSchema<IOrganization> = joi.object({
    name: joi.string().required().label('Organization Name'),
    code: joi.string().required().label('Code')
}).options(JoiValidationOptionsConstant)

const defaultValues = (org) => ({
    ...org,
    name: org?.name || '',
    billingStreet1: org?.billingStreet1 || '',
    billingCity: org?.billingCity || '',
    billingState: org?.billingState || '',
    billingZip: org?.billingZip || '',
    billingCountry: org?.billingCountry || '',
    emailDomain: org?.emailDomain || '',
    code: org?.code || '',
    organizationRegistryUrl: org?.organizationRegistryUrl || '',
    externalStudyUri: org?.externalStudyUri || '',
    alternativeMetadataDocumentUri: org?.alternativeMetadataDocumentUri || '',
    acceptEnquiry: org?.acceptEnquiry === undefined || null ? true : org?.acceptEnquiry,
})

export function useOrgDetailsFormHook(org?: IOrganization, mode: 'onBlur' | 'onChange' | 'onTouched' | 'all' = 'all') {
    return useForm({
        mode,
        resolver: joiResolver(formSchema.options(JoiValidationOptionsConstant)),
        defaultValues: defaultValues(org),
        reValidateMode: 'onChange'
    });
}
