﻿import React, { CSSProperties } from 'react';
import { useListingRequestContext } from '@vivli/features/listing-request/infrastructure/context';
import { outerTabStyle, tabContentContainerStyle } from '../shared/styles';
import {
    EmptyListPlaceholderComponent,
    HistoryListComponent,
    LoadIndicatorCenteredComponent,
} from '@vivli/shared/components';
import { ListingRequestStatusEnum } from '@vivli/features/listing-request/infrastructure/enum';
import { AssetsConstant } from '@vivli/shared/infrastructure/constants';

const placeholderStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 2,
};
const historyItemStyle: CSSProperties = {
    justifyContent: 'start',
    width: 'calc(100% - 100px)',
};

export const ListingRequestHistoryComponent = () => {
    const { listingRequest } = useListingRequestContext();
    const isDraft = listingRequest?.status === ListingRequestStatusEnum.Draft;
    const emptyText = isDraft
        ? 'There is no history for this submission because it has not yet been submitted.'
        : 'There is no history for this submission.';

    if (!listingRequest) {
        return <LoadIndicatorCenteredComponent />;
    }

    return (
        <div
            style={{ ...outerTabStyle, overflowX: 'hidden' }}
            className={'scrolly'}
        >
            <div style={tabContentContainerStyle}>
                {listingRequest.history?.length > 0 ? (
                    <HistoryListComponent
                        historyEntries={listingRequest.history}
                        hideReviewers={false}
                        historyItemStyle={historyItemStyle}
                        excludeComments={true}
                    />
                ) : (
                    <EmptyListPlaceholderComponent
                        optionalStyles={placeholderStyle}
                        imageHeight={'100%'}
                        image={AssetsConstant.TRIALS_ICON_INACTIVE}
                        text={emptyText}
                    />
                )}
            </div>
        </div>
    );
};
