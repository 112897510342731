export const rejectionReasonsIntercept = [
    {
        description:
            'The Data Request is not seeking access to anonymized patient level data',
        code: '1',
        active: true,
    },
    {
        description:
            'The Data Request did not meet the data contributor/sponsor policies for informed consent',
        code: '2',
        active: true,
    },
    {
        description:
            'The Data Request required data that was not collected in the studies requested',
        code: '3',
        active: true,
    },
    {
        description:
            "The Data Request competes with the data contributor/sponsor's publication plan",
        code: '4',
        active: true,
    },
    {
        description:
            "The data contributor or sponsor doesn't have the legal authority to provide the data",
        code: '5',
        active: true,
    },
    {
        description:
            'The data requested is not appropriate for the research proposal in the Data Request',
        code: '6',
        active: true,
    },
    {
        description:
            "The Data Request's research proposal lacks clear scientific merit",
        code: '7',
        active: true,
    },
    {
        description:
            "The Data Request's project aim is commercial or litigious",
        code: '8',
        active: true,
    },
    {
        description: 'The Data Request is Out of Scope',
        code: '9',
        active: true,
    },
    {
        description: 'The data requested is unavailable',
        code: '10',
        active: true,
    },
    {
        description:
            'The data requested cannot be shared due to ongoing regulatory activities',
        code: '11',
        active: true,
    },
    {
        description: 'The data requested was not collected in English',
        code: '12',
        active: true,
    },
    {
        description: 'Other reason for non-approval (please explain below)',
        code: '13',
        active: true,
    },
];
