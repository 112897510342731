import React from 'react';
import {AdvancedSelectComponent} from "@vivli/shared/components";
import {FilterGroupComponent} from "../../filter-group.component";
import {useCtSearchOptionsContext} from "@vivli/features/search/infrastructure/context";
import {ISelectOption} from "@vivli/features/search/infrastructure/interface";
import {DTIStudyPhaseFilterGroup} from "@vivli/shared/infrastructure/constants";

export const StudyPhaseFilterGroupComponent = () => {
    const {setStudyPhaseSelectedOptions, studyPhaseSelectedOptions} = useCtSearchOptionsContext();

    const handleOnChange = (selectedOptions: ISelectOption[]) => {
        setStudyPhaseSelectedOptions(selectedOptions);
    }

    const options = [
        {value: 'NA', label: 'N/A'},
        {value: 'EarlyPhase1', label: 'Early Phase 1'},
        {value: 'Phase1', label: 'Phase 1'},
        {value: 'Phase1Phase2', label: 'Phase 1/Phase 2'},
        {value: 'Phase2', label: 'Phase 2'},
        {value: 'Phase2a', label: 'Phase 2a'},
        {value: 'Phase2b', label: 'Phase 2b'},
        {value: 'Phase2Phase3', label: 'Phase 2/Phase 3'},
        {value: 'Phase3', label: 'Phase 3'},
        {value: 'Phase3a', label: 'Phase 3a'},
        {value: 'Phase3b', label: 'Phase 3b'},
        {value: 'Phase4', label: 'Phase 4'},
    ]

    return <FilterGroupComponent title={'STUDY PHASE'}>
        <div>
            <AdvancedSelectComponent
                isMulti={true}
                isSearchable={true}
                onChange={handleOnChange}
                options={options}
                selectedOptions={studyPhaseSelectedOptions}
                menuPlacement={'top'}
                data-test-id={DTIStudyPhaseFilterGroup.StudiesOption}
            />
        </div>
    </FilterGroupComponent>
}
