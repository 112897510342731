import { IContextWrapper, IModalMessageOptions } from '@vivli/shared/infrastructure/interface';
import { useActiveUser, useConfigService } from '@vivli/core/infrastructure/context';
import { Msal2EventTypeEnum, useAuthService } from '@vivli/core/authentication';
import { useModalService } from '@vivli/shared/infrastructure/context';
import { useEffect, useRef } from 'react';
import { useOrganizationsService } from '@vivli/features/organizations/infrastructure/context';
import { first } from 'rxjs/operators';
import { useUsersService } from '@vivli/features/users/infrastructure/context';
import { useNavigate } from 'react-router';

export const AuthEventsWrapper = ({ children }: IContextWrapper) => {
    const authService = useAuthService();
    const modalService = useModalService();
    const navigate = useNavigate();
    const activeUser = useActiveUser();
    const organizationsService = useOrganizationsService();
    const config = useConfigService();
    const usersService = useUsersService();
    const modalIdRef = useRef<string>(null);

    const modalOptions: IModalMessageOptions = {
        showContinueButton: false,
        showLoadingSpinner: true,
        dismissOnClickOutside: false,
        style: {
            padding: '40px 140px 25px 140px',
            borderRadius: '10px',
        },
        messageStyle: {
            color: 'rgb(5, 61, 88)',
            fontSize: '21px',
            letterSpacing: '0.1em',
        },
        onClick: () => authService.showLoginWindow(),
    };

    const handleAcceptInvite = () => {
        const acceptingInvite = window.location.href.toLowerCase().indexOf('/acceptinvite') !== -1;
        if (activeUser?.orgMemberships.length === 0 && !acceptingInvite) {
            organizationsService
                .joinMember()
                .pipe(first())
                .subscribe((orgNames) => {
                    if (orgNames.length) {
                        modalService.message(`You have been successfully joined to ${orgNames.toString()}`);
                    }
                });
        }
    };

    const handleTrackUserLogin = () => {
        usersService.trackUserActivity().pipe(first()).subscribe();
    };

    const handleLoginRedirect = () => {
        // TODO - at a less risky time find a better way to do this
        // rather than checking all these "exception" states - verify when
        // redirect to dashboard is REALLY required and do the redirect only in
        // those cases.
        const isAcceptingInvite = window.location.pathname.indexOf('acceptinvite') !== -1; // accepting invite
        const isDashboardPath = window.location.pathname === '/'; // going to dashboard
        const isRequestResults = config.viewRequestResultsOnly; // vm side result requests
        const isSearchQuery = window.location.search.indexOf('search') !== -1; // has search query param
        const isReport = window.location.pathname.indexOf('admin/report') !== -1; // is report

        // do not redirect if we are accepting an invite, going to the dashboard,
        // viewing request results on VM side, displaying an admin report,
        // and does not contain a search query param
        const redirectToDashboard = !isAcceptingInvite && isDashboardPath && !isRequestResults && !isSearchQuery && !isReport;

        if (redirectToDashboard) {
            navigate('/admin/dashboard');
        }
    };

    const showMessage = (message: string) => {
        modalIdRef.current = modalService.message(message, modalOptions);
    };

    const hideActiveMessage = (hideAll = false) => {
        if (hideAll) {
            modalService.hide();
        }

        if (!modalIdRef.current) {
            return;
        }

        modalService.hide(modalIdRef.current);
        modalIdRef.current = null;
    };

    const handleLogoutWarning = () => {
        const message = 'Your session will expire soon.  Please press OK to extend your log-in; otherwise you will be logged out shortly.';
        modalIdRef.current = modalService.confirm(message, {
            onConfirm: () => {
                authService.getTokenPopup();
            },
            confirmText: 'OK',
            hideCancel: true,
        });
    };

    const tabVisibilityHandler = () => {
        if (!document.hidden && modalIdRef.current) {
            authService.showLoginWindow();
        }
    };

    const handleUserLogin = () => {
        hideActiveMessage();
        handleTrackUserLogin();
        handleAcceptInvite();
        handleLoginRedirect();
    };

    useEffect(() => {
        const authSub = authService.authEvents.subscribe((event) => {
            switch (event.type) {
                case Msal2EventTypeEnum.LoggingInEvent:
                    showMessage('Logging in...');
                    break;
                case Msal2EventTypeEnum.UpdatingPasswordEvent:
                    showMessage('Updating password...');
                    break;
                case Msal2EventTypeEnum.UpdatingProfileEvent:
                    showMessage('Updating profile...');
                    break;
                case Msal2EventTypeEnum.SignUpEvent:
                    showMessage('Signing up...');
                    break;
                case Msal2EventTypeEnum.UpdatedProfileEvent:
                case Msal2EventTypeEnum.UpdatedPasswordEvent:
                case Msal2EventTypeEnum.UserCancelledLogin:
                case Msal2EventTypeEnum.UnauthorizedEvent:
                case Msal2EventTypeEnum.SignedUpEvent:
                    hideActiveMessage();
                    break;
                case Msal2EventTypeEnum.UserLoginEvent:
                    handleUserLogin();
                    break;
                case Msal2EventTypeEnum.LogoutWarning:
                    handleLogoutWarning();
                    break;
                case Msal2EventTypeEnum.LogoutEvent:
                    hideActiveMessage(true);
                    break;
            }
        });

        document.addEventListener('visibilitychange', tabVisibilityHandler);

        return () => {
            authSub.unsubscribe();

            document.removeEventListener('visibilitychange', tabVisibilityHandler);
        };
    }, []);

    return children;
};
