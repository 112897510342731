import {Observable} from 'rxjs';
import {RestApi} from "@vivli/core/infrastructure/rest";
import {
    ICitation,
    ICitationDoiDto,
    ICitationsApiService
} from "@vivli/shared/features/citations/infrastructure/interface";
import {IRorResponseItem} from "@vivli/features/organizations/infrastructure/interface";
import {IRestRequestOptions} from "@vivli/core/infrastructure/interface";

export class CitationsApiService extends RestApi implements ICitationsApiService {

    getCitationFromDb = (id: string): Observable<ICitation> => {
        return this.handleGet<ICitation>(`dois/${id}/citation`, {bypassAuth: true});
    }

    /*object id  is the document id of the data request or study that "owns" the citation*/
    getCitationsForObject = (objectId: string, isStudy: boolean, doUpdate: boolean): Observable<ICitation[]> => {
        return this.handleGet<ICitation[]>(`dois/${objectId}/citation/${isStudy}/${doUpdate}`);
    };

    /*object id  is the document id of the data request or study that "owns" the citation*/
    getCitationsForObjectAnonymous = (objectId: string, isStudy: boolean, doUpdate: boolean): Observable<ICitation[]> => {
        return this.handleGet<ICitation[]>(`dois/${objectId}/citation/anon/${isStudy}/${doUpdate}`, {bypassAuth: true});
    };

    createCitationFromDoi = (workDoi: string, dataRequestId: string, dataRequestDoiStem: string): Observable<ICitation> => {
        const workDoiDto: ICitationDoiDto = {citationDoi: workDoi};
        return this.handlePost<ICitation>(`/dois/providedDoi/${dataRequestId}/${dataRequestDoiStem}`, workDoiDto)
    };

    createCitationFromObject = (citation: ICitation, dataRequestId: string, dataRequestDoiStem: string): Observable<ICitation> => {
        return this.handlePost<ICitation>(`/dois/providedCitation/${dataRequestId}/${dataRequestDoiStem}`, citation)
    };

    updateCitation = (citation: ICitation, citationId: string, dataRequestDoiStem: string): Observable<ICitation> => {
        return this.handlePut<ICitation>(`dois/${citationId}/citation/${dataRequestDoiStem}`, citation)
    };

    deleteCitation = (citationId: string, dataRequestId: string, dataRequestDoiStem: string): Observable<ICitation> => {
        return this.handleDelete<ICitation>(`dois/${citationId}/citation/${dataRequestId}/${dataRequestDoiStem}`)
    }

    getRorMatches = (inputString: string): Observable<IRorResponseItem[]> => {
        const options: IRestRequestOptions =   {
            bypassAuth: true,
        };
        return this.handleGet<IRorResponseItem[]>(`dois/${inputString}/ror`, options);
    }
}


