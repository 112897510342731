import React, {ReactNode} from 'react';
import {IFormField} from "@vivli/shared/infrastructure/interface";
import {BaseControlledFormFieldComponent} from "./base-controlled-form-field.component";
import {TextFieldComponent} from "../input/text-field.component";

interface TextFormFieldComponentProps extends IFormField {
    truncateReadonly?: boolean;
    truncateLength?: number;
    truncateLabel?: boolean;
    tooltip?: string;
    hint?: ReactNode;
    name: string;
}

export const TextFormFieldComponent = (props: TextFormFieldComponentProps) => {
    return (
        <BaseControlledFormFieldComponent {...props}>
            <TextFieldComponent {...props} />
        </BaseControlledFormFieldComponent>
    )
}
