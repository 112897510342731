import React, {CSSProperties, Fragment} from 'react';
import moment from "moment";
import {IHistory} from "@vivli/shared/infrastructure/interface";
import {Color} from "@vivli/shared/theme";

const containerStyle = (historyItemStyle): CSSProperties => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 10,
    backgroundColor: Color.WHITE,
    paddingBottom: 5,
    paddingRight: 5,
    paddingTop: 5,
    paddingLeft: 25,
    fontSize: '.8em',
    minHeight: '50px',
    alignItems: 'center',
    width: 'calc(100% - 100px)',
    ...historyItemStyle
})

interface HistoryListItemComponentProps {
    historyEntry: IHistory;
    hideReviewers?: boolean;
    historyItemStyle?: CSSProperties;
    excludeComments?: boolean;
}

export const HistoryListItemComponent = ({
    historyEntry,
    hideReviewers,
    historyItemStyle,
    excludeComments
}: HistoryListItemComponentProps) => {
    const language = navigator.language;
    const noUserDisplay = 'N/A'; //this is an "alias" for the user name
    const dateTimeFormatted = language === 'en-US' ? moment(historyEntry.dateTime).format('M/D/YY h:mm a') :
        moment(historyEntry.dateTime).format('D/M/YY h:mm a');
    const dcs_Approved = 'pre-check required';
    const irps_Approved = 'final Approval required';
    const not_all_irps_approved = 'The Approval responses are complete, but';
    const not_all_dcs_approved = 'The Data Contributor pre-checks are complete, but one';
    const nameString = historyEntry.userEmail ? historyEntry.userName + "\n" + historyEntry.userEmail : '';
    let performedBy = historyEntry.description?.includes(dcs_Approved) || historyEntry.description?.includes(irps_Approved) ||
    historyEntry.comment?.includes(not_all_irps_approved) || historyEntry.comment?.includes(not_all_dcs_approved)
        ? ''
        : nameString;
    const comments = historyEntry.description?.includes(dcs_Approved) || historyEntry.description?.includes(irps_Approved)
        ? ''
        : historyEntry.comment;

    // This is the beginning of getting business rules out of this component,
    // for items created with new workflow and set up with a user name alias we
    // want to display the alias instead of the user name.
    performedBy = historyEntry.userName?.includes(noUserDisplay)
        ? noUserDisplay
        : performedBy;

    return (
        <div style={containerStyle(historyItemStyle)}>
            <div style={{width: '15%'}}>{dateTimeFormatted}</div>
            <div style={{width: '45%'}}>{historyEntry.description}</div>
            {!hideReviewers && (
                <Fragment>
                    <div style={{width: '20%'}}>{performedBy}</div>
                    {!excludeComments && <div style={{width: '20%'}} className='wordwrap'>{comments}</div>}
                </Fragment>
            )}
        </div>
    );
}
