﻿import {IContextWrapper} from "@vivli/shared/infrastructure/interface";
import {useDefaultApiOptions} from "@vivli/shared/infrastructure/hook";
import {MetricsApiService} from "@vivli/features/dashboard/infrastructure/service";
import {IMetricsApiService} from "@vivli/features/dashboard/infrastructure/interface";
import React from "react";
import {MetricsServiceContext} from "@vivli/features/dashboard/infrastructure/context";

export const MetricsServiceContextWrapper = ({children}: IContextWrapper) => {
    const apiOptions = useDefaultApiOptions();
    const api = new MetricsApiService(apiOptions);

    const provider: IMetricsApiService = {
        ...api
    }

    return <MetricsServiceContext.Provider value={provider}>
        {children}
    </MetricsServiceContext.Provider>
}
