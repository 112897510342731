import React, { useState } from 'react';
import { first } from 'rxjs/operators';
import { AdminHeaderButtonComponent } from '@vivli/shared/components';
import {
    useDataRequestContext,
    useDataRequestsService,
} from '@vivli/features/data-requests/infrastructure/context';
import { useModalService } from '@vivli/shared/infrastructure/context';
import { DataRequestStatusEnum } from '@vivli/features/data-requests/infrastructure/enum';
import { useAssignedAppType } from '@vivli/core/infrastructure/hook';
import { AssignedAppTypeEnum } from '@vivli/shared/infrastructure/enum';
import { useNavigate } from 'react-router-dom';

export const ResetToDraftButtonComponent = ({ dataId }) => {
    const [isLoading, setIsLoading] = useState(false);
    const {
        dataRequest,
        updateDataRequest,
        setIsUpdatingStatus,
        isUpdatingStatus,
    } = useDataRequestContext();
    const dataRequestsService = useDataRequestsService();
    const modalService = useModalService();
    const navigate = useNavigate();
    const assignedAppType = useAssignedAppType();
    const isAmr = assignedAppType === AssignedAppTypeEnum.Amr;
    const historyUrlTab = isAmr ? 'request' : 'researchTeam';

    const updateLoadingStatus = (status: boolean) => {
        setIsUpdatingStatus(status);
        setIsLoading(status);
    };

    const handleResetToDraft = () => {
        updateLoadingStatus(true);
        dataRequestsService
            .setDataRequestStatus(
                dataRequest.id,
                DataRequestStatusEnum.Draft,
                'Reset to Draft'
            )
            .pipe(first())
            .subscribe(
                (dr) => {
                    updateDataRequest(dr);
                    modalService.message(
                        'Your data request has successfully been reset to Draft status. Click continue to return to Draft overview'
                    );
                    navigate(
                        `/myDataRequestDetails/active/${dataRequest.id}/${historyUrlTab}`
                    );
                },
                (e) => {
                    modalService.error(e);
                    updateLoadingStatus(false);
                }
            );
    };

    return (
        <AdminHeaderButtonComponent
            style={{ marginRight: 10 }}
            title={'Reset to Draft'}
            isLoading={isLoading}
            isDisabled={isUpdatingStatus}
            onClick={handleResetToDraft}
            dataId={dataId}
        />
    );
};
