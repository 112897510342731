﻿import React, { ReactNode, useRef, useState } from 'react';
import { IModal, IModalApi, IModalConfirmOptions, IModalMessageOptions, IModalOptions } from '@vivli/shared/infrastructure/interface';
import { v4 as uuid } from 'uuid';
import { ConfirmDialogModalComponent, MessageModalComponent, ModalComponent } from '@vivli/shared/components';
import { ModalContext } from '@vivli/shared/infrastructure/context';

export const ModalContextWrapper = ({ children }) => {
    const modalsRef = useRef([]);
    const [modals, setModals] = useState<IModal[]>([]);

    const updateState = () => {
        setModals([...modalsRef.current]);
    };

    const addModal = (modal: IModal) => {
        modalsRef.current.push(modal);
        updateState();
    };

    const removeModal = (modal: IModal) => {
        modalsRef.current = modalsRef.current.filter((m) => m.id !== modal.id);
        updateState();
    };

    const clearModals = () => {
        modalsRef.current = [];
        updateState();
    };

    const custom = (component: ReactNode, options?: IModalOptions): string => {
        const id = uuid();

        addModal({ component, id, visible: true, ...options });

        return id;
    };

    const hide = (id?: string) => {
        modalsRef.current = modalsRef.current.map((modal) => {
            if (!id || modal.id === id) {
                modal.visible = false;
            }
            return modal;
        });

        setModals(modalsRef.current);
    };

    const show = (id: string) => {
        modalsRef.current = modalsRef.current.map((modal) => {
            if (modal.id === id) {
                modal.visible = true;
            }
            return modal;
        });

        setModals(modalsRef.current);
    };

    const dismiss = (id?: string) => {
        if (id) {
            const modalToRemove = modalsRef.current.find((m) => m.id === id);
            removeModal(modalToRemove);
        } else {
            clearModals();
        }
    };

    const confirm = (message: ReactNode, options?: IModalConfirmOptions): string => {
        const modalId = custom(
            <ConfirmDialogModalComponent
                {...options}
                message={message}
                onClose={(confirmed, comment, selections, dropdownListSelection) => {
                    if (confirmed) {
                        options?.onConfirm && options?.onConfirm({ comment, selections, dropdownListSelection });
                    } else {
                        options?.onCancel && options?.onCancel();
                    }
                    dismiss(modalId);
                }}
            />
        );
        return modalId;
    };

    const prompt = (message: ReactNode, promptDefaultText?: string, options?: IModalConfirmOptions): string => {
        return confirm(message, {
            ...options,
            showPrompt: true,
            promptDefaultText,
        });
    };

    const message = (
        message: ReactNode,
        {
            showLogo,
            showBackground,
            showContinueButton,
            showLoadingSpinner,
            style,
            messageStyle,
            dismissOnClickOutside,
            title,
            onClick,
        }: IModalMessageOptions = {
            showLogo: true,
            showBackground: true,
            showContinueButton: true,
        }
    ): string => {
        const modal = custom(
            <MessageModalComponent
                message={message}
                onClick={() => dismiss(modal)}
                onOverlayClick={() => {
                    // verbose check for true since if it is false the 2nd statement will get called as well
                    dismissOnClickOutside === true && dismiss(modal);
                    onClick && onClick();
                }}
                showContinueButton={showContinueButton}
                showLogo={showLogo}
                showLoadingSpinner={showLoadingSpinner}
                style={{ padding: '40px', ...style }}
                messageStyle={messageStyle}
                title={title}
            />,
            {
                closeOnOverlayClick: dismissOnClickOutside,
                showBackground,
                onOverlayClick: () => onClick && onClick(),
            }
        );

        return modal;
    };

    const error = (error: ReactNode) => {
        message(error, {
            showBackground: false,
            showContinueButton: true,
            showLogo: true,
            messageStyle: {
                color: 'red',
            },
        });
    };

    const modalApi: IModalApi = {
        custom,
        hide,
        show,
        dismiss,
        confirm,
        message,
        error,
        prompt,
    };

    return (
        <ModalContext.Provider value={modalApi}>
            {children}
            {modals
                ?.filter((m) => m.visible)
                .map((modal) => (
                    <ModalComponent modalApi={modalApi} modal={modal} key={modal.id} />
                ))}
        </ModalContext.Provider>
    );
};
