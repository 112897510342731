﻿import React, {ReactNode, useEffect, useRef, useState} from 'react';
import {FilterOperatorEnum, SearchTabEnum} from '@vivli/features/search/infrastructure/enum';
import {ISearchStudy} from '@vivli/features/dashboard/infrastructure/interface';
import {
    IAutocompleteItem,
    IFilterSet,
    ISampleSize,
    ISearchContext,
    ISelectOption
} from '@vivli/features/search/infrastructure/interface';
import {useCsvExportHook, useQueryParamsHook} from '@vivli/shared/infrastructure/hook';
import {useAssignedAppType} from '@vivli/core/infrastructure/hook';
import {AssignedAppTypeEnum} from '@vivli/shared/infrastructure/enum';
import {SearchContext, useAzureSearchService} from '@vivli/features/search/infrastructure/context';
import {useFacetsHook} from '@vivli/features/search/infrastructure/hook';
import {finalize, first, map, mergeMap, toArray} from 'rxjs/operators';
import {IDateRange} from '@vivli/shared/infrastructure/interface';
import {Subscription} from 'rxjs';
import {useLocation} from 'react-router-dom';
import {useConfigService} from '@vivli/core/infrastructure/context';
import ReactGA from 'react-ga4';
import {LoggerService} from '@vivli/shared/infrastructure/service';
import {fromArray} from "rxjs/internal/observable/fromArray";
import {useStudiesService} from "@vivli/features/studies/infrastructure/context";
import {useToastService} from "@vivli/shared/infrastructure/context";

interface SearchContextWrapperProps {
    children?: ReactNode;
}

export const SearchContextWrapper = ({ children }: SearchContextWrapperProps) => {
    const { initialSearch } = useConfigService();

    const [retrievingStudiesCsv, setRetrievingStudiesCsv] = useState(false);
    const [activeTab, setActiveTab] = useState<SearchTabEnum>(SearchTabEnum.FirstTab);
    const [isShowingResults, setIsShowingResults] = useState(false);
    const [searchResults, setSearchResults] = useState<ISearchStudy[]>([]);
    const [searchResultCount, setSearchResultCount] = useState<number>();
    const [isGettingStudies, setIsGettingStudies] = useState(false);
    const [autoCompleteItems, setAutoCompleteItems] = useState<IAutocompleteItem[]>([]);
    const [searchInput, setSearchInput] = useState(initialSearch || '');
    const { search } = useQueryParamsHook();
    const { getStudyExportData } = useStudiesService();
    const { saveStringToFile } = useCsvExportHook();
    const toastService = useToastService();

    const currentPageRef = useRef<number>(1);
    const pageLength = 15;
    const filterSetsRef = useRef<IFilterSet[]>([]);
    const filterStringRef = useRef<string>('');
    const selectStringsRef = useRef<string[]>([]);

    const getStudyDataSub = useRef<Subscription>(null);
    const location = useLocation();

    const assignedAppType = useAssignedAppType();
    const isAmr = assignedAppType === AssignedAppTypeEnum.Amr;
    const { studiesSearch, filtersSearch, vocabSearch } = useAzureSearchService();
    const { ctFilterValues, amrFilterValues, updateCtFilterValues } = useFacetsHook(filtersSearch);

    const updateAutoCompleteItems = (autoCompleteString: string) => {
        if (autoCompleteString.length <= 0) {
            setAutoCompleteItems([]);
            return;
        }

        vocabSearch
            .search<IAutocompleteItem>({
                select: selectStringsRef.current,
                top: pageLength,
                searchText: autoCompleteString,
                orderBy: 'label',
                filter: 'isDuplicate eq false',
            })
            .pipe(first())
            .subscribe(({ result }) => {
                setAutoCompleteItems(result);
            });
    };

    const getNextStudyData = () => {
        currentPageRef.current += 1;
        const skip = currentPageRef.current * pageLength;
        if (skip <= searchResultCount) {
            updateStudyData(skip);
        }
    };

    const getStudyData = (skip: number = null, select = null, top = pageLength) => {
        return studiesSearch
            .search<ISearchStudy, any>({
                filterSets: filterSetsRef.current,
                top,
                skip,
                searchText: filterStringRef.current?.length > 0 ? filterStringRef.current : '*',
                assignedAppType,
                facets: isAmr ? filtersSearch.amrFacets : filtersSearch.ctFacets,
                select
            })
            .pipe(first())
    }

    const updateStudyData = (skip: number = null) => {
        setIsGettingStudies(true);
        getStudyDataSub.current = getStudyData(skip)
            .subscribe(({ result, count, facets }) => {
                // if we are in skip mode, add additional studies instead of replacing
                const updatedStudies = skip ? [...searchResults, ...result] : [...result];

                if (!skip) {
                    currentPageRef.current = 0;
                }

                setSearchResults(updatedStudies);
                setSearchResultCount(count);
                setIsGettingStudies(false);

                // only update filter values if we are using the PICO search (2nd tab)
                if (!isAmr && activeTab !== SearchTabEnum.FirstTab) {
                    updateCtFilterValues(facets, true);
                }
            });
    };

    const callExport = () => {
        setRetrievingStudiesCsv(true);

        // max 1k results per call to azure search
        const max = 1000;

        const pages = Math.ceil(searchResultCount / max);

        fromArray([...Array(pages).keys()])
            .pipe(
                mergeMap(page =>
                    getStudyData(page * max, ['id'], max).pipe(
                        mergeMap(({ result }) => result),
                        map(data => ({
                            id: data.id,
                            score: data['@search.score']
                        }))
                    )
                ),
                toArray(),
                mergeMap(getStudyExportData),
                finalize(() => setRetrievingStudiesCsv(false))
            )
            .subscribe({
                error: err => toastService.error('Unable to download studies, please try again.'),
                next: exportData => {
                    const formattedDate= new Date().toLocaleDateString();
                    const fileName = `StudySearchResults_${formattedDate}.csv`;
                    saveStringToFile(exportData, fileName);
                },
            })
    };


    const toggleIsShowingResults = () => {
        if (!isShowingResults) {
            LoggerService.debug(`toggleIsShowingResults, search: ${filterStringRef.current}`);
            ReactGA.send({ hitType: 'pageview', page: `/?searchresults=${filterStringRef.current}` });
        }
        setIsShowingResults(!isShowingResults);
    };

    const setFilterSets = (sets: IFilterSet[]) => {
        filterSetsRef.current = sets;
        updateStudyData();
    };

    const setFilterString = (filterString: string) => {
        filterStringRef.current = filterString;
        updateStudyData();
    };

    const updateSelectStrings = (selectStrings: string[]) => {
        selectStringsRef.current = selectStrings;
    };

    const updateFilterString = (updatedFilterString = '') => {
        if (filterStringRef.current === updatedFilterString) {
            return;
        }

        if (!updatedFilterString) {
            updatedFilterString = '';
        }

        if (location.pathname !== '/' && !location.pathname.includes('/portal/')) {
            return;
        }

        setFilterString(updatedFilterString);
    };

    const handleBooleanFilter = (key: string, selected: boolean) => {
        if (selected) {
            addFilterSet({
                key,
                itemOperator: FilterOperatorEnum.Or,
                items: [{ value: true }],
            });
        } else if (filterSetsRef.current.some((x) => x.key === key)) {
            removeFilterSet(key);
        }
    };

    const handleDropdownFilter = (key: string, options: ISelectOption[] = [], useAnySyntax = false) => {
        if (options?.length > 0) {
            addFilterSet({
                key,
                operator: useAnySyntax ? FilterOperatorEnum.Any : null,
                itemOperator: FilterOperatorEnum.Or,
                items: options.map((x) => ({ key, value: x.value })),
            });
        } else if (filterSetsRef.current.some((x) => x.key === key)) {
            removeFilterSet(key);
        }
    };

    const handleDateRangeFilter = (key: string, dateRange: IDateRange, isYearRange = false) => {
        if (dateRange) {
            addFilterSet({
                key,
                isYearRange,
                itemOperator: FilterOperatorEnum.And,
                items: [{ value: dateRange }],
            });
        } else if (filterSetsRef.current.some((x) => x.key === key)) {
            removeFilterSet(key);
        }
    };

    const handleSampleSizeFilter = (key: string, sampleSize: ISampleSize) => {
        if (sampleSize) {
            addFilterSet({
                key,
                itemOperator: FilterOperatorEnum.And,
                items: [{ value: sampleSize }],
            });
        } else if (filterSetsRef.current.some((x) => x.key === key)) {
            removeFilterSet(key);
        }
    };

    const handleYearRangeFilter = (key: string, from?: number, to?: number, firstYear?: number) => {
        const defaultEarliest = 2004;
        const fromDate = new Date();
        if (from) {
            fromDate.setFullYear(from);
        } else {
            //set fromDate to the first year passed in, if any, or a default
            const initialYear = firstYear ? firstYear : defaultEarliest;
            fromDate.setFullYear(initialYear);
        }

        const toDate = new Date();
        if (to) {
            toDate.setFullYear(to);
        } else {
            //set toDate to today
            const thisYear = new Date().getFullYear();
            toDate.setFullYear(thisYear);
        }

        const dateRange: IDateRange = {
            from: fromDate,
            to: toDate,
        };

        handleDateRangeFilter(key, dateRange, true);
    };

    const addFilterSet = (filterSet: IFilterSet) => {
        const cleanFilterSets = filterSetsRef.current.filter((x) => x.key !== filterSet.key);

        setFilterSets([...cleanFilterSets, filterSet]);
    };

    const updateFilterSets = (updatedFilterSets: IFilterSet[]) => {
        const addedSets = updatedFilterSets.filter((ufs) => !filterSetsRef.current.some((fs) => fs.key === ufs.key));

        const newFilterSets = filterSetsRef.current.map((fs) => {
            const updatedSet = updatedFilterSets.find((ufs) => ufs.key === fs.key);
            if (updatedSet) {
                return updatedSet;
            }

            return fs;
        });

        setFilterSets([...newFilterSets, ...addedSets]);
    };

    const removeFilterSet = (key: string) => {
        const cleanFilterSets = filterSetsRef.current.filter((x) => x.key !== key);

        setFilterSets([...cleanFilterSets]);
    };

    const resetFilterSets = () => {
        setFilterSets([]);
    };

    useEffect(() => {
        if (search?.length > 0) {
            toggleIsShowingResults();
        }

        return () => {
            getStudyDataSub.current?.unsubscribe();
        };
    }, []);

    const provider: ISearchContext = {
        isGettingStudies,
        searchResultCount,
        isShowingResults,
        searchResults,
        amrFilterValues,
        ctFilterValues,
        autoCompleteItems,
        activeTab,
        filterString: filterStringRef.current,
        searchInput,
        toggleIsShowingResults,
        updateFilterString,
        updateSelectStrings,
        handleDropdownFilter,
        handleDateRangeFilter,
        handleSampleSizeFilter,
        handleYearRangeFilter,
        handleBooleanFilter,
        updateStudyData,
        getNextStudyData,
        updateAutoCompleteItems,
        addFilterSet,
        updateFilterSets,
        removeFilterSet,
        setActiveTab,
        resetFilterSets,
        setSearchInput,
        callExport,
        retrievingStudiesCsv
    };

    return <SearchContext.Provider value={provider}>{children}</SearchContext.Provider>;
};
