import React, {CSSProperties, useEffect, useState} from 'react';
import {IUserBulkUpdateSpecifier, IUserDetails} from '@vivli/features/users/infrastructure/interface';
import {ButtonComponent, FieldHeaderComponent} from '@vivli/shared/components';
import {DataTransformationService} from '@vivli/shared/infrastructure/service';
import {GridCellRendererEnum} from '@vivli/shared/infrastructure/enum';
import {ColDef} from 'ag-grid-community';
import {useOrganizations} from "@vivli/features/organizations/infrastructure/hook";
import {useUsers} from "@vivli/features/users/infrastructure/hook";
import {useNavigate} from "react-router-dom";
import {DocumentsDataPackageFeature} from "@vivli/shared/features/data-package/feature";
import {Size, Styles} from "@vivli/shared/theme";
import {first} from "rxjs/operators";
import {useModalService} from "@vivli/shared/infrastructure/context";
import {useUsersService} from "@vivli/features/users/infrastructure/context";
import {UserGridPageComponent} from "@vivli/features/users/components";
import {useActiveUser} from "@vivli/core/infrastructure/context";


const uploadContainerStyle: CSSProperties = {
    paddingLeft: Size.INSET_SIZE,
    paddingRight: Size.INSET_SIZE,
    flex: 'auto',
}

const modalMessage = 'User List is  being updated in the Vivli system. You will receive an email when the process is complete.';
export const UsersFeature = () => {
    const [showBulkControl, setShowBulkControl] = useState(false);
    const [bulkUploadId, setBulkUploadId] = useState<string>(null);
    const [uploadFileName, setUploadFileName] = useState<string>(null);

    const activeUser = useActiveUser();
    const navigate = useNavigate();
    const modalService = useModalService();
    const userService = useUsersService();

    const allowBulkUpload = activeUser.isVivliAdmin || activeUser.isOperationsAdmin;

    const handleRowClick = (data) => {
        navigate(`/admin/users/${data.id}`, {state: data});
    }

    const handleError = (error) => {
        modalService.error(error);
    }
    const getDraftUpdateId = () => {
        userService.getUpdatePackage()
            .pipe(first())
            .subscribe((id) => {
                setBulkUploadId(id);
            }, handleError);
    }

    const handleProcessUploadSheet = () => {
        setShowBulkControl(false);
        const uploadSpecifier: IUserBulkUpdateSpecifier = {
            dataPackageId: bulkUploadId,
            fileName: uploadFileName
        }
        modalService.message(modalMessage);
        userService.processBulkUpdate(uploadSpecifier)
            .pipe(first())
            .subscribe(()=>{
                },
                handleError);
    }

    const customHeader = <ButtonComponent
        style={{position: "absolute", left: "85%"}}
        onClick={() => {
            setShowBulkControl(!showBulkControl)
        }}
    >
        {showBulkControl ? "Close" : "Bulk Update"}
    </ButtonComponent>

    const customBulkUploadComponent = <>
        {showBulkControl &&
            <>
                <div style={uploadContainerStyle}>
                    <FieldHeaderComponent
                        title='To Update a set of users please create an update CSV file with the required fileds.Then click on process update sheet.'/>
                    <DocumentsDataPackageFeature
                        dataPackageId={bulkUploadId}
                        availableTypes={[]}
                        useSecureStorage={false}
                        setUploadFileName={setUploadFileName}
                        showVerifyButton={false}

                    />
                </div>

                <div style={{paddingBottom: 30}}>
                    <ButtonComponent
                        isLoading={false}
                        style={Styles.Button.BUTTON_VIVLI_BLUE}
                        onClick={handleProcessUploadSheet}
                        className='bulkUpload_processSheet'
                    >
                        Process Update Sheet
                    </ButtonComponent>
                </div>
            </>
        }
    </>


    useEffect(() => {
        if (allowBulkUpload) {
            getDraftUpdateId()
        }
    }, [])

    return (
        <UserGridPageComponent
            title={showBulkControl ? 'Bulk Update Users' : 'User Management'}
            onRowClick={handleRowClick}
            exportTitle='Users'
            customHeader={allowBulkUpload && customHeader}
            fullWidthColumns={true}
            customComponent={allowBulkUpload && customBulkUploadComponent}
            asyncUpdateKey='id'
            isVivliAdmin={activeUser.isVivliAdmin}
        />
    )
}
