import {
    IAzureSearchOptions,
    IAzureSearchService,
    IFilterSet,
    IFilterSetItem,
    instanceOfSampleSize,
} from '@vivli/features/search/infrastructure/interface';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { instanceOfDateRange } from '@vivli/shared/infrastructure/interface';
import moment from 'moment';
import { AzureSearchBaseService } from './azure-search-base.service';
import { AssignedAppTypeEnum } from '@vivli/shared/infrastructure/enum';
import { FilterOperatorEnum } from '@vivli/features/search/infrastructure/enum';

export class AzureSearchService extends AzureSearchBaseService implements IAzureSearchService {
    private _addToFilterString(filterString: string, stringToAdd: string) {
        if (!stringToAdd || stringToAdd.length <= 0) {
            return filterString;
        }

        if (filterString.trim().length > 0) {
            return `${filterString} and (${stringToAdd})`;
        }

        return stringToAdd;
    }

    private _parseAnySyntaxItem(item: IFilterSetItem, key: string): string {
        return `${key}/any(l: l eq '${item.value}')`;
    }

    private _parseDefaultItem(item: IFilterSetItem, key: string): string {
        if (typeof item.value === 'boolean') {
            return `${key} eq ${item.value}`;
        }

        return `${key} eq '${item.value}'`;
    }

    private _parseRangeItem(from: any, to: any, key: string): string {
        if (!from && !to) {
            return '';
        }

        let result = '';

        const hasFromValue = from !== null && from !== undefined;
        const hasToValue = to !== null && to !== undefined;
        const useAndOperator = hasFromValue && hasToValue;

        if (hasFromValue) {
            result += `${key} ge ${from}`;
        }

        if (useAndOperator) {
            result += ' and ';
        }

        if (hasToValue) {
            result += `${key} le ${to}`;
        }

        return result;
    }

    private _parseDefaultDateRange(item: IFilterSetItem, key: string) {
        if (!instanceOfDateRange(item.value)) {
            return '';
        }

        const from = item.value.from?.toISOString() || null;
        const to = item.value.to?.toISOString() || null;
        return this._parseRangeItem(from, to, key);
    }

    private _enumerateYearsBetweenDates(startDate: Date, endDate: Date): number[] {
        const now = moment(startDate);
        const dates: number[] = [];

        while (now.isSameOrBefore(moment(endDate))) {
            dates.push(now.year());
            now.add(1, 'years');
        }

        return dates;
    }

    private _getYearRange(from?: Date, to?: Date): number[] {
        const fromYear = from?.getFullYear();
        const toYear = to?.getFullYear();

        if (fromYear === toYear) {
            // both years are the same
            return [toYear];
        } else if (fromYear && !toYear) {
            // we only have from year
            return [fromYear];
        } else if (toYear && !fromYear) {
            // we only have to year
            return [toYear];
        } else {
            // we have a selection of years
            return this._enumerateYearsBetweenDates(from, to);
        }
    }

    private _parseYearDateRange(item: IFilterSetItem, key: string) {
        if (!instanceOfDateRange(item.value) || !item.value.from || !item.value.to) {
            return '';
        }

        const yearRange = this._getYearRange(item.value.from, item.value.to);

        let result = '';
        if (yearRange.length === 0) {
            //detect a bad year range - generally, From date is > To Date
            //Return a search string that
            //will never match anything - for the next 7000 years or so
            result += `${key}/any(l: l eq '9999')`;
        }

        yearRange.forEach((year, i) => {
            if (i > 0 && result.length > 0) {
                result += ' or ';
            }

            result += `${key}/any(l: l eq '${year}')`;
        });

        return result;
    }

    private _parseDateRangeItem(item: IFilterSetItem, key: string, isYearRange?: boolean): string {
        if (!instanceOfDateRange(item.value)) {
            return '';
        }

        if (isYearRange) {
            return this._parseYearDateRange(item, key);
        } else {
            return this._parseDefaultDateRange(item, key);
        }
    }

    private _parseSampleSizeItem(item: IFilterSetItem, key: string): string {
        if (!instanceOfSampleSize(item.value)) {
            return '';
        }

        const from = item.value.min;
        const to = item.value.max;
        return this._parseRangeItem(from, to, key);
    }

    private _parseFilterSetItems(filterSet: IFilterSet): string {
        let result = '';

        filterSet.items.forEach((item, i) => {
            const isSampleSize = instanceOfSampleSize(item.value);
            const isDateRange = instanceOfDateRange(item.value);

            const operator = ` ${filterSet.itemOperator} `;

            let parsedItem = '';
            if (filterSet.operator === FilterOperatorEnum.Any) {
                parsedItem = this._parseAnySyntaxItem(item, item.key || filterSet.key);
            } else if (isDateRange) {
                parsedItem = this._parseDateRangeItem(item, item.key || filterSet.key, filterSet.isYearRange);
            } else if (isSampleSize) {
                parsedItem = this._parseSampleSizeItem(item, item.key || filterSet.key);
            } else {
                parsedItem = this._parseDefaultItem(item, item.key || filterSet.key);
            }

            if (parsedItem && parsedItem.length > 0) {
                if (i > 0 && result.length > 0) {
                    result += operator;
                }

                result += parsedItem;
            }
        });

        if (result.length > 0) {
            return `(${result})`;
        }

        return result;
    }

    private _parseFilterSets(filterSets: IFilterSet[]): string {
        let result = '';

        filterSets.forEach((set, i) => {
            const parsedFilterItemsStr = this._parseFilterSetItems(set);

            // add a filter string criteria, encapsulate in braces in case there are multiple "or" statements
            if (parsedFilterItemsStr?.length > 0) {
                if (i > 0 && result.length > 0) {
                    result += ' and ';
                }

                result += parsedFilterItemsStr;
            }
        });

        return result;
    }

    private _parseSelectString(select: string[]) {
        let selectString = '';

        select.forEach((field, i) => {
            selectString += field;
            if (i !== select.length - 1) {
                selectString += ',';
            }
        });

        return selectString;
    }

    private _isQuotedString = (str: string) => {
        const lastChar = str.charAt(str.length - 1);
        const firstChar = str.charAt(0);

        return lastChar === '"' && firstChar === '"';
    };

    private _parseWord(word: string) {
        let result = word;

        // don't format the default search for all "*"
        if (result === '*') {
            return result;
        }

        if (word === '-') {
            result = 'not';
        }

        if (word === '/' || word === '&') {
            result = 'and';
        }

        return this.quoteSearchTerms ? `"${result}"` : result;
    }

    private _parseSearchString(searchString: string) {
        return decodeURIComponent(
            searchString
                ?.toLowerCase()
                .split(/(".*?")/g) // split out any quoted strings
                .filter((x) => x.length > 0) // filter out empty results caused by split
                .reduce((acc, value) => {
                    const isQuotedString = this._isQuotedString(value);

                    if (isQuotedString) {
                        // keep quoted words as-is
                        acc = [...acc, value];
                    } else {
                        // parse any words not inside quotes
                        const words = value
                            .trim()
                            .split(' ')
                            .map((word) => this._parseWord(word));

                        // add them individually to the result array
                        acc = [...acc, ...words];
                    }

                    return acc;
                }, [])
                .join(' ')
                .replace(/ "and" /g, ' + ')
                .replace(/ "not" /g, ' - ')
                .replace(/ "or" /g, ' | ')
        );
    }

    getSearchCount(assignedAppType?: AssignedAppTypeEnum): Observable<number> {
        const filter = assignedAppType ? `assignedAppType eq '${assignedAppType}'` : '';

        return this._searchClient
            .getDocuments({
                filter,
                top: 0,
                includeTotalCount: true,
                search: '*',
            })
            .pipe(map((result) => result['@odata.count']));
    }

    getDocument<T>(documentId: string, searchOptions?: IAzureSearchOptions) {
        const selectString = searchOptions?.select ? this._parseSelectString(searchOptions.select) : null;

        return this._searchClient.getDocument<T>(documentId, {
            filter: searchOptions?.filter,
            includeTotalCount: true,
            skip: searchOptions?.skip,
            top: searchOptions?.top,
            search: searchOptions?.searchText,
            select: selectString,
            orderBy: searchOptions?.orderBy,
        });
    }

    search<DocumentType, FacetType = void>(
        searchOptions: IAzureSearchOptions
    ): Observable<{ result: DocumentType[]; count: number; facets: FacetType }> {
        const { assignedAppType, filterSets, searchText, top, skip, filter, select, orderBy, facets } = {
            ...this._defaultOptions,
            ...searchOptions,
        };

        let filterString = '';

        if (assignedAppType) {
            filterString = this._addToFilterString(filterString, `assignedAppType eq '${assignedAppType}'`);
        }

        if (filterSets?.length > 0) {
            filterString = this._addToFilterString(filterString, this._parseFilterSets(filterSets));
        }

        if (filter && filter.length > 0) {
            filterString = this._addToFilterString(filterString, filter);
        }

        const selectString = select ? this._parseSelectString(select) : null;

        const parsedSearchString = this._parseSearchString(searchText);

        return this._searchClient
            .getDocuments({
                filter: filterString,
                includeTotalCount: true,
                skip,
                top,
                search: parsedSearchString,
                select: selectString,
                orderBy,
                facets,
            })
            .pipe(
                map((result) => ({
                    count: result['@odata.count'],
                    result: result.value,
                    facets: result['@search.facets'],
                }))
            );
    }
}
