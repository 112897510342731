﻿import { IConfig, IContextWrapper } from '@vivli/shared/infrastructure/interface';
import React, { useCallback, useEffect, useState } from 'react';
import { ConfigServiceContext, useAnonymousApiService } from '@vivli/core/infrastructure/context';
import { useAssignedAppTypeInitialization } from '@vivli/core/infrastructure/hook';
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import { usePortalConfigHook } from '@vivli/shared/infrastructure/hook';
import { environment } from '@vivli/core/environments';
import { first } from 'rxjs/operators';


export const ConfigServiceContextWrapper = ({ children }: IContextWrapper) => {
    const { getConfig, getTranslations } = useAnonymousApiService();
    const [config, setConfig] = useState<IConfig>(null);
    const [translationsReady, setTranslationsReady] = useState<boolean>(false);
    const assignedAppType = useAssignedAppTypeInitialization();
    const { getPortalConfig } = usePortalConfigHook();

    const handleTranslations = useCallback(() => {
        if (i18next.isInitialized) {
            return;
        }

        return getTranslations(assignedAppType)
            .pipe(first())
            .subscribe((translation) => {
                i18next.use(initReactI18next).init(
                    {
                        react: {
                            transKeepBasicHtmlNodesFor: ['br', 'strong', 'u', 'p'],
                        },
                        lng: 'en', // if you're using a language detector, do not define the lng option
                        debug: true,
                        resources: {
                            en: {
                                translation,
                            },
                        },
                    },
                    () => {
                        setTranslationsReady(true);
                    }
                );
            });
    }, []);

    const handleConfig = useCallback(() => {
        return getConfig().subscribe((config) => {
            let configResult = { ...config };
            if (!configResult.signupEnabled) {
                configResult.b2CSignInPolicyName = 'B2C_1_VivliSignInOnly';
            }

            configResult = getPortalConfig(configResult);

            // handle local override to view request results app
            if (environment.viewRequestResultsOnly) {
                configResult.viewRequestResultsOnly = true;
            }

            setConfig({
                ...configResult,
                assignedAppType,
            });
        });
    }, []);

    useEffect(() => {
        if (!assignedAppType) {
            return;
        }

        const configSub = handleConfig();
        handleTranslations();

        return () => {
            configSub.unsubscribe();
        };
    }, [handleConfig, handleTranslations, assignedAppType]);

    return config && translationsReady ? <ConfigServiceContext.Provider value={config}>{children}</ConfigServiceContext.Provider> : null;
};
