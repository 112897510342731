import {IDataRequest} from "@vivli/features/data-requests/infrastructure/interface";
import {useDataRequestPermissions} from "./use-data-request-permissions.hook";
import {useActiveUser} from "@vivli/core/infrastructure/context";
import {useEffect, useState} from "react";

export function useResearchTeamPermissions(dataRequest: IDataRequest) {
    const [userCanEdit, setUserCanEdit] = useState(false);
    const { userIsOwner, userIsAdmin } = useDataRequestPermissions();
    const user = useActiveUser();

    const handlePermissions = () => {
        setUserCanEdit(user?.isVivliAdmin || userIsOwner(dataRequest) || userIsAdmin(dataRequest));
    }

    useEffect(() => {
        handlePermissions();
    }, [dataRequest, user])

    return {
        userCanEdit
    }
}
