﻿import {ButtonComponent} from "@vivli/shared/components";
import {Size, Styles} from "@vivli/shared/theme";
import React, {CSSProperties} from "react";
import {useModalService} from "@vivli/shared/infrastructure/context";
import {DTICommonConst} from "@vivli/shared/infrastructure/constants";

const genericStyle: CSSProperties = {
    marginTop: Size.FIELD_SEPARATION,
    marginBottom: Size.FIELD_SEPARATION,
}

const oneAcrossStyleSmallNoBox: CSSProperties = {
    ...genericStyle,
    flex: '1 1 100%',
    maxWidth: '20%',
    marginLeft: 'auto',
    marginRight: 'auto',
}

interface AddStudyInProcessComponentProps {
    studyName: string;
    isAmr: boolean;
    addUnlistedStudy?: () => void;
    addInProcessStudy?: (id: string) => void;
}

export const AddStudyInProcessComponent = (
    {
        studyName,
        isAmr,
        addUnlistedStudy,
        addInProcessStudy,
    }: AddStudyInProcessComponentProps
) => {

    const modal = useModalService();

    const studyLc = studyName.toLowerCase();
    const studyCapitalized = studyName.charAt(0).toUpperCase() + studyName.slice(1);
    const message = `Please enter the NCT Id or Sponsor Protocol Id of the ${studyLc} you would like to add to this data request.`;
    const modalTitle = `Add ${studyCapitalized} to In-Process Request`;
    const addLabel = isAmr ? 'Add Dataset' : 'Add Vivli Study';

    const handleResult = (id: string) => {
        console.log('id provided is '+ id);
        addInProcessStudy(id);
    };

    const handleAddStudy = (e) => {
        e.preventDefault();
        e.stopPropagation();

        const modalId = modal.confirm(message, {
            title: modalTitle,
            style: { textAlign: 'left', minWidth: '50em' },
            messageStyle: { textAlign: 'left' },
            showMultiSelect: false,
            showTextAreaPrompt: true,
            showPrompt: false,
            promptLabel: `NCt or Sponsor Id to add (must be from data provider already in this data request):`,
            confirmText: 'OK',
            cancelText: 'Cancel',
            onConfirm: ({  comment }) => {
                handleResult(comment);
                modal.dismiss(modalId);
            },
        });
    }

    return (
        <div style={{marginTop: '20px'}}>
            <div style={Styles.FORM_ROW}>
                <ButtonComponent
                    style={oneAcrossStyleSmallNoBox}
                    onClick={(e) => {
                        handleAddStudy(e)
                    }}
                    dataId={DTICommonConst.AddButton}
                >
                    {addLabel}
                </ButtonComponent>
                {!isAmr &&
                    <ButtonComponent
                        style={oneAcrossStyleSmallNoBox}
                        onClick={(e) => {
                            addUnlistedStudy()
                        }}
                        dataId={DTICommonConst.AddStudy}
                    >
                        Add Unlisted Study
                    </ButtonComponent>
                }
            </div>
        </div>

    )


}
