﻿import React, {CSSProperties} from 'react';
import {MultiSelectDropdownFormFieldComponent} from '../form-field/multi-select-dropdown-form-field.component';
import {TextFormFieldComponent} from '../form-field/text-form-field.component';
import {useConfigService} from '@vivli/core/infrastructure/context';
import {creditHint} from '@vivli/features/listing-request/infrastructure/constants';
import {Color} from '@vivli/shared/theme';
import {AssetsConstant, DTICTFormFields, DTIFormFields} from '@vivli/shared/infrastructure/constants';

const orcidImageStyle: CSSProperties = {
    paddingRight: 0,
    alignContent: 'end',
    paddingLeft: 20,
    width: '44px',
};
const svgImageStyle: CSSProperties = {
    paddingRight: 0,
    alignContent: 'end',
    paddingLeft: 2,
    width: '24px',
};
const containerGridColumnStyle: CSSProperties = {
    display: 'grid',
    gridTemplateColumns: '70% 30%',
    padding: 15,
    backgroundColor: Color.TRANSPARENT_ONE,
};
const innerGridRowStyle: CSSProperties = {
    display: 'grid',
    gridTemplateRows: 'auto',
    rowGap: '10px',
};
const teamGridColumnStyle: CSSProperties = {
    display: 'grid',
    gridTemplateColumns: '48% 4% 44%',
    columnGap: '10px',
};
const teamGridColumnStyle2: CSSProperties = {
    display: 'grid',
    gridTemplateColumns: '48% 4% 44%',
    columnGap: '10px',
};
const teamGridRightColumnStyle: CSSProperties = {
    display: 'grid',
    gridTemplateColumns: '92% 1%',
    columnGap: '8px',
};
const buttonImgStyle: CSSProperties = {
    width: '35px',
    height: '100%',
    cursor: 'pointer',
};

interface ResearchMemberRowComponentProps {
    index: number;
    isReadOnly: boolean;
    isOrcidUpdated: boolean;
    arrayName: string;
    handleRemoveRow: (rowIndex) => void;

}

export const ResearchMemberRowComponent = ({
                                               index,
                                               isReadOnly,
                                               isOrcidUpdated,
                                               arrayName,
                                               handleRemoveRow,
                                           }: ResearchMemberRowComponentProps) => {
    const fieldName = `${arrayName}[${index}].`;
    const config = useConfigService();
    const creditRoles = config?.creditRoles;
    const creditOptions = Object.entries(creditRoles).map((creditRole) => ({
        id: creditRole[0],
        contributorRole: creditRole[1],
    }));
    const isReadOnlyOrcid = isReadOnly || isOrcidUpdated;
    return (
        <div style={containerGridColumnStyle} key={index}>
            <div style={innerGridRowStyle}>
                <div style={teamGridColumnStyle2}>
                    <TextFormFieldComponent
                        name={`${fieldName}emailAddress`}
                        label={'Email address'}
                        readonly={isReadOnlyOrcid}
                        dataId={DTICTFormFields.Email}
                    />
                    <div style={orcidImageStyle}>
                        <img
                            alt="ORCID logo"
                            src="https://info.orcid.org/wp-content/uploads/2019/11/orcid_24x24.png"
                            width="24"
                            height="24"
                        />

                        {isOrcidUpdated && <svg width="16px" height="24px" viewBox="0 0 16 24" version="1.1"
                                                xmlns="http://www.w3.org/2000/svg" style={svgImageStyle}>
                            <title>Research Team member's credits updated in ORCID</title>
                            <desc>Created with Sketch.</desc>
                            <defs></defs>
                            <g id="Latest-Screens" stroke="none" strokeWidth="2" fill="none" fillRule="evenodd">
                                <g id="Iconography" transform="translate(-332.000000, -238.000000)" fill="#84BD00">
                                    <g id="Icons_CheckMark"
                                       transform="translate(340.485281, 242.485281) rotate(45.00000) translate(-340.485281, -242.485281) translate(336.485281, 234.485281)">
                                        <rect id="Rectangle-17" x="0.0226678541" y="14.639203" width="7"
                                              height="3"></rect>
                                        <rect id="Rectangle-17-Copy" x="6.02081528" y="0.636038969" width="2"
                                              height="15"></rect>
                                    </g>
                                </g>
                            </g>
                        </svg>
                        }
                    </div>

                    <TextFormFieldComponent
                        name={`${fieldName}orcidId`}
                        label={'ORCID iD'}
                        readonly={isReadOnlyOrcid}
                        dataId={DTICTFormFields.OrcidId}
                    />
                </div>
                <div style={teamGridColumnStyle}>
                    <TextFormFieldComponent
                        name={`${fieldName}givenName`}
                        label={'Given Name'}
                        readonly={isReadOnlyOrcid}
                        dataId={DTICTFormFields.FName}
                    />
                    <div style={orcidImageStyle}></div>
                    <TextFormFieldComponent
                        name={`${fieldName}familyName`}
                        label={'Family Name'}
                        readonly={isReadOnlyOrcid}
                        dataId={DTICTFormFields.LName}
                    />
                </div>
                <div style={teamGridColumnStyle}>
                    <TextFormFieldComponent
                        name={`${fieldName}rorInformation.rorId`}
                        label={'ROR Id'}
                        readonly={true}
                        dataId={DTICTFormFields.RorId}
                    />
                    <div style={orcidImageStyle}></div>
                    <TextFormFieldComponent
                        name={`${fieldName}rorInformation.rorName`}
                        label={'Organization'}
                        readonly={true}
                        dataId={DTICTFormFields.RorName}
                    />
                </div>
            </div>
            <div style={teamGridRightColumnStyle}>
                <MultiSelectDropdownFormFieldComponent
                    label={'CRediT Role(s)'}
                    name={`${fieldName}creditRoles`}
                    items={creditOptions}
                    objectKey={'id'}
                    labelKey={'contributorRole'}
                    isDisabled={isReadOnly}
                    hint={creditHint}
                    style={{height: '100%'}}
                />
                {!isReadOnly && (
                    <span onClick={() => handleRemoveRow(index)}>
                        <img
                            src={AssetsConstant.REMOVE_RESEARCHER}
                            style={buttonImgStyle}
                        />
                    </span>
                )}
            </div>
        </div>
    );
};
