﻿import React, { CSSProperties } from 'react';
import './load-indicator.styles.scss';
import { LoadIndicatorColorEnum, LoadIndicatorSizeEnum } from '@vivli/shared/infrastructure/enum';

export interface LoadIndicatorProps {
    message?: string;
    color?: LoadIndicatorColorEnum;
    style?: CSSProperties;
    size?: LoadIndicatorSizeEnum;
}

export const LoadIndicatorComponent = ({
    message,
    color = LoadIndicatorColorEnum.Blue,
    style,
    size = LoadIndicatorSizeEnum.Small,
}: LoadIndicatorProps) => {
    let sizeInPx = '22px';

    switch (size) {
        case LoadIndicatorSizeEnum.Small:
            break;
        case LoadIndicatorSizeEnum.Medium:
            sizeInPx = '50px';
            break;
        case LoadIndicatorSizeEnum.Large:
            sizeInPx = '75px';
            break;
    }

    const containerStyle: CSSProperties = {
        position: 'relative',
        overflow: 'visible',
        paddingTop: sizeInPx,
        height: 0,
        width: sizeInPx,
        ...style,
    };

    return (
        <div style={containerStyle}>
            <div className={`load-indicator-${size}-${color}`} />
            <div className={`paragraph-style-${size}-${color}`}>{message}</div>
        </div>
    );
};
