import React from 'react';
import {ICellRendererParams, RowNode} from 'ag-grid-community';
import {IDataRequest, IRequestedStudy} from '@vivli/features/data-requests/infrastructure/interface';
import {DataRequestRequestedStudyRowComponent} from "./data-request-requested-study-row.component";

interface  RequestedStudiesRowRendererComponentProps extends ICellRendererParams {
    data: { study: IRequestedStudy, dataRequest: IDataRequest },
    onRemoveRow?: (node: RowNode, dataRequest: IDataRequest) => void;
}

export const RequestedStudiesRowRenderer = ({data, node, api, onRemoveRow}:
                                                RequestedStudiesRowRendererComponentProps) => {

    const handleHeightChange = (height: number) => {
        node.setRowHeight(height);
        api.onRowHeightChanged();
    }

    return (
        <DataRequestRequestedStudyRowComponent
            study={data.study}
            dataRequest={data.dataRequest}
            onHeightChange={handleHeightChange}
            onRemoveRow={dataRequest => onRemoveRow(node, dataRequest)}
        />
    )
}
