import {useEffect, useState} from "react";
import {IMsal2AuthService, LogoutWarning, Msal2EventTypeEnum} from "../interface";
import {interval, Subscription} from "rxjs";
import {LoggerService, StorageService} from "@vivli/shared/infrastructure/service";
import moment from "moment";

export const useLoginExpiration = (authService: IMsal2AuthService) => {
    const [innerAuthService, setInnerAuthService] = useState(authService)

    const expirationItemName = 'msal_timeout';
    const warningItemName = 'msal_warning';
    const intervalDelay = 30000; // 30 sec

    let warningWatcherSub: Subscription;
    let expirationWatcherSub: Subscription;

    const removeExpiration = () => {
        warningWatcherSub?.unsubscribe();
        expirationWatcherSub?.unsubscribe();
        StorageService.deleteItem(expirationItemName);
        StorageService.deleteItem(warningItemName);
    }

    const handleAuthExpiration = () => {
        removeExpiration();

        if (innerAuthService.getAccount()) {
            innerAuthService.logout();
        }
    }

    const setupExpirationTimer = () => {
        LoggerService.debug('Initializing expiration timer', moment())

        // check to see if it's time to log out the user
        expirationWatcherSub = interval(intervalDelay).subscribe(() => {
            const date = StorageService.getItem<string>(expirationItemName);

            const expirationDate = moment(date);
            LoggerService.debug('Checking login expiration', expirationDate)

            // check if the current time is the same or after the warning time
            if (moment().isSameOrAfter(moment(expirationDate))) {
                expirationWatcherSub?.unsubscribe();

                // log out the user
                handleAuthExpiration();
            }
        })
    }

    const setupWarningTimer = () => {
        // check to see if it's time to warn the user they will be logged out soon
        warningWatcherSub = interval(intervalDelay).subscribe(() => {
            const date = StorageService.getItem<string>(warningItemName);

            const warningDate = moment(date);
            LoggerService.debug('Checking warning expiration', warningDate)

            // check if the current time is the same or after the warning time
            if (moment().isSameOrAfter(warningDate)) {
                warningWatcherSub?.unsubscribe();

                // trigger the warning
                innerAuthService.triggerEvent(new LogoutWarning());

                // activate the expiration timer
                setupExpirationTimer();
            }
        })
    }

    const refreshExpiration = () => {
        removeExpiration();
        initializeExpiration();
    }

    const initializeExpiration = (): void => {
        if (!innerAuthService.options.timeout) {
            return;
        }

        // used to sync warning and timout dates
        const currentDate = moment();
        LoggerService.debug('Initializing Expiration', currentDate)

        // get minutes to expiration and minutes to warning
        const {expirationMinutes, warningMinutes} = innerAuthService.options.timeout;

        // save the date + minutes to warning/expiration to storage
        const timeTillWarning = currentDate.add(warningMinutes, 'minutes').toISOString(true);
        StorageService.setItem(warningItemName, timeTillWarning);

        const timeTillExpiration = currentDate.add(expirationMinutes - warningMinutes, 'minutes').toISOString(true);
        StorageService.setItem(expirationItemName, timeTillExpiration);

        setupWarningTimer();
    }

    const disableExpiration = () => {
        removeExpiration();
    }

    useEffect(() => {
        if (!innerAuthService || !innerAuthService.options.timeout) {
            return;
        }

        const eventsSub = innerAuthService.authEvents.subscribe(event => {
            switch (event.type) {
                case Msal2EventTypeEnum.LoginEvent:
                    initializeExpiration();
                    break;
                case Msal2EventTypeEnum.LogoutEvent:
                    disableExpiration();
                    break;
                case Msal2EventTypeEnum.RefreshAuthExpirationEvent:
                    refreshExpiration();
                    break;
            }
        })

        return () => {
            eventsSub.unsubscribe();
            disableExpiration();
        }
    }, [innerAuthService])

    useEffect(() => {
        setInnerAuthService(authService);
    }, [authService])
}
