﻿import React from "react";
import {AmrDataRequestTabsComponent} from "./amr-data-request-tabs.component";
import {DataRequestStatusEnum} from "@vivli/features/data-requests/infrastructure/enum";
import {useDataRequestContext} from "@vivli/features/data-requests/infrastructure/context";
import {useDataRequestAdminPath, useDataRequestPermissions} from "@vivli/features/data-requests/infrastructure/hook";

export const AmrDataRequestAdminTabsComponent = () => {
    const {dataRequest, organizations, isAdminEditing} = useDataRequestContext();
    const {shouldHideReviewers} = useDataRequestPermissions();
    const isDataRequestsView = useDataRequestAdminPath();
    const isDraft = dataRequest.status === DataRequestStatusEnum.Draft
    const hideReviewers = shouldHideReviewers(dataRequest, organizations);
    const showRequestTab = isDraft && !isDataRequestsView;
    const showPrintTab = !hideReviewers && !isAdminEditing && !isDraft && !isDataRequestsView;
    const showRequestPrintTab = isDataRequestsView || isAdminEditing;

    return (
        <AmrDataRequestTabsComponent
            showRequestTab={showRequestTab}
            showPrintTab={showPrintTab}
            showRequestPrintTab={showRequestPrintTab}
            showStatusUpdateTab={true}
        />
    )
}
