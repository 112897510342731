﻿import React, {useEffect, useState} from 'react';
import {
    btnStyle,
    ButtonComponent,
    innerFormContainerStyle,
    outerFormContainerStyle,
    rightHeaderContainerStyle,
    submitBtnStyle,
} from '@vivli/shared/components';
import {useActiveUser} from '@vivli/core/infrastructure/context';
import {DataRequestStatusEnum} from '@vivli/features/data-requests/infrastructure/enum';
import {useDataRequestContext, useStatusUpdateContext,} from '@vivli/features/data-requests/infrastructure/context';
import {ApproveRejectButtonsComponent} from '../approve-reject-buttons.component';
import {CancelButtonComponent} from '../cancel-button.component';
import {EditDataRequestButtonComponent} from '../edit-data-request-button.component';
import {ResetToDraftButtonComponent} from '../reset-to-draft-button.component';
import {SetTrackingButtonComponent} from '../set-tracking-button.component';
import {ShowApprovalButtonsComponent} from '../show-approval-buttons.component';
import {LeftHeaderComponent} from '../left-header.component';
import {Size} from '@vivli/shared/theme';
import {PrintButtonContainerComponent} from '../print-button-container.component';
import {useDataRequestAdminPath} from '@vivli/features/data-requests/infrastructure/hook';
import {useLocation} from 'react-router';
import {DTICommonConst} from "@vivli/shared/infrastructure/constants";

interface RequestHeaderComponentProps {
    onEditTitleClick?: () => void;
    onSaveClick?: () => void;
    onSubmitClick?: () => void;
    formHasErrors?: boolean;
    formIsDirty?: boolean;
    title?: string;
    showTrackingButton: boolean;
    showResetToDraftButton: boolean;
    showCancelButton: boolean;
    showEditDataRequestButton: boolean;
    showSaveButtons: boolean;
    showEditTitleButton: boolean;
    showPrintButton: boolean;
    myDataRequests?: boolean;
    showArchiveButton: boolean;
    onClickArchiveButton?: () => void;
    style?: React.CSSProperties;
}

export const RequestHeaderComponent = ({
                                           onEditTitleClick,
                                           onSaveClick,
                                           onSubmitClick,
                                           formHasErrors,
                                           formIsDirty,
                                           title,
                                           showTrackingButton,
                                           showResetToDraftButton,
                                           showCancelButton,
                                           showEditDataRequestButton,
                                           showSaveButtons,
                                           showEditTitleButton,
                                           showPrintButton,
                                           myDataRequests,
                                           showArchiveButton,
                                           onClickArchiveButton,
                                           style
                                       }: RequestHeaderComponentProps) => {
    const activeUser = useActiveUser();
    const {
        dataRequest,
        showApprovalButtons,
        isAdminEditing,
        setIsAdminEditing,
        isSavingDataRequest,
        isSubmittingDataRequest,
        isUpdatingStatus,
        setIsUpdatingStatus,
        updateDataRequest,
    } = useDataRequestContext();
    const {submitStatusUpdate} = useStatusUpdateContext();
    const [showIRPOverride, setShowIRPOverride] = useState<boolean>(false);
    const [showDataProviderOverride, setShowDataProviderOverride] =
        useState<boolean>(false);
    const isDataRequestsView = useDataRequestAdminPath();
    const isOrgAdmin =
        !activeUser.isVivliAdmin &&
        activeUser?.orgMemberships?.find((om) => om.isOrgAdmin) !== undefined;
    const buttonText = isOrgAdmin
        ? 'Save Feedback Update'
        : 'Save Status Update';

    // Location hook used to read the params passed from the history hook, if any
    // and THEN setting the property in the context. Setting the property
    // in the context directly was a problem.

    const location = useLocation();
    const path = location.pathname.toLowerCase();
    const isStatusUpdateTab = path.search('statusupdate') >= 0;
    const vivliAdminRequestedEdit = location?.state?.detail === true;
    if (vivliAdminRequestedEdit) {
        setIsAdminEditing(true);
    }
    const notSubmittable = dataRequest.status !== DataRequestStatusEnum.Draft;

    //Need to hide certain buttons while in AMR if admin is editing
    const hideButtons = isAdminEditing || !isDataRequestsView;
    useEffect(() => {
        setShowIRPOverride(
            dataRequest.status === DataRequestStatusEnum.AwaitingIRPApproval &&
            activeUser?.isVivliAdmin &&
            !showApprovalButtons &&
            !vivliAdminRequestedEdit
        );
        setShowDataProviderOverride(
            dataRequest.status ===
            DataRequestStatusEnum.AwaitingDataProviderApproval &&
            activeUser?.isVivliAdmin &&
            !showApprovalButtons &&
            !vivliAdminRequestedEdit
        );
    }, [dataRequest.status, showApprovalButtons]);

    return (
        <div style={outerFormContainerStyle} className="do-not-print">
            <div style={innerFormContainerStyle}>
                <LeftHeaderComponent
                    title={title}
                    onEditTitleClick={onEditTitleClick}
                    showEditTitleButton={showEditTitleButton}
                    myDataRequests={myDataRequests}
                />

                <div style={rightHeaderContainerStyle}>
                    {!isOrgAdmin && (
                        <>
                            {showArchiveButton && onClickArchiveButton && (
                                <ButtonComponent
                                    dataId={DTICommonConst.ArchiveButton}
                                    title="Archive"
                                    style={{
                                        ...style,
                                        marginLeft: Size.PADDING,
                                        marginRight: Size.PADDING
                                    }}

                                    onClick={onClickArchiveButton}
                                >
                                    Archive
                                </ButtonComponent>

                            )}
                            {showTrackingButton && <SetTrackingButtonComponent/>}

                            {showResetToDraftButton && (
                                <ResetToDraftButtonComponent
                                    dataId={DTICommonConst.DraftButton}
                                />
                            )}


                            {showCancelButton && (
                                <CancelButtonComponent
                                    dataId={DTICommonConst.CancelButton}
                                    setIsUpdatingStatus={setIsUpdatingStatus}
                                    isDisabled={isUpdatingStatus}
                                    dataRequest={dataRequest}
                                    updateDataRequest={updateDataRequest}
                                />
                            )}

                            {showEditDataRequestButton && (
                                <EditDataRequestButtonComponent/>
                            )}
                        </>
                    )}

                    {showIRPOverride && (
                        <ShowApprovalButtonsComponent
                            message={
                                'Proceeding will allow you to take actions on behalf of an IRP/Approver or Data Contributor.  Proceed?'
                            }
                            title={'IRP Admin Override'}
                        />
                    )}

                    {showDataProviderOverride && (
                        <ShowApprovalButtonsComponent
                            message={
                                'Proceeding will allow you to take actions on behalf of a Data Provider.  Proceed?'
                            }
                            title={'Data Provider Override'}
                        />
                    )}

                    {showApprovalButtons && !hideButtons && (
                        <span style={{marginLeft: 10}}>
                            <ApproveRejectButtonsComponent/>
                        </span>
                    )}

                    {showSaveButtons && (
                        <>
                            <ButtonComponent
                                title="Save"
                                style={{
                                    ...btnStyle,
                                    marginLeft: Size.PADDING,
                                }}
                                disabled={!formIsDirty || isSavingDataRequest}
                                onClick={onSaveClick}
                                dataId={DTICommonConst.SaveButton}
                            >
                                Save
                            </ButtonComponent>

                            <ButtonComponent
                                title="Submit"
                                style={submitBtnStyle}
                                disabled={
                                    formHasErrors ||
                                    isSubmittingDataRequest ||
                                    notSubmittable
                                }
                                onClick={onSubmitClick}
                                dataId={DTICommonConst.SubmitButton}
                            >
                                Submit
                            </ButtonComponent>
                        </>
                    )}

                    {showPrintButton && <PrintButtonContainerComponent/>}
                </div>
            </div>
        </div>
    );
};
