import React, { CSSProperties } from 'react';
import { DataPackageBaseComponent, DataPackageComponent, DataPackageContext } from '@vivli/shared/features/data-package/components';
import { StudyMetadataPackageFilesConstant } from '@vivli/shared/infrastructure/constants';
import { IExternalLinkOptions } from '@vivli/shared/infrastructure/interface';
import { StudyExternalLinksComponent } from '@vivli/shared/components';
import {useStudyContext} from "@vivli/features/studies/infrastructure/context";

interface DocumentsDataPackageFeatureProps {
    dataPackageId: string;
    secondaryDataPackageId?: string;
    availableTypes?: string[];
    onValidation?: (isValid: boolean) => void;
    allowDownload?: boolean;
    useSecureStorage?: boolean;
    showExternalLinks?: IExternalLinkOptions;
    scrollEnabled?: boolean;
    showFileWarning?:boolean;
    showVerifyButton?:boolean
    setUploadFileName?: React.Dispatch<React.SetStateAction<string>>
}

const containerStyle: CSSProperties = {
    display: 'grid',
    gridTemplateRows: 'auto 1fr',
    height: '100%',
};

export const DocumentsDataPackageFeature = ({
    dataPackageId,
    secondaryDataPackageId,
    availableTypes,
    onValidation,
    useSecureStorage,
    showExternalLinks,
    showVerifyButton=true,
    setUploadFileName,
    scrollEnabled = true,
    showFileWarning,
}: DocumentsDataPackageFeatureProps) => {

    const studyContext =(secondaryDataPackageId)? useStudyContext():null;

    return (
        <div style={containerStyle}>
            <DataPackageBaseComponent dataPackageId={dataPackageId} secondaryDataPackageId={secondaryDataPackageId}
             filesRefresh={studyContext?.documentsRefresh}>
                <DataPackageContext.Consumer>
                    {(dataPackage) => (
                        <DataPackageComponent
                            dataPackage={dataPackage}
                            readOnly={false}
                            allowDownload={true}
                            allowUpload={true}
                            allowDelete={true}
                            setUploadFileName={setUploadFileName}
                            allowSubmit={false}
                            showVerifyButton={showVerifyButton}
                            useSecureStorage={useSecureStorage}
                            availableTypes={availableTypes || StudyMetadataPackageFilesConstant}
                            onValidation={onValidation}
                            scrollEnabled={scrollEnabled}
                            showFileWarning={showFileWarning}
                        />
                    )}
                </DataPackageContext.Consumer>
            </DataPackageBaseComponent>
            {showExternalLinks && <StudyExternalLinksComponent {...showExternalLinks} />}
        </div>
    );
}
