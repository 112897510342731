import React from 'react';
import { ButtonComponent } from '@vivli/shared/components';
import { buttonIconStyle, buttonStyle } from './button.styles';
import { AssetsConstant } from '@vivli/shared/infrastructure/constants';

export const InfoButtonComponent = ({ onClick }) => (
    <ButtonComponent
        style={buttonStyle}
        onClick={onClick}
        className="search_instructions"
        dataId="search-info-button"
    >
        <img
            src={AssetsConstant.INFO_BANG}
            style={{ ...buttonIconStyle, width: '40px' }}
        />
    </ButtonComponent>
);
