﻿import {IStudy} from "@vivli/features/studies/infrastructure/interface";
import {useForm} from "react-hook-form";
import {joiResolver} from "@hookform/resolvers/joi";
import {JoiValidationOptionsConstant} from "@vivli/shared/infrastructure/constants";
import joi, {ObjectSchema} from "joi";

const formSchema: ObjectSchema<IStudy> = joi.object({

}).options(JoiValidationOptionsConstant)

const defaultValues = (study) => ({
    ...study,
    studyType: study.studyType || null,
    studyDesign: {
        interventionModel: study.studyDesign?.interventionModel || null,
        assignmentToIntervention: study.studyDesign?.assignmentToIntervention || null,
        subjectMaskedOrBlinded: study.studyDesign?.subjectMaskedOrBlinded || false,
        investigatorMaskedOrBlinded: study.studyDesign?.investigatorMaskedOrBlinded || false,
        careProviderMaskedOrBlinded: study.studyDesign?.careProviderMaskedOrBlinded || false,
        outcomeAssessorMaskedOrBlinded: study.studyDesign?.outcomeAssessorMaskedOrBlinded || false
    }
})

export function useAnnotateStudyFormHook(study?: IStudy, mode: 'onBlur' | 'onChange' | 'onTouched' | 'all' = 'all') {
    return useForm({
        mode,
        resolver: joiResolver(formSchema.options(JoiValidationOptionsConstant)),
        defaultValues: defaultValues(study),
        reValidateMode: 'onChange'
    });
}
