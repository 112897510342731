﻿import React, { ReactNode, useEffect, useRef, useState } from 'react';
import {
    IFilterSet,
    IFilterSetItem,
    INormalTerm,
    IPicoSearchOptionsContext,
    IPicoSearchTerm,
    IVocabDocument,
} from '@vivli/features/search/infrastructure/interface';
import { PicoSearchOptionsContext, useAzureSearchService, useSearchContext } from '@vivli/features/search/infrastructure/context';
import { first } from 'rxjs/operators';
import { PicoTypeEnum } from '@vivli/shared/infrastructure/enum';
import { FilterOperatorEnum } from '@vivli/features/search/infrastructure/enum';

interface PicoSearchOptionsContextWrapperProps {
    children?: ReactNode;
}

const picoSelectStrings = [
    'id',
    'displayLabel',
    'displayAlternateLabels',
    'displayLongLabel',
    'usesInStudyMetadata',
    'type',
    'isDuplicate',
    'duplicateIDs',
];

enum PicoSearchKeysEnum {
    P = 'searchParticipantTermCodes',
    I = 'searchInterventionTermCodes',
    C = 'searchInterventionTermCodes',
    O = 'searchOutcomeTermCodes',
}

export const PicoSearchOptionsContextWrapper = ({ children }: PicoSearchOptionsContextWrapperProps) => {
    const [picoTerms, setPicoTerms] = useState<IPicoSearchTerm[]>([]);
    const [activeTerm, setActiveTerm] = useState<IPicoSearchTerm>();
    const [normalTerms, setNormalTerms] = useState<INormalTerm[]>([]);
    const { updateSelectStrings, updateAutoCompleteItems, autoCompleteItems, updateFilterSets, removeFilterSet, resetFilterSets } =
        useSearchContext();
    const { vocabSearch } = useAzureSearchService();
    const resetRef = useRef(false);
    const initializedRef = useRef(false);

    const getPicoTerm = (termId: string) => {
        return vocabSearch.getDocument<IVocabDocument>(termId).pipe(first());
    };

    const addPicoTerm = (picoTerm: IPicoSearchTerm, overridePicoTerms = picoTerms, setIsActive = false) => {
        return getPicoTerm(picoTerm.key).subscribe((document) => {
            const newTerm = {
                ...picoTerm,
                document,
            };

            const updatedPicoTerms = [...overridePicoTerms, newTerm];

            setPicoTerms(updatedPicoTerms);
            if (setIsActive) {
                setActiveTerm({ ...newTerm });
            }
        });
    };

    const updatePicoTermType = (key: string, type: PicoTypeEnum) => {
        const picoTerm = picoTerms.find((t) => t.key === key);
        const updatedPicoTerm = {
            ...picoTerm,
            type,
        };

        const updatedPicoTerms = picoTerms.map((pt) => {
            if (pt.key === key) {
                return updatedPicoTerm;
            }
            return pt;
        });

        setPicoTerms(updatedPicoTerms);
        setActiveTerm(updatedPicoTerm);
    };

    const replacePicoTerm = (newTerm: INormalTerm, oldTerm: IPicoSearchTerm) => {
        const updatedNormalTerms = normalTerms.map((nt) => {
            if (nt.value === oldTerm.key) {
                return {
                    ...newTerm,
                    type: oldTerm.type,
                    active: true,
                };
            }
            return nt;
        });

        setNormalTerms(updatedNormalTerms);
        updateActiveTerm(newTerm.value);
    };

    const removePicoTerm = (picoTerm: IPicoSearchTerm) => {
        if (!picoTerm) {
            return;
        }

        // updating the normal terms will trigger the removal of the pico term
        const updatedNormalTerms = normalTerms.filter((nt) => nt.value !== picoTerm.key);
        setNormalTerms(updatedNormalTerms);
    };

    const getSearchKeyForTerm = (picoTerm: IPicoSearchTerm): PicoSearchKeysEnum => {
        switch (picoTerm.type) {
            case PicoTypeEnum.P:
                return PicoSearchKeysEnum.P;
            case PicoTypeEnum.I:
                return PicoSearchKeysEnum.I;
            case PicoTypeEnum.C:
                return PicoSearchKeysEnum.C;
            case PicoTypeEnum.O:
                return PicoSearchKeysEnum.O;
        }
    };

    const getPicoTermFilterSet = (picoTerm: IPicoSearchTerm): IFilterSet => {
        const searchKey = getSearchKeyForTerm(picoTerm);

        let items: IFilterSetItem[] = [
            {
                key: searchKey,
                value: picoTerm.key,
            },
        ];

        if (picoTerm?.document?.duplicateIDs?.length > 1) {
            items = picoTerm.document.duplicateIDs.map((id) => ({
                key: searchKey,
                value: id,
            }));
        }

        const filterSet: IFilterSet = {
            key: picoTerm.key,
            items,
            itemOperator: FilterOperatorEnum.Or,
            operator: FilterOperatorEnum.Any,
        };

        return filterSet;
    };

    const getFilterSetItems = (id: string) => {
        return [
            {
                key: PicoSearchKeysEnum.P,
                value: id,
            },
            {
                key: PicoSearchKeysEnum.I,
                value: id,
            },
            {
                key: PicoSearchKeysEnum.O,
                value: id,
            },
        ];
    };

    const getNormalTermFilterSet = (picoTerm: IPicoSearchTerm): IFilterSet => {
        let items: IFilterSetItem[] = getFilterSetItems(picoTerm.key);

        if (picoTerm?.document?.duplicateIDs?.length > 1) {
            items = []; // reset to empty
            picoTerm.document.duplicateIDs.forEach((id) => {
                const newItems = getFilterSetItems(id);

                items = [...items, ...newItems];
            });
        }

        const filterSet: IFilterSet = {
            key: picoTerm.key,
            items,
            itemOperator: FilterOperatorEnum.Or,
            operator: FilterOperatorEnum.Any,
        };
        return filterSet;
    };

    const updatePicoTerms = () => {
        const addedNormalTerms = normalTerms.filter((nt) => !picoTerms.some((pt) => pt.key === nt.value));
        const removedPicoTerms = picoTerms.filter((pt) => !normalTerms.some((nt) => nt.value === pt.key));

        // remove pico terms first since we don't need to do any api calls here
        const updatedPicoTerms = picoTerms.filter((pt) => !removedPicoTerms.some((rpt) => rpt.key === pt.key));

        if (removedPicoTerms.length > 0) {
            removedPicoTerms.forEach((rpt) => {
                removeFilterSet(rpt.key);
            });
        }

        let isReplacedTerm = false;
        if (addedNormalTerms?.length > 0) {
            // we only ever add 1 term at a time to the select list
            const addedTerm = addedNormalTerms[0];
            const newPicoTerm: IPicoSearchTerm = {
                type: addedTerm.type || PicoTypeEnum.None,
                key: addedTerm.value,
            };

            // if it has a type that means the user is replacing an old keyword with this keyword
            if (addedTerm.type) {
                isReplacedTerm = true;
            }

            addPicoTerm(newPicoTerm, updatedPicoTerms, isReplacedTerm);
        } else {
            setPicoTerms(updatedPicoTerms);
        }

        const removedFromPicoTerms = activeTerm && !updatedPicoTerms.some((pt) => pt.key === activeTerm.key);
        if (removedFromPicoTerms && !isReplacedTerm) {
            setActiveTerm(null);
        }
    };

    const updateTermFilterSets = () => {
        let filterSets: IFilterSet[] = [];
        picoTerms.forEach((picoTerm) => {
            if (picoTerm.type !== PicoTypeEnum.None) {
                filterSets = [...filterSets, getPicoTermFilterSet(picoTerm)];
            } else {
                filterSets = [...filterSets, getNormalTermFilterSet(picoTerm)];
            }
        });

        updateFilterSets(filterSets);
    };

    const updateActiveTerm = (key: string) => {
        const term = picoTerms.find((t) => t.key === key);
        setActiveTerm({ ...term });
    };

    const resetOptions = () => {
        resetRef.current = true;

        setNormalTerms([]);
        setPicoTerms([]);
        setActiveTerm(null);
        resetFilterSets();

        resetRef.current = false;
    };

    useEffect(() => {
        if (resetRef.current || !initializedRef.current) {
            return;
        }

        updatePicoTerms();
    }, [normalTerms]);

    useEffect(() => {
        if (resetRef.current || !initializedRef.current) {
            return;
        }

        updateTermFilterSets();
    }, [picoTerms]);

    useEffect(() => {
        if (!initializedRef.current) {
            return;
        }
        updateSelectStrings(picoSelectStrings);

        return () => {
            updateSelectStrings([]);
        };
    }, []);

    const provider: IPicoSearchOptionsContext = {
        setNormalTerms,
        updateAutoCompleteItems,
        addPicoTerm,
        updateActiveTerm,
        updatePicoTermType,
        replacePicoTerm,
        removePicoTerm,
        resetOptions,
        autoCompleteItems,
        normalTerms,
        picoTerms,
        activeTerm,
        initializedRef,
    };

    return <PicoSearchOptionsContext.Provider value={provider}>{children}</PicoSearchOptionsContext.Provider>;
};
