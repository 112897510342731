﻿import {IContextWrapper, IUser} from '@vivli/shared/infrastructure/interface';
import {useConfigService, UserContext,} from '@vivli/core/infrastructure/context';
import {useUsersService} from '@vivli/features/users/infrastructure/context';
import React, {useEffect, useState} from 'react';
import {first} from 'rxjs/operators';
import {StorageService} from '@vivli/shared/infrastructure/service';
import {Msal2EventTypeEnum, useAuthService, UserLoginEvent,} from '@vivli/core/authentication';
import ReactGA from 'react-ga4';
import {environment} from "@vivli/core/environments";

const getClaimsFromIdToken = (idToken: string): any =>
    idToken ? JSON.parse(atob(idToken.split('.')[1])) : {};
const userKey = 'vivli_app_user';

export const UserContextWrapper = ({ children }: IContextWrapper) => {
    const { authEvents, getTokenPopup } = useAuthService();
    const { getUser } = useUsersService();
    const [user, setUser] = useState<IUser>(null);
    const config = useConfigService();

    const assignUserParams = (userToAssign: IUser) => {
        userToAssign.isAnnotator = userToAssign?.orgMemberships?.some(
            (om) => om.isAnnotator
        );
        userToAssign.isQaReviewer = userToAssign?.orgMemberships?.some(
            (om) => om.isQaReviewer
        );
    };

    const getUserWithRoles = (idToken: string) => {
        if (environment.isAutomation) {
            window.localStorage.setItem('cypress-api-key', idToken);
        }

        const claims = getClaimsFromIdToken(idToken);
        const oidClaim = claims['oid'];
        const nameClaim = claims['name'];
        const emailClaim = claims['email'];
        const expClaim = claims['exp'];
        const idpClaim = claims['idp'];

        let user: IUser;
        getUser(oidClaim, { token: idToken })
            .pipe(first())
            .subscribe((response) => {
                user = {
                    ...response,
                    apiToken: idToken,
                    userId: oidClaim,
                    email: emailClaim,
                    expiration: expClaim,
                    name: nameClaim,
                };

                assignUserParams(user);

                // always set user regardless of getting roles
                StorageService.setItem(userKey, user);
                setUser(user);
                if (config.gaMeasurementId) {
                    ReactGA.set({ userId: user.userId });
                }

                authEvents.next(new UserLoginEvent());
            });
    };

    const setupUser = (idToken: string) => {
        if (!idToken) {
            getTokenPopup().then((token) => {
                if (token) {
                    getUserWithRoles(token);
                }
            });
        } else {
            getUserWithRoles(idToken);
        }
    };

    const getUserFromStorage = () => {
        const userFromStorage = StorageService.getItem<IUser>(userKey);
        if (userFromStorage) {
            assignUserParams(userFromStorage);
            return userFromStorage;
        }

        return user;
    };

    const cleanupUser = () => {
        StorageService.deleteItem(userKey);
        setUser(null);
    };

    useEffect(() => {
        setUser(getUserFromStorage());
    }, []);

    useEffect(() => {
        const sub = authEvents.subscribe((event) => {
            switch (event.type) {
                case Msal2EventTypeEnum.UnauthorizedEvent:
                case Msal2EventTypeEnum.LogoutEvent:
                    cleanupUser();
                    break;
                case Msal2EventTypeEnum.LoginEvent:
                case Msal2EventTypeEnum.SilentLoginEvent:
                case Msal2EventTypeEnum.UpdatedProfileEvent:
                case Msal2EventTypeEnum.SignedUpEvent:
                    setupUser(event.idToken);
                    break;
            }
        });

        return () => {
            sub.unsubscribe();
        };
    }, [authEvents]);

    return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
};
