import {AssetsConstant, DTIAMRCommonConst} from '@vivli/shared/infrastructure/constants';
import { ButtonComponent } from './button.component';
import React, { CSSProperties } from 'react';
import { Color } from '@vivli/shared/theme';

const buttonStyle = (minature?: boolean): CSSProperties => ({
    maxWidth: 100,
    minWidth: minature ? 'initial' : '100px',
    cursor: 'pointer',
    textDecoration: 'none',
    display: 'flex',
    justifyContent: 'space-between',
});

const deleteButtonStyle: CSSProperties = {
    '@media print': {
        display: 'none',
    },
    justifyContent: 'center',
    display: 'inline-flex',
    backgroundColor: 'white',
    color: Color.ERROR_RED,
    border: `1px solid ${Color.ERROR_RED}`,
    minWidth: 'initial',
} as React.CSSProperties;

const buttonImgStyle: CSSProperties = {
    width: '15px',
};

const buttonTxtStyle: CSSProperties = {
    marginRight: '10px',
};

interface IDeleteButtonComponent {
    style?: CSSProperties;
    onClick?: (e: any) => void;
    isLoading?: boolean;
    miniature?: boolean;
}

export const DeleteButtonComponent = ({
    style,
    onClick,
    isLoading,
    miniature,
}: IDeleteButtonComponent) => {
    return (
        <ButtonComponent
            style={{ ...deleteButtonStyle, ...style }}
            onClick={onClick}
            isLoading={isLoading}
            className="do-not-print"
            dataId={DTIAMRCommonConst.DeleteButton}
        >
            <div style={buttonStyle(miniature)}>
                {!miniature && <span style={buttonTxtStyle}>Delete</span>}
                <img src={AssetsConstant.RED_X_ICON} style={buttonImgStyle} />
            </div>
        </ButtonComponent>
    );
};
