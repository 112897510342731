import React from 'react';
import {Route, Routes, useNavigate} from "react-router-dom";
import {
    bodyContainerStyle,
    bodyStyle,
    fillerStyle,
    navButtonContainerStyle,
    navButtonStyle,
    navButtonTextContainerStyle,
    navButtonTextStyle
} from "./data-request-form.styles";
import {
    DataRequestHistoryComponent
} from "../../data-request/data-request-routes/data-request-history/data-request-history.component";
import {DraftResearchTeamComponent} from '../../research-team/draft-research-team.component';
import {AttestationsFormComponent} from '../attestations-form.component';
import {ResearchProposalFormComponent} from '../research-proposal-form.component';
import {StatisticalAnalysisPlanFormComponent} from '../statistical-analysis-plan-form.component';
import {OtherInformationFormComponent} from '../other-information-form.component';
import {FundingFormComponent} from '../funding-form.component';
import {RequestedStudiesFeature} from "@vivli/features/studies/feature";
import {ChatRollupFeature} from "@vivli/features/chat/feature";
import {ResultRequestsFeature} from "@vivli/features/results-request/feature";
import {IDataRequest} from "@vivli/features/data-requests/infrastructure/interface";
import {useDataRequestContext} from "@vivli/features/data-requests/infrastructure/context";
import {useFormContext, useWatch} from "react-hook-form";
import {DataRequestNotificationsSubTabComponent} from "@vivli/features/data-requests/components";
import {DTITab} from "@vivli/shared/infrastructure/constants";

interface DataRequestFormRoutesComponentProps {
    routes: { route: string, title: string }[];
    handleFormSave: () => void;
    isSavingDataRequest: boolean;
    isSubmittingDataRequest: boolean;
    handleDataRequestFormUpdate: (dataRequest: IDataRequest) => void;
    hideReviewers: boolean;
}

export const DataRequestFormRoutesComponent = (
    {
        routes,
        handleFormSave,
        isSavingDataRequest,
        isSubmittingDataRequest,
        handleDataRequestFormUpdate,
        hideReviewers
    }: DataRequestFormRoutesComponentProps) => {
    const navigate = useNavigate();
    const {dataRequest} = useDataRequestContext();
    const requestedStudies = useWatch({name: 'requestedStudies'});
    const formApi = useFormContext()
    const researchTeamHasError=formApi.getFieldState('researchTeam').invalid;

    return (
        <div style={bodyContainerStyle}>
            <div style={navButtonContainerStyle}>
                {routes.map(({route, title}) => (
                    <div
                        key={route}
                        style={navButtonStyle(route)}
                        onClick={() => {
                            navigate(route, {replace: true});
                            handleFormSave();
                        }}
                        data-test-id={DTITab.DataRequestTabs(title.replace(/\s/g, ""))}
                    >
                        <div style={navButtonTextContainerStyle}>
                            {title == 'Research Team' && researchTeamHasError ?
                                <span style={navButtonTextStyle(route)}>
                                    {title} ❗
                                </span> :
                                <span style={navButtonTextStyle(route)}>
                                    {title}
                                </span>
                            }

                        </div>
                    </div>
                ))}

                <div style={fillerStyle}/>
            </div>

            <div style={bodyStyle} className='scrolly'>
                <div style={{flex: 'auto'}}>
                    <Routes>
                        <Route path={'researchTeam'} element={<DraftResearchTeamComponent
                            dataRequest={dataRequest}
                            isLoading={isSavingDataRequest || isSubmittingDataRequest}
                            onDataRequestUpdated={handleDataRequestFormUpdate}
                        />}/>

                        <Route path={'researchProposal'} element={<ResearchProposalFormComponent/>}/>

                        <Route path={'statisticalAnalysisPlan'} element={<StatisticalAnalysisPlanFormComponent/>}/>

                        <Route path={'funding'} element={<FundingFormComponent/>}/>

                        <Route path={'otherInformation'} element={<OtherInformationFormComponent/>}/>

                        <Route path={'attestations'} element={<AttestationsFormComponent/>}/>

                        <Route path={'requestedStudies'} element={<RequestedStudiesFeature
                            dataRequest={dataRequest}
                            requestedStudies={requestedStudies}
                            onChange={updatedDataRequest => {
                                handleDataRequestFormUpdate(updatedDataRequest);
                            }}
                            editable={true}
                        />}/>

                        <Route path={'chat'} element={<ChatRollupFeature
                            channelId={dataRequest.chatChannelId}
                            dataRequestId={dataRequest.id}
                        />}/>

                        <Route path={'researchResults'} element={<ResultRequestsFeature
                            requestId={dataRequest.id}
                        />}/>

                        <Route path={'requestHistory'} element={<DataRequestHistoryComponent/>}/>

                        <Route path="Notifications" element={<DataRequestNotificationsSubTabComponent/>}/>
                    </Routes>
                </div>
            </div>
        </div>
    )
}
