﻿import React, { CSSProperties } from 'react';
import { TabComponent, TabsComponent } from '@vivli/shared/components';
import { DocumentsDataPackageFeature } from '@vivli/shared/features/data-package/feature';
import { StudyDetailsComponent } from './study-details.component';
import { VivliDetailsComponent } from './vivli-details.component';
import { useStudyContext } from '@vivli/features/studies/infrastructure/context';
import { IStudy } from '@vivli/features/studies/infrastructure/interface';
import { useExternalLinkPermissionsHook } from '@vivli/features/studies/infrastructure/hook';

const containerStyle: CSSProperties = {
    position: 'relative',
    height: '100%',
    width: '100%',
    padding: '0 40px',
};

export const SubmitStudyTabsComponent = ({ study }) => {
    const { setActiveStudy } = useStudyContext();
    const { canEditLinks, isLoadingPermissions } = useExternalLinkPermissionsHook(study);

    return (
        <div style={{ height: '100%', width: '100%' }}>
            <div style={containerStyle}>
                <TabsComponent defaultTab={0}>
                    <TabComponent title={'Study Details'}>
                        <>
                            <StudyDetailsComponent />
                            <VivliDetailsComponent />
                        </>
                    </TabComponent>
                    <TabComponent title={'Study Documents'}>
                        {!isLoadingPermissions && (
                            <DocumentsDataPackageFeature
                                dataPackageId={study.metaDataDataPackageId}
                                showFileWarning={true}
                                showExternalLinks={{
                                    documentId: study.id,
                                    externalLinks: study.externalLinks,
                                    editable: canEditLinks,
                                    onLinksUpdated: (result) => setActiveStudy(result as IStudy),
                                }}
                            />
                        )}
                    </TabComponent>
                </TabsComponent>
            </div>
        </div>
    );
};
