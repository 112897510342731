import {useEffect, useState} from "react";
import {IDataRequest} from "@vivli/features/data-requests/infrastructure/interface";
import {useDataRequestsService} from "@vivli/features/data-requests/infrastructure/context";
import {filter, finalize} from "rxjs/operators";
import {useActiveUser} from "@vivli/core/infrastructure/context";
import {DataRequestFilterService} from "@vivli/features/data-requests/infrastructure/service";

export function useMyDataRequests() {
    const activeUser = useActiveUser();
    const [myDataRequests, setMyDataRequests] = useState<IDataRequest[]>();
    const [draftCount, setDraftCount] = useState(0);
    const [inProgressCount, setInProgressCount] = useState(0);
    const [fulfilledCount, setFulfilledCount] = useState(0);
    const [notApprovedCount, setNotApprovedCount] = useState(0);
    const [archivedCount, setArchivedCount] = useState(0);
    const [isLoadingMyDataRequests, setIsLoadingMyDataRequests] = useState(false);

    const dataRequestsService = useDataRequestsService();

    const setCounts = () => {
        setDraftCount(myDataRequests?.filter(DataRequestFilterService.draftFilter)?.length || 0);
        setInProgressCount(myDataRequests?.filter(DataRequestFilterService.activeFilter)?.length || 0)
        setFulfilledCount(myDataRequests?.filter(DataRequestFilterService.fulfilledFilter)?.length || 0)
        setNotApprovedCount(myDataRequests?.filter(DataRequestFilterService.notApprovedFilter)?.length || 0)
        setArchivedCount(myDataRequests?.filter(DataRequestFilterService.archivedFilter)?.length || 0)
    }

    useEffect(() => {
        if (!activeUser) { return; }

        setIsLoadingMyDataRequests(true)
        const dataRequestsSub = dataRequestsService.getMyDataRequests()
            .pipe(
                filter(dr => dr !== null),
                finalize(() => setIsLoadingMyDataRequests(false))
            )
            .subscribe(setMyDataRequests);

        return () => {
            dataRequestsSub.unsubscribe();
        }
    }, [activeUser])

    useEffect(() => {
        if (!myDataRequests) { return; }

        setCounts();
    }, [myDataRequests])

    return {
        myDataRequests,
        setMyDataRequests,
        isLoadingMyDataRequests,
        draftCount,
        inProgressCount,
        fulfilledCount,
        notApprovedCount,
        archivedCount
    };
}
