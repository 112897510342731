export enum DataRequestStatusEnum {
    None = 'None',
    Draft = 'Draft',
    Blinded = 'Blinded',
    SubmittedToVivli = 'SubmittedToVivli',
    RejectedByVivli = 'RejectedByVivli',
    AwaitingDataProviderApproval = 'AwaitingDataProviderApproval',
    AwaitingIRPApproval = 'AwaitingIRPApproval',
    RevisionsRequestedByDataProvider = 'RevisionsRequestedByDataProvider',
    RejectedByDataProvider = 'RejectedByDataProvider',
    RevisionsRequestedByIRP = 'RevisionsRequestedByIRP',
    RejectedByIRP = 'RejectedByIRP',
    Approved = 'Approved',
    PartiallyFulfilled = 'PartiallyFulfilled',
    Fulfilled = 'Fulfilled',
    Archived = 'Archived',
    Cancelled = 'Cancelled',
    AwaitingDUAValidation = 'AwaitingDUAValidation',
    DUAValidated = 'DUAValidated',
    DUAReturnedToRequester = 'DUAReturnedToRequester',
}
