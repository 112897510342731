import React, { Fragment, useState } from 'react';
import { ButtonComponent } from '@vivli/shared/components';
import { Styles } from '@vivli/shared/theme';
import { useDataRequestContext, useDataRequestsService } from '@vivli/features/data-requests/infrastructure/context';
import { useDataPackageService } from '@vivli/shared/features/data-package/infrastructure/context';
import { ApprovalStatusEnum, DataRequestStatusEnum } from '@vivli/features/data-requests/infrastructure/enum';
import { useDataRequestPermissions } from '@vivli/features/data-requests/infrastructure/hook';
import { useModalService } from '@vivli/shared/infrastructure/context';
import { finalize, first } from 'rxjs/operators';
import { GetOrgIdResult } from '@vivli/features/data-requests/infrastructure/interface';
import { LoadIndicatorColorEnum } from '@vivli/shared/infrastructure/enum';
import {DTICommonConst} from "@vivli/shared/infrastructure/constants";

interface ApproveButtonComponentProps {
    getOrgId: (orgIds: string[]) => Promise<GetOrgIdResult>;
}

export const ApproveButtonComponent = ({ getOrgId }: ApproveButtonComponentProps) => {
    const [isLoading, setIsLoading] = useState(false);
    const { dataRequest, isUpdatingStatus, setIsUpdatingStatus, updateDataRequest } = useDataRequestContext();
    const { getAllowedTransitions } = useDataRequestPermissions();
    const modalService = useModalService();
    const dataRequestsService = useDataRequestsService();

    const approveText =
        dataRequest.status !== DataRequestStatusEnum.AwaitingDUAValidation &&
        dataRequest.status !== DataRequestStatusEnum.DUAReturnedToRequester
            ? 'Process Request'
            : 'Set DUA to Validated';

    const handleFinally = () => setLoadingStatus(false);
    const dataPackageService = useDataPackageService();
    const handleError = (e) => {
        handleFinally();
    };

    const setLoadingStatus = (status: boolean) => {
        setIsLoading(status);
        setIsUpdatingStatus(status);
    };

    const handleApproveSubmission = (orgId: string, state: DataRequestStatusEnum) => {
        const message = (
            <Fragment>
                <p>If you wish, you may provide context for your processed request here. Simply click "OK" to continue without comment.</p>
                {(dataRequest.status === DataRequestStatusEnum.AwaitingDataProviderApproval ||
                    dataRequest.status === DataRequestStatusEnum.AwaitingIRPApproval) && (
                    <p>Include any feedback for the researcher in the chat.</p>
                )}
            </Fragment>
        );

        modalService.confirm(message, {
            title: 'Process Request Comment',
            promptLabel: 'Comment',
            showTextAreaPrompt: true,
            confirmText: 'Ok',
            cancelText: 'Cancel',
            onConfirm: (result) => {
                if (!result) {
                    handleFinally();
                    return;
                }

                setLoadingStatus(true);

                if (
                    dataRequest.status === DataRequestStatusEnum.AwaitingDataProviderApproval ||
                    dataRequest.status === DataRequestStatusEnum.AwaitingIRPApproval
                ) {
                    dataRequestsService
                        .approveRequest(dataRequest.id, result.comment, orgId, [], ApprovalStatusEnum.Approved)
                        .pipe(first(), finalize(handleFinally))
                        .subscribe(updateDataRequest, modalService.error);
                } else {
                    dataRequestsService
                        .setDataRequestStatus(dataRequest.id, state, result.comment)
                        .pipe(first(), finalize(handleFinally))
                        .subscribe(updateDataRequest, (error) =>
                            dataPackageService.handleSubmitErrorWithFileCheck(error, false, '', modalService)
                        );
                }
            },
        });
    };

    const handleOnApprove = () => {
        const nextAllowedStatus: DataRequestStatusEnum = getAllowedTransitions(dataRequest).approve;

        if (
            nextAllowedStatus !== null ||
            dataRequest.status === DataRequestStatusEnum.AwaitingDataProviderApproval ||
            dataRequest.status === DataRequestStatusEnum.AwaitingIRPApproval
        ) {
            setLoadingStatus(true);

            if (
                dataRequest.status === DataRequestStatusEnum.SubmittedToVivli ||
                dataRequest.status === DataRequestStatusEnum.Approved ||
                dataRequest.status === DataRequestStatusEnum.AwaitingDUAValidation
            ) {
                handleApproveSubmission(null, nextAllowedStatus);
            } else {
                // we only need to worry about approval orgs for DataproviderApproval &&
                // IRPApproval stages

                dataRequestsService
                    .getApprovalOrgs(dataRequest.id)
                    .pipe(first())
                    .subscribe((orgIds) => {
                        getOrgId(orgIds)
                            .then(({ orgId }) => {
                                return handleApproveSubmission(orgId, nextAllowedStatus);
                            })
                            .finally(handleFinally);
                    }, handleError);
            }
        }
    };

    return (
        <ButtonComponent
            style={Styles.Button.BUTTON_DARKER_GREEN_CHECK}
            onClick={handleOnApprove}
            isLoading={isLoading}
            disabled={isUpdatingStatus}
            className="approveReject_approve"
            dataId={DTICommonConst.ApproveButton}
            loaderColor={LoadIndicatorColorEnum.DarkerGreen}
        >
            {approveText}
        </ButtonComponent>
    );
};
