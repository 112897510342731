import React from 'react';
import {IDataRequest} from "@vivli/features/data-requests/infrastructure/interface";

interface AmrPublicationTextComponentProps {
    dataRequest: IDataRequest;
}

const getDataContributorNames = (dataRequest: IDataRequest): string[] => {
    const dcNames = [];
    dataRequest?.requestedStudies?.forEach((study) => {
        const cleanOrgNames = study.organizationName.replace(" (AMR)", "");
        if (!dcNames.includes(cleanOrgNames)) {
            dcNames.push(cleanOrgNames);
        }
    });
    return dcNames;
}

export const getPublicationText = (dataRequest: IDataRequest): string => {
    const dcNames = getDataContributorNames(dataRequest);

    return `
        Recipient agrees to include the following acknowledgment in any publication or
        presentation of the Analysis results. "This publication or presentation is based on
        research using data from ${dcNames}, obtained through https://amr.vivli.org"
    `
}

export const AmrPublicationTextComponent = ({dataRequest}: AmrPublicationTextComponentProps) => {
    return <div>{getPublicationText(dataRequest)}</div>
}
