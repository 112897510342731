﻿import Tippy from "@tippyjs/react";
import React, {CSSProperties, useState} from "react";
import {IDropdownMenuItem} from "@vivli/shared/infrastructure/interface";
import {DropdownMenuContentComponent} from "@vivli/shared/components";
import {Size} from "@vivli/shared/theme";

interface AdvancedDropdownMenuComponentProps {
    items: IDropdownMenuItem[];
    onItemClick?: (item: IDropdownMenuItem) => void;
    onClickOutside?: (e: MouseEvent) => void;
    children,
    dataId?: any
}

const optionStyle: CSSProperties = {
    //color: Color.DARK_GRAY,
    fontSize: Size.FontSize.Large,
    fontWeight: Size.FontWeight.Regular,
    paddingTop: 10,
    paddingBottom: 10,
}

export const AdvancedDropdownMenuComponent = (
    {
        items,
        onItemClick,
        onClickOutside,
        children,
        dataId
    }: AdvancedDropdownMenuComponentProps
) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleItemClick = (item: IDropdownMenuItem) => {
        setIsOpen(false);
        onItemClick && onItemClick(item);
    }

    const handleOnClickOutside = (e) => {
        setIsOpen(false);
        onClickOutside(e as MouseEvent);
    }

    const handleMenuClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsOpen(!isOpen);

        return false;
    }

    return (
        <Tippy
            content={<DropdownMenuContentComponent items={items} onItemClick={handleItemClick} optionStyle={optionStyle} />}
            placement={'bottom-end'}
            visible={isOpen}
            arrow={false}
            className='tippy-dropdown-menu'
            onClickOutside={handleOnClickOutside}
            animation='shift-away-extreme'
            interactive={true}
            appendTo={'parent'}
        >
            <div onClick={handleMenuClick} data-test-id={dataId}>
                {children}
            </div>
        </Tippy>
    )
}
