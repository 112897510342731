import React, {CSSProperties, forwardRef, MutableRefObject, useEffect, useRef, useState} from 'react';
import {Size} from "@vivli/shared/theme";
import {IFormField} from "@vivli/shared/infrastructure/interface";
import {FormFieldTypeEnum} from "@vivli/shared/infrastructure/enum";
import {BaseInputFieldComponent} from "./base-input-field.component";
import {RadioComponent} from './radio.component';
import mergeRefs from "react-merge-refs";

interface RadioGroupComponentProps extends IFormField {
    style?: CSSProperties;
    radioStyle?: CSSProperties;
    options?: string[];
    defaultOption?: string;
    onChange?: (value: string) => void;
    horizontal?: boolean;
    inputRef?: MutableRefObject<any>;
    isInherited?: boolean;
}

export const RadioGroupComponent = forwardRef((props: RadioGroupComponentProps, ref) => {
    const {
        style,
        radioStyle,
        options,
        defaultOption,
        onChange,
        horizontal,
        inputRef,
        isInherited = false,
        readonly
    } = props;

    const useInitializedRef = useRef(false);
    const [selectedOption, setSelectedOption] = useState(defaultOption);

    let containerStyle: CSSProperties = {
        padding: Size.PADDING,
        ...style,
    }

    if (horizontal) {
        containerStyle = {
            ...containerStyle,
            display: 'flex'
        }
    }

    useEffect(() => {
        if (!useInitializedRef.current) {
            useInitializedRef.current = true;
            return;
        }

        onChange && onChange(selectedOption);
    }, [selectedOption])

    const fieldDisplay = (
        <div style={containerStyle} ref={mergeRefs([inputRef, ref])}>
            {options.map(option => (
                <RadioComponent
                    key={option}
                    checked={option === selectedOption}
                    style={radioStyle}
                    onChange={e => {
                        e.target.checked && setSelectedOption(option)
                    }}
                    label={option}
                    isInherited={true}
                    readonly={readonly}
                />
            ))}
        </div>
    )

    if (isInherited) {
        return fieldDisplay;
    }

    return (
        <BaseInputFieldComponent
            {...props}
            type={FormFieldTypeEnum.RadioGroup}
            inputRef={inputRef}
        >
            {fieldDisplay}
        </BaseInputFieldComponent>
    )
})
