import React, { CSSProperties } from 'react';
import { AssetsConstant } from '@vivli/shared/infrastructure/constants';

const tabTitleStyle: CSSProperties = {
    fontWeight: 400,
    fontSize: '20px',
};

const tabArrowStyle: CSSProperties = {
    width: '21px',
};

const arrowContainerStyle: CSSProperties = {
    alignSelf: 'end',
    textAlign: 'center',
};

interface PicoTabComponent {
    onClick?: () => void;
    isActive?: boolean;
    title: string;
    count?: number;
    backgroundColor: string;
}

export const PicoTabComponent = ({
    onClick,
    isActive,
    title,
    count = null,
    backgroundColor,
}: PicoTabComponent) => {
    const tabStyle = (isActive?: boolean): CSSProperties => ({
        display: 'grid',
        gridTemplateRows: 'auto 1fr',
        alignItems: 'center',
        backgroundImage: !isActive
            ? `linear-gradient(135deg, rgba(${backgroundColor}, 0.65) 0%, rgb(${backgroundColor}) 90%)`
            : 'none',
        backgroundColor: isActive ? `rgb(${backgroundColor})` : 'white',
        cursor: 'pointer',
        transition: 'opacity 0.3s ease 0s',
        fontWeight: '300',
        color: 'rgb(255, 255, 255)',
        opacity: 1,
        height: '70px',
        justifyContent: 'center',
        padding: '5px',
    });

    return (
        <div style={tabStyle(isActive)} onClick={onClick}>
            <div style={tabTitleStyle}>
                {title} {count !== null && <span>({count})</span>}
            </div>
            <div style={arrowContainerStyle}>
                <img
                    style={tabArrowStyle}
                    src={AssetsConstant.DOWN_CHEVRON_WHITE}
                />
            </div>
        </div>
    );
};
