﻿import React, { CSSProperties } from 'react';
import { Color } from '@vivli/shared/theme';
import { Outlet, Route, Routes } from 'react-router-dom';
import { YourOrganizationComponent } from '../tabs/your-organization.component';
import { YourStudyComponent } from '../tabs/your-study.component';
import { DataSharingSettingsComponent } from '../tabs/data-sharing-settings.component';
import { AgreementsComponent } from '../tabs/agreements.component';
import { UploadDataComponent } from '../tabs/upload-data.component';
import { ListingRequestChatComponent } from '../tabs/listing-request-chat.component';
import { InformationAboutYourTeamComponent } from '../tabs/information-about-your-team.component';
import { ListingRequestHistoryComponent } from '../tabs/listing-request-history.component';
import { ListingStatusUpdateComponent } from '../tabs/listing-status-update.component';

export const ListingRequestRoutesComponent = () => {
    const containerStyle: CSSProperties = {
        width: document.documentElement.clientWidth - 231,
        flex: '1 1 auto',
        backgroundColor: Color.LIGHT_GRAY,
        overflowY: 'hidden',
        overflowX: 'hidden',
    };

    return (
        <div className={'listingRequestRouterWrapper'} style={containerStyle}>
            <Routes>
                <Route index path="InformationAboutYourTeam" element={<InformationAboutYourTeamComponent />} />
                <Route path="YourOrganization" element={<YourOrganizationComponent />} />
                <Route path="YourStudy" element={<YourStudyComponent />} />
                <Route path="DataSharingSettings" element={<DataSharingSettingsComponent />} />
                <Route path="Agreements" element={<AgreementsComponent />} />
                <Route path="UploadData" element={<UploadDataComponent />} />
                <Route path="History" element={<ListingRequestHistoryComponent />} />
                <Route path="Chat" element={<ListingRequestChatComponent />} />
                <Route path="StatusUpdate" element={<ListingStatusUpdateComponent />} />
            </Routes>
            <Outlet />
        </div>
    );
};
