import React from 'react';
import {IFormField} from "@vivli/shared/infrastructure/interface";
import {BaseControlledFormFieldComponent} from "./base-controlled-form-field.component";
import {MultiSelectDropdownFieldComponent} from "../input/multi-select-dropdown-field.component";

interface MultiSelectDropdownFormFieldComponentProps extends IFormField {
    items: any[];
    name: string;
    onChange?: (value: string | object) => void;
    objectKey: string;
    labelKey: string;
    isDisabled?: boolean;
    arrayKey?: any;
}

export const MultiSelectDropdownFormFieldComponent = (props: MultiSelectDropdownFormFieldComponentProps) => {
    return (
        <BaseControlledFormFieldComponent {...props}>
            <MultiSelectDropdownFieldComponent {...props} />
        </BaseControlledFormFieldComponent>
    )
}
