﻿import {StudyStatusEnum} from "@vivli/features/studies/infrastructure/enum";
import {ICurationStatus} from "@vivli/features/studies/infrastructure/interface";

export const draftStatus = {
    userCanApprove: false,
    userCanReject: false,
    userCanSoftReject: false,
    approveProps: {
        buttonText: null,
        nextStatus: StudyStatusEnum.SubmittedToVivliForPreCheck,
        comment: null
    },
    rejectProps: null,
    softRejectProps: null
} as ICurationStatus

export const awaitingCurationStatus = {
    userCanApprove: true,
    userCanReject: false,
    userCanSoftReject: false,
    permFailText: 'You are not authorized to Accept for Curation.',
    approveProps: {
        buttonText: 'Accept for Curation',
        nextStatus: StudyStatusEnum.CurationInProgress,
        comment: 'Accepted study for Curation',
    },
    rejectProps: null,
    softRejectProps: null
} as ICurationStatus

export const curationInProgressStatus = {
    userCanApprove: true,
    userCanReject: true,
    userCanSoftReject: false,
    permFailText: 'You are not authorized to release to Internal Curation Review.',
    approveProps: {
        // CurationInternalReview
        buttonText: 'Release to Internal Review',
        nextStatus: StudyStatusEnum.CurationInternalReview,
        hasPrompt: true,
        promptTitle: 'Internal Review Comment',
        promptText: 'Please provide any notes for the Internal Reviewer.',
    },
    rejectProps: {
        // CurationBlocked
        buttonText: 'Curation is Blocked',
        nextStatus: StudyStatusEnum.CurationBlocked,
        hasPrompt: true,
        promptTitle: 'Curation Blocked Comment',
        promptText: 'Please describe the reason the curation is blocked.'
    },
    softRejectProps: null
} as ICurationStatus

export const curationInternalReviewStatus = {
    userCanApprove: true,
    userCanReject: true,
    userCanSoftReject: true,
    permFailText: 'You are not authorized to release to Curation QC.',
    approveProps: {
        // AwaitingCurationQaReview
        buttonText: 'Release to Curation QC',
        nextStatus: StudyStatusEnum.AwaitingCurationQaReview,
        hasPrompt: true,
        promptTitle: 'Releasing to QC Comment',
        promptText: 'Please provide any notes for the external QC reviewer.',
    },
    rejectProps: {
        // CurationBlocked
        buttonText: 'Curation is Blocked',
        nextStatus: StudyStatusEnum.CurationBlocked,
        hasPrompt: true,
        requiresComment: true,
        promptTitle: 'Curation Blocked Comment',
        promptText: 'Please describe the reason the curation is blocked.',
    },
    softRejectProps:{
        // CurationInProgress
        buttonText: 'Return to Curator for Revisions',
        nextStatus: StudyStatusEnum.CurationInProgress,
        hasPrompt: true,
        requiresComment: true,
        promptTitle: 'Curation Revisions Required Comment',
        promptText: 'Please describe the changes required in the curation.'
    }
} as ICurationStatus

export const awaitingCurationReviewStatus = {
    userCanApprove: true,
    userCanReject: false,
    userCanSoftReject: false,
    permFailText: 'You are not authorized to accept for QA.',
    approveProps: {
        buttonText: 'Accept for QC',
        nextStatus: StudyStatusEnum.CurationQaReviewInProgress,
        comment: 'Accepting for QC Review',
    },
    rejectProps: null,
    softRejectProps: null
} as ICurationStatus

export const curationQaReviewInProgressStatus = {
    userCanApprove: true,
    userCanReject: true,
    userCanSoftReject: true,
    permFailText: 'You are not authorized to submit curated JSON to Vivli.',
    approveProps: {
        buttonText: 'Approve Curation',
    },
    rejectProps: {
        // CurationBlocked
        buttonText: 'Curation Is Blocked',
        nextStatus: StudyStatusEnum.CurationBlocked,
        hasPrompt: true,
        requiresComment: true,
        promptTitle: 'Curation Blocked Comment',
        promptText: 'Please describe the reason the curation is blocked.',
    },
    softRejectProps: {
        // CurationRevisionsRequired
        buttonText: 'Return to Curator Org for Revisions',
        nextStatus: StudyStatusEnum.CurationRevisionsRequired,
        hasPrompt: true,
        requiresComment: true,
        promptTitle: 'Curation Revisions Required Comment',
        promptText: 'Please describe the changes required in the curation.'
    }
} as ICurationStatus

export const curationRevisionsRequiredStatus = {
    userCanApprove: true,
    userCanReject: false,
    userCanSoftReject: false,
    permFailText: 'You are not authorized to return for Re-Curation.',
    approveProps:{
        buttonText: 'Return for Re-Curation',
        nextStatus: StudyStatusEnum.CurationInProgress,
        comment: 'Return for Re-Curation Due to QC Revisions',
    },
    rejectProps: null,
    softRejectProps: null
} as ICurationStatus

export const curationBlockedStatus = {
    userCanApprove: true,
    userCanReject: true,
    userCanSoftReject: false,
    permFailText: 'You are not authorized to release to Internal Curation QC.',
    approveProps:{
        buttonText: 'Un-Block',
        nextStatus: StudyStatusEnum.CurationInProgress,
        comment: 'Curation unblocked and returned to Curation In Progress'
    },
    rejectProps:{
        buttonText: 'Keep in Blocked Status'
    },
    softRejectProps: null
} as ICurationStatus
