﻿import React, { CSSProperties, useEffect, useState } from 'react';
import { ITab } from '@vivli/shared/infrastructure/interface';
import { IListingRequestSummary } from '@vivli/features/listing-request/infrastructure/interface';
import { ExportCsvButtonComponent, TabComponent, TabsComponent } from '@vivli/shared/components';
import { ColDef, GridApi } from 'ag-grid-community';
import { BehaviorSubject } from 'rxjs';
import { ListingRequestsGridComponent } from './listing-requests-grid.component';
import { Size } from '@vivli/shared/theme';
import { useActiveUser } from '@vivli/core/infrastructure/context';
import { AssetsConstant } from '@vivli/shared/infrastructure/constants';
import { useNavigate } from 'react-router-dom';
import { usePathEndingHook } from '@vivli/shared/infrastructure/hook';

const containerStyle: CSSProperties = {
    padding: `0 ${Size.INSET_SIZE}px`,
    height: '100%',
    position: 'relative',
    backgroundColor: 'rgb(233, 234, 239)',
};
const exportButtonStyle = {
    right: '13%',
    '@media screen and (maxWidth: 1480px)': {
        position: 'initial',
    },
} as CSSProperties;

const baseExportColumnKeys = ['studyTitle', 'status', 'studyNctId'];

const adminExportColumnKeys = ['listingRequesterEmail', 'studyContributor'];

const submittedDateColumnDef = {
    field: 'submittedDateDisplay',
    headerName: 'Submitted',
    headerTooltip: 'Submitted',
    filter: true,
    sortable: true,
};

const createdDateColumnDef = {
    field: 'createdDateDisplay',
    headerName: 'Created',
    headerTooltip: 'Created',
    filter: true,
    sortable: true,
};

const approvedDateColumnDef = {
    field: 'approvedDateDisplay',
    headerName: 'Approved',
    headerTooltip: 'Approved',
    filter: true,
    sortable: true,
};

const withdrawnDateColumnDef = {
    field: 'withdrawnDateDisplay',
    headerName: 'Withdrawn',
    headerTooltip: 'Withdrawn',
    filter: true,
    sortable: true,
};

const baseColumnDefs = (isAdmin): ColDef[] => [
    {
        field: 'studyTitle',
        headerName: 'Title',
        headerTooltip: 'Study Title',
        filter: true,
        sortable: true,
        minWidth: !isAdmin ? 600 : 500,
    },
    {
        field: 'status',
        headerName: 'Status',
        headerTooltip: 'Listing Request Status',
        filter: true,
        sortable: true,
        minWidth: 200,
        valueGetter: (params) => (params.data.status === 'StudyInCuration' ? 'Study In Curation' : params.data.status),
    },
    {
        field: 'studyNctId',
        headerName: 'NCT ID',
        headerTooltip: 'NCTID',
        filter: true,
        sortable: true,
    },
    {
        field: 'studySponsorProtocolId',
        headerName: 'Sponsor ID',
        headerTooltip: 'Sponsor Protocol ID',
        filter: true,
        sortable: true,
    },
];

const adminColumnDefs: ColDef[] = [
    {
        field: 'listingRequesterEmail',
        headerName: 'Requester',
        headerTooltip: 'Requester Email',
        filter: true,
        sortable: true,
    },
    {
        field: 'studyContributor',
        headerName: 'Contributor',
        headerTooltip: 'Contributor',
        filter: true,
        sortable: true,
        minWidth: 150,
    },
];

const gridApis: { gridApi: GridApi; id: string }[] = [];

interface ListingRequestsPageComponentProps {
    title?: string;
    tabs?: ITab<IListingRequestSummary[]>[];
    onRowClick?: (listingRequestSummary: IListingRequestSummary) => void;
    onTabClick?: (tab: ITab<IListingRequestSummary[]>) => void;
    dataRefreshing?: boolean;
}
export const ListingRequestsPageComponent = ({
    title,
    tabs,
    onRowClick,
    onTabClick,
    dataRefreshing,
}: ListingRequestsPageComponentProps) => {
    const user = useActiveUser();
    const navigate = useNavigate();
    const pathEnd = usePathEndingHook();
    const validTabs = tabs?.map((tab) => tab.id);
    const tab = validTabs?.some((vt) => vt === pathEnd) ? pathEnd : 'draft';
    const [activeTabs, setActiveTabs] = useState<ITab<IListingRequestSummary[]>[]>(null);
    const [activeTabIndex, setActiveTabIndex] = useState(0);

    const getDateColumn = () => {
        switch (tab) {
            case 'inprogress':
                return submittedDateColumnDef;
            case 'approved':
                return approvedDateColumnDef;
            case 'withdrawn':
                return withdrawnDateColumnDef;
            case 'draft':
            default:
                return createdDateColumnDef;
        }
    };
    const dateColumn = getDateColumn();
    const activeColumnDefs = user?.isVivliAdmin
        ? [...baseColumnDefs(user?.isVivliAdmin), ...adminColumnDefs]
        : baseColumnDefs(user?.isVivliAdmin);
    const activeColumnKeys = user?.isVivliAdmin ? [...baseExportColumnKeys, ...adminExportColumnKeys] : baseExportColumnKeys;
    const columnDefs = [...activeColumnDefs, dateColumn];
    const exportColumnKeys = [...activeColumnKeys, dateColumn?.field];

    const exportGridData = (tab: ITab<IListingRequestSummary[]>) => {
        const gridApi = gridApis.find((ga) => ga.id === tab.id)?.gridApi;
        const date = new Date();
        const dateStr = `${date.getMonth()}${date.getDay()}${date.getFullYear()}`;
        const titleStr = tab.title.replace(/\s+/g, '_').toLowerCase();
        const fileName = `${titleStr}_academic_sharing_requests_${dateStr}`;

        gridApi.exportDataAsCsv({ fileName, columnKeys: exportColumnKeys });
    };

    const addGridApi = (gridApi: GridApi, title) => {
        gridApis.push({ gridApi, id: title });
    };

    const handleTabClick = (tab: ITab<IListingRequestSummary[]>) => {
        onTabClick && onTabClick(tab);
    };

    const handleRowClick = (listingRequestSummary: IListingRequestSummary) => {
        onRowClick && onRowClick(listingRequestSummary);
    };

    useEffect(() => {
        if (!tabs) {
            return;
        }

        const updatedTabs = [
            ...tabs.map((t) => ({
                ...t,
                countSubject: new BehaviorSubject(t.count),
            })),
        ];

        setActiveTabs(updatedTabs);
    }, [tabs]);

    useEffect(() => {
        if (!tab || !activeTabs) {
            return;
        }

        const index = activeTabs.findIndex((t) => t.id.toLowerCase() === tab.toLowerCase());

        setActiveTabIndex(index);
    }, [tab, activeTabs]);

    return (
        <div style={containerStyle}>
            <TabsComponent
                title={title}
                defaultTab={activeTabIndex}
                commandImage={AssetsConstant.PLUS_SYMBOL}
                commandTitle={'Add Submission'}
                commandOnClick={() => {
                    navigate(`/study-submission/draft/InformationAboutYourTeam`, { state: { skipPortal: true } });
                }}
            >
                {activeTabs?.map((tab, i) => {
                    const { title, data, hint, count, countSubject } = tab;
                    return (
                        <TabComponent
                            key={i}
                            title={title}
                            hint={hint}
                            count={count}
                            countSubject={countSubject}
                            onTabClick={() => handleTabClick(tab)}
                            rightHeaderContent={<ExportCsvButtonComponent onClick={() => exportGridData(tab)} style={exportButtonStyle} />}
                        >
                            <ListingRequestsGridComponent
                                columnDefs={columnDefs}
                                listingRequestSummaries={data}
                                onRowClick={handleRowClick}
                                onGridReady={(gridApi) => addGridApi(gridApi, tab.id)}
                                onRowCountChange={(rowCount) => {
                                    countSubject?.next(rowCount);
                                }}
                                dataRefreshing={dataRefreshing}
                            />
                        </TabComponent>
                    );
                })}
            </TabsComponent>
        </div>
    );
};
