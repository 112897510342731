import React, {CSSProperties, useEffect} from 'react';
import {ISearchStudy} from "@vivli/features/dashboard/infrastructure/interface";
import {useInView} from "react-cool-inview";
import {useSearchContext} from "@vivli/features/search/infrastructure/context";
import {SearchResultRowComponent} from "./search-result-row.component";
import {NoSearchResultsFoundModalComponent} from "@vivli/shared/components";
import {useAssignedAppType} from "@vivli/core/infrastructure/hook";
import {AssignedAppTypeEnum} from "@vivli/shared/infrastructure/enum";
import {DTIAMRSearchRowResult} from "@vivli/shared/infrastructure/constants";

interface SearchResultRowsComponent {
    searchResults: ISearchStudy[];
    onRowClick: (e, searchResult: ISearchStudy) => void;
    isOpen: boolean;
    innerStyle?: CSSProperties;
}

export const SearchResultRowsComponent = ({searchResults, onRowClick, isOpen, innerStyle}: SearchResultRowsComponent) => {
    const {getNextStudyData} = useSearchContext();
    const assignedAppType = useAssignedAppType();
    const isAmr = assignedAppType === AssignedAppTypeEnum.Amr;

    const {observe, unobserve, updatePosition} = useInView<HTMLDivElement>({
        // For better UX, we can grow the root margin so the data will be loaded earlier
        rootMargin: "120px 0px",
        // When the last item comes to the viewport
        onEnter: (api) => {
            // Pause observe when loading data
            api.unobserve();
            // Load more data
            getNextStudyData();
        }
    });

    useEffect(() => {
        if (isOpen) {
            updatePosition();
            observe();
        } else {
            unobserve();
        }
    }, [isOpen])

    if (!isAmr && (!searchResults || searchResults?.length === 0)){
        return (
            <NoSearchResultsFoundModalComponent
                onClose={null}
                noneFoundMessage={'No studies matched your search criteria.'}
                hideCancelButton={true}
                buttonRowStyle={{justifyContent: 'center'}}
                style={{maxWidth: 'none'}}
            />
        )
    }

    return <>
        {searchResults?.map((searchResult, i) => {
            return <div ref={i === searchResults.length - 3 ? observe : null} key={i}>
                <SearchResultRowComponent
                    key={searchResult.id}
                    searchResult={searchResult}
                    onViewStudyClick={onRowClick}
                    innerStyle={innerStyle}
                    dataId={DTIAMRSearchRowResult.SearchRowResult(i)}
                />
            </div>;
        })}
    </>
}
