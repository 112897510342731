import React, {CSSProperties} from 'react';
import {Color, Size} from '@vivli/shared/theme';
import {IDataRequest, IRequestedStudy} from '@vivli/features/data-requests/infrastructure/interface';
import {useAssignedAppType} from "@vivli/core/infrastructure/hook";
import {AssignedAppTypeEnum} from "@vivli/shared/infrastructure/enum";
import {RequestedStudyTypeEnum} from "@vivli/features/data-requests/infrastructure/enum";
import {useRequestedStudyBehavior} from "@vivli/features/studies/infrastructure/hook";


const detailContainerStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    width: '70%',
    minWidth: '350px',
}

const fieldBodyStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    fontSize: Size.FontSize.Medium,
    flexWrap: 'wrap'
}

const labelStyle: CSSProperties = {
    color: Color.DARK_GRAY,
    marginRight: '10px'
}

const fieldStyle: CSSProperties = {marginRight: '15px'}

interface StudyRowDetailComponentProps {
    study: IRequestedStudy;
    dataRequest: IDataRequest;
}

export const RequestedStudyRowDetailComponent = ({study, dataRequest}: StudyRowDetailComponentProps) => {
    const assignedAppType = useAssignedAppType();
    const isAmr = assignedAppType === AssignedAppTypeEnum.Amr;
    const {getStudyIdForDisplay} = useRequestedStudyBehavior();

    const unlistedStudy = study.requestedStudyType === RequestedStudyTypeEnum.Unlisted
    const displayStudyId = getStudyIdForDisplay(study);

    return (
        <div style={detailContainerStyle}>
            {!isAmr &&
                <div style={fieldBodyStyle}>
                    <div style={fieldStyle}>
                        <span style={labelStyle}>Study ID:</span>
                        <span>{displayStudyId}</span>
                    </div>
                    {!unlistedStudy &&
                        <div style={fieldStyle}>
                            <span style={labelStyle}>Sponsor ID:</span>
                            <span>{study.sponsorProtocolId}</span>
                        </div>
                    }
                </div>
            }

            <div style={fieldBodyStyle}>
                <div style={fieldStyle}>
                    <span style={labelStyle}>Data Request ID:</span>
                    <span>{dataRequest?.dataRequestDoi}</span>
                </div>
            </div>

            {!isAmr &&
                <div style={fieldBodyStyle}>
                    <div style={fieldStyle}>
                        <span style={labelStyle}>
                            <span>
                                Data Contributor:
                            </span>
                        </span>
                            <span>{study.organizationName}</span>
                    </div>
                    {<div style={fieldStyle}>
                        <span style={labelStyle}>IRP/Approver:</span>
                        <span>{study.approvalBodyOrgName}</span>
                    </div>}
                </div>
            }

            {isAmr &&
                <div style={fieldBodyStyle}>
                    <div style={fieldStyle}>
                        <span style={labelStyle}>Sponsor:</span>
                        <span>{study.sponsorName}</span>
                    </div>
                </div>
            }
        </div>
    )
}
