﻿import {IContextWrapper} from "@vivli/shared/infrastructure/interface";
import {useDefaultApiOptions} from "@vivli/shared/infrastructure/hook";
import {VirtualMachineApiService} from "@vivli/features/virtual-machine/infrastructure/service";
import {IVirtualMachineService} from "@vivli/features/virtual-machine/infrastructure/interface";
import React from "react";
import {VirtualMachineServiceContext} from "@vivli/features/virtual-machine/infrastructure/context";

export const VirtualMachineServiceContextWrapper = ({children}: IContextWrapper) => {
    const apiOptions = useDefaultApiOptions();
    const api = new VirtualMachineApiService(apiOptions);

    const provider: IVirtualMachineService = {
        ...api
    }

    return <VirtualMachineServiceContext.Provider value={provider}>
        {children}
    </VirtualMachineServiceContext.Provider>
}
