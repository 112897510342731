﻿import React from "react";
import {AmrDataRequestPrintViewComponent} from "./amr-data-request-print-view.component";
import {useDataRequestContext} from "@vivli/features/data-requests/infrastructure/context";
import {AmrRequestFormComponent} from "./amr-request-form.component";


export const AmrDataRequestViewComponent = () => {
    const {isAdminEditing, dataRequest} = useDataRequestContext();
    const viewDisplay = isAdminEditing
        ? <AmrRequestFormComponent dataRequest={dataRequest} />
        : <AmrDataRequestPrintViewComponent />;

    return viewDisplay;
}
