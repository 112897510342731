﻿import {Size, Styles} from "@vivli/shared/theme";
import {ButtonComponent} from "@vivli/shared/components";
import React, {CSSProperties} from "react";
import {IModalOptions} from "@vivli/shared/infrastructure/interface";
import {useModalService} from "@vivli/shared/infrastructure/context";
import {useCitationsContext} from "@vivli/shared/features/citations/infrastructure/context";
import {AddCitationComponent} from "./add-citation.component";
import {EditCitationFormComponent} from "./edit-citation-form.component";
import {DTIAMRCommonConst} from "@vivli/shared/infrastructure/constants";

const wrapperStyle = {
    padding: '15px',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'rgb(233, 234, 239)',
} as React.CSSProperties;


const genericStyle: CSSProperties = {
    marginTop: Size.FIELD_SEPARATION,
    marginBottom: Size.FIELD_SEPARATION,
}

const oneAcrossStyleSmallNoBox: CSSProperties = {
    ...genericStyle,
    flex: '1 1 100%',
    maxWidth: '20%',
    marginLeft: 'auto',
    marginRight: 'auto',
}

interface CitationsAddButtonsComponentProps {
    objectId: string;   // the id of the data request we are adding the citation to
    isStudy: boolean;
    dataRequestDoiStem: string;
}

export const CitationsAddButtonsComponent = ({objectId, isStudy, dataRequestDoiStem
                                             }: CitationsAddButtonsComponentProps) => {
    const modal = useModalService();
    const {addCitation, defaultCitation, isLoading} = useCitationsContext()
    const handleAddFromDoi = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const options: IModalOptions = {
            style: wrapperStyle,
        }
        const modalId = modal.custom(<AddCitationComponent
                onCancel={() => modal.dismiss(modalId)}
                onSave={(citationDoi) => {
                    modal.dismiss(modalId);
                    addCitation(citationDoi, objectId, isStudy, dataRequestDoiStem, null);
                }}/>, options)
    }
    const handleAddFromUrl = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const options: IModalOptions = {
            style: wrapperStyle,
        }
        const modalId = modal.custom(<EditCitationFormComponent
                citation={null}
                defaultCitationObject={defaultCitation}
                onCancel={() => modal.dismiss(modalId)}
                onSave={(citation) => {
                    modal.dismiss(modalId);
                    addCitation('', objectId, isStudy, dataRequestDoiStem, citation);
                }}/>
            , options)
    }

    return (
        <div style={Styles.FORM_ROW}>
            <ButtonComponent
                style={oneAcrossStyleSmallNoBox}
                isLoading={isLoading}
                onClick={(e) => {
                    handleAddFromDoi(e)
                }}
                dataId={DTIAMRCommonConst.AddCitationButton('FromDOI')}
            >
                Add Citation From DOI
            </ButtonComponent>
            <ButtonComponent
                style={oneAcrossStyleSmallNoBox}
                isLoading={isLoading}
                onClick={(e) => {
                    handleAddFromUrl(e)
                }}
                dataId={DTIAMRCommonConst.AddCitationButton('Manually')}
            >
                Add Citation Manually
            </ButtonComponent>
        </div>
    )


}
