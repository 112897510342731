﻿import React, { CSSProperties, useEffect, useState } from 'react';
import Select, { StylesConfig } from 'react-select';
import { Color, Size, Styles } from '@vivli/shared/theme';
import { AdvancedHeaderComponent } from './advanced-header.component';
import { ISelectOption } from '@vivli/features/search/infrastructure/interface';

const headerStyle: CSSProperties = {
    color: Color.DARK_GRAY,
    fontSize: 16,
    marginBottom: 8,
};

interface AdvancedSelectProps {
    isMulti?: boolean;
    isSearchable?: boolean;
    isClearable?: true;
    className?: string;
    title?: string;
    onChange: (selectedOptions: ISelectOption[]) => void;
    options: ISelectOption[];
    selectedOptions?: ISelectOption[];
    targetStyle?: CSSProperties;
    fieldStyle?: CSSProperties;
    isDisabled?: boolean;
    sort?: 'asc' | 'desc';
    menuPosition?: 'fixed' | 'absolute';
    menuPlacement?: 'auto' | 'bottom' | 'top';
}
export const AdvancedSelectComponent = ({
    isMulti,
    isSearchable,
    isClearable,
    className,
    title,
    onChange,
    options,
    selectedOptions,
    targetStyle,
    fieldStyle,
    isDisabled,
    sort,
    menuPosition = 'fixed',
    menuPlacement = 'auto',
}: AdvancedSelectProps) => {
    const [activeOptions, setActiveOptions] = useState<ISelectOption[]>();

    const selectStyles: StylesConfig<ISelectOption, false> = {
        control: (provided) => ({
            ...provided,
            ...selectStyle,
            paddingLeft: Size.PADDING,
        }),
        menu: (selectStyle) => ({ ...selectStyle }),
        menuList: (menuStyle) => ({ ...menuStyle, width: '99%' }),
        menuPortal: (style) => ({ ...style, zIndex: '9999' }),
        option: (styles) => ({
            ...styles,
            backgroundColor: Color.ADVANCED_MENU_TARGET_BACKGROUND_COLOR,
            color: Color.DARK_GRAY,
            ':hover': {
                backgroundColor: Color.HOVER_GRAY,
                color: Color.DARK_GRAY,
            },
            ':active': {
                backgroundColor: Color.HOVER_GRAY,
                color: Color.DARK_GRAY,
            },
        }),
        multiValue: (mvStyle) => ({
            ...mvStyle,
            backgroundColor: 'rgb(176, 176, 176)',
            color: 'white',
            borderRadius: 6,
        }),
        multiValueLabel: (mvLabelStyle) => ({
            ...mvLabelStyle,
            color: 'white',
        }),
    };

    const selectStyle: CSSProperties = {
        position: 'relative',
        backgroundColor: Color.ADVANCED_MENU_TARGET_BACKGROUND_COLOR,
        paddingTop: 8,
        paddingBottom: 8,
        boxShadow: Size.BOX_SHADOW_EXTRA_LIGHT,
        display: 'flex',
        border: 'none',
        fontSize: Size.FontSize.Large,
        color: Color.DARK_GRAY,
        cursor: 'pointer',
        ...Styles.borderRadius(Size.BORDER_RADIUS),
        ...Styles.NO_SELECT,
        ...targetStyle,
        pointerEvents: isDisabled ? 'none' : 'auto',
    };

    const placeholderText = isMulti ? 'Select Multiple' : 'Select One';

    const handleOnChange = (value: any) => {
        if (Array.isArray(value)) {
            onChange(value);
        } else {
            onChange([value]);
        }
    };

    useEffect(() => {
        if (!sort) {
            setActiveOptions(options);
            return;
        }

        if (sort === 'asc') {
            const sortedOptions = options?.sort((a, b) =>
                a.label.localeCompare(b.label)
            );
            setActiveOptions(sortedOptions);
            return;
        }

        const sortedOptions = options?.sort((a, b) =>
            b.label.localeCompare(a.label)
        );
        setActiveOptions(sortedOptions);
    }, [options]);

    return (
        <>
            <AdvancedHeaderComponent
                title={title}
                style={{ ...headerStyle, ...fieldStyle }}
            />
            <Select
                value={selectedOptions}
                options={activeOptions}
                isSearchable={isSearchable}
                isMulti={isMulti}
                isClearable={isClearable}
                placeholder={placeholderText}
                onChange={handleOnChange}
                backspaceRemovesValue
                className={className}
                isDisabled={isDisabled}
                closeMenuOnSelect={!isMulti}
                menuPosition={menuPosition}
                menuPlacement={menuPlacement}
                styles={selectStyles}
            />
        </>
    );
};
