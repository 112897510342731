﻿import React from "react";
import {
    FieldHeaderComponent,
    TextFormFieldComponent,
    threeAcrossStyle
} from "@vivli/shared/components";
import {Styles} from "@vivli/shared/theme";
import {DTICTFormFields} from "@vivli/shared/infrastructure/constants";

interface PiDetailsComponentProps {
    userIsReadOnly: boolean,
}

export const PiDetailsComponent = (
    {   userIsReadOnly}: PiDetailsComponentProps
) => {

    return (
        <div style={{width: '100%'}}>
            <FieldHeaderComponent title={'PRINCIPAL INVESTIGATOR'}/>
            <div style={Styles.FORM_ROW}>
                <TextFormFieldComponent
                    name={`principalInvestigator.firstName`}
                    label='First Name'
                    style={threeAcrossStyle}
                    readonly={userIsReadOnly}
                    dataId={DTICTFormFields.FName}
                />
                <TextFormFieldComponent
                    name={`principalInvestigator.lastName`}
                    label='Last Name'
                    style={threeAcrossStyle}
                    readonly={userIsReadOnly}
                    dataId={DTICTFormFields.LName}
                />
                <TextFormFieldComponent
                    name={`principalInvestigator.orcidId`}
                    label='ORCID iD'
                    style={threeAcrossStyle}
                    readonly={userIsReadOnly}
                    dataId={DTICTFormFields.OrcidId}
                />
            </div>
        </div>
    );
}
