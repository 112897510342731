export type storageType = 'local' | 'session';

export abstract class StorageService {

    static setItem(key: string, item: any, type: storageType = 'local'): boolean {
        try {
            this._setByType(key, item, type);
            return true;
        } catch {
            return false;
        }
    }

    static getItem<T>(key: string, type: storageType = 'local'): T {
        try {
            return this._getByType<T>(key, type);
        } catch {
            return null;
        }
    }

    static deleteItem(key, type: storageType = 'local'): void {
        try {
            this._deleteByType(key, type);
        } catch { }
    }

    private static _getStorage(type: storageType): Storage {
        switch (type) {
            case "local":
                return localStorage;
            case "session":
                return sessionStorage;
        }
    }

    private static _deleteByType(key: string, type: storageType): void {
        const storage = this._getStorage(type);
        storage.removeItem(key);
    }

    private static _setByType(key: string, item: any, type: storageType) {
        const storage = this._getStorage(type);
        const storageItem = JSON.stringify({ value: item });
        storage.setItem(key, storageItem);
    }

    private static _getByType<T>(key: string, type: storageType): T {
        const storage = this._getStorage(type);
        const storageItem = storage.getItem(key);
        const parsedItem = JSON.parse(storageItem);

        return parsedItem.value as T;
    }
}
