export enum DirStatusEnum {
    None = 0,
    New = 1,
    RequesterCommented = 2,
    DCCommented = 3,
    VivliCommented = 4,
    AvailableUnlisted = 5,
    AvailableListed = 6,
    NotAvailable = 7
}
