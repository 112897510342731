import React, {createContext, useContext} from "react";
import {IProvisionAdminOptions} from "../../../interface/src/lib/provision-admin-options";
import {Observable} from "rxjs";

interface IAdminConsoleContext {
       handleAction: (action:string,  id: string, data: string, isAsync:boolean) => void;
       isLoading:boolean;
       provisionVm?: (id: string,  options: IProvisionAdminOptions , callback: ()=>void) => void;
}

export const AdminConsoleContext = createContext<IAdminConsoleContext>(null);

export const useAdminConsoleContext = () => useContext(AdminConsoleContext);
