﻿import React, { CSSProperties } from 'react';
import {
    ListingRequestFormComponent,
    ListingRequestPortalComponent,
} from '@vivli/features/listing-request/components';
import { ListingRequestContextWrapper } from '@vivli/features/listing-request/infrastructure/wrappers';

const containerStyle: CSSProperties = {
    backgroundColor: 'rgb(233, 234, 239)',
    overflow: 'hidden',
    transform: 'translate3d(0,0,0)', // force chrome to use GPU for rendering - fixes UI 'whiteout' glitch related to scrollbars (I think),
    display: 'flex',
    flexDirection: 'column',
    minWidth: '100%',
    flexGrow: 2,
    height: '100%',
};

export const ListingRequestFeature = () => {
    return (
        <ListingRequestPortalComponent>
            <div style={containerStyle}>
                <ListingRequestContextWrapper>
                    <ListingRequestFormComponent />
                </ListingRequestContextWrapper>
            </div>
        </ListingRequestPortalComponent>
    );
};
