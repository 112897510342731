import React, {CSSProperties} from 'react';
import {AdminHeaderDetailTitleComponent, AdminHeaderTitleComponent} from "@vivli/shared/components";
import {DataRequestFormatService} from "@vivli/features/data-requests/infrastructure/service";
import {DataRequestStatusEnum} from "@vivli/features/data-requests/infrastructure/enum";
import {useActiveUser} from "@vivli/core/infrastructure/context";
import {useDataRequestContext} from "@vivli/features/data-requests/infrastructure/context";
import {useAssignedAppType} from "@vivli/core/infrastructure/hook";
import {AssignedAppTypeEnum, TranslationKeyEnum} from "@vivli/shared/infrastructure/enum";
import {useTranslation} from "react-i18next";

const titleContainerStyle: CSSProperties = {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 7,
    marginBottom: 7,
    marginLeft: 30,
    flex: 1,
    height: 35,
}

export const RequestTitleComponent = ({myDataRequests}) => {
    const activeUser = useActiveUser();
    const {dataRequest} = useDataRequestContext();
    const assignedAppType = useAssignedAppType();
    const isAmr = assignedAppType === AssignedAppTypeEnum.Amr;
    const isResearchTeamMember = dataRequest.researchTeam?.researchTeamDetails.some(rtd => rtd.userId === activeUser.userId)
        || dataRequest.researchTeam?.leadResearcherTeamMemberDetail?.userId === activeUser.userId
        || dataRequest.researchTeam?.statisticianTeamMemberDetail?.userId === activeUser.userId;
    const piName = `${dataRequest.researchTeam?.leadResearcherTeamMemberDetail?.firstName} ${dataRequest.researchTeam?.leadResearcherTeamMemberDetail?.lastName}`;
    const lrName = dataRequest.antimicrobialDetail?.amrResearcherDetails?.researcherName ? `${dataRequest.antimicrobialDetail?.amrResearcherDetails?.researcherName}` : '';
    const pi = isAmr ? lrName : piName;
    const {t} = useTranslation();
    const duaTranslation = t(TranslationKeyEnum.duaValidatedStatusString);
    const statusMessage = DataRequestFormatService.mapStatusToMessage(DataRequestStatusEnum[dataRequest.status], isAmr, duaTranslation);
    const adminDetailedHeader = <AdminHeaderDetailTitleComponent
        requestNumber={dataRequest.dataRequestDoi ? parseInt(dataRequest.dataRequestDoi, 10) : 0}
        title={dataRequest.requestTitle}
        status={statusMessage}
    />

    return (
        <div style={titleContainerStyle} title={dataRequest.requestTitle}>
            {myDataRequests ?
                <AdminHeaderDetailTitleComponent
                    requestNumber={dataRequest.dataRequestDoi ? parseInt(dataRequest.dataRequestDoi, 10) : 0}
                    title={dataRequest.requestTitle}
                    status={statusMessage} myDataRequestsAdminHeader={false}
                /> :
                <>{!isResearchTeamMember ?
                    <AdminHeaderDetailTitleComponent
                        requestNumber={dataRequest.dataRequestDoi ? parseInt(dataRequest.dataRequestDoi, 10) : 0}
                        pi={pi}
                        status={statusMessage} myDataRequestsAdminHeader={true}
                    />:
                <AdminHeaderTitleComponent title={dataRequest.requestTitle} truncateText={true} />}</>
            }

        </div>
    )
}
