import React from "react";
import {Configuration, LogLevel} from "@azure/msal-browser";
import {useConfigService} from "@vivli/core/infrastructure/context";
import {IB2COptions, IMsal2ProviderOptions, Msal2Wrapper} from "@vivli/core/authentication";
import {IConfig, IContextWrapper} from "@vivli/shared/infrastructure/interface";
import {LoggerService, StorageService} from "@vivli/shared/infrastructure/service";

const getAuthority = (config: IConfig, policyName?: string): string => {
    const storedPolicyName = StorageService.getItem('policyName');
    const policy = policyName || storedPolicyName || config.b2CSignInPolicyName;
    StorageService.setItem('policyName', policy);
    const b2cName = config.b2CTenant.split('.')[0]
    return `https://${b2cName}.b2clogin.com/${config.b2CTenant}/${policy}`;
}

const getMsalConfig = ({
                           config, authority
                       }: { config: IConfig, authority?: any }): Configuration => {

    let portDelimAndNumber = `:${window.location.port}`;
    portDelimAndNumber = (portDelimAndNumber === ':') ? '' : portDelimAndNumber;
    const redirectUri = `${window.location.protocol}//${window.location.hostname}${portDelimAndNumber}`;
    const postLogoutRedirectUri = `${window.location.protocol}//${window.location.hostname}${portDelimAndNumber}`;

    return {
        auth: {
            clientId: config.b2CAppId,
            authority,
            redirectUri,
            postLogoutRedirectUri,
            knownAuthorities: [authority]
        },
        cache: {
            cacheLocation: 'localStorage'
        },
        system: {
            loggerOptions: {
                loggerCallback: (
                    level,
                    message,
                    containsPii
                ) => {
                    if (containsPii) {
                        return;
                    }
                    switch (level) {
                        case LogLevel.Error:
                            LoggerService.error(message);
                            return;
                        case LogLevel.Info:
                            LoggerService.info(message);
                            return;
                        case LogLevel.Verbose:
                            LoggerService.debug(message);
                            return;
                        case LogLevel.Warning:
                            LoggerService.warn(message);
                            return;
                    }
                },
                piiLoggingEnabled: false,
            },
            windowHashTimeout: 60000,
            iframeHashTimeout: 6000,
            loadFrameTimeout: 0,
        }
    }
}

export const AuthWrapper = ({children}: IContextWrapper) => {
    const config = useConfigService();
    const authority = getAuthority(config);
    const msalConfig = getMsalConfig({config, authority});

    const {
        b2CEditProfilePolicyName,
        b2CSignInOnlyPolicyName,
        b2CPasswordPolicyName,
        b2CSignInPolicyName,
        b2CSignUpPolicyName,
        b2CSSOPolicyName,
    } = config;

    //Detect a login from a partner set up as an SSO provider -
    //in the parnterLog the authorities configured
    //will use an SSO policy as an override policy for the sign in and default policies.
    //in the "normal login" case we will use the usual signin and default policies.
    const isPartnerLogin = window.location.href.indexOf('partner-login') !== -1;
    let overridePolicyName = null;
    if (isPartnerLogin) {
        overridePolicyName = b2CSSOPolicyName;
    }

    const b2cOptions: IB2COptions = {
        scopes: [config.b2CApiAccessScope, 'openid', 'profile'],
        tenant: config.b2CTenant,
        authorities: {
            default: overridePolicyName || b2CSignInOnlyPolicyName,
            signInOnly: overridePolicyName || b2CSignInOnlyPolicyName,
            signUpOnly: b2CSignUpPolicyName,
            signUpSignIn: b2CSignInPolicyName,
            editProfile: b2CEditProfilePolicyName,
            forgotPassword: b2CPasswordPolicyName,
            sso: b2CSSOPolicyName,
        }
    }

    const options: IMsal2ProviderOptions = {
        b2cOptions,
        config: msalConfig,
        logoutOnBrowserClose: {
            keepAliveIntervalSeconds: 1,
            keepAliveSeconds: 30
        },
        timeout: {
            expirationMinutes: (config.sessionCookieLengthValueSeconds / 60),
            warningMinutes: (config.sessionCookieLengthValueSeconds - config.sessionExpirationWarningIntervalSeconds) / 60
        }
    }

    // in partner/SSO provider case use redirectLoginOnLoad instead of
    // silentLoginOnLoad, because we don't have an existing context;
    // the redirect lets the specified B2C flow do its job instead of
    // forcing user to interact with login if no existing context
    return (
        <Msal2Wrapper
            externalLoginOnLoad={isPartnerLogin}
            options={options}
        >
            {children}
        </Msal2Wrapper>
    )
}
