﻿import React, {CSSProperties} from "react";
import {useParams} from "react-router";
import {useStudyWithUserCheck} from "@vivli/features/studies/infrastructure/hook";
import {LoadIndicatorCenteredComponent} from "@vivli/shared/components";
import {StudyContext} from "@vivli/features/studies/infrastructure/context";
import {AnnotateStudyFormComponent} from "@vivli/features/studies/components";
import {StudyContextWrapper} from "@vivli/features/studies/infrastructure/wrappers";

const containerStyle: CSSProperties = {
    backgroundColor: 'rgb(233, 234, 239)',
    overflow: 'hidden',
    transform: 'translate3d(0,0,0)', // force chrome to use GPU for rendering - fixes UI 'whiteout' glitch related to scrollbars (I think),
    display: 'flex',
    flexDirection: 'column',
    minWidth: '100%',
    flexGrow: 2,
    height: '100%',
}

export const AnnotateStudyFeature = () => {
    const {studyId} = useParams();
    const {study, studyUsedIn, studyIpdDataPackageSubmitted} = useStudyWithUserCheck(studyId);

    return (
        <div style={containerStyle}>
            <StudyContextWrapper
                study={study}
                usedIn={studyUsedIn}
                studyIpdPackageSubmitted={studyIpdDataPackageSubmitted}
            >
                <StudyContext.Consumer>
                    {({study}) => {
                        if (!study) {
                            return <LoadIndicatorCenteredComponent/>;
                        }
                        return (
                            <AnnotateStudyFormComponent/>
                        )
                    }}
                </StudyContext.Consumer>
            </StudyContextWrapper>
        </div>
    )
}
