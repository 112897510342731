﻿import {AmrAnalysisPurposeEnum} from "@vivli/features/data-requests/infrastructure/enum";

export function useAmrAnalysisPurposeTextMap(): (purpose: AmrAnalysisPurposeEnum) => string {
    const mapText = (purpose: AmrAnalysisPurposeEnum) => {
        switch (purpose) {
            case AmrAnalysisPurposeEnum.AmrTrendsForCountryRegion:
                return 'AMR trends for a country/region';
            case AmrAnalysisPurposeEnum.AmrTrendsForPathogen:
                return 'AMR trends for a pathogen';
            case AmrAnalysisPurposeEnum.AmrTrendsForSpecificResistanceMechanism:
                return 'AMR trends for a specific resistance mechanism including phenotype to genotype comparisons';
            case AmrAnalysisPurposeEnum.AmrTrendsForInfectionTypeOrIndication:
                return 'AMR trends for infection type or indication';
            case AmrAnalysisPurposeEnum.AmrTrendsOverDifferentYears:
                return 'AMR trends over different years';
            case AmrAnalysisPurposeEnum.PkPd:
                return 'PK/PD';
            case AmrAnalysisPurposeEnum.InformAntimicrobialStewardship:
                return 'Inform Antimicrobial Stewardship';
            case AmrAnalysisPurposeEnum.Other:
                return 'Other (elaborate below)';
        }
        return purpose;
    }
    return mapText;
}
