import {DatasetFormatService} from "@vivli/features/datasets/infrastructure/service";
import React from "react";
import {
    AdminHeaderTitleComponent,
    CheckboxComponent,
    TextAreaReadOnlyFormComponent,
    TextFieldReadOnlyFormComponent,
    fourAcrossStyle,
    oneAcrossStyle,
    threeAcrossStyle,
    twoAcrossStyle,
    twoAcrossStyleCbs,
    titleStyle
} from "@vivli/shared/components";
import {Color, Size, Styles} from "@vivli/shared/theme";
import moment from 'moment';
import {noop} from "rxjs";
import {AboutDataGridComponent} from "./admin-details/about-data-grid.component";
import {IAmrDataset} from "@vivli/features/studies/infrastructure/interface";
import {DTIAMRAgGrid, DTIFormFields} from "@vivli/shared/infrastructure/constants";

interface AmrDataSetDetailsComponentProps {
    dataset? : IAmrDataset
}

export const AmrDatasetDetailsComponent = ({dataset}: AmrDataSetDetailsComponentProps) => {

    const amrDataset = dataset;
    const content = amrDataset.antimicrobialContent;

    const yearsCollected = DatasetFormatService.getYearsCollectedString(amrDataset.yearsDataCollected);
    const antimicrobials = DatasetFormatService.getStringsForVerticalDisplay(content.antimicrobials);
    const organisms = DatasetFormatService.getStringsForVerticalDisplay(content.organisms);
    const specimens = DatasetFormatService.getStringsForVerticalDisplay(content.specimenSources);

    const delimiter = '  ';
    const timingString = DatasetFormatService.getStringForCommaDelimitedDisplay(content.timesSampleTaken, delimiter);
    const groupingString = DatasetFormatService.getStringForCommaDelimitedDisplay(content.groupingsOfResistancePatterns, delimiter);

    const countriesList = amrDataset.countries;
    const countriesString = countriesList.map(c => c.name);
    const countriesNameString = DatasetFormatService.getStringsForVerticalDisplay(countriesString);

    let aboutTheData = amrDataset.aboutTheDataList;
    const aboutTheDataArray = Object.keys(aboutTheData).map(key => ({
        key,
        value: aboutTheData[key]
    }));

    return (
        <div style={{width: '100%'}}>
            {
                <div style={Styles.FORM_ROW}>
                    <TextFieldReadOnlyFormComponent
                        label={'Data Collected From'}
                        style={threeAcrossStyle}
                        defaultValue={yearsCollected}
                        readonly={true}
                        dataId={DTIFormFields.DataCollectedFrom}
                    />
                    <TextFieldReadOnlyFormComponent
                        label={'Date of last Update'}
                        style={threeAcrossStyle}
                        defaultValue={moment(amrDataset?.lastUpdatedDate).format('MMM YYYY')}
                        readonly={true}
                        dataId={DTIFormFields.LastUpdate}
                    />
                    <TextFieldReadOnlyFormComponent
                        label={'Number of Isolates'}
                        style={threeAcrossStyle}
                        defaultValue={amrDataset?.numberOfIsolates}
                        readonly={true}
                        dataId={DTIFormFields.NumberOfIsolates}
                    />
                </div>
            }
            {
                <div style={Styles.FORM_ROW}>
                    <TextAreaReadOnlyFormComponent
                        label={'Primary Objective'}
                        style={oneAcrossStyle}
                        defaultValue={amrDataset.primaryObjective}
                        dataId={DTIFormFields.PrimaryObjective}
                    />
                    <TextAreaReadOnlyFormComponent
                        label={'Protocol Summary'}
                        style={oneAcrossStyle}
                        defaultValue={amrDataset.protocolSummary}
                        dataId={DTIFormFields.DataCollectedFrom}
                    />
                    <TextAreaReadOnlyFormComponent
                        label={'Additional Information'}
                        style={oneAcrossStyle}
                        defaultValue={amrDataset.additionalAmrInformation}
                        dataId={DTIFormFields.AdditionalInformation}
                    />
                </div>
            }
            {
                <div style={Styles.FORM_ROW}>
                    <TextAreaReadOnlyFormComponent
                        label={'Antimicrobials'}
                        rows={9}
                        style={fourAcrossStyle}
                        defaultValue={antimicrobials}
                        dataId={DTIFormFields.Antimicrobials}
                    />
                    <TextAreaReadOnlyFormComponent
                        label={'Organisms'}
                        rows={9}
                        style={fourAcrossStyle}
                        defaultValue={organisms}
                        dataId={DTIFormFields.Organisms}
                    />
                    <TextAreaReadOnlyFormComponent
                        label={'Countries'}
                        rows={9}
                        style={fourAcrossStyle}
                        defaultValue={countriesNameString}
                        dataId={DTIFormFields.Country}
                    />
                    <TextAreaReadOnlyFormComponent
                        label={'Sources of Samples'}
                        rows={9}
                        style={fourAcrossStyle}
                        defaultValue={specimens}
                        dataId={DTIFormFields.SourceOfSample}
                    />
                </div>
            }
            {
                <div style={Styles.FORM_ROW}>
                    <TextAreaReadOnlyFormComponent
                        label={'Timing of Samples'}
                        rows={1}
                        style={twoAcrossStyle}
                        defaultValue={timingString}
                        readonly={true}
                        dataId={DTIFormFields.TimingOfSample}
                    />
                    <TextAreaReadOnlyFormComponent
                        label={'Resistance Grouping'}
                        rows={1}
                        style={twoAcrossStyle}
                        defaultValue={groupingString}
                        readonly={true}
                        dataId={DTIFormFields.ResistanceGroup}
                    />
                </div>
            }
            {
                <div style={Styles.FORM_ROW}>
                    <CheckboxComponent
                        label={'Contains Pediatrics'}
                        checked={amrDataset.containsPediatrics}
                        onChange={noop}
                        style={twoAcrossStyleCbs}
                        defaultValue={amrDataset.containsPediatrics}
                        readonly={true}
                        dataId={DTIFormFields.PediatricsCheckBox}
                    />
                    <CheckboxComponent
                        label={'Contains Genotype'}
                        checked={amrDataset.containsGenotype}
                        onChange={noop}
                        style={twoAcrossStyleCbs}
                        defaultValue={amrDataset.containsGenotype}
                        readonly={true}
                        dataId={DTIFormFields.GenotypeCheckBox}
                    />
                </div>
            }
            {
                <div style={Styles.FORM_ROW}>
                    <div style={{paddingTop: '15px',  color: Color.DARK_GRAY, fontWeight: Size.FontWeight.Bold, fontSize: Size.FontSize.Medium}}>
                        <AdminHeaderTitleComponent title={'About the Data'} style={titleStyle}/>
                    </div>
                    <div style={{width: '100%', marginLeft: '0px', paddingBottom: '10px', paddingTop: '15px'}}>
                        <AboutDataGridComponent
                            aboutTheDataList={aboutTheDataArray}
                            type={'About the Data'}
                            autoHeight={true}
                        />
                    </div>
                </div>
            }
        </div>
    );
}
