export const therapeuticAreaItemsIntercept = [
    {
        description: 'Infectious Diseases',
        code: 'AC0456DD-3CF0-48CB-93BD-F9F4948311AD',
        active: true,
    },
    {
        description: 'Gastroenterology',
        code: 'A68D10C2-2CCD-4603-A260-DA721941D779',
        active: true,
    },
    {
        description: 'Neurology',
        code: '2553E0CC-4638-44DF-93B0-2973FC09F0C6',
        active: true,
    },
    {
        description: 'Rheumatology',
        code: '0064F029-93DD-4591-B66A-E0C7A18C3ADF',
        active: true,
    },
    {
        description: 'Dermatology',
        code: 'C67221DA-6BC0-4209-9DDE-CAF160618603',
        active: true,
    },
    {
        description: 'Immunology',
        code: '07238C71-BCA5-4A11-84FE-DDC7548BDAAE',
        active: true,
    },
    {
        description: 'Methods',
        code: '76E7A0AA-E09A-4C95-A840-D16ED7119531',
        active: true,
    },
    {
        description: 'Cardiovascular',
        code: '7F648F75-04BA-491A-8C3B-26D28D7624A4',
        active: true,
    },
    {
        description: 'Psychiatry',
        code: 'FF66C281-325E-4DCD-ACEB-1B8A2D116508',
        active: true,
    },
    {
        description: 'Pulmonary',
        code: 'DA4A4863-4E24-4AC5-99D6-B14CD8A80872',
        active: true,
    },
    {
        description: 'Orthopedics',
        code: 'AD5C31B0-755D-423E-8563-880ED405BD53',
        active: true,
    },
    {
        description: 'Oncology',
        code: '414594CB-D676-4B1E-9812-1D147294AAF8',
        active: true,
    },
    {
        description: 'Cardiology',
        code: '5DCDC335-EF4A-4C4D-9E5F-E0E8825E7ADD',
        active: true,
    },
    {
        description: 'Endocrinology',
        code: '5EABAA0F-A595-4A67-8E02-51CA26776B49',
        active: true,
    },
    {
        description: 'Nephrology',
        code: '067B772A-3943-456C-9BB7-2EC6D9DC9343',
        active: true,
    },
    {
        description: 'Vaccines',
        code: 'CAF6A8A3-87EA-4B28-8FB0-8FAACCBBE8E5',
        active: true,
    },
    {
        description: 'Hematology',
        code: 'B23BD45E-44DD-4CAF-B4DA-BC595BF260F3',
        active: true,
    },
    {
        description: 'Training',
        code: '1909E4F4-6471-4684-9207-0D0080E2CC96',
        active: true,
    },
    {
        description: 'N/A',
        code: 'DDF54406-7022-4331-997C-439D5BD0E46E',
        active: true,
    },
    {
        description: 'Urology',
        code: 'D2E81B6E-8A3E-4A84-9791-37D10C777097',
        active: true,
    },
    {
        description: 'Opthamology',
        code: '5A937E19-1A11-4F16-9AEE-C7DD376A6F95',
        active: true,
    },
    {
        description: 'Gynecology',
        code: '2F4FA489-76A8-42E0-BDD1-02689EF785C0',
        active: true,
    },
    {
        description: 'Respiratory',
        code: 'DC2F2DBF-790C-4D1C-AB08-834FD19BF82A',
        active: true,
    },
    {
        description: 'AI technology',
        code: 'AC9D752E-7AC1-48C8-B492-D4A8282377A6',
        active: true,
    },
    {
        description: 'Alcohol dependence',
        code: 'D295C4F9-3E51-4DD8-91E0-1E437A86A495',
        active: true,
    },
    {
        description: 'Blockchain architecture',
        code: '0CC99E00-39A5-4899-A1D1-F04D2118158D',
        active: true,
    },
    {
        description: 'Clinical Trial design',
        code: 'E8A54E96-3A85-4B75-AE85-DD6738FEC373',
        active: true,
    },
    {
        description: 'Device',
        code: 'E8EE6D92-115E-41C9-890B-7469E2B1FF5E',
        active: true,
    },
    {
        description: 'Genomics',
        code: 'FE734D77-1F5A-4D04-9AC7-A553678D7711',
        active: true,
    },
    {
        description: 'Pharmacokinetic (PK) model',
        code: '33992960-E515-4C7B-9D6C-FF02D89C8D09',
        active: true,
    },
];
