export enum TranslationKeyEnum {
    isDefault = 'isDefault',
    Vivli = 'Vivli',
    picoButton = 'picoButton',
    studies = 'studies',
    study = 'study',
    welcomeMessage = 'welcomeMessage',
    studyID = 'studyID',
    studyType = 'studyType',
    fileUploadInstructions = 'fileUploadInstructions',
    popUpWelcomeMessage = 'popUpWelcomeMessage',
    title = 'title',
    update = 'update',
    duaValidatedStatusString = 'duaValidatedStatusString',
    chatHelperText = 'chatHelperText',
    howToGuideLink = 'howToGuideLink',
    privacyLink = 'privacyLink',
    cookiePolicyLink = 'cookiePolicyLink',
    eeaDisclosurePolicyLink = 'eeaDisclosurePolicyLink',
    contactUsLink = 'contactUsLink',
    vmResearchEnvironmentConfig = 'vmResearchEnvironmentConfig',
    pathway2SubmitConfirmText = 'pathway2SubmitConfirmText',
    accessDataPackagesIPD = 'accessDataPackagesIPD',
    accessDPResearchEnvironment = 'accessDPResearchEnvironment',
}
