import React from 'react';
import {
    DataPackageBaseComponent,
    DataPackageComponent,
    DataPackageContext
} from "@vivli/shared/features/data-package/components";
import {useStudyContext} from "@vivli/features/studies/infrastructure/context";

interface DownloadOnlyDataPackageFeatureProps {
    dataPackageId?: string;
    //data package id for study IPD package
    secondaryDataPackageId?: string;
    datasetMetaDataDoi?: string;
    studyOrgId?: string;
    onReady?: () => void;
    onClose?: () => void;
    disallowDownload?: boolean;
    showPubliclyAvailable?: boolean;

}

export const DownloadOnlyDataPackageFeature = (
    {
        dataPackageId,
        secondaryDataPackageId,
        datasetMetaDataDoi,
        studyOrgId,
        onReady,
        onClose,
        disallowDownload,
        showPubliclyAvailable
    }: DownloadOnlyDataPackageFeatureProps
) => {

    const studyContext = (showPubliclyAvailable) ? useStudyContext() : null;
    return (
        <DataPackageBaseComponent dataPackageId={dataPackageId} secondaryDataPackageId={secondaryDataPackageId}
                                  filesRefresh={studyContext?.ipdRefresh}>
            <DataPackageContext.Consumer>
                {dataPackage => <DataPackageComponent
                    dataPackage={dataPackage}
                    readOnly={true}
                    allowDownload={!disallowDownload}
                    allowUpload={false}
                    allowSubmit={false}
                    hideFileType={!showPubliclyAvailable}
                    publiclyAvailableOrgId={showPubliclyAvailable ? 'FakeOrgId' : null}
                    onReady={onReady}
                    datasetMetaDataDoi={datasetMetaDataDoi}
                    studyOrgId={studyOrgId}
                    recordFileDownload={true}
                    onClose={onClose}
                />}
            </DataPackageContext.Consumer>
        </DataPackageBaseComponent>
    );
}

