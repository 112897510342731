import React, { CSSProperties, ReactNode } from 'react';
import { ICellRendererParams } from 'ag-grid-community';

const containerStyle: CSSProperties = {
    height: '100%',
    overflowX: 'hidden',
    overflowY: 'auto',
    padding: '10px 0',
};

const paragraphStyle: CSSProperties = {
    lineHeight: 1.2,
    wordBreak: 'break-word',
    margin: 0,
    marginBottom: '3px',
};

interface GridCellListComponentProps extends ICellRendererParams {}

//note: don't use this where you could have array/list data - it will blow up
// - use GridCellList instead
export const GridCellLargeTextComponent = ({
    value,
}: GridCellListComponentProps): ReactNode => {
    return (
        <div style={containerStyle} className="scrolly">
            {value
                ?.toString()
                ?.split('\n')
                ?.map((paragraph, i) => (
                    <p key={i} style={paragraphStyle}>
                        {paragraph}
                    </p>
                ))}
        </div>
    );
};
