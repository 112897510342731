import React from 'react';
import {TeamMemberDetailsFormComponent} from "./team-member-details-form.component";
import {IDataRequest, IResearchTeamMemberDetail} from "@vivli/features/data-requests/infrastructure/interface";
import {TeamMemberControlsFormComponent} from "./team-member-controls-form.component";

interface TeamMemberDraftFormComponentProps {
    isLoading?: boolean;
    readonly?: boolean;
    dataRequest: IDataRequest;
    fieldName?: string;
    subTitle: string
    setLeadIsStatistician?: (leadIsStatistician: boolean) => void;
    teamMember: IResearchTeamMemberDetail;
}

export const TeamMemberFormComponent = (
    {
        isLoading,
        dataRequest,
        fieldName,
        subTitle,
        readonly,
        setLeadIsStatistician,
        teamMember
    }: TeamMemberDraftFormComponentProps) => {
    let baseFieldName = '';

    if (fieldName) {
        baseFieldName = `${fieldName}.`;
    }

    return (
        <React.Fragment>
            <TeamMemberControlsFormComponent
                subTitle={subTitle}
                baseFieldName={baseFieldName}
                isLoading={isLoading}
                readonly={isLoading || readonly}
                setLeadIsStatistician={setLeadIsStatistician}
                teamMember={teamMember}
            />

            <TeamMemberDetailsFormComponent
                baseFieldName={baseFieldName}
                dataRequest={dataRequest}
                readonly={isLoading || readonly}
                teamMember={teamMember}
            />
        </React.Fragment>
    )
}
