﻿import React, {useEffect, useState} from "react";
import {IDropdownMenuItem} from "@vivli/shared/infrastructure/interface";
import {StudyRequestBehaviorEnum} from "@vivli/features/studies/infrastructure/enum";
import {DropdownFormFieldComponent, TextFormFieldComponent, twoAcrossStyle} from "@vivli/shared/components";
import {IStudy} from "@vivli/features/studies/infrastructure/interface";
import {Styles} from "@vivli/shared/theme";
import {DTICommonConst, DTICTFormFields} from "@vivli/shared/infrastructure/constants";


interface  StudyDatasetRequestTypeComponentProps {
    study?: IStudy,
}

export const StudyDatasetRequestTypeComponent = (
    {study}:StudyDatasetRequestTypeComponentProps  )  => {

    const [studyRequestBehaviorItems, setStudyRequestBehaviorItems] = useState<IDropdownMenuItem[]>();

    useEffect(() => {

        const requestTypeValues = Object.values(StudyRequestBehaviorEnum);
        const requestTypeKeys = Object.keys(StudyRequestBehaviorEnum);
        const menuItems = Object.keys(requestTypeValues).map<IDropdownMenuItem>(key => {
            return {
                title: requestTypeValues[key],
                value: requestTypeKeys[key]
            }
        })

        setStudyRequestBehaviorItems(menuItems);

    }, []);


    return (
    <div style={{width: '100%'}}>
        {<div style={Styles.FORM_ROW}>
            <DropdownFormFieldComponent
                name={`studyRequestBehavior`}
                label={"Process when Requested"}
                items={studyRequestBehaviorItems}
                style={twoAcrossStyle}
                objectKey={'name'}
                value={study.studyRequestBehavior}
                dataId={DTICommonConst.DropdownValue}

            />
            <TextFormFieldComponent
                name='externalStudyUri'
                label='URL to request item from External Contributor'
                style={twoAcrossStyle}
                readonly={false}
                hint={'For Pathway 2 - Copy the URL used by the external partner provider.'}
                dataId={DTICTFormFields.ExternalUrl}
            />
        </div>
        }
    </div>
    )
}
