﻿import React, {CSSProperties, ReactNode} from "react";
import {IContextWrapper, IModalApi} from "@vivli/shared/infrastructure/interface";
import {useDefaultApiOptions} from "@vivli/shared/infrastructure/hook";
import {DataPackageApiService} from "@vivli/shared/features/data-package/infrastructure/service";
import {IDataPackageService} from "@vivli/shared/features/data-package/infrastructure/interface";
import {DataPackageServiceContext} from "@vivli/shared/features/data-package/infrastructure/context";

const textStyle: CSSProperties = {
    textAlign: "left"
};

export const DataPackageServiceContextWrapper = ({children}: IContextWrapper) => {
    const apiOptions = useDefaultApiOptions();
    const api = new DataPackageApiService({
        ...apiOptions,
        errorHandler: null
    });

    const handleSubmitErrorWithFileCheck = (error: ReactNode, showFileNames: boolean, details:string, modalService: IModalApi) => {
        const errorString = error?.toString();
        const errorParts = errorString?.split("||");
        const prefix = errorParts?errorParts[0]:"";
        const errorFiles = errorParts.slice(2, -1).join("<br/>").replace('\\r\\n','');
        const errorFinal = `The following file(s) did not upload properly for ${details}: ${errorFiles} Please delete
          those files, close the browser, re-open the Vivli platform and re-upload those files.`;
        const copyingFilesErr = 'There was a problem copying the data for one of the studies for this request. ' +
            'Please notify the Vivli Technical team at support@vivli.org';
        const copyingFilesErrExpanded = copyingFilesErr + ' and include the following' +
            errorParts[errorParts.length-1].replace("\"",".");
        const errDiv = <div style ={textStyle} dangerouslySetInnerHTML={{__html: errorFinal}} />;


        if (errorParts.length > 1 && (prefix ==="\"!" || prefix ==="!")){

            if (showFileNames && errorParts.length >=4){
                //this is files missing
                modalService.error(errDiv);
                return;
            } else {
                //container is missing or generic message should be shown
                if (errorParts.length > 2 ) {
                    modalService.error(copyingFilesErrExpanded);
                } else {
                    modalService.error(copyingFilesErr);
                }

                return;
            }
        }

        modalService.error(error);
    }

    const provider: IDataPackageService = {
        ...api,
        handleSubmitErrorWithFileCheck
    }



    return <DataPackageServiceContext.Provider value={provider}>
        {children}
    </DataPackageServiceContext.Provider>
}
