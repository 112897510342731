import React from 'react';
import {AdvancedSelectComponent, AdvancedSubHeaderComponent} from "@vivli/shared/components";
import {inputGroupBreakStyle} from "../../search-filters.styles";
import {FilterGroupComponent} from "../../filter-group.component";
import {useCtSearchOptionsContext, useSearchContext} from "@vivli/features/search/infrastructure/context";

export const SponsorInfoFilterGroupComponent = () => {
    const {ctFilterValues} = useSearchContext();
    const {
        sponsorSelectedOptions,
        sponsorTypeSelectedOptions,
        setSponsorSelectedOptions,
        setSponsorTypeSelectedOptions
    } = useCtSearchOptionsContext();

    const sponsorOptions = ctFilterValues?.sponsorName.map(l => ({value: l.value, label: l.value}))
    const sponsorTypeOptions = ctFilterValues?.sponsorType.map(l => ({value: l.value, label: l.value}))

    return <FilterGroupComponent title={'SPONSOR INFORMATION'} noBottomPad={true}>
        <div style={inputGroupBreakStyle}>
            <AdvancedSubHeaderComponent title={'SPONSOR TYPE'}/>
            <AdvancedSelectComponent
                isMulti={true}
                isSearchable={true}
                onChange={setSponsorTypeSelectedOptions}
                options={sponsorTypeOptions}
                selectedOptions={sponsorTypeSelectedOptions}
            />
        </div>

        <div>
            <AdvancedSubHeaderComponent title={'SPONSOR'}/>
            <AdvancedSelectComponent
                isMulti={true}
                isSearchable={true}
                onChange={setSponsorSelectedOptions}
                options={sponsorOptions}
                selectedOptions={sponsorSelectedOptions}
            />
        </div>
    </FilterGroupComponent>
}
