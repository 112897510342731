import React, {useEffect, useRef, useState} from 'react';
import {GridComponent} from '@vivli/shared/components';
import {ColDef, GridApi, RowNode} from 'ag-grid-community';
import {
    IDataRequest,
    IDataRequestSummary,
    IRequestedStudy
} from '@vivli/features/data-requests/infrastructure/interface';
import {RequestedStudiesRowRenderer} from './requested-studies-row-renderer.component';

interface RequestedStudiesGridComponentProps {
    studies: IRequestedStudy[];
    dataRequests?: IDataRequestSummary[];
    dataRequest?: IDataRequest;
    onGridReady?: (gridApi: GridApi) => void;
    emptyMessage?: string;
    hideFloatingFilter?: boolean;
    hideHeader?: boolean;
    autoHeight?: boolean;
    asyncUpdateKey?: string;
    dataRefreshing?: boolean;
    onChange?: (dataRequest: IDataRequest) => void;
}

export const RequestedStudiesGridComponent = (
    {
        studies,
        onGridReady,
        emptyMessage,
        hideFloatingFilter,
        hideHeader,
        autoHeight,
        dataRequests,
        dataRequest,
        asyncUpdateKey,
        dataRefreshing,
        onChange
    }: RequestedStudiesGridComponentProps) => {
    const [rowData, setRowData] = useState<{ study: IRequestedStudy, dataRequest: IDataRequest }[]>();
    const gridApiRef = useRef<GridApi>();

    const handleOnGridReady = (gridApi: GridApi) => {
        onGridReady && onGridReady(gridApi);
        gridApiRef.current = gridApi;
    }

    const handleRemoveRow = (node: RowNode, dataRequest: IDataRequest) => {
        gridApiRef.current.applyTransaction({ remove: [node.data] });
        onChange && onChange(dataRequest);
    }

    const columnDefs: ColDef[] = [
        {
            field: 'studyTitle',
            headerName: 'Title',
            filter: true,
            sortable: true,
            cellRenderer: 'StudyRow',
            cellRendererParams: {
                onRemoveRow: handleRemoveRow
            }
        }
    ]

    const customComponents = [
        {
            name: 'StudyRow',
            component: RequestedStudiesRowRenderer
        }
    ]

    useEffect(() => {
        if ((!studies || !dataRequests) && !dataRequest) {
            return;
        }

        const newRowData = [];

        studies.forEach(study => {
            const drToUse = dataRequest || dataRequests.find(dr => dr.id === study.requestId);
            newRowData.push({study, dataRequest: drToUse || null});
        })

        setRowData(newRowData);
    }, [dataRequests, studies, dataRequest])

    return (
        <GridComponent
            rowData={rowData}
            fullWidthColumns={true}
            onGridReady={handleOnGridReady}
            columns={columnDefs}
            emptyMessage={emptyMessage}
            hideFloatingFilter={hideFloatingFilter}
            hideHeader={hideHeader}
            autoHeight={autoHeight}
            customComponents={customComponents}
            rowHeight={100}
            asyncUpdateKey={asyncUpdateKey}
            dataRefreshing={dataRefreshing}
        />
    )
}
