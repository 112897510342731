import React, {CSSProperties, useEffect, useState} from 'react';
import { EnquiresGridComponent} from "@vivli/features/enquiry/components";
import { useEnquiryListHook} from "@vivli/features/enquiry/infastructure/hook";
import {ExportCsvButtonComponent, TabComponent, TabsComponent} from "@vivli/shared/components";
import {AssetsConstant} from "@vivli/shared/infrastructure/constants";
import {GridApi} from "ag-grid-community";
import {useActiveUser} from "@vivli/core/infrastructure/context";
import {useNavigate} from "react-router-dom";
import {useUserPermissions} from "@vivli/features/users/infrastructure/hook";
import {Size} from "@vivli/shared/theme";
import {EnquiresTabEnum, EnquiryStatusEnum} from "@vivli/features/enquiry/infastructure/enum";
import {IEnquiry} from "@vivli/features/enquiry/infastructure/interface";

const containerStyle: CSSProperties = {
    height: '100%',
    padding: `0 ${Size.INSET_SIZE}px`,
};

enum tabEnum {
    draft ,
    formCheck ,
    accepted ,
    withdrawn,
    archived,
}
export const EnquiresFeature = () => {
    const [formCheckEnquires, setFormCheckEnquires] = useState<IEnquiry[]>();
    const [draftEnquires, setDraftEnquires] = useState<IEnquiry[]>();
    const [acceptedEnquires, setAcceptedEnquires] = useState<IEnquiry[]>();
    const [archivedEnquiries, setArchivedEnquiries] = useState<IEnquiry[]>();
    const [withdrawnEnquiries, setWithdrawnEnquiries] = useState<IEnquiry[]>();

    const [activeTab, setActiveTab] = useState<number>(tabEnum.draft);

    const [formCheckGridApi, setFormCheckGridApi] = useState<GridApi>(null);
    const [draftGridApi, setDraftGridApi] = useState<GridApi>(null);
    const [acceptedGridApi, setAcceptedGridApi] = useState<GridApi>(null);
    const [archivedGridApi, setArchivedGridApi] = useState<GridApi>(null);
    const [withdrawnGridApi, setWithdrawnGridApi] = useState<GridApi>(null);

    const user = useActiveUser();
    const navigate = useNavigate();
    const userPermissions = useUserPermissions();
    const {enquires, isLoadingEnquires} = useEnquiryListHook();


    const showTitle = `Add Enquiry`;

    const setInitialTab = () => {
        const currentPath = window.location.pathname;
        const routePath = currentPath
            .replace('/admin/enquires/', '')
            ?.toLowerCase();

        if (!routePath) {
            return;
        }

        switch (routePath) {
            case 'formCheck':
                setActiveTab(tabEnum.formCheck);
                break;
            case 'accepted':
                setActiveTab(tabEnum.accepted);
                break;
            case 'withdrawn':
                setActiveTab(tabEnum.withdrawn);
                break;
            case 'archived':
                setActiveTab(tabEnum.archived)
            default :
                setActiveTab(tabEnum.draft);
                break;
        }
    };

    const exportColumnKeysCommon = [
        'id',
        'requesterName',
        'enquiryPurpose',
        'submittedDate',
        'statusForDownload',
        'dataInfoRequestsCount'
    ];

    const exportColumnKeysReview = [
        'id',
        'requesterName',
        'enquiryPurpose',
        'updatedDate',
        'submittedDate',
        'statusForDownload',
        'dataInfoRequestsCount'
    ];

    const exportColumnKeysDraft = [
        'id',
        'requesterName',
        'enquiryPurpose',
        'createdDate',
        'statusForDownload',
        'dataInfoRequestsCount'
    ];

    const exportToCsv = (tab: tabEnum) => {
        const dateStr = new Date().toLocaleDateString().replace('/', '_');
        switch (tab) {
            case tabEnum.formCheck:
                formCheckGridApi.exportDataAsCsv({
                    fileName: `Form_Check_Enquiries_${dateStr}`,
                    columnKeys: exportColumnKeysCommon,
                });
                break;
            case tabEnum.accepted:
                acceptedGridApi.exportDataAsCsv({
                    fileName: `Accepted_Enquiries_${dateStr}`,
                    columnKeys: exportColumnKeysReview,
                });
                break;
            case tabEnum.draft:
                draftGridApi.exportDataAsCsv({
                    fileName: `Draft_Enquiries_${dateStr}`,
                    columnKeys: exportColumnKeysDraft,
                });
                break;
            case tabEnum.withdrawn:
                withdrawnGridApi.exportDataAsCsv({
                    fileName: `Withdrawn_Enquiries_${dateStr}`,
                    columnKeys: exportColumnKeysCommon,
                });
                break;
            case tabEnum.archived:
                archivedGridApi.exportDataAsCsv({
                    fileName: `Archived_Enquiries_${dateStr}`,
                    columnKeys: exportColumnKeysCommon,
                });
                break;
        }
    };

    useEffect(() => {
        setInitialTab();
    }, []);

    useEffect(() => {
        if (!enquires) {
            return;
        }

        const formCheckEnquires = enquires?.filter(
            (s) => s.status === EnquiryStatusEnum.EnquiryValidation
        );
        const draftEnquires = enquires?.filter(
            (s) => s.status === EnquiryStatusEnum.Draft
        );
        const acceptedEnquires = enquires?.filter(
            (s) => s.status == EnquiryStatusEnum.Review
        );
        const withdrawnEnquiries = enquires?.filter(
            (s) => s.status == EnquiryStatusEnum.Withdrawn
        );
        const archivedEnquiries = enquires?.filter(
            (s) => s.status == EnquiryStatusEnum.Archived
        );

        setAcceptedEnquires(acceptedEnquires);
        setDraftEnquires(draftEnquires);
        setFormCheckEnquires(formCheckEnquires);
        setWithdrawnEnquiries(withdrawnEnquiries);
        setArchivedEnquiries(archivedEnquiries);
    }, [enquires]);


    return (
        <div style={containerStyle}>
            <TabsComponent
                defaultTab={activeTab}
                commandImage={AssetsConstant.PLUS_SYMBOL}
                commandTitle={showTitle}
                title={'Enquiries about Vivli Member Studies'}
                commandOnClick={() => {
                        navigate('/admin/enquiry');
                }}
            >
                <TabComponent
                    title={EnquiresTabEnum.Draft}
                    count={draftEnquires?.length}
                    onTabClick={() => {
                        navigate('/admin/enquiries/draft');
                    }}
                    rightHeaderContent={
                        <ExportCsvButtonComponent
                            onClick={() => exportToCsv(tabEnum.draft)}
                        />
                    }
                >
                    <EnquiresGridComponent
                        type="Drafted"
                        enquires={draftEnquires}
                        onGridReady={setDraftGridApi}
                        dataRefreshing={isLoadingEnquires}
                        hideCreatedColumn={false}
                        hideUpdatedColumn={true}
                        hideDateColumn={true}
                    />
                </TabComponent>
                <TabComponent
                    title={EnquiresTabEnum.EnquiryValidation}
                    count={formCheckEnquires?.length}
                    onTabClick={() => {
                        navigate('/admin/enquiries/formCheck');
                    }}
                    rightHeaderContent={
                        <ExportCsvButtonComponent
                            onClick={() => exportToCsv(tabEnum.formCheck)}
                        />
                    }
                >
                    <EnquiresGridComponent
                        type="Submitted"
                        enquires={formCheckEnquires}
                        onGridReady={setFormCheckGridApi}
                        dataRefreshing={isLoadingEnquires}
                        hideUpdatedColumn={true}
                        hideCreatedColumn={true}
                    />
                </TabComponent>
                <TabComponent
                    title={EnquiresTabEnum.Review}
                    count={acceptedEnquires?.length}
                    onTabClick={() => {
                        navigate('/admin/enquiries/accepted');
                    }}
                    rightHeaderContent={
                        <ExportCsvButtonComponent
                            onClick={() => exportToCsv(tabEnum.accepted)}
                        />
                    }
                >
                    <EnquiresGridComponent
                        type="Submitted"  //we do not have a date stored that reliably has Submitted for Review value
                        enquires={acceptedEnquires}
                        onGridReady={setAcceptedGridApi}
                        dataRefreshing={isLoadingEnquires}
                        hideCreatedColumn={true}
                        hideUpdatedColumn={false}
                        hideDateColumn={false}
                    />
                </TabComponent>
                <TabComponent
                    title={EnquiresTabEnum.Withdrawn}
                    count={withdrawnEnquiries?.length}
                    onTabClick={() => {
                        navigate('/admin/enquiries/accepted');
                    }}
                    rightHeaderContent={
                        <ExportCsvButtonComponent
                            onClick={() => exportToCsv(tabEnum.withdrawn)}
                        />
                    }
                >
                    <EnquiresGridComponent
                        type="Submitted"
                        enquires={withdrawnEnquiries}
                        onGridReady={setWithdrawnGridApi}
                        dataRefreshing={isLoadingEnquires}
                        hideUpdatedColumn={true}
                        hideCreatedColumn={true}
                    />
                </TabComponent>
                <TabComponent
                    title={EnquiresTabEnum.Archived}
                    count={archivedEnquiries?.length}
                    onTabClick={() => {
                        navigate('/admin/enquiries/accepted');
                    }}
                    rightHeaderContent={
                        <ExportCsvButtonComponent
                            onClick={() => exportToCsv(tabEnum.archived)}
                        />
                    }
                >
                    <EnquiresGridComponent
                        type="Submitted"
                        enquires={archivedEnquiries}
                        onGridReady={setArchivedGridApi}
                        dataRefreshing={isLoadingEnquires}
                        hideUpdatedColumn={true}
                        hideCreatedColumn={true}
                    />
                </TabComponent>
            </TabsComponent>
        </div>
    );
}
