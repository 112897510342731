import React from 'react';
import { AnonymousApiServiceContextWrapper, ConfigServiceContextWrapper, UserContextWrapper } from '@vivli/core/infrastructure/wrappers';
import { ServicesWrapper } from './services.wrapper';
import { ModulesWrapper } from './modules.wrapper';
import { AuthWrapper } from './auth.wrapper';
import { AuthEventsWrapper } from './auth-events.wrapper';
import { SharedDataContextWrapper } from '@vivli/features/data-requests/infrastructure/wrappers';
import { AcceptInvitePopupWrapper } from './accept-invite-popup.wrapper';
import { QuickSearchContextWrapper, SearchContextWrapper } from '@vivli/features/search/infrastructure/wrappers';
import { ContextInjectorComponent } from '@vivli/shared/components';
import { IContextWrapper } from '@vivli/shared/infrastructure/interface';
import { LoginExpirationWrapper } from './login-expiration.wrapper';
import { GlobalStylesWrapper } from './global-styles.wrapper';

const contextWrappers = [
    <GlobalStylesWrapper />,
    <AnonymousApiServiceContextWrapper options={{ baseUrl: '/' }} />,
    <ConfigServiceContextWrapper />,
    <AuthWrapper />,
    <LoginExpirationWrapper />,
    <ServicesWrapper />,
    <UserContextWrapper />,
    <ModulesWrapper />,
    <AuthEventsWrapper />,
    <AcceptInvitePopupWrapper />,
    <SharedDataContextWrapper />,
    <SearchContextWrapper />,
    <QuickSearchContextWrapper />,
];

export const InitializationWrapper = ({ children }: IContextWrapper) => {
    return <ContextInjectorComponent contextWrappers={contextWrappers}>{children}</ContextInjectorComponent>;
};
