﻿import {useLocation} from "react-router";
import {useOrganizations} from "@vivli/features/organizations/infrastructure/hook";
import React from "react";
import {UserDetailsComponent} from './user-details.component';
import {FormProvider} from "react-hook-form";
import {useUserInfoFormHook} from "@vivli/features/users/infrastructure/hook";
import {UserInfoContextWrapper} from "@vivli/features/users/infrastructure/wrappers";


export const UserDetailsFeature = () => {

    const {organizations} = useOrganizations();
    const {state} = useLocation()
    const formApi = useUserInfoFormHook(state.userRole)


    return (
        <FormProvider {...formApi} >
            <UserInfoContextWrapper>
                <UserDetailsComponent
                    currentUser={state}
                    organizations={organizations}
                />
            </UserInfoContextWrapper>
        </FormProvider>

    )

}
