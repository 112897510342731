import React, { CSSProperties } from 'react';
import { ButtonComponent } from '@vivli/shared/components';
import { useResearchEnvironmentContext } from '@vivli/features/data-requests/infrastructure/context';
import { Color } from '@vivli/shared/theme';
import { AssetsConstant } from '@vivli/shared/infrastructure/constants';

interface VmFailureComponent {
    isRedButton?: boolean;
    onClick: () => void;
    title: string;
}

export const VmFailureComponent = ({
    isRedButton,
    onClick,
    title,
}: VmFailureComponent) => {
    const { isWorking } = useResearchEnvironmentContext();

    const buttonStyle: CSSProperties = {
        minHeight: 50,
        paddingLeft: 50,
        backgroundImage: `url(${AssetsConstant.WHITE_CHECK_MARK})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '20px',
        backgroundColor: isRedButton ? Color.VIVLI_RED : Color.TEXT_DARK_BLUE,
        marginLeft: 'auto',
        marginRight: 'auto',
    };

    const messsage = `Vivli has been notified and will look into this failure.
    You will be notified when the problem is resolved.
    You may also retry by clicking the button below...`;

    return (
        <div>
            <br />
            <br />
            {messsage}
            <br />
            <br />
            <br />
            <ButtonComponent
                style={buttonStyle}
                isLoading={isWorking}
                onClick={onClick}
                className="vmFailMessage_continue"
            >
                {title}
            </ButtonComponent>
        </div>
    );
};
