import React, {createContext, useContext} from "react";
import {IUser} from "@vivli/shared/infrastructure/interface";

export const UserContext = createContext<IUser>(null);

export const useActiveUser = () => useContext(UserContext);




