import React from 'react';
import {IDataRequest, IResearchTeamMemberDetail} from "@vivli/features/data-requests/infrastructure/interface";
import {FormProvider} from 'react-hook-form';
import {TeamMemberFormComponent} from "./team-member-form.component";
import {
    useResearcherSubtitle,
    useResearchTeamMemberForm,
    useResearchTeamPermissions
} from "@vivli/features/data-requests/infrastructure/hook";
import {TeamMemberModalButtonsComponent} from "./team-member-modal-buttons.component";

const wrapperStyle = {
    padding: '15px',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'rgb(233, 234, 239)',
} as React.CSSProperties;

interface TeamMemberModalComponentProps {
    teamMember: IResearchTeamMemberDetail;
    readonly?: boolean;
    isLoading?: boolean;
    onCancel?: () => void;
    onSave?: (updatedTeamMember: IResearchTeamMemberDetail) => void;
    dataRequest: IDataRequest;
}

export const TeamMemberModalComponent = (
    {
        teamMember,
        onCancel,
        onSave,
        isLoading,
        dataRequest,
        readonly
    }: TeamMemberModalComponentProps) => {
    const {userCanEdit} = useResearchTeamPermissions(dataRequest);
    const formApi = useResearchTeamMemberForm(teamMember);
    const getSubtitle = useResearcherSubtitle();

    const handleSaveClick = () => {
        formApi.handleSubmit((formValues) => {
            const updatedTeamMember = {...teamMember, ...formValues};
            onSave && onSave(updatedTeamMember);
        })()
    }

    const subTitle = getSubtitle(teamMember);

    return (
        <div style={wrapperStyle}>
            <FormProvider {...formApi}>
                <TeamMemberFormComponent
                    isLoading={isLoading}
                    dataRequest={dataRequest}
                    subTitle={subTitle}
                    readonly={readonly}
                    teamMember={teamMember}
                />
            </FormProvider>

            <TeamMemberModalButtonsComponent
                onCancel={onCancel}
                onSave={handleSaveClick}
                showSaveButton={userCanEdit && !readonly}
                isLoading={isLoading}
            />
        </div>
    );
}
