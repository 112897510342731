import {StorageService} from "./storage.service";
import {environment} from "@vivli/core/environments";

const activeKeyStorageName = 'current-debug-key';

enum ErrorTypeEnum {
    All = 'all',
    Info = 'info',
    Error = 'error',
    Warn = 'warn',
    Debug = 'debug'
}

const getActiveKey = (): string => {
    const params = new URLSearchParams(window.location.search);

    let key = params.get('debug-key');

    // logger bypass for local development
    if (environment.isLoggerEnabled) {
        key = 'all'
    }

    if (key) {
        StorageService.setItem(activeKeyStorageName, key, 'session');
    } else {
        key = StorageService.getItem<string>(activeKeyStorageName, 'session');
    }

    return key;
}

export abstract class LoggerService {

    public static info = (message: any, ...optionalParams: any[]) => {
        const activeKey = getActiveKey();
        if (activeKey === ErrorTypeEnum.Info || activeKey === ErrorTypeEnum.All) {
            console.info(message, optionalParams)
        }
    }

    public static error = (message: any, ...optionalParams: any[]) => {
        const activeKey = getActiveKey();
        if (activeKey === ErrorTypeEnum.Error || activeKey === ErrorTypeEnum.All) {
            console.error(message, optionalParams);
        }
    }

    public static warn = (message: any, ...optionalParams: any[]) => {
        const activeKey = getActiveKey();
        if (activeKey === ErrorTypeEnum.Warn || activeKey === ErrorTypeEnum.All) {
            console.warn(message, optionalParams);
        }
    }

    public static debug = (message: any, ...optionalParams: any[]) => {
        const activeKey = getActiveKey();
        if (activeKey === ErrorTypeEnum.Debug || activeKey === ErrorTypeEnum.All) {
            console.debug(message, optionalParams);
        }
    }

    public static log = (message: any, ...optionalParams: any[]) => {
        const activeKey = getActiveKey();
        if (activeKey === ErrorTypeEnum.All) {
            console.log(message, optionalParams);
        }
    }
}
