import tinycolor from 'tinycolor2';
import {PicoTypeEnum} from '@vivli/shared/infrastructure/enum';

const Color = {
    WHITE: tinycolor('rgb( 255, 255, 255)').toRgbString(),
    BLACK: tinycolor('rgb( 0, 0, 0)').toRgbString(),
    ERROR_RED: tinycolor('rgb( 186, 65, 101)').toRgbString(),
    TRANSPARENT: tinycolor('transparent').toRgbString(),
    WHITE_TINTED: tinycolor('rgb( 246, 247, 251)').toRgbString(),
    LIGHT_GRAY: tinycolor('rgb( 233, 234, 239)').toRgbString(),
    LIGHTER_GRAY: tinycolor('rgb( 175, 195, 205)').toRgbString(),
    DARK_GRAY: tinycolor('rgb( 96, 104, 111)').toRgbString(),
    HOVER_GRAY: tinycolor('rgb( 228, 231, 243)').toRgbString(),
    TRANSPARENT_ONE: tinycolor('rgba(0, 0, 0, 0.1)').toRgbString(),
    VIVLI_LIGHT_BLUE: tinycolor('rgb( 0, 149, 199)').toRgbString(),
    VIVLI_BLUE: tinycolor('rgb( 7, 79, 112)').toRgbString(),
    VIVLI_DARK_BLUE: tinycolor('rgb( 5, 61, 88)').toRgbString(),
    TEXT_LIGHT_BLUE: tinycolor('rgb( 81, 180, 213)').toRgbString(),
    TEXT_DARK_BLUE: tinycolor('rgb( 23, 150, 197)').toRgbString(),
    TEXT_GRAY: tinycolor('rgb( 176, 176, 176)').toRgbString(),
    TEXT_DARK_GRAY: tinycolor('rgb( 68, 68, 68)').toRgbString(),
    VIVLI_GREEN: tinycolor('rgb( 132, 189, 0)').toRgbString(),
    VIVLI_DARKER_GREEN: tinycolor('rgb( 0, 128, 0)').toRgbString(),
    VIVLI_TEAL: tinycolor('rgb( 106, 191, 188)').toRgbString(),
    VIVLI_GREY: tinycolor('rgb( 175, 195, 205)').toRgbString(),
    VIVLI_RED: tinycolor('rgb( 186, 65, 101)').toRgbString(),
    VIVLI_YELLOW: tinycolor('rgb( 224, 201, 24)').toRgbString(),
    ADVANCED_COLOR: tinycolor('rgb(209, 220, 226)').darken(20).toRgbString(),
    ADVANCED_MENU_TARGET_BACKGROUND_COLOR: tinycolor('rgb(246, 247, 251)').toRgbString(),
    Pico: {
        [PicoTypeEnum.None]: tinycolor('#9E9E9E').toRgbString(),
        [PicoTypeEnum.P]: tinycolor('#5157A6').toRgbString(),
        [PicoTypeEnum.I]: tinycolor('#0097C6').toRgbString(),
        [PicoTypeEnum.C]: tinycolor('#ED8B00').toRgbString(),
        [PicoTypeEnum.O]: tinycolor('#84BD00').toRgbString(),
    }
};

export { Color };
