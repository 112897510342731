import React, { CSSProperties, useState } from 'react';
import { ButtonComponent } from '@vivli/shared/components';
import { Color } from '@vivli/shared/theme';
import {
    useDataRequestContext,
    useDataRequestsService,
} from '@vivli/features/data-requests/infrastructure/context';
import {
    ApprovalStatusEnum,
    DataRequestStatusEnum,
} from '@vivli/features/data-requests/infrastructure/enum';
import { useDataRequestPermissions } from '@vivli/features/data-requests/infrastructure/hook';
import { finalize, first } from 'rxjs/operators';
import { useModalService } from '@vivli/shared/infrastructure/context';
import { GetOrgIdResult } from '@vivli/features/data-requests/infrastructure/interface';
import {AssetsConstant, DTICommonConst} from '@vivli/shared/infrastructure/constants';

const buttonStyle: CSSProperties = {
    backgroundColor: 'white',
    backgroundImage: `url(${AssetsConstant.YELLOW_X_ICON})`,
    backgroundPosition: 'center left 15px',
    backgroundRepeat: 'no-repeat',
    borderBottom: '1px solid',
    borderLeft: '1px solid',
    borderRight: '1px solid',
    borderTop: '1px solid',
    borderColor: Color.VIVLI_YELLOW,
    boxShadow: 'none',
    color: Color.VIVLI_YELLOW,
    marginRight: 10,
    maxHeight: 40,
    paddingLeft: 30,
};

interface SoftRejectButtonComponentProps {
    getOrgId: (orgIds: string[]) => Promise<GetOrgIdResult>;
}

export const SoftRejectButtonComponent = ({
    getOrgId,
}: SoftRejectButtonComponentProps) => {
    const [isLoading, setIsLoading] = useState(false);
    const {
        isUpdatingStatus,
        setIsUpdatingStatus,
        dataRequest,
        updateDataRequest,
    } = useDataRequestContext();
    const { getAllowedTransitions } = useDataRequestPermissions();
    const dataRequestsService = useDataRequestsService();
    const modalService = useModalService();

    const updateLoadingStatus = (status: boolean) => {
        setIsUpdatingStatus(status);
        setIsLoading(status);
    };

    const handleFinally = () => updateLoadingStatus(false);

    const handleError = (e) => {
        handleFinally();
        modalService.error(e);
    };

    const handleSoftRejectSubmission = (
        orgId: string,
        state: DataRequestStatusEnum
    ) => {
        modalService.confirm(
            'Please use the chat to describe any revisions that you are requesting.',
            {
                title: 'Request Revision Comment',
                promptLabel: 'Revisions Requested',
                showTextAreaPrompt: true,
                confirmText: 'Ok',
                cancelText: 'Cancel',
                onConfirm: (result) => {
                    if (!result) {
                        handleFinally();
                        return;
                    }

                    if (
                        dataRequest.status ===
                            DataRequestStatusEnum.AwaitingDataProviderApproval ||
                        dataRequest.status ===
                            DataRequestStatusEnum.AwaitingIRPApproval
                    ) {
                        return dataRequestsService
                            .approveRequest(
                                dataRequest.id,
                                result.comment,
                                orgId,
                                null,
                                ApprovalStatusEnum.RevisionsRequested
                            )
                            .pipe(first(), finalize(handleFinally))
                            .subscribe(updateDataRequest, modalService.error);
                    } else {
                        return dataRequestsService
                            .setDataRequestStatus(
                                dataRequest.id,
                                state,
                                result.comment
                            )
                            .pipe(first(), finalize(handleFinally))
                            .subscribe(updateDataRequest, modalService.error);
                    }
                },
            }
        );
    };

    const handleSoftReject = () => {
        const state: DataRequestStatusEnum =
            getAllowedTransitions(dataRequest).softReject;
        if (!state) {
            return;
        }

        updateLoadingStatus(true);
        dataRequestsService
            .getApprovalOrgs(dataRequest.id)
            .pipe(first())
            .subscribe((orgIds) => {
                getOrgId(orgIds)
                    .then((result) => {
                        return handleSoftRejectSubmission(result.orgId, state);
                    })
                    .finally(handleFinally);
            }, handleError);
    };

    return (
        <ButtonComponent
            style={buttonStyle}
            onClick={handleSoftReject}
            isLoading={isLoading}
            disabled={isUpdatingStatus}
            className="approveReject_softReject"
            dataId={DTICommonConst.SoftRejectButton}
        >
            Request Revision
        </ButtonComponent>
    );
};
