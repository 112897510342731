import {useForm} from 'react-hook-form';
import {IResearchTeamMemberDetail} from '@vivli/features/data-requests/infrastructure/interface';
import joi, {ObjectSchema} from 'joi';
import {joiResolver} from '@hookform/resolvers/joi';
import {JoiValidationOptionsConstant, OrcidRegex} from '@vivli/shared/infrastructure/constants';
import {orcidInputSchema} from "@vivli/shared/infrastructure/schema";

export const researchTeamMemberFormSchema: ObjectSchema<IResearchTeamMemberDetail> =
    joi
        .object({
            firstName: joi
                .string()
                .required()
                .min(1)
                .max(500)
                .label('First Name'),
            lastName: joi
                .string()
                .required()
                .min(1)
                .max(500)
                .label('Last Name'),
            position: joi.string().required().min(1).max(500).label('Position'),
            employerOrAffiliation: joi
                .string()
                .required()
                .min(1)
                .max(500)
                .label('Employer or Affiliation'),
            country: joi
                .object({
                    code: joi.string(),
                    name: joi.string(),
                    numberSites: joi.optional(),
                })
                .required()
                .label('Country Name'),
            educationOrQualifications: joi
                .string()
                .required()
                .min(1)
                .max(1000)
                .label('Education or Qualifications'),
            email: joi
                .string()
                .required()
                .email({tlds: {allow: false}})
                .min(1)
                .max(500)
                .label('Email'),
            orcidId: orcidInputSchema('ORCID iD', false),
            conflictsOfInterest: joi
                .string()
                .required()
                .min(1)
                .max(15000)
                .label('Conflicts of Interest'),
        })
        .options({allowUnknown: true});

export function useResearchTeamMemberForm(
    teamMember: IResearchTeamMemberDetail,
    mode: 'onBlur' | 'onChange' | 'onTouched' | 'all' = 'onChange'
) {
    return useForm({
        mode,
        resolver: joiResolver(
            researchTeamMemberFormSchema.options(JoiValidationOptionsConstant)
        ),
        defaultValues: teamMember,
        reValidateMode: 'onChange',
    });
}
