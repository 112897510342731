import React from 'react';
import {AdminHeaderButtonComponent} from "@vivli/shared/components";
import {useModalService} from "@vivli/shared/infrastructure/context";
import {AmrDataRequestPrintViewComponent} from "./amr-data-request-print-view.component";
import {useDataRequestContext} from "@vivli/features/data-requests/infrastructure/context";

export const AmrPrintButtonComponent = () => {
    const modalService = useModalService();
    const {dataRequest} = useDataRequestContext();

    const handlePrintClick = () => {
        const modalId = modalService.custom(
            <AmrDataRequestPrintViewComponent
                dataRequest={dataRequest}
                onReady={() => {
                    window.print();
                    modalService.dismiss(modalId);
                }}
            />,
            {
                style: { textAlign: "left" },
                fullScreen: true
            }
        )
    }

    return (
        <AdminHeaderButtonComponent
            title={'Print'}
            style={{marginLeft: 10}}
            onClick={handlePrintClick}
        />
    )
}
