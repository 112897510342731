import {useActiveUser} from "@vivli/core/infrastructure/context";
import {
    DataRequestStatusEnum,
    StudyRequestStatusEnum,
    VmAdminApprovalStatusEnum
} from "@vivli/features/data-requests/infrastructure/enum";
import {IDataRequest, IRequestedStudy} from "@vivli/features/data-requests/infrastructure/interface";
import {useAssignedAppType} from "@vivli/core/infrastructure/hook";
import {AssignedAppTypeEnum} from "@vivli/shared/infrastructure/enum";

export function useRequestedStudyPermissions() {
    const activeUser = useActiveUser();
    const appType = useAssignedAppType();

    const getResearchTeamMemberForCurrentUser = (dataRequest: IDataRequest) => {
        return dataRequest?.researchTeam?.researchTeamDetails?.find(rtd => rtd.userId === activeUser.userId)
    }

    //this just checks whether the requested study IPD meets downloadable prerequisites -
    //either because it's a downloadable package (so it will eventually be downloadable
    //by requesters) or because IPD has already been provided for it (in which case
    //its owner would be able to download it). This doesn't mean the current user
    //will be able to download it - that is tested by userCanDownloadIpd
    const requestedStudyIpdIsDownloadable = (requestedStudy: IRequestedStudy) => {
        let isDownloadable = false;
        const hasDataPackageId = requestedStudy?.ipdDataPackageId !== null;
        if (hasDataPackageId) {
            isDownloadable = requestedStudy?.downloadableRequestedStudyIPDDataPackage ||
                requestedStudy.studyRequestStatus === StudyRequestStatusEnum.IPDProvided;
        }
       return isDownloadable;
    }

    const userIsInStudyOrg = (requestedStudy: IRequestedStudy) => {
        return activeUser?.orgMemberships.some(o => o.orgId === requestedStudy.organizationId);
    }

    const userIsOrgAdmin = (requestedStudy: IRequestedStudy) => {
        let isOrgAdmin = false;
        const testOrgAdmin = activeUser?.orgMemberships.some(o => o.orgId === requestedStudy.organizationId && o.isOrgAdmin);
        if (!!testOrgAdmin) {
            isOrgAdmin = testOrgAdmin;
        }
        return isOrgAdmin;
    }


    const userIsOrgDataProvider = (requestedStudy: IRequestedStudy) => {
        return activeUser?.orgMemberships.some(o => o.orgId === requestedStudy.organizationId && o.isDataProvider)
    }

    const userIsDataProviderOrgAdmin = (requestedStudy: IRequestedStudy) => {
        return (userIsOrgDataProvider(requestedStudy) && userIsOrgAdmin(requestedStudy));
    }

    const userIsDatasetOwner = (requestedStudy: IRequestedStudy) => {
        return activeUser?.orgMemberships.some(o => o.orgId === requestedStudy.organizationId
            && (o.isDataProvider && o.isDatasetOwner));
    }

    const userOwnsStudy = (requestedStudy: IRequestedStudy) => {
        const isOwner = userIsDatasetOwner(requestedStudy) || userIsDataProviderOrgAdmin(requestedStudy);
        return isOwner;
    }

    const userIsDuaValidated = (dataRequest: IDataRequest) => {
        if (appType !== AssignedAppTypeEnum.Amr)
        {
            const teamMember = getResearchTeamMemberForCurrentUser(dataRequest);

            return teamMember?.adminApprovalStatus === VmAdminApprovalStatusEnum.Approved ||
                teamMember?.adminApprovalStatus === VmAdminApprovalStatusEnum.Automatic
        } else {
            //Amr has no research team members - user is always DUA validated
            //once data request DUA approval status has been reached
            let duaApprovalStage = false;
            switch (dataRequest.status){
                case DataRequestStatusEnum.DUAValidated:
                case DataRequestStatusEnum.PartiallyFulfilled:
                case DataRequestStatusEnum.Fulfilled:
                    duaApprovalStage = true;
                    break;
            }
            return duaApprovalStage;
        }

    }

    const userCanDownloadIpd = (requestedStudy: IRequestedStudy, dataRequest: IDataRequest) => {
        const activeUserHasDownloadPermissions =
            appType === AssignedAppTypeEnum.Amr
                ? true  //anyone can download, even a Vivli Admin
                : !activeUser.isVivliAdmin;
        return requestedStudyIpdIsDownloadable(requestedStudy) &&
            activeUserHasDownloadPermissions &&
            ( //2 cases: 1) a validated data requester for a downloadable study 2) the study's owner
                (userIsDuaValidated(dataRequest) && requestedStudy.downloadableRequestedStudyIPDDataPackage) ||
                userOwnsStudy(requestedStudy)
            )
    }

    return {
        userCanDownloadIpd,
        userIsInStudyOrg,
        userIsDuaValidated,
        requestedStudyIpdIsDownloadable,
        userIsOrgDataProvider,
        userIsDataProviderOrgAdmin,
        userIsDatasetOwner,
        userOwnsStudy,
    }
}
