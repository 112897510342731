import {CSSProperties} from "react";
import {Size, Styles} from "@vivli/shared/theme";

export const buttonStyle: CSSProperties = {
    paddingLeft: 0,
    paddingRight: 0,
    marginRight: 5,
    minWidth: 'inherit',
    width: 55,
    height: 55,
    ...Styles.borderRadius((Size.SEARCH_HEIGHT - Size.PADDING) / 2),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 8,
    backgroundColor: '#0097c6',
}

export const buttonIconStyle: CSSProperties = {
    width: '25px'
}
