﻿import {useDataRequestPermissions} from './use-data-request-permissions.hook';
import {useActiveUser} from '@vivli/core/infrastructure/context';
import {useAssignedAppType} from '@vivli/core/infrastructure/hook';
import {AssignedAppTypeEnum} from '@vivli/shared/infrastructure/enum';
import {DataRequestPermissionsService} from '@vivli/features/data-requests/infrastructure/service';
import {DataRequestStatusEnum} from '@vivli/features/data-requests/infrastructure/enum';
import {useParams} from 'react-router-dom';
import {IDataRequest} from '@vivli/features/data-requests/infrastructure/interface';
import {IOrganization} from '@vivli/features/organizations/infrastructure/interface';

export function useDataRequestDefaultTabHook() {
    const {userIsContributorOrIRPApprover} = useDataRequestPermissions();
    const activeUser = useActiveUser();
    const {requestStatus} = useParams();
    const assignedAppType = useAssignedAppType();
    const isAmr = assignedAppType === AssignedAppTypeEnum.Amr;

    const getAmrRowClick = (isDraft: boolean, isContributorOrIRPApprover: boolean, isOwner: boolean) => {
        const isContributorIrpOrNotDraft = isContributorOrIRPApprover || !isDraft;
        const secondaryAmrPath = isContributorIrpOrNotDraft ? 'printview' : 'request';
        const amrTabPath = activeUser.isVivliAdmin && !isOwner ? 'requestprintview' : secondaryAmrPath;
        return amrTabPath;
    };

    const getDefaultTabRoute = (dataRequest: IDataRequest, organizations: IOrganization[]) => {
        const teamMember = dataRequest.researchTeam.researchTeamDetails.find((x) => x.userId === activeUser.userId);
        const shouldHideReviews = DataRequestPermissionsService.shouldHideReviewers(dataRequest, activeUser, organizations);
        const isContributorOrIRPApprover = userIsContributorOrIRPApprover(dataRequest, organizations);
        const isDraft = dataRequest.status === DataRequestStatusEnum.Draft;
        const isOwner = dataRequest.userId === activeUser.userId;
        const navTabPath = isDraft && (teamMember || activeUser.isVivliAdmin || isOwner) && !shouldHideReviews ? 'researchTeam' : 'chat';
        const amrTabPath = getAmrRowClick(isDraft, isContributorOrIRPApprover, isOwner);
        const chosenTab = isAmr ? amrTabPath : navTabPath;
        const orgAdmin = activeUser.orgMemberships?.some(om => organizations.some(o => o.id === om.orgId && om.isOrgAdmin))
        const chosenRoute = isDraft && (!activeUser.isVivliAdmin && !orgAdmin || isOwner) ? 'myDataRequestDetails' : 'admindatarequests';

        return `/${chosenRoute}/${requestStatus}/${dataRequest.id}/${chosenTab}`;
    };

    return {
        getDefaultTabRoute,
    };
}
