import React from "react";
import {IVivliAdminSummary} from "@vivli/features/dashboard/infrastructure/interface";
import {MetricsConstants} from "@vivli/features/dashboard/infrastructure/constants";
import './admin-statistics-summary.css';

interface AdminStatisticsSummaryTableComponentProps {
    adminSummaryReport? : IVivliAdminSummary
}
export const AdminStatisticsSummaryTableComponent = (
    {
        adminSummaryReport
    }:AdminStatisticsSummaryTableComponentProps
) => {
    const getHeader1 = (index) => {
        return index === 0 ? MetricsConstants.Text.ADMIN_STAT_SUMMARY_HEADER_1 : '\u00A0';
    }
    const getHeader2 = (index) => {
        return index === 0 ? MetricsConstants.Text.ADMIN_STAT_SUMMARY_HEADER_2 : '\u00A0';
    }

    return (
        <div className={'stat-table'}>
            {Object.keys(adminSummaryReport).map((statisticGroup, index) => {
                return (
                    <div key={index}>
                        <div className={'stat-row stat-header'}>
                            <div>{getHeader1(index)}</div>
                            <div>{getHeader2(index)}</div>
                        </div>
                        {Object.keys(adminSummaryReport[statisticGroup]).map((stat, index) => {
                            return (
                                <div className={'stat-row'} key={index}>
                                    <div>{MetricsConstants.Text.ADMIN_STAT_SUMMARY_GROUP_HEADERS[statisticGroup][stat]}</div>
                                    <div>{adminSummaryReport[statisticGroup][stat]}</div>
                                </div>
                            );
                        })}
                    </div>
                );
            })}
        </div>
    )
}
