import React, { useEffect, useState } from 'react';
import { useModalService } from '@vivli/shared/infrastructure/context';
import {
    IDataRequest,
    IResearchTeamMemberDetail,
    IVirtualMachineAccessInvitation,
} from '@vivli/features/data-requests/infrastructure/interface';
import { useDataRequestsService } from '@vivli/features/data-requests/infrastructure/context';
import { finalize, first } from 'rxjs/operators';
import { useActiveUser } from '@vivli/core/infrastructure/context';
import { TeamMemberModalComponent } from './team-member-modal.component';
import { TeamMemberRowComponent } from './team-member-row.component';

interface TeamMemberRowComponentProps {
    dataRequestId: string;
    teamMember: IResearchTeamMemberDetail;
    onChange: (dataRequest: IDataRequest) => void;
    onSave?: (updatedTeamMember: IResearchTeamMemberDetail) => void;
    onRemove?: (teamMember: IResearchTeamMemberDetail) => void;
    readonly?: boolean;
    isSaving?: boolean;
    dataRequest: IDataRequest;
}

export const TeamMemberListItemComponent = ({
    teamMember,
    onRemove,
    dataRequestId,
    onChange,
    readonly,
    onSave,
    isSaving,
    dataRequest,
}: TeamMemberRowComponentProps) => {
    const [activeTeamMember, setActiveTeamMember] = useState<IResearchTeamMemberDetail>(teamMember);
    const [isLoading, setIsLoading] = useState(false);

    const modal = useModalService();
    const dataRequestsService = useDataRequestsService();
    const user = useActiveUser();

    const handleError = (e) => {
        modal.message(e.message);
    };

    const handleChange = (dataRequest: IDataRequest) => {
        const updatedMember = dataRequest.researchTeam.researchTeamDetails.find(
            (rtd) => rtd.teamMemberId === activeTeamMember.teamMemberId
        );
        setActiveTeamMember(updatedMember);

        onChange && onChange(dataRequest);
    };

    const handleCleanup = () => {
        setIsLoading(false);
    };

    const handleOnRemove = () => {
        const confirmMsg = `Are you sure you want to remove "${activeTeamMember.firstName} ${activeTeamMember.lastName}"?`;
        modal.confirm(confirmMsg, {
            onConfirm: () => {
                setIsLoading(true);
                onRemove(activeTeamMember);
            },
        });
    };

    const handleRevokeAccessRequest = (requestMessage: string) => {
        modal.confirm(requestMessage, {
            onConfirm: () => {
                setIsLoading(true);
                dataRequestsService
                    .revokeVmAccess(dataRequestId, activeTeamMember.teamMemberId)
                    .pipe(first(), finalize(handleCleanup))
                    .subscribe((dr) => {
                        handleChange(dr);
                    }, handleError);
            },
        });
    };

    const handleOnRevokeVmAccess = () => {
        const confirmMsg = `Are you sure you want to revoke access for "${activeTeamMember.firstName} ${activeTeamMember.lastName}"?`;
        handleRevokeAccessRequest(confirmMsg);
    };

    const handleOnInviteToVm = () => {
        const confirmMsg = `Are you sure you want to activate "${activeTeamMember.firstName} ${activeTeamMember.lastName}" for access to your data request?`;
        modal.confirm(confirmMsg, {
            onConfirm: () => {
                setIsLoading(true);

                const invitation: IVirtualMachineAccessInvitation = {
                    email: activeTeamMember.email,
                    InvitedById: user.userId,
                    dataRequestId,
                    teamMemberId: activeTeamMember.teamMemberId,
                };

                dataRequestsService
                    .inviteToVm(invitation)
                    .pipe(first(), finalize(handleCleanup))
                    .subscribe(
                        ({ invitation, dataRequest }) => {
                            handleChange(dataRequest);
                        },
                        (error) => {
                            modal.error(error);
                        }
                    );
            },
        });
    };

    const handleOnCancelInvite = () => {
        const confirmMsg = `Are you sure you want to cancel your invite for "${activeTeamMember.firstName} ${activeTeamMember.lastName}"?`;
        handleRevokeAccessRequest(confirmMsg);
    };

    const handleOnMakeOwner = () => {
        const confirmMsg = `Are you sure you want to make "${activeTeamMember.firstName} ${activeTeamMember.lastName}" the Admin of the data request?`;
        modal.confirm(confirmMsg, {
            onConfirm: () => {
                setIsLoading(true);
                dataRequestsService
                    .changeDataRequestOwner(dataRequestId, activeTeamMember)
                    .pipe(first(), finalize(handleCleanup))
                    .subscribe(handleChange, handleError);
            },
        });
    };

    const setApprovalStatus = (isApprove: boolean) => {
        const approvalVerb = isApprove ? 'provide' : 'deny';
        const confirmMsg = `Are you sure you want to ${approvalVerb} DUA approval for ${activeTeamMember.firstName} ${activeTeamMember.lastName}?`;
        modal.confirm(confirmMsg, {
            title: 'Set DUA Approval',
            onConfirm: () => {
                setIsLoading(true);
                dataRequestsService
                    .setTeamMemberAdminApproval(dataRequestId, activeTeamMember, isApprove ? 'true' : 'false')
                    .pipe(first(), finalize(handleCleanup))
                    .subscribe(handleChange, handleError);
            },
        });
    };

    const handleOnGrantDuaApproval = () => {
        setApprovalStatus(true);
    };

    const handleOnRevokeDuaApproval = () => {
        setApprovalStatus(false);
    };

    const handleRowClick = (teamMember: IResearchTeamMemberDetail) => {
        if (isLoading) {
            return;
        }

        const modalId = modal.custom(
            <TeamMemberModalComponent
                onSave={(updatedTeamMember) => {
                    modal.dismiss(modalId);
                    onSave(updatedTeamMember);
                }}
                onCancel={() => modal.dismiss(modalId)}
                teamMember={teamMember}
                readonly={readonly}
                dataRequest={dataRequest}
            />,
            {
                style: {
                    width: '80%',
                },
            }
        );
    };

    useEffect(() => {
        if (!teamMember) {
            return;
        }

        setActiveTeamMember(teamMember);
    }, [teamMember]);

    useEffect(() => {
        if (isSaving === undefined) {
            return;
        }

        setIsLoading(isSaving);
    }, [isSaving]);

    return (
        <TeamMemberRowComponent
            teamMember={activeTeamMember}
            isLoading={isLoading}
            dataRequest={dataRequest}
            onRowClick={handleRowClick}
            onRemove={handleOnRemove}
            onGrantDuaApproval={handleOnGrantDuaApproval}
            onRevokeDuaApproval={handleOnRevokeDuaApproval}
            onCancelInvite={handleOnCancelInvite}
            onMakeOwner={handleOnMakeOwner}
            onRevokeVmAccess={handleOnRevokeVmAccess}
            onInviteToVm={handleOnInviteToVm}
        />
    );
};
