﻿import React, {createContext, useContext} from 'react';
import {IStudy} from "@vivli/features/studies/infrastructure/interface";
import {IUser} from "@vivli/shared/infrastructure/interface";
import {AssignedAppTypeEnum} from "@vivli/shared/infrastructure/enum";
import {UseFormReturn} from "react-hook-form";


interface IStudyContext {
    study: IStudy;
    user: IUser;
    usedIn: string,
    studyIpdPackageSubmitted: boolean,
    assignedAppType: AssignedAppTypeEnum;
    isSavingStudy: boolean;
    setActiveStudy: (study: IStudy) => void;
    handleFormSave: (formApi: UseFormReturn<IStudy, object>) => void;
    handleRemovePosting: () => void;
    handleSetupForNewIpdVersion: () => void;
    handleCreateDoiForIpdPackage: () => void;
    handleDeleteIpdPackage: () => void;
    handleApproveStudy: () => void;
    handleRejectStudy: () => void;
    handleApproveCurationSubmit: () => void;
    handleUpdateAndStatusChange: (formApi: UseFormReturn<IStudy, object>, statusObject, finalStep?) => void;
    handleDeleteStudy: () => void;
    documentsRefresh: number;
    setDocumentsRefresh: (value: number) => void;
    ipdRefresh: number;
    setIpdRefresh: (value: number) => void;
}

export const StudyContext = createContext<IStudyContext>(null);

export const useStudyContext = () => useContext(StudyContext);


