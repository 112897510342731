import {first} from 'rxjs/operators';
import {useCitationsService} from '@vivli/shared/features/citations/infrastructure/context';
import {useModalService} from '@vivli/shared/infrastructure/context';
import {IRorResponseItem} from '@vivli/features/organizations/infrastructure/interface';
import {ICheckboxMenuItem} from '@vivli/shared/infrastructure/interface';

const promptText =
    'Please select the best-matching ROR organization. If no ROR organizations appear, please cancel and enter a different name.';

const getCheckboxes = (matches) => {
    const checkboxValues: ICheckboxMenuItem[] = [];
    matches.map((r) => {
        let title = `${r.name}: (${r.links[0]})${' '.repeat(8)}Location: ${r.addresses[0].city}`;
        const parent = r.relationships.find((r) => r.type === 'Parent');
        if (parent) {
            title = title.concat(`    Parent: ${parent.label}`);
        }
        checkboxValues.push({
            title: title,
            value: r.id,
            checked: false,
        });
    });
    return checkboxValues;
};

interface ISearchOptions {
    searchTerm: string;
    onConfirm: (selection: string, matches: IRorResponseItem[]) => void;
    onError: (err, message: string) => void;
    setIsLoading: (value: boolean) => void;
}

export const useRorSearchHook = () => {
    const citationsService = useCitationsService(false);
    const modalService = useModalService();

    return ({searchTerm, onConfirm, onError, setIsLoading}: ISearchOptions) =>
        citationsService
            .getRorMatches(searchTerm)
            .pipe(first())
            .subscribe(
                (matches) => {
                    return modalService.confirm(promptText, {
                        title: 'Find ROR Organization',
                        style: {textAlign: 'left', minWidth: '50em'},
                        messageStyle: {textAlign: 'left'},
                        multiSelectOptions: getCheckboxes(matches),
                        showMultiSelect: true,
                        multiSelectAllowSingleOnly: true,
                        requireSelectOption: true,
                        showTextAreaPrompt: false,
                        showPrompt: false,
                        confirmText: 'OK',
                        cancelText: 'Cancel',
                        onConfirm: ({selections}) => {
                            onConfirm(selections[0], matches);
                        },
                        onCancel: () => setIsLoading(false)
                    });
                },
                (e) => {
                    onError(e, 'There was a problem getting ROR matches: ');
                }
            );
};
