﻿import React, {CSSProperties} from "react";
import {AdminHeaderComponent, GoBackLinkComponent} from "@vivli/shared/components";
import {Size} from "@vivli/shared/theme";
import {useStudyContext} from "@vivli/features/studies/infrastructure/context";
import {StudyWorkflowButtonsComponent} from "./study-workflow-buttons.component";
import {useFormContext} from "react-hook-form";
import {IStudy} from "@vivli/features/studies/infrastructure/interface";
import {DTICommonConst} from "@vivli/shared/infrastructure/constants";

const flexColumnStyle: CSSProperties = {
    flexDirection: 'column'
}
const goBackStyle: CSSProperties = {
    justifyContent: 'normal',
    paddingBottom: 5
}

export const AnnotateStudyHeaderComponent = () =>{
    const {study, handleFormSave, handleUpdateAndStatusChange} = useStudyContext();
    const formApi = useFormContext<IStudy>();
    const studyInformationPath = `/admin/studies/${study.id}/admin-study-feature`;

    const handleUpdate = (statusObject) => {
        handleUpdateAndStatusChange(formApi, statusObject);
    }

    return (
        <>
            <AdminHeaderComponent style={{ marginBottom: Size.INSET_SIZE / 2 }}>
                <div>
                    <div style={flexColumnStyle}>
                        <GoBackLinkComponent style={goBackStyle} dataId={DTICommonConst.HeaderApprovalButton('Back')}/>
                    </div>
                    <div style={flexColumnStyle}>
                        <GoBackLinkComponent
                            destination={studyInformationPath}
                            textToShow={`Go to Study Information`}
                            dataId={DTICommonConst.HeaderApprovalButton('GoBackInitial')}
                        />
                    </div>
                </div>
                <StudyWorkflowButtonsComponent
                    onSaveClick={() => handleFormSave(formApi)}
                    onStatusChange={handleUpdate}
                    formHasErrors={!formApi.formState.isValid}
                    formIsDirty={formApi.formState.isDirty}
                />
            </AdminHeaderComponent>
        </>
    )
}
