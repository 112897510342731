import React, {CSSProperties, useEffect, useState} from 'react';
import {useActiveUser} from '@vivli/core/infrastructure/context'
import {
    AdminStatisticsSummaryDashboardComponent,
    MetricsDashboardComponent,
    OrganizationMembershipListComponent
} from '@vivli/features/dashboard/components';
import {
    AdminHeaderComponent,
    AdminHeaderTitleComponent,
    FieldHeaderComponent,
    TabComponent,
    TabsComponent
} from '@vivli/shared/components';
import {IDataRequestSummary} from '@vivli/features/data-requests/infrastructure/interface';
import {Color, Size} from '@vivli/shared/theme';
import {DataRequestsGridComponent} from '@vivli/features/data-requests/components';
import {RequestedStudiesGridComponent} from '@vivli/features/studies/components';
import {DataRequestStatusEnum} from '@vivli/features/data-requests/infrastructure/enum';
import {useStudiesAwaitingUpload} from "@vivli/features/studies/infrastructure/hook";
import {Trans} from 'react-i18next';
import {useAssignedAppType} from "@vivli/core/infrastructure/hook";
import {AssignedAppTypeEnum} from "@vivli/shared/infrastructure/enum";
import {useNavigate} from "react-router-dom";

const textContainerStyle: CSSProperties = {
    marginLeft: Size.INSET_SIZE,
    fontSize: Size.FontSize.Larger,
    color: Color.DARK_GRAY,
}

const awaitingTextStyle: CSSProperties = {
    marginBottom: Size.FIELD_SEPARATION,
    marginTop: 20,
}

const studiesContainerStyle: CSSProperties = {
    marginTop: Size.INSET_SIZE,
    marginBottom: Size.FIELD_SEPARATION,
}

export const DashboardFeature = () => {
    const [isOrgAdmin, setIsOrgAdmin] = useState(false);
    const [isDataContributor, setIsDataContributor] = useState(false);
    const user = useActiveUser();
    const navigate = useNavigate();
    const assignedAppType = useAssignedAppType();
    const isAmr = assignedAppType === AssignedAppTypeEnum.Amr;

    const {
        dataRequestSummaries,
        requestedStudies,
        awaitingDataRequests,
        organizations,
        isLoadingDataRequestSummaries
    } = useStudiesAwaitingUpload();

    const handleRowClick = (dataRequest: IDataRequestSummary) => {
        const navTab = dataRequest.status === DataRequestStatusEnum.Draft ? 'researchTeam' : 'chat';
        const url = `/adminDataRequests/awaitingmyaction/${dataRequest.id}/${navTab}`;
        navigate(url);
    }

    const showDataRequestsGrid = awaitingDataRequests?.length > 0 || isLoadingDataRequestSummaries;
    const showRequestedStudiesGrid = user.isVivliAdmin || isDataContributor;
    const showMetricsTab = (user?.isVivliAdmin) && !isAmr;
    const showAdminSummaryTab = (user?.isVivliAdmin || (isAmr && !user?.isVivliAdmin)) && !isAmr;


    useEffect(() => {
        if (!organizations) {
            return;
        }

        const userHasOrgAdmin = user.orgMemberships
            ?.some(om => organizations?.some(o => o.id === om.orgId && om.isOrgAdmin));

        const userIsDataContributor = user.orgMemberships
            ?.some(om => organizations?.some(o => o.id === om.orgId && om.isDataProvider));

        setIsOrgAdmin(userHasOrgAdmin);
        setIsDataContributor(userIsDataContributor);
    }, [organizations])

    return (
        <div style={{height: '100%', overflowX: 'auto', marginBottom: '50px'}} className='scrolly'>
            <div style={{display: 'flex', flexFlow: 'row'}} className={'do-not-print'}>
                <div style={{width: '50%', display: 'flex', flexFlow: 'column'}}>
                    <AdminHeaderComponent>
                        <AdminHeaderTitleComponent title={`Welcome, ${user.name}!`}/>
                    </AdminHeaderComponent>
                    <div style={textContainerStyle}>
                        <div>
                            <Trans components={[
                                <a href="mailto:amr@vivli.org"/>
                            ]}>
                                welcomeMessage
                            </Trans>
                        </div>
                    </div>
                </div>
                <OrganizationMembershipListComponent/>
            </div>
            <div style={{height: '50%', marginLeft: Size.INSET_SIZE, marginRight: Size.INSET_SIZE}}>
                <TabsComponent defaultTab={0} hideBarIfSingleTab={true}>
                    {!isAmr && (
                        <TabComponent title='Awaiting My Action'>
                            <div style={{paddingBottom: '50px'}} className='do-not-print'>
                                <div style={awaitingTextStyle}>
                                    Data Requests Awaiting My Approval
                                </div>
                                {showDataRequestsGrid ? (
                                    <DataRequestsGridComponent
                                        dataRequests={awaitingDataRequests}
                                        emptyMessage='No Research Data Requests awaiting action'
                                        hideFloatingFilter={true}
                                        onRowClick={handleRowClick}
                                        autoHeight={true}
                                        asyncUpdateKey={null}
                                        dataRefreshing={isLoadingDataRequestSummaries}
                                    />
                                ) : (
                                    <div style={{marginLeft: Size.INSET_SIZE}}>
                                        <FieldHeaderComponent title={'No Data Requests Awaiting Approval'}/>
                                    </div>
                                )}

                                <div style={studiesContainerStyle}>
                                    Studies Awaiting Data Package Upload
                                </div>

                                {showRequestedStudiesGrid ?
                                    <RequestedStudiesGridComponent
                                        studies={requestedStudies}
                                        dataRequests={dataRequestSummaries}
                                        emptyMessage='No studies are awaiting upload'
                                        hideFloatingFilter={true}
                                        hideHeader={true}
                                        autoHeight={true}
                                        dataRefreshing={isLoadingDataRequestSummaries}
                                    /> :
                                    <div style={{marginLeft: Size.INSET_SIZE}}>
                                        <FieldHeaderComponent
                                            title={organizations ? 'Only data contributors are authorized to upload IPD data' : 'Loading Studies...'}/>
                                    </div>
                                }
                            </div>
                        </TabComponent>
                    )}

                    {showMetricsTab &&
                        <TabComponent title='Metrics'>
                            <MetricsDashboardComponent />
                        </TabComponent>
                    }

                    {showAdminSummaryTab &&
                        <TabComponent title='Admin Summary'>
                            <AdminStatisticsSummaryDashboardComponent/>
                        </TabComponent>
                    }
                </TabsComponent>
            </div>
        </div>
    )
}
