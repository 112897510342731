﻿import {IRequestedStudy} from "@vivli/features/data-requests/infrastructure/interface";
import {CSSProperties} from "react";
import {Size, Styles} from "@vivli/shared/theme";

export const containerStyle = (study: IRequestedStudy): CSSProperties => ({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    transitionProperty: 'height',
    transitionDuration: '.6s',
    transitionTimingFunction: 'ease',
    cursor: study.requestedStudyType !== 'Unlisted' ? 'pointer' : 'default',
    lineHeight: 1
})

export const myOrgContainerStyle: CSSProperties = {
    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 15px, rgba(0, 0, 0, 0.2) 0px 1px 2px',
    backgroundColor: 'rgb(23, 150, 197)',
    position: 'absolute',
    top: '4px',
    left: '-12px',
    borderRadius: '50%',
    paddingTop: '4px',
    marginBottom: '10px',
    width: '21px',
    height: '21px',
    textAlign: 'center',
    fontSize: '14px',
    color: 'white',
    lineHeight: 1
}

export const studyDetailContainerStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    minHeight: 50,
    alignItems: 'center',
}

export const statusMessageStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    minWidth: '320px',
    justifyContent: 'space-around',
    textAlign: 'right',
    marginRight: '10px',
}

export const removeButtonStyle: CSSProperties = {
    ...Styles.Button.BUTTON_RED_X,
    height: 30,
    width: 116,
    justifyContent: 'flex-start',
    alignSelf: 'center',
    marginRight: '30px',
    paddingLeft: '25px',
    paddingBottom: '3px',
}

export const uploadContainerStyle = (isUploading: boolean): CSSProperties => ({
    marginTop: isUploading ? Size.PADDING : 0,
    height: isUploading ? 'initial' : 0,
    paddingBottom: isUploading ? Size.PADDING : 0,
    paddingLeft: isUploading ? Size.PADDING : 0,
    paddingRight: isUploading ? Size.PADDING : 0,
    paddingTop: isUploading ? Size.PADDING : 0,
    flex: 'auto',
    transitionProperty: 'height',
    transitionDuration: '.6s',
    transitionTimingFunction: 'ease',
    cursor: 'auto',
})

export const fullStudyDetailContainerStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'left',
    marginTop: '23px'
}
