import React, {CSSProperties} from "react";
import {IDataRequest} from "@vivli/features/data-requests/infrastructure/interface";
import {SignedDocumentsDataPackageFeature} from "@vivli/shared/features/data-package/feature";
import {Size} from "@vivli/shared/theme";
import {FieldHeaderComponent, LoadIndicatorCenteredComponent} from "@vivli/shared/components";
import {DataRequestStatusEnum} from "@vivli/features/data-requests/infrastructure/enum";


const signedDocsTitleStyle: CSSProperties = {
    paddingLeft: Size.INSET_SIZE + Size.PADDING,
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
}

const dataPackageStyle: CSSProperties = {
    paddingLeft: Size.INSET_SIZE,
    paddingRight: Size.INSET_SIZE,
    flex: 'auto',
}

const loadingStyle: CSSProperties = {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    height: '100%',
}

interface SignedDocumentsViewComponentProps {
    dataRequest?: IDataRequest,
}

export const SignedDocumentsViewComponent = (
    {
        dataRequest
    } : SignedDocumentsViewComponentProps
) => {

    const unValidatedTitle = 'There are no Signed Documents';
    const validatedTitle = 'The Data Usage Agreement (DUA) has been signed and is available for download below. ' +
        'If more than one DUA version was uploaded, the latest is the signed and validated version.';
    const validatedItems = [DataRequestStatusEnum.DUAValidated, DataRequestStatusEnum.Fulfilled,
        DataRequestStatusEnum.PartiallyFulfilled, DataRequestStatusEnum.Archived];
    const noStatusItems = [DataRequestStatusEnum.Draft, DataRequestStatusEnum.Blinded, DataRequestStatusEnum.Cancelled]
    const status = dataRequest.status;
    const uploadPrompt = 'If you have not already done so, please upload the signed and completed copy of the DUA';

    const getSignedDocsTitle = () => {
        if(validatedItems.includes(status)) {
            return validatedTitle;
        } else if(noStatusItems.includes(status)) {
            return '';
        } else {
            return unValidatedTitle;
        }
    }

    const showUploadPrompt = !getSignedDocsTitle().includes('(DUA) has been signed') ? uploadPrompt : '';

    if (!dataRequest) {
        return <div style={loadingStyle}>
            <LoadIndicatorCenteredComponent/>
        </div>;
    }


    return (
        <div>

            <div style={signedDocsTitleStyle}>
                <FieldHeaderComponent title={getSignedDocsTitle()} />
            </div>

            {dataRequest?.duaDataPackageId &&
                <div style={dataPackageStyle}>
                    <FieldHeaderComponent title={showUploadPrompt} />
                    <SignedDocumentsDataPackageFeature
                        dataPackageId={dataRequest?.duaDataPackageId}
                        dataRequest={dataRequest}
                    />
                </div>
            }

        </div>
    )
}
