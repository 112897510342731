import React, {CSSProperties, Fragment, useEffect} from 'react';
import {useParams} from 'react-router';
import {useStudyWithUserCheck} from '@vivli/features/studies/infrastructure/hook';
import {DatasetTabsComponent} from '@vivli/features/datasets/components';
import {DatasetContext} from '@vivli/features/datasets/infrastructure/context';
import {LoadIndicatorCenteredComponent} from '@vivli/shared/components';
import {useConfigService} from '@vivli/core/infrastructure/context';
import {useStudiesService} from '@vivli/features/studies/infrastructure/context';
import {first} from 'rxjs/operators';
import {DatasetContextWrapper} from '@vivli/features/datasets/infrastructure/wrappers';

// This feature is the top level for readonly forms for any type of
// user to VIEW the individual content of an
// object that implements the IStudy interface. Note that this "IStudy object" may be a clinical trial
// (for Default mode) or an Amr Dataset (for Amr mode).
//
// Consumers of the feature include people involved in a data request who are looking
// at the studies/amr datasets that are part of their data requests, and anyone searching
// for information about the study/amr dataset either from the Vivli search screen
// or by following the study/amr dataset's DOI link

//
// Contrast this "datasets" area with the ADMIN only views in the admin-study.feature.tsx.
//

const containerStyle: CSSProperties = {
    backgroundColor: 'rgb(233, 234, 239)',
    overflow: 'hidden',
    transform: 'translate3d(0,0,0)', // force chrome to use GPU for rendering - fixes UI 'whiteout' glitch related to scrollbars (I think),
    display: 'flex',
    flexDirection: 'column',
    minWidth: '100%',
    flexGrow: 2,
    height: '100%',
};

const bodyContainerStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 2,
    width: '100%',
    height: 'calc(100% - 70px)',
    overflow: 'auto',
};

export const DatasetFeature = () => {

    const isLanding = window.location.href.includes('doiLanding');
    const { studyId, dataRequestId } = useParams();
    const config = useConfigService();
    const studiesService = useStudiesService();

    let requestedStudyDownloadableIpdPackageId: string = null;
    let isUsedIn = true;
    const allParams = useParams();
    if (allParams.requestedStudyIpdDataPackageId) {
        requestedStudyDownloadableIpdPackageId =
            allParams['requestedStudyIpdDataPackageId'];
        //this is a case where we DON'T need to get "UsedIn" information
        //it is for requested study access only
        isUsedIn = false;
    }
    const { study } = useStudyWithUserCheck(studyId, isLanding, isUsedIn);

    // check if we are coming from search or from landing page
    const checkUrlForCount = () => {
        return (
            window.location.href.includes('doiLanding') ||
            window.location.href.includes('fromSearch')
        );
    };

    // send data count to IRUS
    useEffect(() => {
        if (
            study &&
            config.irusCountOn &&
            checkUrlForCount() &&
            study.studyMetadataDoi
        ) {
            studiesService
                .sendDataCountCollectionData(study.studyMetadataDoi, '')
                .pipe(first())
                .subscribe();
        }
    }, [study]);

    return (
        <div style={containerStyle}>
            <DatasetContextWrapper
                study={study}
                requestedStudyDownloadableIpdPackageId={
                    requestedStudyDownloadableIpdPackageId
                }
                isLanding={isLanding}
                dataRequestId={dataRequestId}
            >
                <DatasetContext.Consumer>
                    {({ study }) => {
                        if (!study) {
                            return <LoadIndicatorCenteredComponent />;
                        }
                        return (
                            <Fragment>
                                <div style={bodyContainerStyle}>
                                    <DatasetTabsComponent />
                                </div>
                            </Fragment>
                        );
                    }}
                </DatasetContext.Consumer>
            </DatasetContextWrapper>
        </div>
    );
};
