import React, {CSSProperties, useState} from 'react';
import {InfoButtonComponent} from "./search-buttons/info-button.component";
import {SearchButtonComponent} from "./search-buttons/search-button.component";
import {ExportCsvButtonComponent, WelcomeModalComponent} from "@vivli/shared/components";
import {useModalService} from "@vivli/shared/infrastructure/context";
import {useSearchContext} from "@vivli/features/search/infrastructure/context";
import {useActiveUser} from "@vivli/core/infrastructure/context";

const inputButtonsContainerStyle: CSSProperties = {
    display: 'flex',
    paddingTop: '0'
}

const exportButtonStyle: CSSProperties = {
    right: '13px',
    '@media screen and (maxWidth: 1480px)': {
        position: 'initial',
    },
} as CSSProperties;

const closeButtonStyle = (isHovering: boolean): CSSProperties => ({
    cursor: 'pointer',
    height: '60px',
    width: '100px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: isHovering ? 'grey' : 'initial'
})

export const SearchInputButtonsComponent = () => {
    const activeUser = useActiveUser();
    const modalService = useModalService();
    const [isHovering, setIsHovering] = useState(false);
    const {toggleIsShowingResults, isShowingResults, searchResults, callExport, retrievingStudiesCsv } = useSearchContext();

    const handleInfoButtonClick = () => {
        const modalId = modalService.custom(<WelcomeModalComponent onClose={() => modalService.dismiss(modalId)}/>)
    }

    const exportToCsv = (e) => {
        e.stopPropagation();
        callExport();
        return false;
    }

    return <div style={inputButtonsContainerStyle}>
        {!isShowingResults && <>
            <InfoButtonComponent onClick={handleInfoButtonClick}/>
            <SearchButtonComponent onClick={toggleIsShowingResults}/>
        </>}

        {isShowingResults && activeUser == null && <div
            style={closeButtonStyle(isHovering)}
            onMouseEnter={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
            onClick={toggleIsShowingResults}>CLOSE
        </div>}

        {isShowingResults && activeUser != null && <>
            {!activeUser.isVivliAdmin && <div
                style={closeButtonStyle(isHovering)}
                onMouseEnter={() => setIsHovering(true)}
                onMouseLeave={() => setIsHovering(false)}
                onClick={toggleIsShowingResults}>CLOSE
            </div>}

            {activeUser.isVivliAdmin && <div
                style={closeButtonStyle(isHovering)}
                onMouseEnter={() => setIsHovering(true)}
                onMouseLeave={() => setIsHovering(false)}
                onClick={toggleIsShowingResults}>CLOSE
                <ExportCsvButtonComponent onClick={exportToCsv} isLoading={retrievingStudiesCsv} />
            </div>}
        </>}
    </div>
}
