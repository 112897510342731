import React, { CSSProperties } from 'react';
import { Color, Size, Styles } from '@vivli/shared/theme';

const menuItemStyle: CSSProperties = {
    height: Size.HEADER_HEIGHT / 2,
    color: Color.DARK_GRAY,
    paddingBottom: Size.PADDING,
    paddingLeft: Size.PADDING,
    paddingRight: Size.PADDING,
    paddingTop: Size.PADDING,
    fontSize: Size.FontSize.Medium,
    fontWeight: Size.FontWeight.Bold,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    ...Styles.Transition.HOVER_OPACITY_TRANSITION,
};

export const MenuItemComponent = ({ title, onClick, dataId }) => (
    <div
        style={menuItemStyle}
        onClick={() => {
            onClick && onClick();
        }}
        data-test-id={dataId}
    >
        {title}
    </div>
);
