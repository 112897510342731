﻿import React, {CSSProperties} from "react";
import {Size} from "@vivli/shared/theme";
import {ITimeIntervalMetrics} from "@vivli/features/dashboard/infrastructure/interface";
import {MetricsConstants} from "@vivli/features/dashboard/infrastructure/constants";
import {TimeIntervalMetricsEnum} from "@vivli/features/dashboard/infrastructure/enum";
import {Bar} from "@nivo/bar";
import tinycolor from "tinycolor2";
import {BarComponent, NumericTick, StatusTick} from "./bar-and-tick.component";

const containerStyle = (height): CSSProperties => ({
    height: height + (MetricsConstants.Sizing.DOUBLE_SPACING),
    backgroundColor: 'white',
    padding: MetricsConstants.Sizing.SPACING
})
const legendDescriptionStyle: CSSProperties = {
    display: 'flex',
    height: '24px',
    alignItems: 'center',
    fontSize: '16px',
    color: MetricsConstants.Colors.DARK_BLUE_TEXT,
    fontWeight: Size.FontWeight.SemiBold,
    marginBottom: '-24px',
}

interface AverageTimeChartComponentProps {
    color: string;
    data: ITimeIntervalMetrics;
    width: number;
    isAggregateReport?: boolean;
}
export const AverageTimeChartComponent = (
    {
        color,
        data,
        width,
        isAggregateReport
    }: AverageTimeChartComponentProps
) => {
    let keys = [MetricsConstants.Text.CHART_ORG_LEGEND_LABEL, MetricsConstants.Text.CHART_ALL_LEGEND_LABEL];
    let formattedData = Object.keys(data)
        .filter(k => k !== MetricsConstants.Text.OVERALL_TIME_TO_PUBLICATION_KEY)
        .map((key: string) => {
            const dataPoint = data[key];
            const formattedDataObject = {
                status: TimeIntervalMetricsEnum[key],
            };
            if (!isAggregateReport) {
                formattedDataObject[MetricsConstants.Text.CHART_ORG_LEGEND_LABEL] = dataPoint.orgAverage;
            } else {
                keys = [keys.pop()];
            }
            formattedDataObject[MetricsConstants.Text.CHART_ALL_LEGEND_LABEL] = dataPoint.allDataContributorsAverage;
            return formattedDataObject;
        });
    // Data goes from bottom to top in chart, so we need to reverse the order
    formattedData = formattedData.reverse();
    const height = isAggregateReport ? MetricsConstants.Sizing.AGGREGATE_CHART_HEIGHT : MetricsConstants.Sizing.STANDARD_CHART_HEIGHT;
    const barWidth = width ? width - MetricsConstants.Sizing.LABEL_OFFSET : 0;

    if (!width){
        return null;
    }

    return (
        <div className={MetricsConstants.Text.TIME_CHART_CONTAINER_CLASS} style={containerStyle(height)}>
            <div style={legendDescriptionStyle}>
                {MetricsConstants.Text.CHART_LEGEND_DESCRIPTION_LABEL}
            </div>
            <Bar
                width={barWidth}
                height={height}
                barComponent={BarComponent}
                data={formattedData}
                keys={keys}
                indexBy='status'
                margin={
                    {
                        top: 50,
                        right: 40,
                        bottom: 0,
                        left: 188,
                    }
                }
                padding={0.3}
                groupMode='grouped'
                layout='horizontal'
                colors={[color, MetricsConstants.Colors.CHART_SECONDARY]}
                borderColor={{from: 'color', modifiers: [['darker', 1.6]]}}
                axisTop={
                    {
                        tickSize: 0,
                        tickPadding: 5,
                        tickRotation: 0,
                        legendOffset: -50,
                        legendPosition: 'start',
                        renderTick: NumericTick,
                    }
                }
                axisRight={null}
                axisBottom={null}
                axisLeft={{
                    tickSize: 0,
                    tickPadding: 10,
                    tickRotation: 0,
                    renderTick: StatusTick,
                }}
                enableGridY={false}
                labelSkipWidth={12}
                labelSkipHeight={12}
                labelTextColor={MetricsConstants.Colors.DARK_BLUE_TEXT}
                legends={[
                    {
                        dataFrom: 'keys',
                        anchor: 'top',
                        direction: 'row',
                        justify: false,
                        translateX: 0,
                        translateY: -45,
                        itemsSpacing: 1,
                        itemWidth: 60,
                        itemHeight: 10,
                        itemDirection: 'left-to-right',
                        itemOpacity: 1,
                        symbolSize: 20,
                        itemTextColor: MetricsConstants.Colors.DARK_BLUE_TEXT,
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemTextColor: tinycolor(MetricsConstants.Colors.DARK_BLUE_TEXT).darken(20).toRgbString(),
                                },
                            },
                        ],
                    },
                ]}
                markers={[{
                    axis: 'y',
                    value: 1,
                    lineStyle: {stroke: MetricsConstants.Colors.LIGHT_GRAY_TEXT},
                }]}
                animate={false}
                motionStiffness={90}
                motionDamping={15}
                theme={{
                    axis: {
                        domain: {
                            line: {
                                stroke: 'white',
                                strokeWidth: 10,
                            },
                        },
                    },
                }}
            />
        </div>
    )
}
