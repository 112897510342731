﻿import React, {CSSProperties, useEffect, useState} from 'react';
import {
    fourAcrossStyle,
    fourSmallSplitAcrossStyle,
    LinkFieldComponent, MultiSelectDropdownFormFieldComponent,
    oneAcrossStyle,
    TextFieldReadOnlyFormComponent,
    TextFormFieldComponent,
    threeAcrossStyle,
} from '@vivli/shared/components';
import { IStudy } from '@vivli/features/studies/infrastructure/interface';
import { Styles } from '@vivli/shared/theme';
import { CollaboratorsAndSecondaryIdsComponent } from './collaborators-and-secondary-ids';
import { PublishingAndContactingComponent } from './publishing-and-contacting.component';
import {useDoiTransform, useStudyPrimaryRegistryUrlHook} from '@vivli/features/studies/infrastructure/hook';
import {ICodeItem, IDropdownMenuItem, IUser} from "@vivli/shared/infrastructure/interface";
import {AssetsConstant} from "@vivli/shared/infrastructure/constants";
import {useVivliConfig} from "@vivli/core/infrastructure/hook";
import {DTICommonConst, DTICTFormFields} from "@vivli/shared/infrastructure/constants";

const tightRow: CSSProperties = {
    ...Styles.FORM_ROW,
    marginBottom: 0,
};

export const notAsSmallFourAcrossStyle: CSSProperties = {
    ...fourSmallSplitAcrossStyle,
    flex: '1 1 14',
    maxWidth: '14%',
};

const teamGridRightColumnStyle: CSSProperties = {
    display: 'grid',
    gridTemplateColumns: '92% 1%',
    columnGap: '8px',
    marginBottom: 30,
};
//component for shared use by both org admins and vivli admins
//ct only
interface AdminCtAdministrativeSharedComponentProps {
    study: IStudy;
    isVivliAdmin: boolean;
    otherResourcesLabel: string;
    user?: IUser;
}

export const AdminCtAdministrativeSharedComponent = ({
    study,
    isVivliAdmin,
    otherResourcesLabel,
    user,
}: AdminCtAdministrativeSharedComponentProps) => {
    const showLinkDoi = study.studyMetadataDoi?.indexOf('DOINotCreated') < 0;
    const AltDoi = study.alternateDoi;
    const doiStem = !study.doiStem ? '' : `VIV${study.doiStem}`;
    const {registryUrl} = useStudyPrimaryRegistryUrlHook();
    const nctString = registryUrl(study);
    const userIsReadOnlyForGeneralFields = !isVivliAdmin;
    const {transformDoiUrl} = useDoiTransform();

    const vivliConfig = useVivliConfig();
    const areas = (vivliConfig?.therapeuticAreaItems);

    const PrimaryDOIField = () => {
        if (AltDoi) {
            return (
                <LinkFieldComponent
                    label="Primary DOI"
                    linkContent={study.alternateDoi}
                    linkTitle={study.alternateDoi}
                    style={fourAcrossStyle}
                    readonly={true}
                />
            )
        }
        if(showLinkDoi){
            return (
                <LinkFieldComponent
                    label="Primary DOI"
                    linkContent={transformDoiUrl(study.studyMetadataDoi)/*11301*/}
                    linkTitle={study.studyMetadataDoi}
                    style={threeAcrossStyle}
                />
            )
        }
        return (
            <TextFormFieldComponent
                name={`studyMetadataDoi`}
                label="Primary DOI"
                style={fourAcrossStyle}
                readonly={true}
            />
        )
    }


    return (
        <div style={{width: '100%'}}>
            <div style={Styles.FORM_ROW}>
                <PrimaryDOIField/>
                <TextFieldReadOnlyFormComponent
                    label={'Vivli ID'}
                    style={notAsSmallFourAcrossStyle}
                    defaultValue={doiStem}
                    readonly={true}
                    dataId={DTICTFormFields.VivliId}
                />
                <TextFormFieldComponent
                    name={`sponsorProtocolId`}
                    label="Sponsor Protocol Id"
                    style={fourAcrossStyle}
                    readonly={!isVivliAdmin}
                    dataId={DTICTFormFields.SponsorProtocolId}
                />
                <TextFormFieldComponent
                    name={`acronym`}
                    label="Acronym"
                    style={fourAcrossStyle}
                    readonly={!isVivliAdmin}
                    dataId={DTICTFormFields.Acronym}
                />
            </div>
            {study.studyIPDDataPackageDoi && (
                <div style={tightRow}>
                    <LinkFieldComponent
                        linkTitle={study.studyIPDDataPackageDoi}
                        linkContent={transformDoiUrl(study.studyIPDDataPackageDoi)}
                        label={'Data Package DOI(s) Available for this Study'}
                        style={oneAcrossStyle}
                        dataId={DTICommonConst.HeaderApprovalButton('IPDDataPackageDoi')}
                    />
                </div>
            )}
            <div style={Styles.FORM_ROW}>
                <TextFormFieldComponent
                    name="orgName"
                    label={'Data Contributor Organization Name'}
                    style={threeAcrossStyle}
                    readonly={true}
                    dataId={DTICTFormFields.OrgName}
                />
                <TextFormFieldComponent
                    name="leadSponsor.agency"
                    label={'Lead Sponsor Agency'}
                    style={threeAcrossStyle}
                    readonly={!isVivliAdmin}
                    dataId={DTICTFormFields.LeadAgency('SponsorAgency')}
                />
                <TextFormFieldComponent
                    name="leadSponsor.agencyClass"
                    label={'Lead Sponsor Agency Class'}
                    style={threeAcrossStyle}
                    readonly={true}
                    dataId={DTICTFormFields.LeadAgency('AgencyClass')}
                />
            </div>

            <div style={teamGridRightColumnStyle}>
                <MultiSelectDropdownFormFieldComponent
                    label={'Therapeutic Areas'}
                    name={`therapeuticAreas`}
                    items={areas}
                    objectKey={'code'}
                    labelKey={'description'}
                    readonly={!isVivliAdmin}
                    arrayKey={'description'}
                    style={{height: '100%'}}
                />
            </div>

            <div style={Styles.FORM_ROW}>
                <TextFormFieldComponent
                    name="alternativeMetadataDocumentUri"
                    label={otherResourcesLabel}
                    style={oneAcrossStyle}
                    readonly={true}
                    dataId={DTICTFormFields.AlternateDoi}
                />
            </div>

            <div style={Styles.FORM_ROW}>
                <TextFormFieldComponent
                    name={'registryInfo[0].registryName'}
                    label={'Primary Registry Name'}
                    style={threeAcrossStyle}
                    readonly={userIsReadOnlyForGeneralFields}
                    dataId={DTICTFormFields.RegistryDetials('RegistryName')}
                />
                <TextFormFieldComponent
                    name={'registryInfo[0].registryId'}
                    label={'Primary Registry Id'}
                    style={threeAcrossStyle}
                    readonly={userIsReadOnlyForGeneralFields}
                    dataId={DTICTFormFields.RegistryDetials('RegistryId')}
                />
                <LinkFieldComponent
                    linkTitle={nctString}
                    linkContent={nctString}
                    label={'Primary Registry Url'}
                    style={threeAcrossStyle}
                    dataId={DTICTFormFields.RegistryDetials('RegistryUrl')}
                />
            </div>
            <CollaboratorsAndSecondaryIdsComponent
                study={study}
                isVivliAdmin={isVivliAdmin}
            />
            <PublishingAndContactingComponent/>
        </div>
    );
};
