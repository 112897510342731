import React, {useEffect} from 'react';
import {useModalService} from "@vivli/shared/infrastructure/context";
import {useLocation} from "react-router";
import {AcceptDataRequestInviteModal} from "@vivli/features/data-requests/components";
import {AcceptOrgInviteModal} from "@vivli/features/organizations/components";
import {IContextWrapper} from "@vivli/shared/infrastructure/interface";

export const AcceptInvitePopupWrapper = ({children}: IContextWrapper) => {
    const modalService = useModalService();
    const {pathname} = useLocation();

    const getAcceptOrgInviteModal = () => {
        const orgId = pathname?.split('/')[2];
        modalService.message(<AcceptOrgInviteModal orgId={orgId}/>, {showLogo: true, showBackground: true});
    }

    const getDataRequestInviteModal = () => {
        const dataRequestId = pathname?.split('/')[2];
        const teamMemberId = pathname?.split('/')[3];
        modalService.message(<AcceptDataRequestInviteModal dataRequestId={dataRequestId} teamMemberId={teamMemberId} />, {showLogo: true, showBackground: true});
    }

    useEffect(() => {
        if (pathname?.indexOf('/acceptinvite/') >= 0){
            getAcceptOrgInviteModal();
        }
        if (pathname?.indexOf('/acceptinvitefordatarequest/') >= 0){
            getDataRequestInviteModal();
        }

    }, [])

    return children;
}
