import React from 'react';
import {VmFailureComponent} from "../vm-failure.component";
import {BlockMessageComponent} from "../block-message.component";
import {useResearchEnvironmentContext} from "@vivli/features/data-requests/infrastructure/context";

export const VmStoppingFailedViewComponent = () => {
    const {stopVm} = useResearchEnvironmentContext();
    return (
        <BlockMessageComponent message={'Failed to Stop Research Environment'}>
            <VmFailureComponent title={'Retry Stopping Research Environment'} onClick={stopVm} />
        </BlockMessageComponent>
    )
}
