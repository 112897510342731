﻿import {RestApi} from "@vivli/core/infrastructure/rest";
import {
    IListingRequest,
    IListingRequestApiService,
    IListingRequestSummary
} from "@vivli/features/listing-request/infrastructure/interface";
import {Observable} from "rxjs";
import {ListingRequestStatusEnum} from "@vivli/features/listing-request/infrastructure/enum";

export class ListingRequestApiService extends RestApi implements IListingRequestApiService {

    getListingRequest = (listingRequestId: string): Observable<IListingRequest> => {
        return this.handleGet<IListingRequest>(`/listingRequest/${listingRequestId}` );
    }

    getAllListingRequests = (): Observable<IListingRequest[]> => {
        return this.handleGet<IListingRequest[]>(`/listingRequest/list`);
    }

    getAllListingRequestSummaries = (): Observable<IListingRequestSummary[]> => {
        return this.handleGet<IListingRequestSummary[]>(`/listingRequest/summaries`);
    }

    getListingRequestByStatus = (type: ListingRequestStatusEnum): Observable<IListingRequest[]> => {
        return this.handleGet<IListingRequest[]>(`/listingRequest/statuses/${type}`);
    }

    upsertListingRequest = (listingRequest: IListingRequest): Observable<IListingRequest> => {
        const dataset = listingRequest.datasets[0];
        return this.handlePut<IListingRequest>(`/listingRequest/save`, {listingRequest, dataset});
    }

    updateListingRequestStatus = (listingRequest: IListingRequest, status: ListingRequestStatusEnum,): Observable<IListingRequest> => {
        const trimmedStatus = status.replace(/\s/g, '');
        const dataset = listingRequest.datasets[0];
        return this.handlePut<IListingRequest>(`/listingRequest/changestatus/${listingRequest.id}/${trimmedStatus}`, {listingRequest, dataset, status: trimmedStatus});
    }

    deleteListingRequest = (listingRequestId: string): Observable<void> => {
        return this.handleDelete<void>(`/listingRequest/${listingRequestId}`);
    }
}
