export const workflowStatusDescriptorDetailsIntercept = [
    {
        statusEnumVal: 'Draft',
        statusEnumString: 'Draft',
        displayName: 'Draft',
        allowableTransitionsTo: [
            {
                targetStatus: 'Submitted',
                targetStatusString: 'Submitted',
                vivliRolesAllowedForTransition: [],
                anyUserCanTransition: true,
                possibleWorkflowStatusCategories: ['ApprovedCategory'],
            },
            {
                targetStatus: 'Withdrawn',
                targetStatusString: 'Withdrawn',
                vivliRolesAllowedForTransition: [
                    {
                        vivliRole: 'VivliAdmin',
                        transitionConstraintsForRole: [],
                    },
                    {
                        vivliRole: '',
                        transitionConstraintsForRole: ['Draft'],
                    },
                ],
                anyUserCanTransition: false,
                possibleWorkflowStatusCategories: ['CancelledCategory'],
            },
        ],
        workflowType: 'ListingRequestWorkflow',
        possibleWorkflowStatusCategories: ['InitialCategory'],
        notificationDetail: {
            emailDescriptionText:
                'from <organization> has been reset to the draft status.',
            emailLinkText:
                'to access the study, and see the comments in the chat tab for information about any updates that need to be made.',
            emailSubjectText: 'has been reset to draft',
            notificationRecipientRules: [
                {
                    vivliRole: 'VivliAdmin',
                    recipientDescriptionText: null,
                    recipientLinkText: null,
                    recipientSubjectText: null,
                },
                {
                    vivliRole: 'DatasetOwner',
                    recipientDescriptionText: null,
                    recipientLinkText: null,
                    recipientSubjectText: null,
                },
                {
                    vivliRole: 'RequesterNoRoleYet',
                    recipientDescriptionText: null,
                    recipientLinkText: null,
                    recipientSubjectText: null,
                },
                {
                    vivliRole: null,
                    recipientDescriptionText: null,
                    recipientLinkText: null,
                    recipientSubjectText: null,
                },
            ],
        },
        historyDetail: {
            tokenizedHistoryDescription:
                'Status changed to Draft status (reset).',
            historyActionDescriptor: 'ListingRequestResetToDraft',
        },
    },
    {
        statusEnumVal: 'Submitted',
        statusEnumString: 'Submitted',
        displayName: 'Submitted to Vivli',
        allowableTransitionsTo: [
            {
                targetStatus: 'StudyInCuration',
                targetStatusString: 'StudyInCuration',
                vivliRolesAllowedForTransition: [
                    {
                        vivliRole: 'VivliAdmin',
                        transitionConstraintsForRole: [],
                    },
                ],
                anyUserCanTransition: false,
                possibleWorkflowStatusCategories: ['ApprovedCategory'],
            },
            {
                targetStatus: 'Draft',
                targetStatusString: 'Draft',
                vivliRolesAllowedForTransition: [
                    {
                        vivliRole: 'VivliAdmin',
                        transitionConstraintsForRole: [],
                    },
                ],
                anyUserCanTransition: false,
                possibleWorkflowStatusCategories: ['RejectedCategory'],
            },
            {
                targetStatus: 'Withdrawn',
                targetStatusString: 'Withdrawn',
                vivliRolesAllowedForTransition: [
                    {
                        vivliRole: 'VivliAdmin',
                        transitionConstraintsForRole: [],
                    },
                ],
                anyUserCanTransition: false,
                possibleWorkflowStatusCategories: ['CancelledCategory'],
            },
        ],
        workflowType: 'ListingRequestWorkflow',
        possibleWorkflowStatusCategories: ['ApprovedCategory'],
        notificationDetail: {
            emailDescriptionText:
                'from <organization> is now ready for your Vivli Admin review.',
            emailLinkText: 'to access it',
            emailSubjectText: 'is Ready For Review',
            notificationRecipientRules: [
                {
                    vivliRole: 'VivliAdmin',
                    recipientDescriptionText: null,
                    recipientLinkText: null,
                    recipientSubjectText: null,
                },
            ],
        },
        historyDetail: {
            tokenizedHistoryDescription:
                'Status changed to Submitted to Vivli.',
            historyActionDescriptor: 'ListingRequestSubmitted',
        },
    },
    {
        statusEnumVal: 'StudyInCuration',
        statusEnumString: 'StudyInCuration',
        displayName: 'Study In Curation',
        allowableTransitionsTo: [
            {
                targetStatus: 'Approved',
                targetStatusString: 'Approved',
                vivliRolesAllowedForTransition: [
                    {
                        vivliRole: 'VivliAdmin',
                        transitionConstraintsForRole: [],
                    },
                ],
                anyUserCanTransition: false,
                possibleWorkflowStatusCategories: ['ApprovedCategory'],
            },
            {
                targetStatus: 'Draft',
                targetStatusString: 'Draft',
                vivliRolesAllowedForTransition: [
                    {
                        vivliRole: 'VivliAdmin',
                        transitionConstraintsForRole: [],
                    },
                ],
                anyUserCanTransition: false,
                possibleWorkflowStatusCategories: ['RejectedCategory'],
            },
            {
                targetStatus: 'Withdrawn',
                targetStatusString: 'Withdrawn',
                vivliRolesAllowedForTransition: [
                    {
                        vivliRole: 'VivliAdmin',
                        transitionConstraintsForRole: [],
                    },
                ],
                anyUserCanTransition: false,
                possibleWorkflowStatusCategories: ['CancelledCategory'],
            },
        ],
        workflowType: 'ListingRequestWorkflow',
        possibleWorkflowStatusCategories: ['ApprovedCategory'],
        notificationDetail: {
            emailDescriptionText:
                'from <organization> has been accepted and Vivli will begin preparing this study for posting.',
            emailLinkText: 'to access it.',
            emailSubjectText: 'has been accepted for processing by Vivli',
            notificationRecipientRules: [
                {
                    vivliRole: 'VivliAdmin',
                    recipientDescriptionText: null,
                    recipientLinkText: null,
                    recipientSubjectText: null,
                },
                {
                    vivliRole: 'DatasetOwner',
                    recipientDescriptionText: null,
                    recipientLinkText: null,
                    recipientSubjectText: null,
                },
                {
                    vivliRole: 'RequesterNoRoleYet',
                    recipientDescriptionText: null,
                    recipientLinkText: null,
                    recipientSubjectText: null,
                },
            ],
        },
        historyDetail: {
            tokenizedHistoryDescription: 'Status changed to Study In Curation.',
            historyActionDescriptor: 'ListingRequestStudyInCuration',
        },
    },
    {
        statusEnumVal: 'Approved',
        statusEnumString: 'Approved',
        displayName: 'Approved/Posted',
        allowableTransitionsTo: [
            {
                targetStatus: 'Draft',
                targetStatusString: 'Draft',
                vivliRolesAllowedForTransition: [
                    {
                        vivliRole: 'VivliAdmin',
                        transitionConstraintsForRole: [],
                    },
                ],
                anyUserCanTransition: false,
                possibleWorkflowStatusCategories: ['RejectedCategory'],
            },
            {
                targetStatus: 'Withdrawn',
                targetStatusString: 'Withdrawn',
                vivliRolesAllowedForTransition: [
                    {
                        vivliRole: 'VivliAdmin',
                        transitionConstraintsForRole: [],
                    },
                ],
                anyUserCanTransition: false,
                possibleWorkflowStatusCategories: ['CancelledCategory'],
            },
        ],
        workflowType: 'ListingRequestWorkflow',
        possibleWorkflowStatusCategories: ['ApprovedCategory'],
        notificationDetail: {
            emailDescriptionText:
                'from <organization> is now listed on the Vivli platform. You may now upload the study data to the Vivli Platform.',
            emailLinkText: 'to access it',
            emailSubjectText: 'has been approved and is now listed on Vivli',
            notificationRecipientRules: [
                {
                    vivliRole: 'VivliAdmin',
                    recipientDescriptionText: null,
                    recipientLinkText: null,
                    recipientSubjectText: null,
                },
                {
                    vivliRole: 'DatasetOwner',
                    recipientDescriptionText: null,
                    recipientLinkText: null,
                    recipientSubjectText: null,
                },
                {
                    vivliRole: 'RequesterNoRoleYet',
                    recipientDescriptionText: null,
                    recipientLinkText: null,
                    recipientSubjectText: null,
                },
            ],
        },
        historyDetail: {
            tokenizedHistoryDescription: 'Status changed to Approved/Posted.',
            historyActionDescriptor: 'ListingRequestApproved',
        },
    },
    {
        statusEnumVal: 'Withdrawn',
        statusEnumString: 'Withdrawn',
        displayName: null,
        allowableTransitionsTo: [],
        workflowType: 'ListingRequestWorkflow',
        possibleWorkflowStatusCategories: ['CancelledCategory'],
        notificationDetail: {
            emailDescriptionText: 'from <organization> has been withdrawn.',
            emailLinkText: 'to access it',
            emailSubjectText: 'has been withdrawn',
            notificationRecipientRules: [
                {
                    vivliRole: 'VivliAdmin',
                    recipientDescriptionText: null,
                    recipientLinkText: null,
                    recipientSubjectText: null,
                },
            ],
        },
        historyDetail: {
            tokenizedHistoryDescription: 'Status changed to Withdrawn.',
            historyActionDescriptor: 'ListingRequestWithdrawn',
        },
    },
];
