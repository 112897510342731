import React from 'react';
import {RequestedStudiesGridComponent} from "./requested-studies-grid.component";
import {IDataRequest, IRequestedStudy} from "@vivli/features/data-requests/infrastructure/interface";

interface RequestedStudiesFeatureProps {
    requestedStudies: IRequestedStudy[];
    dataRequest: IDataRequest;
    onChange?: (dataRequest: IDataRequest) => void;
}

export const RequestedStudiesComponent = (
    {
        requestedStudies,
        dataRequest,
        onChange
    }: RequestedStudiesFeatureProps
) => {
    return (
        <RequestedStudiesGridComponent
            studies={requestedStudies}
            dataRequest={dataRequest}
            emptyMessage='No studies found.'
            hideFloatingFilter={true}
            hideHeader={true}
            autoHeight={true}
            onChange={onChange}
        />
    )
}
