import React, {useEffect} from 'react';
import {IDataRequest, IResearchTeamMemberDetail} from "@vivli/features/data-requests/infrastructure/interface";
import {FormProvider} from 'react-hook-form';
import {TeamMemberFormComponent} from "./team-member-form.component";
import {useResearcherSubtitle, useResearchTeamMemberForm} from "@vivli/features/data-requests/infrastructure/hook";
import {TeamMemberModalButtonsComponent} from "./team-member-modal-buttons.component";

const wrapperStyle = {
    padding: '15px',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'rgb(233, 234, 239)',
} as React.CSSProperties;

interface TeamMemberModalComponentProps {
    teamMember: IResearchTeamMemberDetail;
    isLoading?: boolean;
    onCancel: () => void;
    onSave: (teamMember: IResearchTeamMemberDetail) => void;
    dataRequest: IDataRequest;
    userCanEdit: boolean;
}

export const DraftTeamMemberModalComponent = (
    {
        onCancel,
        onSave,
        isLoading,
        teamMember,
        userCanEdit,
        dataRequest
    }: TeamMemberModalComponentProps) => {
    const getSubtitle = useResearcherSubtitle();
    const subTitle = getSubtitle(teamMember);

    const formApi = useResearchTeamMemberForm(teamMember);

    const handleSaveClick = () => {
        formApi.handleSubmit(onSave)();
    }

    useEffect(() => {
        // trigger initial validation
        formApi.trigger();
    }, [])

    return (
        <div style={wrapperStyle}>
            <FormProvider {...formApi}>
                <TeamMemberFormComponent
                    dataRequest={dataRequest}
                    subTitle={subTitle}
                    teamMember={teamMember}
                />
            </FormProvider>

            <TeamMemberModalButtonsComponent
                onCancel={onCancel}
                onSave={handleSaveClick}
                showSaveButton={userCanEdit}
                isLoading={isLoading}
            />
        </div>
    );
}
