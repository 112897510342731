import React, {CSSProperties} from 'react';

const containerStyle: CSSProperties = {
    minHeight: '65px',
    fontWeight: 'bold',
    color: 'rgb(23, 150, 197)',
    fontFamily: 'Open Sans, sans-serif',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    lineHeight: '1.1',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: 'transparent',
    flex: '1'
};

const cellStyle: CSSProperties = { display: 'table-cell', verticalAlign: 'middle' }

export const VmTableHeaderComponent = ({title}) => (
    <div style={containerStyle}>
        <div style={cellStyle}>{title}</div>
    </div>
)
