﻿import {NotificationFormWrapper} from "@vivli/shared/features/notification/infrastructure/wrappers";


interface NotificationFeatureProps {
    topicId: string
}

export const NotificationFeature = ({topicId}: NotificationFeatureProps) => {


    return (<NotificationFormWrapper topicId={topicId}/>)

}
