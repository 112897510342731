﻿import {useParams} from "react-router";
import React, {CSSProperties, useEffect, useState} from "react";
import {useDataRequestAnonymously} from "@vivli/features/data-requests/infrastructure/hook";
import {IDataRequest} from "@vivli/features/data-requests/infrastructure/interface";
import {
    LinkFieldComponent,
    LoadIndicatorCenteredComponent,
    TextAreaReadOnlyFormComponent,
    TextFieldReadOnlyFormComponent
} from "@vivli/shared/components";
import {Color, Size, Styles} from "@vivli/shared/theme";
import {ViewSecondaryAnalysisRowComponent} from "./view-secondary-analysis-row.component";

const outerContainerStyle: CSSProperties = {
    paddingLeft: Size.INSET_SIZE,
    paddingRight: Size.INSET_SIZE,
    flex: 'auto',
    overflowY: 'auto',
    height: '100%',
    backgroundColor: Color.LIGHT_GRAY,
    paddingTop: Size.INSET_SIZE
}
const requestTitleStyle: CSSProperties = {
    ...Styles.FORM_ROW,
    fontSize: Size.FontSize.Larger,
    fontWeight: Size.FontWeight.Bold
}
const textFieldStyle: CSSProperties = {
    flex: '1 1 100%',
    maxWidth: '100%',
    marginBottom: Size.FIELD_SEPARATION
}
const textFieldHalfWidthStyle: CSSProperties = {
    flex: '1 1 50%',
    maxWidth: '49%',
    marginBottom: Size.FIELD_SEPARATION,
}

const getFundingSources = (dataRequest: IDataRequest) => {
    let fundingSourceString = '';
    if (dataRequest.funding.researchGrantsFromGovernments) {
        fundingSourceString += 'Government Research Grants: (' + dataRequest.funding.researchGrantsFromGovernmentsDetail + ')\n';
    }
    if (dataRequest.funding.fundingFromEmployers) {
        fundingSourceString += 'Employment Contract Funding: (' + dataRequest.funding.fundingFromEmployersDetail + ')\n';
    }
    if (dataRequest.funding.additionalContractsAndConsultancies) {
        fundingSourceString += 'Additional Contractor Funding: (' + dataRequest.funding.additionalContractsAndConsultanciesDetail + ')\n';
    }
    if (dataRequest.funding.fundingFromCommercialOrganizations) {
        fundingSourceString += 'Commercial Organization Funding: (' + dataRequest.funding.fundingFromCommercialOrganizationsDetail + ')\n';
    }

    return fundingSourceString;
}

export const ViewSecondaryAnalysisComponent = () => {
    const {dataRequestDoi} = useParams();
    const {dataRequest} = useDataRequestAnonymously(dataRequestDoi);
    const [hasError, setHasError] = useState(false);

    const pi = dataRequest && (dataRequest.researchTeam.leadResearcherTeamMemberDetail.firstName + ' ' + dataRequest.researchTeam.leadResearcherTeamMemberDetail.lastName);
    const piAffiliation = dataRequest && (dataRequest.researchTeam.leadResearcherTeamMemberDetail.employerOrAffiliation);
    const funding = dataRequest && getFundingSources(dataRequest);
    const requestFunding = dataRequest?.funding ? funding : 'Not available';
    const header = dataRequest?.requestTitle ? ('Vivli Digital Object Identifier Information Sheet: Data Request DOI') : '';
    const laySummary = dataRequest?.researchProposal?.laySummary ? dataRequest.researchProposal.laySummary : 'Not available';

    useEffect(() => {
        const interval = setInterval(() => {
            if (!dataRequest) {
                setHasError(true);
            }
        }, 10000)

        return () => {
            clearInterval(interval);
        }
    }, [])

    if (!dataRequest) {
        if (!hasError) {
            return <LoadIndicatorCenteredComponent/>;
        }
        return (
            <div style={requestTitleStyle}>
                The Secondary Analysis specified in the browser cannot be found. Please check the URL you entered.
            </div>
        )
    }

    return (
        <div style={outerContainerStyle} className='scrolly'>
            <div style={requestTitleStyle}>
                {header}
            </div>
            <div style={Styles.FORM_ROW}>
                <TextAreaReadOnlyFormComponent
                    label={'Research Project Name'}
                    style={textFieldStyle}
                    defaultValue={dataRequest?.requestTitle}
                    rows={3}
                />
            </div>

            <div style={Styles.FORM_ROW}>
                <TextAreaReadOnlyFormComponent
                    label={'Research Proposal Title'}
                    style={textFieldStyle}
                    defaultValue={dataRequest?.researchProposal?.title}
                    rows={3}
                />
            </div>
            <div style={Styles.FORM_ROW}>
                <LinkFieldComponent
                    label={'Data Request DOI'}
                    linkContent={dataRequest?.secondaryAnalysisDoi}
                    linkTitle={dataRequest?.secondaryAnalysisDoi}
                    style={textFieldStyle}/>
            </div>

            <div style={Styles.FORM_ROW}>
                <TextFieldReadOnlyFormComponent
                    label={'Principal Investigator'}
                    style={textFieldHalfWidthStyle}
                    defaultValue={pi}
                />
                <TextFieldReadOnlyFormComponent
                    label={'Affiliation'}
                    style={textFieldHalfWidthStyle}
                    defaultValue={piAffiliation}
                />
            </div>
            <div style={Styles.FORM_ROW}>
                <TextAreaReadOnlyFormComponent
                    label={'Summary of Proposed Research'}
                    style={textFieldStyle}
                    defaultValue={laySummary}
                    rows={3}
                />
            </div>
            <div style={Styles.FORM_ROW}>
                <TextAreaReadOnlyFormComponent
                    label={'Funding Sources'}
                    style={textFieldStyle}
                    defaultValue={requestFunding}
                    rows={3}
                />
            </div>

            <div style={Styles.FORM_ROW}>
                <TextFieldReadOnlyFormComponent
                    label={'Managing Conflicts of Interest'}
                    style={textFieldStyle}
                    defaultValue={dataRequest?.researchTeam?.leadResearcherTeamMemberDetail?.conflictsOfInterest}
                />
            </div>
            {dataRequest?.requestedStudies?.map((s, index) => (
                <div key={index} style={Styles.FORM_ROW}>
                    <ViewSecondaryAnalysisRowComponent study={s}/>
                </div>
            ))
            }
            <LinkFieldComponent
                label={'Research Summary and Publications List'}
                linkContent={'https://vivli.org/resources/platform_metrics/'}
                linkTitle={'Once this secondary analysis is complete, click here to view the Research Summary and list of any publications.'}
                style={textFieldStyle}
            />
        </div>
    )
}
