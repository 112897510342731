export enum StudyStatusEnum {
    None = '',
    Draft = 'Draft',
    SubmittedToVivliForPreCheck = 'SubmittedToVivliForPreCheck',
    AwaitingCuration = 'AwaitingCuration',
    CurationInProgress = 'CurationInProgress',
    CurationInternalReview = 'CurationInternalReview',
    AwaitingCurationQaReview = 'AwaitingCurationQaReview',
    CurationQaReviewInProgress = 'CurationQaReviewInProgress',
    CurationRevisionsRequired = 'CurationRevisionsRequired',
    CurationDiscussionsRequired = 'CurationDiscussionsRequired',
    CurationBlocked = 'CurationBlocked',
    SponsorRequiresCurationRevisions = 'SponsorRequiresCurationRevisions',
    SponsorApprovesCuration = 'SponsorApprovesCuration',
    SubmittedToVivliForPostCheck = 'SubmittedToVivliForPostCheck',
    Posted = 'Posted',
    Cancelled = 'Cancelled',
}
