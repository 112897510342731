﻿import React, {useState} from "react";
import {RequestHeaderComponent} from "./request-header.component";
import {useDataRequestAdminPath} from "@vivli/features/data-requests/infrastructure/hook";
import {useDataRequestContext, useDataRequestsService,} from "@vivli/features/data-requests/infrastructure/context";
import {DataRequestStatusEnum} from "@vivli/features/data-requests/infrastructure/enum";
import {useAssignedAppType} from "@vivli/core/infrastructure/hook";
import {AssignedAppTypeEnum} from "@vivli/shared/infrastructure/enum";
import {first} from "rxjs/operators";
import {useModalService} from "@vivli/shared/infrastructure/context";
import {useNavigate} from "react-router-dom";


interface DataRequestAdminHeaderComponentProps {
    onEditTitleClick?: () => void;
    onSaveClick?: () => void;
    onSubmitClick?: () => void;
    formHasErrors?: boolean;
    formIsDirty?: boolean;
    title?: string;
    isVivliAdmin?: boolean;
}

export const DataRequestAdminHeaderComponent = (
    {
        onEditTitleClick,
        onSaveClick,
        onSubmitClick,
        formHasErrors,
        formIsDirty,
        title,
        isVivliAdmin = false
    }: DataRequestAdminHeaderComponentProps
) => {
    const assignedAppType = useAssignedAppType();
    const isAmr = assignedAppType === AssignedAppTypeEnum.Amr;
    const isDataRequestsView = useDataRequestAdminPath();

    const [isLoading, setIsLoading] = useState(false);
    const {
        dataRequest, isAdminEditing, getResetToDraftVisibility, updateDataRequest,
        setIsUpdatingStatus,
        isUpdatingStatus,
    } = useDataRequestContext();
    const modalService = useModalService();
    const navigate = useNavigate();

    const isDraft = dataRequest.status === DataRequestStatusEnum.Draft;
    const isCancelled = dataRequest.status === DataRequestStatusEnum.Cancelled;
    const isEditingOrMyRequest = isAdminEditing || !isDataRequestsView;
    const showResetToDraftButton = getResetToDraftVisibility() && !isEditingOrMyRequest;
    const showSaveButtons = isAdminEditing || (isDraft && !isDataRequestsView);
    const dataRequestsService = useDataRequestsService();
    const showArchiveButton: boolean = isVivliAdmin;

    const showAmrPrintButton = !isDraft && !isAdminEditing;
    const showDefaultPrintButton = (isDataRequestsView && !isAdminEditing) || (!isDataRequestsView && !isDraft);
    const showPrintButton = isAmr ? showAmrPrintButton : showDefaultPrintButton;
    const showAmrCancelButton = (!isEditingOrMyRequest && !isDraft) || !isCancelled;
    const showDefaultCancelButton = !isCancelled && (isDataRequestsView || (!isDataRequestsView && isDraft));
    const showCancelButton = isAmr ? showAmrCancelButton : showDefaultCancelButton;


    const updateLoadingStatus = (status: boolean) => {
        setIsUpdatingStatus(status);
        setIsLoading(status);
    };

    const onClickArchiveButton = () => {
        updateLoadingStatus(true);
        dataRequestsService.setDataRequestStatus(
            dataRequest.id, DataRequestStatusEnum.Archived, "Set to Archive")
            .pipe(first())
            .subscribe(
                (dr) => {
                    updateDataRequest(dr);
                    modalService.message(
                        'Your data request has successfully been reset to Archive status. Click continue to return to Archive overview'
                    );
                    navigate(
                        `/admin/requests/archived`
                    );
                },
                (e) => {
                    modalService.error(e);
                    updateLoadingStatus(false);
                }
            );
    }


    return (
        <RequestHeaderComponent
            onEditTitleClick={onEditTitleClick}
            onSaveClick={onSaveClick}
            onSubmitClick={onSubmitClick}
            formHasErrors={formHasErrors}
            formIsDirty={formIsDirty}
            title={title}
            showTrackingButton={!isEditingOrMyRequest}
            showResetToDraftButton={showResetToDraftButton}
            showCancelButton={showCancelButton}
            showEditDataRequestButton={!isEditingOrMyRequest}
            showSaveButtons={showSaveButtons}
            showEditTitleButton={showSaveButtons}
            showPrintButton={showPrintButton}
            showArchiveButton={showArchiveButton}
            onClickArchiveButton={onClickArchiveButton}
        />
    )
}
