import React from 'react';
import {AdvancedSelectComponent} from "@vivli/shared/components";
import {inputGroupBreakStyle} from "../../search-filters.styles";
import {FilterGroupComponent} from "../../filter-group.component";
import {useCtSearchOptionsContext, useSearchContext} from "@vivli/features/search/infrastructure/context";
import {ISelectOption} from "@vivli/features/search/infrastructure/interface";

export const LocationFilterGroupComponent = () => {
    const { ctFilterValues } = useSearchContext();
    const { locationSelectedOptions, setLocationSelectedOptions} = useCtSearchOptionsContext();

    const handleOnChange = (selectedOptions: ISelectOption[]) => {
        setLocationSelectedOptions(selectedOptions);
    }

    const options = ctFilterValues?.locationsOfStudySites.map(l => ({ value: l.value, label: l.value }))

    return <FilterGroupComponent title={'LOCATION'} innerStyle={{height: "100%"}}>
        <div style={inputGroupBreakStyle}>
            <AdvancedSelectComponent
                isMulti={true}
                isSearchable={true}
                menuPlacement='top'
                onChange={handleOnChange}
                options={options}
                selectedOptions={locationSelectedOptions}
            />
        </div>
    </FilterGroupComponent>
}
