export enum InterventionModelEnum {
    SingleGroup = 'Single Group',
    ParallelGroup = 'ParallelGroup',
    ParallelGroupRandomized = 'ParallelGroupRandomized',
    ParallelGroupNonRandomized = 'ParallelGroupNonRandomized',
    Crossover = 'Crossover',
    CaseCrossOver = 'CaseCrossover',
    CaseControl = 'CaseControl',
    Cohort = 'Cohort',
    CrossSectional = 'CrossSectional',
}
