import {IContextWrapper} from "@vivli/shared/infrastructure/interface";
import {useDefaultApiOptions} from "@vivli/shared/infrastructure/hook";
import React from "react";
import {CitationsApiService} from "@vivli/shared/features/citations/infrastructure/service";
import {ICitationsService} from "@vivli/shared/features/citations/infrastructure/interface";
import {CitationsServiceContext,} from "@vivli/shared/features/citations/infrastructure/context";

/* eslint-disable-next-line */
export const CitationsServiceContextWrapper = ({children}: IContextWrapper) => {

    const apiOptions = useDefaultApiOptions(false);

    const api = new CitationsApiService(
        {
            ...apiOptions,
            errorHandler: null
        }
    );

    const provider: ICitationsService = {
        ...api,
    }

    return<CitationsServiceContext.Provider value={provider}>
            {children}
    </CitationsServiceContext.Provider>
}
