export const countriesIntercept = [
    { name: 'Afghanistan', code: 'AFG', numberSites: 0 },
    {
        name: 'Åland Islands',
        code: 'ALA',
        numberSites: 0,
    },
    { name: 'Albania', code: 'ALB', numberSites: 0 },
    {
        name: 'Algeria',
        code: 'DZA',
        numberSites: 0,
    },
    { name: 'American Samoa', code: 'ASM', numberSites: 0 },
    {
        name: 'Andorra',
        code: 'AND',
        numberSites: 0,
    },
    { name: 'Angola', code: 'AGO', numberSites: 0 },
    {
        name: 'Anguilla',
        code: 'AIA',
        numberSites: 0,
    },
    { name: 'Antarctica', code: 'ATA', numberSites: 0 },
    {
        name: 'Antigua and Barbuda',
        code: 'ATG',
        numberSites: 0,
    },
    { name: 'Argentina', code: 'ARG', numberSites: 0 },
    {
        name: 'Armenia',
        code: 'ARM',
        numberSites: 0,
    },
    { name: 'Aruba', code: 'ABW', numberSites: 0 },
    {
        name: 'Australia',
        code: 'AUS',
        numberSites: 0,
    },
    { name: 'Austria', code: 'AUT', numberSites: 0 },
    {
        name: 'Azerbaijan',
        code: 'AZE',
        numberSites: 0,
    },
    { name: 'Bahamas', code: 'BHS', numberSites: 0 },
    {
        name: 'Bahrain',
        code: 'BHR',
        numberSites: 0,
    },
    { name: 'Bangladesh', code: 'BGD', numberSites: 0 },
    {
        name: 'Barbados',
        code: 'BRB',
        numberSites: 0,
    },
    { name: 'Belarus', code: 'BLR', numberSites: 0 },
    {
        name: 'Belgium',
        code: 'BEL',
        numberSites: 0,
    },
    { name: 'Belize', code: 'BLZ', numberSites: 0 },
    {
        name: 'Benin',
        code: 'BEN',
        numberSites: 0,
    },
    { name: 'Bermuda', code: 'BMU', numberSites: 0 },
    {
        name: 'Bhutan',
        code: 'BTN',
        numberSites: 0,
    },
    {
        name: 'Bolivia (Plurinational State of)',
        code: 'BOL',
        numberSites: 0,
    },
    {
        name: 'Bonaire, Sint Eustatius and Saba',
        code: 'BES',
        numberSites: 0,
    },
    { name: 'Bosnia and Herzegovina', code: 'BIH', numberSites: 0 },
    {
        name: 'Botswana',
        code: 'BWA',
        numberSites: 0,
    },
    { name: 'Bouvet Island', code: 'BVT', numberSites: 0 },
    {
        name: 'Brazil',
        code: 'BRA',
        numberSites: 0,
    },
    { name: 'British Indian Ocean Territory', code: 'IOT', numberSites: 0 },
    {
        name: 'Brunei Darussalam',
        code: 'BRN',
        numberSites: 0,
    },
    { name: 'Bulgaria', code: 'BGR', numberSites: 0 },
    {
        name: 'Burkina Faso',
        code: 'BFA',
        numberSites: 0,
    },
    { name: 'Burundi', code: 'BDI', numberSites: 0 },
    {
        name: 'Cambodia',
        code: 'KHM',
        numberSites: 0,
    },
    { name: 'Cameroon', code: 'CMR', numberSites: 0 },
    {
        name: 'Canada',
        code: 'CAN',
        numberSites: 0,
    },
    { name: 'Cabo Verde', code: 'CPV', numberSites: 0 },
    {
        name: 'Cayman Islands',
        code: 'CYM',
        numberSites: 0,
    },
    { name: 'Central African Republic', code: 'CAF', numberSites: 0 },
    {
        name: 'Chad',
        code: 'TCD',
        numberSites: 0,
    },
    { name: 'Chile', code: 'CHL', numberSites: 0 },
    {
        name: 'China',
        code: 'CHN',
        numberSites: 0,
    },
    { name: 'Christmas Island', code: 'CXR', numberSites: 0 },
    {
        name: 'Cocos (Keeling) Islands',
        code: 'CCK',
        numberSites: 0,
    },
    { name: 'Colombia', code: 'COL', numberSites: 0 },
    {
        name: 'Comoros',
        code: 'COM',
        numberSites: 0,
    },
    { name: 'Congo', code: 'COG', numberSites: 0 },
    {
        name: 'Congo (Democratic Republic of the)',
        code: 'COD',
        numberSites: 0,
    },
    { name: 'Cook Islands', code: 'COK', numberSites: 0 },
    {
        name: 'Costa Rica',
        code: 'CRI',
        numberSites: 0,
    },
    { name: 'Ivory Coast', code: 'CIV', numberSites: 0 },
    {
        name: 'Croatia',
        code: 'HRV',
        numberSites: 0,
    },
    { name: 'Cuba', code: 'CUB', numberSites: 0 },
    {
        name: 'Curaçao',
        code: 'CUW',
        numberSites: 0,
    },
    { name: 'Cyprus', code: 'CYP', numberSites: 0 },
    {
        name: 'Czech Republic',
        code: 'CZE',
        numberSites: 0,
    },
    { name: 'Denmark', code: 'DNK', numberSites: 0 },
    {
        name: 'Djibouti',
        code: 'DJI',
        numberSites: 0,
    },
    { name: 'Dominica', code: 'DMA', numberSites: 0 },
    {
        name: 'Dominican Republic',
        code: 'DOM',
        numberSites: 0,
    },
    { name: 'Ecuador', code: 'ECU', numberSites: 0 },
    {
        name: 'Egypt',
        code: 'EGY',
        numberSites: 0,
    },
    { name: 'El Salvador', code: 'SLV', numberSites: 0 },
    {
        name: 'Equatorial Guinea',
        code: 'GNQ',
        numberSites: 0,
    },
    { name: 'Eritrea', code: 'ERI', numberSites: 0 },
    {
        name: 'Estonia',
        code: 'EST',
        numberSites: 0,
    },
    { name: 'Ethiopia', code: 'ETH', numberSites: 0 },
    {
        name: 'Falkland Islands (Malvinas)',
        code: 'FLK',
        numberSites: 0,
    },
    { name: 'Faroe Islands', code: 'FRO', numberSites: 0 },
    {
        name: 'Fiji',
        code: 'FJI',
        numberSites: 0,
    },
    { name: 'Finland', code: 'FIN', numberSites: 0 },
    {
        name: 'France',
        code: 'FRA',
        numberSites: 0,
    },
    { name: 'French Guiana', code: 'GUF', numberSites: 0 },
    {
        name: 'French Polynesia',
        code: 'PYF',
        numberSites: 0,
    },
    { name: 'French Southern Territories', code: 'ATF', numberSites: 0 },
    {
        name: 'Gabon',
        code: 'GAB',
        numberSites: 0,
    },
    { name: 'Gambia', code: 'GMB', numberSites: 0 },
    {
        name: 'Georgia',
        code: 'GEO',
        numberSites: 0,
    },
    { name: 'Germany', code: 'DEU', numberSites: 0 },
    {
        name: 'Ghana',
        code: 'GHA',
        numberSites: 0,
    },
    { name: 'Gibraltar', code: 'GIB', numberSites: 0 },
    {
        name: 'Greece',
        code: 'GRC',
        numberSites: 0,
    },
    { name: 'Greenland', code: 'GRL', numberSites: 0 },
    {
        name: 'Grenada',
        code: 'GRD',
        numberSites: 0,
    },
    { name: 'Guadeloupe', code: 'GLP', numberSites: 0 },
    {
        name: 'Guam',
        code: 'GUM',
        numberSites: 0,
    },
    { name: 'Guatemala', code: 'GTM', numberSites: 0 },
    {
        name: 'Guernsey',
        code: 'GGY',
        numberSites: 0,
    },
    { name: 'Guinea', code: 'GIN', numberSites: 0 },
    {
        name: 'Guinea-Bissau',
        code: 'GNB',
        numberSites: 0,
    },
    { name: 'Guyana', code: 'GUY', numberSites: 0 },
    {
        name: 'Haiti',
        code: 'HTI',
        numberSites: 0,
    },
    {
        name: 'Heard Island and McDonald Islands',
        code: 'HMD',
        numberSites: 0,
    },
    {
        name: 'Holy See',
        code: 'VAT',
        numberSites: 0,
    },
    { name: 'Honduras', code: 'HND', numberSites: 0 },
    {
        name: 'Hong Kong',
        code: 'HKG',
        numberSites: 0,
    },
    { name: 'Hungary', code: 'HUN', numberSites: 0 },
    {
        name: 'Iceland',
        code: 'ISL',
        numberSites: 0,
    },
    { name: 'India', code: 'IND', numberSites: 0 },
    {
        name: 'Indonesia',
        code: 'IDN',
        numberSites: 0,
    },
    { name: 'Iran (Islamic Republic of)', code: 'IRN', numberSites: 0 },
    {
        name: 'Iraq',
        code: 'IRQ',
        numberSites: 0,
    },
    { name: 'Ireland', code: 'IRL', numberSites: 0 },
    {
        name: 'Isle of Man',
        code: 'IMN',
        numberSites: 0,
    },
    { name: 'Israel', code: 'ISR', numberSites: 0 },
    {
        name: 'Italy',
        code: 'ITA',
        numberSites: 0,
    },
    { name: 'Jamaica', code: 'JAM', numberSites: 0 },
    {
        name: 'Japan',
        code: 'JPN',
        numberSites: 0,
    },
    { name: 'Jersey', code: 'JEY', numberSites: 0 },
    {
        name: 'Jordan',
        code: 'JOR',
        numberSites: 0,
    },
    { name: 'Kazakhstan', code: 'KAZ', numberSites: 0 },
    {
        name: 'Kenya',
        code: 'KEN',
        numberSites: 0,
    },
    { name: 'Kiribati', code: 'KIR', numberSites: 0 },
    {
        name: "Korea (Democratic People's Republic of)",
        code: 'PRK',
        numberSites: 0,
    },
    { name: 'Korea (Republic of)', code: 'KOR', numberSites: 0 },
    {
        name: 'Kuwait',
        code: 'KWT',
        numberSites: 0,
    },
    { name: 'Kyrgyzstan', code: 'KGZ', numberSites: 0 },
    {
        name: "Lao People's Democratic Republic",
        code: 'LAO',
        numberSites: 0,
    },
    { name: 'Latvia', code: 'LVA', numberSites: 0 },
    {
        name: 'Lebanon',
        code: 'LBN',
        numberSites: 0,
    },
    { name: 'Lesotho', code: 'LSO', numberSites: 0 },
    {
        name: 'Liberia',
        code: 'LBR',
        numberSites: 0,
    },
    { name: 'Libya', code: 'LBY', numberSites: 0 },
    {
        name: 'Liechtenstein',
        code: 'LIE',
        numberSites: 0,
    },
    { name: 'Lithuania', code: 'LTU', numberSites: 0 },
    {
        name: 'Luxembourg',
        code: 'LUX',
        numberSites: 0,
    },
    { name: 'Macao', code: 'MAC', numberSites: 0 },
    {
        name: 'Macedonia (the former Yugoslav Republic of)',
        code: 'MKD',
        numberSites: 0,
    },
    { name: 'Madagascar', code: 'MDG', numberSites: 0 },
    {
        name: 'Malawi',
        code: 'MWI',
        numberSites: 0,
    },
    { name: 'Malaysia', code: 'MYS', numberSites: 0 },
    {
        name: 'Maldives',
        code: 'MDV',
        numberSites: 0,
    },
    { name: 'Mali', code: 'MLI', numberSites: 0 },
    {
        name: 'Malta',
        code: 'MLT',
        numberSites: 0,
    },
    { name: 'Marshall Islands', code: 'MHL', numberSites: 0 },
    {
        name: 'Martinique',
        code: 'MTQ',
        numberSites: 0,
    },
    { name: 'Mauritania', code: 'MRT', numberSites: 0 },
    {
        name: 'Mauritius',
        code: 'MUS',
        numberSites: 0,
    },
    { name: 'Mayotte', code: 'MYT', numberSites: 0 },
    {
        name: 'Mexico',
        code: 'MEX',
        numberSites: 0,
    },
    {
        name: 'Micronesia (Federated States of)',
        code: 'FSM',
        numberSites: 0,
    },
    { name: 'Moldova (Republic of)', code: 'MDA', numberSites: 0 },
    {
        name: 'Monaco',
        code: 'MCO',
        numberSites: 0,
    },
    { name: 'Mongolia', code: 'MNG', numberSites: 0 },
    {
        name: 'Montenegro',
        code: 'MNE',
        numberSites: 0,
    },
    { name: 'Montserrat', code: 'MSR', numberSites: 0 },
    {
        name: 'Morocco',
        code: 'MAR',
        numberSites: 0,
    },
    { name: 'Mozambique', code: 'MOZ', numberSites: 0 },
    {
        name: 'Myanmar',
        code: 'MMR',
        numberSites: 0,
    },
    { name: 'Namibia', code: 'NAM', numberSites: 0 },
    {
        name: 'Nauru',
        code: 'NRU',
        numberSites: 0,
    },
    { name: 'Nepal', code: 'NPL', numberSites: 0 },
    {
        name: 'Netherlands',
        code: 'NLD',
        numberSites: 0,
    },
    { name: 'New Caledonia', code: 'NCL', numberSites: 0 },
    {
        name: 'New Zealand',
        code: 'NZL',
        numberSites: 0,
    },
    { name: 'Nicaragua', code: 'NIC', numberSites: 0 },
    {
        name: 'Niger',
        code: 'NER',
        numberSites: 0,
    },
    { name: 'Nigeria', code: 'NGA', numberSites: 0 },
    {
        name: 'Niue',
        code: 'NIU',
        numberSites: 0,
    },
    { name: 'Norfolk Island', code: 'NFK', numberSites: 0 },
    {
        name: 'Northern Mariana Islands',
        code: 'MNP',
        numberSites: 0,
    },
    { name: 'Norway', code: 'NOR', numberSites: 0 },
    {
        name: 'Oman',
        code: 'OMN',
        numberSites: 0,
    },
    { name: 'Pakistan', code: 'PAK', numberSites: 0 },
    {
        name: 'Palau',
        code: 'PLW',
        numberSites: 0,
    },
    { name: 'Palestine, State of', code: 'PSE', numberSites: 0 },
    {
        name: 'Panama',
        code: 'PAN',
        numberSites: 0,
    },
    { name: 'Papua New Guinea', code: 'PNG', numberSites: 0 },
    {
        name: 'Paraguay',
        code: 'PRY',
        numberSites: 0,
    },
    { name: 'Peru', code: 'PER', numberSites: 0 },
    {
        name: 'Philippines',
        code: 'PHL',
        numberSites: 0,
    },
    { name: 'Pitcairn', code: 'PCN', numberSites: 0 },
    {
        name: 'Poland',
        code: 'POL',
        numberSites: 0,
    },
    { name: 'Portugal', code: 'PRT', numberSites: 0 },
    {
        name: 'Puerto Rico',
        code: 'PRI',
        numberSites: 0,
    },
    { name: 'Qatar', code: 'QAT', numberSites: 0 },
    {
        name: 'Réunion',
        code: 'REU',
        numberSites: 0,
    },
    { name: 'Romania', code: 'ROU', numberSites: 0 },
    {
        name: 'Russian Federation',
        code: 'RUS',
        numberSites: 0,
    },
    { name: 'Rwanda', code: 'RWA', numberSites: 0 },
    {
        name: 'Saint Barthélemy',
        code: 'BLM',
        numberSites: 0,
    },
    {
        name: 'Saint Helena, Ascension and Tristan da Cunha',
        code: 'SHN',
        numberSites: 0,
    },
    { name: 'Saint Kitts and Nevis', code: 'KNA', numberSites: 0 },
    {
        name: 'Saint Lucia',
        code: 'LCA',
        numberSites: 0,
    },
    { name: 'Saint Martin (French part)', code: 'MAF', numberSites: 0 },
    {
        name: 'Saint Pierre and Miquelon',
        code: 'SPM',
        numberSites: 0,
    },
    {
        name: 'Saint Vincent and the Grenadines',
        code: 'VCT',
        numberSites: 0,
    },
    {
        name: 'Samoa',
        code: 'WSM',
        numberSites: 0,
    },
    { name: 'San Marino', code: 'SMR', numberSites: 0 },
    {
        name: 'Sao Tome and Principe',
        code: 'STP',
        numberSites: 0,
    },
    { name: 'Saudi Arabia', code: 'SAU', numberSites: 0 },
    {
        name: 'Senegal',
        code: 'SEN',
        numberSites: 0,
    },
    { name: 'Serbia', code: 'SRB', numberSites: 0 },
    {
        name: 'Seychelles',
        code: 'SYC',
        numberSites: 0,
    },
    { name: 'Sierra Leone', code: 'SLE', numberSites: 0 },
    {
        name: 'Singapore',
        code: 'SGP',
        numberSites: 0,
    },
    { name: 'Sint Maarten (Dutch part)', code: 'SXM', numberSites: 0 },
    {
        name: 'Slovakia',
        code: 'SVK',
        numberSites: 0,
    },
    { name: 'Slovenia', code: 'SVN', numberSites: 0 },
    {
        name: 'Solomon Islands',
        code: 'SLB',
        numberSites: 0,
    },
    { name: 'Somalia', code: 'SOM', numberSites: 0 },
    {
        name: 'South Africa',
        code: 'ZAF',
        numberSites: 0,
    },
    {
        name: 'South Georgia and the South Sandwich Islands',
        code: 'SGS',
        numberSites: 0,
    },
    { name: 'South Sudan', code: 'SSD', numberSites: 0 },
    {
        name: 'Spain',
        code: 'ESP',
        numberSites: 0,
    },
    { name: 'Sri Lanka', code: 'LKA', numberSites: 0 },
    {
        name: 'Sudan',
        code: 'SDN',
        numberSites: 0,
    },
    { name: 'Suriname', code: 'SUR', numberSites: 0 },
    {
        name: 'Svalbard and Jan Mayen',
        code: 'SJM',
        numberSites: 0,
    },
    { name: 'Swaziland', code: 'SWZ', numberSites: 0 },
    {
        name: 'Sweden',
        code: 'SWE',
        numberSites: 0,
    },
    { name: 'Switzerland', code: 'CHE', numberSites: 0 },
    {
        name: 'Syrian Arab Republic',
        code: 'SYR',
        numberSites: 0,
    },
    { name: 'Taiwan, Province of China', code: 'TWN', numberSites: 0 },
    {
        name: 'Tajikistan',
        code: 'TJK',
        numberSites: 0,
    },
    { name: 'Tanzania, United Republic of', code: 'TZA', numberSites: 0 },
    {
        name: 'Thailand',
        code: 'THA',
        numberSites: 0,
    },
    { name: 'Timor-Leste', code: 'TLS', numberSites: 0 },
    {
        name: 'Togo',
        code: 'TGO',
        numberSites: 0,
    },
    { name: 'Tokelau', code: 'TKL', numberSites: 0 },
    {
        name: 'Tonga',
        code: 'TON',
        numberSites: 0,
    },
    { name: 'Trinidad and Tobago', code: 'TTO', numberSites: 0 },
    {
        name: 'Tunisia',
        code: 'TUN',
        numberSites: 0,
    },
    { name: 'Turkey', code: 'TUR', numberSites: 0 },
    {
        name: 'Turkmenistan',
        code: 'TKM',
        numberSites: 0,
    },
    { name: 'Turks and Caicos Islands', code: 'TCA', numberSites: 0 },
    {
        name: 'Tuvalu',
        code: 'TUV',
        numberSites: 0,
    },
    { name: 'Uganda', code: 'UGA', numberSites: 0 },
    {
        name: 'Ukraine',
        code: 'UKR',
        numberSites: 0,
    },
    {
        name: 'United Arab Emirates',
        code: 'ARE',
        numberSites: 0,
    },
    {
        name: 'United Kingdom of Great Britain and Northern Ireland',
        code: 'GBR',
        numberSites: 0,
    },
    {
        name: 'United States of America',
        code: 'USA',
        numberSites: 0,
    },
    {
        name: 'United States Minor Outlying Islands',
        code: 'UMI',
        numberSites: 0,
    },
    {
        name: 'Uruguay',
        code: 'URY',
        numberSites: 0,
    },
    { name: 'Uzbekistan', code: 'UZB', numberSites: 0 },
    {
        name: 'Vanuatu',
        code: 'VUT',
        numberSites: 0,
    },
    {
        name: 'Venezuela (Bolivarian Republic of)',
        code: 'VEN',
        numberSites: 0,
    },
    {
        name: 'Viet Nam',
        code: 'VNM',
        numberSites: 0,
    },
    { name: 'Virgin Islands (British)', code: 'VGB', numberSites: 0 },
    {
        name: 'Virgin Islands (U.S.)',
        code: 'VIR',
        numberSites: 0,
    },
    { name: 'Wallis and Futuna', code: 'WLF', numberSites: 0 },
    {
        name: 'Western Sahara',
        code: 'ESH',
        numberSites: 0,
    },
    { name: 'Yemen', code: 'YEM', numberSites: 0 },
    {
        name: 'Zambia',
        code: 'ZMB',
        numberSites: 0,
    },
    { name: 'Zimbabwe', code: 'ZWE', numberSites: 0 },
];
