﻿import React from "react";
import {useActiveUser} from "@vivli/core/infrastructure/context";
import {DataRequestAdminHeaderComponent} from "./data-request-admin-header.component";
import {DataRequestHeaderComponent} from "./data-request-header.component";


interface RequestHeaderContainerComponentProps {
    onEditTitleClick?: () => void;
    onSaveClick?: () => void;
    onSubmitClick?: () => void;
    formHasErrors?: boolean;
    formIsDirty?: boolean;
    title?: string;
}
export const RequestHeaderContainerComponent = (
    {
        onEditTitleClick,
        onSaveClick,
        onSubmitClick,
        formHasErrors,
        formIsDirty,
        title
    }: RequestHeaderContainerComponentProps
) => {
    const activeUser = useActiveUser();
    const isOrgAdmin =
        !activeUser.isVivliAdmin &&
        activeUser?.orgMemberships?.find((om) => om.isOrgAdmin) !== undefined;

    //For vivli admin users of both Clinical and AMR
    if (activeUser.isVivliAdmin || isOrgAdmin ){
        return (
            <DataRequestAdminHeaderComponent
                onEditTitleClick={onEditTitleClick}
                onSaveClick={onSaveClick}
                onSubmitClick={onSubmitClick}
                formHasErrors={formHasErrors}
                formIsDirty={formIsDirty}
                title={title}
                isVivliAdmin={true}
            />
        )
    }

    //For non-admin users of both Clinical and AMR
    return (
        <DataRequestHeaderComponent
            onEditTitleClick={onEditTitleClick}
            onSaveClick={onSaveClick}
            onSubmitClick={onSubmitClick}
            formHasErrors={formHasErrors}
            formIsDirty={formIsDirty}
            title={title}
        />
    )
}
