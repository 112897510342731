import React, {CSSProperties} from 'react';
import {AdminHeaderButtonComponent} from "@vivli/shared/components";
import {useDataRequestContext} from "@vivli/features/data-requests/infrastructure/context";
import {useModalService} from "@vivli/shared/infrastructure/context";
import {useDataRequestPermissions} from "@vivli/features/data-requests/infrastructure/hook";
import {DataRequestViewComponent} from "../../data-request-view/data-request-view.component";

export const PrintButtonComponent = () => {
    const {dataRequest, organizations} = useDataRequestContext();
    const modalService = useModalService();
    const {shouldHideReviewers} = useDataRequestPermissions();

    const printStyle: CSSProperties ={
        textAlign: "left"
    }

    const handlePrintClick = () => {
        // TODO: convert data request view to new form
        const modalId = modalService.custom(<DataRequestViewComponent
            onReady={() => {
                window.print();
                modalService.dismiss(modalId);
            }}
            dataRequest={dataRequest as any}
            hideReviewers={shouldHideReviewers(dataRequest, organizations, true)}
            isPrintModal={true}/>,
        {style: printStyle, fullScreen: true}
            )
    }

    return (
        <AdminHeaderButtonComponent
            title={'Print'}
            style={{marginLeft: 10}}
            onClick={handlePrintClick}
        />
    )
}
