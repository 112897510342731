import {IVirtualMachine} from "@vivli/features/virtual-machine/infrastructure/interface";
import {VmStatusEnum} from "@vivli/features/virtual-machine/infrastructure/enum";
import {LoggerService, PubSubService} from "@vivli/shared/infrastructure/service";
import {useConfigService} from "@vivli/core/infrastructure/context";
import {useRef} from "react";



export function useVmPubSubHook(virtualMachine:IVirtualMachine , updateVmStatus: (message: string)=>void)
{
    const config = useConfigService();
    const pubSubService = useRef<PubSubService>(new PubSubService(config.environmentName, config.apiUri));
    const isSubscribed = useRef<boolean>(false);
    const isSubscribedToMessages = useRef<boolean>(false);
    const pollRef = useRef<number>(null);

    const initiatePubSubIfNeeded = (authorizedUserExists: boolean, callback: (message: string)=>void = null) => {
        if ( virtualMachine && (
            virtualMachine.status === VmStatusEnum.Provisioning ||
            virtualMachine.status === VmStatusEnum.DeProvisioning ||
            virtualMachine.status === VmStatusEnum.Starting ||
            virtualMachine.status === VmStatusEnum.Stopping ||
            virtualMachine.status === VmStatusEnum.AddingDataPackages ||
            !authorizedUserExists)
        ) {
            subscribeToVmStatusChanged();
            if(callback) {
                subscribeToVmMessages(callback);
            }

        }
    };

    const startVmPolling = () => {
        if (pollRef.current !== null) {
            return;
        }

        pollRef.current = window.setInterval(() => {
            updateVmStatus('Polling');
        }, 15000);
    };
    const subscribeToVmStatusChanged = () => {
        if (!isSubscribed.current && virtualMachine) {
            pubSubService.current?.SubscribeToEvent(`VM:${virtualMachine.id}`, 'VMStatusChanged', updateVmStatus);
            isSubscribed.current = true;
            //unsubscribe from polling, if it was started
            if (pollRef.current !== null) {
                clearInterval(pollRef.current);
                pollRef.current = null;
            }
            LoggerService.info(`Subscribed to VMStatusChanged for VM:${virtualMachine.id}`);
        }
    };

    const subscribeToVmMessages = ( callback: (message: string) => void) => {

        if (!isSubscribedToMessages.current && virtualMachine) {
            pubSubService.current?.SubscribeToEvent(`VM:${virtualMachine.id}`, 'VmProcessingMessage', callback);
            isSubscribedToMessages.current = true;
        } else if (!isSubscribedToMessages.current && !virtualMachine) {
            //if vm is not defined yet - try again in 15 seconds
            window.setTimeout(() => {
                subscribeToVmMessages(callback);
            }, 15100);
        }
    };

    const closeConnections = () => {

        if (pollRef.current !== null) {
            clearInterval(pollRef.current);
        }

        isSubscribedToMessages.current = false;
        isSubscribed.current = false;
        pubSubService.current?.CloseConnection();
    }

    const CloseChannel = () => {
        isSubscribedToMessages.current = false;
        isSubscribed.current = false;
        pubSubService.current?.UnsubscribeFromChannel(`VM:${virtualMachine?.id}`);
    }

    return {
        initiatePubSubIfNeeded,
        startVmPolling,
        subscribeToVmStatusChanged,
        subscribeToVmMessages,
        closeConnections,
        CloseChannel
    }
}
