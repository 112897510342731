﻿import {
    IContextWrapper,
    IVivliConfigurationApiService,
} from '@vivli/shared/infrastructure/interface';
import { useDefaultApiOptions } from '@vivli/shared/infrastructure/hook';
import { VivliConfigurationApiService } from '@vivli/core/infrastructure/service';
import React from 'react';
import { VivliConfigurationServiceContext } from '@vivli/core/infrastructure/context';


export const VivliConfigurationServiceContextWrapper = ({
    children,
}: IContextWrapper) => {
    const apiOptions = useDefaultApiOptions();
    const api = new VivliConfigurationApiService(apiOptions);


    const provider: IVivliConfigurationApiService = {
        ...api
    };

    return (
        <VivliConfigurationServiceContext.Provider value={provider}>
            {children}
        </VivliConfigurationServiceContext.Provider>
    );
};
