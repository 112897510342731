import React, { CSSProperties, useEffect, useRef } from 'react';
import { Outlet, Route, Routes } from 'react-router-dom';
import { ChatRollupFeature } from '@vivli/features/chat/feature';
import { ResultRequestsFeature } from '@vivli/features/results-request/feature';
import { LoadIndicatorCenteredComponent } from '@vivli/shared/components';
import { DataRequestSignedDocumentsComponent } from './data-request-signed-documents.component';
import { DataRequestStudiesComponent } from './data-request-studies.component';
import { DataRequestResearchEnvironmentComponent } from './data-request-research-environment.component';
import { DataRequestAttachmentsComponent } from './data-request-attachments.component';
import { DataRequestHistoryComponent } from './data-request-history/data-request-history.component';
import { DataRequestPrintViewComponent } from './data-request-print-view.component';
import { DataRequestResearchTeamComponent } from './data-request-research-team.component';
import { DataRequestStatusUpdateComponent } from '../data-request-status-update.component';
import { useDataRequestContext } from '@vivli/features/data-requests/infrastructure/context';
import { ReportSafetyConcernComponent } from './report-safety-concern/report-safety-concern.component';
import { useActiveUser } from '@vivli/core/infrastructure/context';
import { useModalService } from '@vivli/shared/infrastructure/context';
import { DataRequestStatusEnum } from '@vivli/features/data-requests/infrastructure/enum';
import { DisclosuresContainerComponent } from './public-disclosures/disclosures-container.component';
import {useDataRequestRoutes} from "@vivli/features/data-requests/infrastructure/hook";

const routesContainerStyle: CSSProperties = {
    marginTop: 20,
    overflowY: 'auto',
    overflowX: 'hidden',
    display: 'flex',
    flexGrow: 1,
    paddingRight: '5px',
    position: 'relative',
    flexDirection: 'column',
};

export const DataRequestRoutesComponent = () => {
    const { dataRequest, showResearchResults } = useDataRequestContext();
    const activeUser = useActiveUser();
    const modalService = useModalService();
    const messageShownRef = useRef(false);
    const {isFromEmailLink} = useDataRequestRoutes(dataRequest.id);

    useEffect(() => {
        if (!dataRequest || !activeUser || messageShownRef.current) {
            return;
        }

        if (dataRequest.userId === activeUser.userId && dataRequest.status === DataRequestStatusEnum.Draft && !isFromEmailLink) {
            modalService.message(
                'You are viewing your own draft data request as an administrator.  To edit this request, view your request through the link on the site header.'
            );
            messageShownRef.current = true;
        }
    }, [dataRequest, activeUser]);

    if (!dataRequest) {
        return <LoadIndicatorCenteredComponent />;
    }

    return (
        <div style={routesContainerStyle} className="scrolly">
            <Routes>
                <Route path="ResearchTeam" element={<DataRequestResearchTeamComponent />} />

                <Route path="Studies" element={<DataRequestStudiesComponent />} />

                <Route path="RequestHistory" element={<DataRequestHistoryComponent />} />

                <Route path="Attachments" element={<DataRequestAttachmentsComponent />} />

                <Route path="RequestDetailsPrintView" element={<DataRequestPrintViewComponent />} />

                <Route path="PublicDisclosures" element={<DisclosuresContainerComponent />} />

                <Route path="ResearchEnvironment" element={<DataRequestResearchEnvironmentComponent />} />

                <Route path="SignedAgreements" element={<DataRequestSignedDocumentsComponent />} />

                {showResearchResults && (
                    <Route path="ResearchResults/:resultsRequestId?" element={<ResultRequestsFeature requestId={dataRequest.id} />} />
                )}

                <Route path="Chat" element={<ChatRollupFeature dataRequestId={dataRequest.id} channelId={dataRequest.chatChannelId} />} />

                <Route path="SafetyConcerns" element={<ReportSafetyConcernComponent />} />

                <Route path="StatusUpdate" element={<DataRequestStatusUpdateComponent />} />

                //default, for use from email links only
                <Route path="" element={<ChatRollupFeature dataRequestId={dataRequest.id}
                                                           channelId={dataRequest.chatChannelId}/>}/>
            </Routes>
            <Outlet />
        </div>
    );
};
