﻿import React, {CSSProperties} from "react";
import {Size} from "@vivli/shared/theme";
import {IStudy} from "@vivli/features/studies/infrastructure/interface";
import {FieldHeaderComponent, LoadIndicatorCenteredComponent} from "@vivli/shared/components";

const containerStyle: CSSProperties = {
    backgroundColor: 'white',
    paddingBottom: Size.PADDING,
    paddingLeft: Size.PADDING,
    paddingRight: Size.PADDING,
    paddingTop: Size.PADDING,
    borderRadius: Size.BORDER_RADIUS,
    marginBottom: 20
}

const leftPaddingStyle: CSSProperties = {
    paddingLeft: Size.PADDING
}

interface StudySampleComponentProps {
    sampleStudy: IStudy
}
export const StudySampleComponent = (
    {
        sampleStudy
    }: StudySampleComponentProps
) => {

    if (!sampleStudy){
        return <LoadIndicatorCenteredComponent />
    }

    return (
        <div style={containerStyle}>
            <div>
                <strong>The following information was found for {sampleStudy.nctId} on ClinicalTrials.gov.  Please verify this is the study you intended before continuing...</strong>
                <br />
            </div>
            <FieldHeaderComponent title='Title' />
            <div style={leftPaddingStyle}>
                {sampleStudy.studyTitle}
            </div>
            <FieldHeaderComponent title='Sponsor' />
            <div style={leftPaddingStyle}>
                {sampleStudy.leadSponsor?.agency}
            </div>
        </div>
    )
}
