﻿export enum CurrentDataRequestMetricsEnum {
    requestsInProgress = 'Requests in Progress',
    returnedToDraft = 'Returned to Draft',
    formCheck = 'Form Check',
    dataContributorReview = 'Data Contributor Review',
    irpFinalApproval = 'IRP/Final Approval',
    duaExecution = 'DUA Execution',
    dataPackageUpload = 'Data Package Upload',
    analysisAndPublication = 'Analysis and Publication',
}

export enum TotalDataRequestMetricsEnum {
    submitted = 'Submitted',
    approved = 'Approved',
    revisionRequested = 'Revision Requested',
    notApproved = 'Not Approved',
    withdrawn = 'Withdrawn',
    published = 'Published',
    studiesAccessedInResearchEnvironment = 'Studies Accessed in Research Environment',
    studiesDownloaded = 'Studies Downloaded',
    participantsInAccessedStudies = 'Participants in Accessed Studies',
}

export enum TimeIntervalMetricsEnum {
    formCheck = 'Form Check',
    dataContributorReview = 'Data Contributor Review',
    irpApproval = 'IRP Approval',
    duaExecution = 'DUA Execution',
    dataPackageUpload = 'Data Package Upload',
    analysisAndPublication = 'Analysis and Publication',
    timeFromSubmissionToPublication = 'Overall Process to Publication',
}

export enum StatusDataPointEnum {
    orgAverage = 'orgAverage',
    minimum = 'minimum',
    maximum = 'maximum',
    allDataContributorsAverage = 'allDataContributorsAverage',
}
