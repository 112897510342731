export const accessibleRightsIntercept = [
    {
        rightsHostName: 'vivli',
        rightsHostUrl: 'https://vivli.org',
        rightsText:
            'Study data may be accessed by completing a request form at https://vivli.org. It will then be reviewed according to the data contributor’s governance policy, see https://vivli.org/members/ourmembers/ for Vivli member’s review process. If approved, use of the data is governed by the data use agreement at https://vivli.org/resources/vivli-data-use-agreement/',
        active: true,
    },
    {
        rightsHostName: 'amr',
        rightsHostUrl: 'https://amr.vivli.org',
        rightsText:
            'AMR surveillance data may be accessed by completing a request form at https://amr.vivli.org. More information about the available datasets and access process can be found at https://amr.vivli.org/resources.',
        active: true,
    },
];
